import React, { useState, useRef, useEffect } from "react";
import { TextField, Button, Popover, Paper, IconButton } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import CloseIcon from "@mui/icons-material/Close";

const CalendarInput = ({ getDashboardData, disabled }) => {
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const today = new Date();

  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  
  const [selectedDate, setSelectedDate] = useState({
    startDate: startOfMonth,
    endDate: endOfMonth,
    key: "selection",
  });
  
  const anchorRef = useRef(null);

  const handleDateRangeChange = (item) => {
    setSelectedDate({
      startDate: item.selection.startDate,
      endDate: item.selection.endDate,
      key: "selection",
    });
  };

  const openDateRangePicker = () => {
    setIsDateRangePickerOpen(true);
  };

  const closeDateRangePicker = () => {
    setIsDateRangePickerOpen(false);
  };

  const handleApply = () => {
    // Do something with the selectedDate, e.g., close the Popover
    getDashboardData(selectedDate.startDate, selectedDate.endDate);
    setIsDateRangePickerOpen(false);
  };

  useEffect(() => {
    getDashboardData(selectedDate.startDate, selectedDate.endDate);
  }, []);

  const id = isDateRangePickerOpen ? "popover" : undefined;

  return (
    <div>
      <TextField
        ref={anchorRef}
        label="Select Date Range"
        value={`${selectedDate.startDate.toLocaleDateString(
          "en-IN"
        )} - ${selectedDate.endDate.toLocaleDateString("en-IN")}`}
        InputProps={{ readOnly: true }}
        onClick={openDateRangePicker}
        fullWidth
        margin="normal"
        variant="outlined"
        disabled={disabled}
      />

      <Popover
        id={id}
        open={isDateRangePickerOpen}
        anchorEl={anchorRef.current}
        onClose={closeDateRangePicker}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper style={{ padding: "16px", position: "relative" }}>
          <div className="d-flex justify-content-end">
            <IconButton
              edge="end"
              color="inherit"
              onClick={closeDateRangePicker}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="range-picker-dashboard">
            <DateRangePicker
              onChange={handleDateRangeChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              direction="horizontal"
              ranges={[selectedDate]}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <Button
              sx={{
                "&:hover": {
                  lineHeight: 1.75,
                  color: "white",
                },
              }}
              variant="contained"
              color="primary"
              onClick={handleApply}
            >
              Apply
            </Button>
          </div>
        </Paper>
      </Popover>
    </div>
  );
};

export default CalendarInput;
