import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Input from "../../common/Input";
import { Formik, useFormik } from "formik";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import * as Yup from "yup";
import SelectBox from "../../common/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createService,
  getAllService,
  updateService,
} from "../../redux/action/ServiceAction";
import MuiPhoneNumber from "material-ui-phone-number";
import parsePhoneNumberFromString from "libphonenumber-js";
import { snackBar } from "../../redux/common";

const statusOptions = [
  { value: "true", label: "Active" },
  { value: "false", label: "Inactive" },
];

const CreateServices = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    _id,
    service_name,
    service_price,
    contact_number,
    description,
    is_active,
    service_images,
  } = location?.state?.serviceData;
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(service_images || []);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [imageAllowMsg, setImageAllowMsg] = useState();
  const [selectedImageError, setSelectedImageError] = useState(false);
  
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImageError(false);

    const allowedTypes = [
     "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "image/avif",
      "image/avchd",
      "image/flv",
      "image/f4v",
      "image/swf",
      "mp4",
      "webm",
      "avi",
      "mov",
      "mkv",
      "wmv",
      "video/mp4", // MIME type for MP4 videos
      "video/webm", // MIME type for WebM videos
      "video/avi", // MIME type for AVI videos
      "video/quicktime", // MIME type for MOV videos
      "video/x-matroska", // MIME type for MKV videos
      // Add more video types as needed
    ];

    const invalidFiles = files.filter((file) => {
      const fileType = file.type.split("/")[1];
      return !allowedTypes.includes(fileType);
    });

    if (invalidFiles.length > 0) {
      setImageAllowMsg(
        `Selected ${
          invalidFiles.length === 1
            ? `${invalidFiles.length} file is`
            : `${invalidFiles.length} files are`
        } not image type, Please selete only image type file`
      );
      return;
    }

    if (selectedImage && selectedImage.length) {
      const totalImages = selectedImage.length + files.length;
      if (totalImages > 10) {
        const remainingSlots = 10 - selectedImage.length;
        const filesToAdd = files.slice(0, remainingSlots);
        setSelectedImage([...selectedImage, ...filesToAdd]);
        setImageAllowMsg(
          `You can only upload a maximum of 10 images. ${
            files.length - remainingSlots
          } image(s) were not added.`
        );
      } else {
        setSelectedImage([...selectedImage, ...files]);
        setImageAllowMsg("");
      }
    } else {
      if (files.length > 10) {
        setSelectedImage(files.slice(0, 10));
        setImageAllowMsg(
          `You can only upload a maximum of 10 images. ${
            files.length - 10
          } image(s) were not added.`
        );
      } else {
        setSelectedImage(files);
        setImageAllowMsg("");
      }
    }
    e.target.value = ""
  };

  const { createLoading, updateLoading } = useSelector(
    (state) => state.ServiceReducer
  );
  const [addedDiscritption, setAddedDiscritption] = useState(
    description && description.length
      ? description
      : [{ title: "", points: [""] }]
  );

  const removeSelectedImage = (indexToRemove) => {
    let imagesArray = [];
    if (selectedImage instanceof FileList) {
      imagesArray = Array.from(selectedImage);
    } else {
      imagesArray = Array.from(selectedImage);
    }

    const remainingImg = imagesArray.filter((item, i) => {
      return i !== indexToRemove;
    });
    setSelectedImage(remainingImg);
    if (remainingImg && remainingImg.length <= 0) {
      formik.setFieldValue("service_images", "");
    }

    setImageAllowMsg("");
  };

  const changeSelectedImage = (e, changeIndex) => {
    const files = e.target.files;
    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "avif",
      "avchd",
      "flv",
      "f4v",
      "swf",
      "mp4",
      "webm",
      "avi",
      "mov",
      "mkv",
      "wmv",
      "video/mp4", // MIME type for MP4 videos
      "video/webm", // MIME type for WebM videos
      "video/avi", // MIME type for AVI videos
      "video/quicktime", // MIME type for MOV videos
      "video/x-matroska", // MIME type for MKV videos
      // Add more video types as needed
    ];

    const file = files[0];
    const fileType = file.type.split("/").pop().toLowerCase();

    if (allowedTypes.includes(fileType)) {
      const updatedImages = [...selectedImage];
      updatedImages[changeIndex] = file;
      setSelectedImage(updatedImages);
    } else {
      snackBar("File type not allowed. Please select a valid image file.", false)
    }
  };

  const showImages = (selectedImage) => {
    let imagesArray = [];
    if (selectedImage instanceof FileList) {
      imagesArray = Array.from(selectedImage);
    } else {
      imagesArray = Array.from(selectedImage);
    }
    return (
      <>
        {imagesArray.map((item, i) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid lightgray",
                  padding: "5px",
                }}
              >
                <div style={{ flex: "1" }}>
                  <a
                    target="_blank"
                    href={
                      typeof item === "string"
                        ? item
                        : URL.createObjectURL(item)
                    }
                  >
                    <img
                      key={i}
                      style={{ width: 80 }}
                      src={
                        typeof item === "string"
                          ? item
                          : URL.createObjectURL(item)
                      }
                      alt={`Selected Image ${i + 1}`}
                      loading="lazy"
                      onError={(e) => {
                        console.error(`Error loading image ${i + 1}:`, e);
                      }}
                    />
                  </a>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => removeSelectedImage(i)}
                  >
                    X
                  </Button>
                  <br />
                </div>
                {location.state.formName === "Update Service" ? (
                  <div
                    style={{
                      marginLeft: "auto",
                      paddingLeft: "10px",
                      marginTop: "9px",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      id={`change_images_${i}`}
                      name={`change_images_${i}`}
                      style={{ display: "none" }}
                      onChange={(e) => changeSelectedImage(e, i)}
                    />
                    <label htmlFor={`change_images_${i}`}>
                      <Button
                        component="span"
                        color="success"
                        variant="outlined"
                      >
                        Change
                      </Button>
                    </label>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          );
        })}
      </>
    );
  };


  const phoneValidation = (value) => {
    if (!value) {
      return false;
    }
    const phoneNumber = parsePhoneNumberFromString(value);
    return phoneNumber && phoneNumber.isValid();
  };

  const nameRegex = /^[a-zA-Z]+([a-zA-Z\s]*[a-zA-Z]+)?$/;

  const UserSchema = Yup.object().shape({
    service_name: Yup.string()
      .required("Please enter service name")
      .matches(
        /^[a-zA-Z0-9.]+([a-zA-Z\s.,/]*[a-zA-Z.,/]+)?$/,
        "Service name must contain at least one alphabet character and without leading spaces"
      ),
    description_title: Yup.string().matches(
      /^[a-zA-Z0-9#@$%!.]+([a-zA-Z0-9\s#@$%!.,/]*[a-zA-Z0-9#@$%!.,/]+)?$/,
      "Description title must without leading spaces"
    ),
    description: Yup.string().matches(
      /^[a-zA-Z0-9#@$%!.]+([a-zA-Z0-9\s#@$%!.,/]*[a-zA-Z0-9#@$%!.,/]+)?$/,
      "Description must without leading spaces"
    ),
    service_price: Yup.string().required("Please select service price"),
    contact_number: Yup.string().required("Please select contact number").test("is-valid-phone", "Mobile number is not valid", phoneValidation),
  });

  const handleClose = () => {
    navigate("/admin-services");
  };
  const formik = useFormik({
    initialValues: {
      service_name: service_name || "",
      contact_number: /^\d{10}$/.test(contact_number)
        ? `+91 ${contact_number && String(contact_number).slice(0, 5)}-${
            contact_number && String(contact_number).slice(5)
          }`
        : contact_number || "",
      service_price: service_price || "",
      title: description?.[0].title || "",
      description: description?.[0]?.points[0] || "",
      is_active: is_active,
      // service_images: service_images || "",
    },
    validationSchema: UserSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!selectedImage?.length) {
        setSelectedImageError(true);
        return;
      }
      const body = {
        service_name: values.service_name,
        service_price: values.service_price,
        contact_number: values.contact_number,
        description: addedDiscritption,
        service_images: selectedImage,
        is_active: values.is_active,
      };
      const formData = new FormData();

      if (location.state.formName === "Create Service") {
        formData.append("service_name", body.service_name);
        formData.append(
          "contact_number",
          body.contact_number.replace(/\+91|\s|-/g, "")
        );
        formData.append("service_price", body.service_price);
        let imagesArray = [];
        imagesArray = Array.from(selectedImage);
        imagesArray.forEach((image, index) => {
          formData.append("service_images", image);
        });
        if (body.description)
          formData.append("description", JSON.stringify(body.description));
      } else {
        formData.append("service_name", body.service_name);
        formData.append(
          "contact_number",
          body.contact_number.replace(/\+91|\s|-/g, "")
        );
        formData.append("service_price", body.service_price);
        let imagesArray = [];
        if (selectedImage instanceof FileList) {
          imagesArray = Array.from(selectedImage);
        } else {
          selectedImage.forEach(function (element) {
            if (typeof element == "object") {
              imagesArray.push(element);
            }
          });
        }
        imagesArray.forEach((image, index) => {
          formData.append(`service_images`, image);
        });
        let existImage = [];
        for (let i = 0; i < selectedImage.length; i++) {
          if (typeof selectedImage[i] == "string") {
            existImage.push(selectedImage[i]);
          }
        }
        if (existImage.length > 0) {
          formData.append(`service_images`, JSON.stringify(existImage));
        } else {
          formData.append(`service_images`, JSON.stringify([]));
        }

        if (body.description)
          formData.append("description", JSON.stringify(body.description));
        if (body.is_active) formData.append("is_active", body.is_active);
      }

      const resetInitForm = () => {
        resetForm();
        setSelectedImage(null);
      };

      if (location.state.formName === "Create Service") {
        await dispatch(createService(formData)).then((res) => {
          if (res?.data?.status === true) {
            navigate("/admin-services");
            dispatch(getAllService(1, "", rowsPerPage));
          }
        });
        resetInitForm();
        handleClose();
      } else {
        await dispatch(updateService(_id, formData)).then((res) => {
          if (res?.data?.status === true) {
            navigate("/admin-services");
          }
        });
      }
    },
  });

  const handleRemoveAddedDesc = (ind, subDataInd) => {
    setAddedDiscritption((prev) => {
      const data = prev.map((dataobj, index) => {
        if (ind == index) {
          return {
            title: dataobj.title,
            points: dataobj["points"].filter(
              (data, indexx) => indexx !== subDataInd
            ),
          };
        }
        return dataobj;
      });
      return data;
    });
  };
  const handleDiscriptionAdd = () => {
    setAddedDiscritption([...addedDiscritption, { title: "", points: [] }]);
  };
  const addSubDesc = (dataInd) => {
    setAddedDiscritption((prev) => {
      const data = prev.map((dataobj, index) => {
        if (dataInd == index) {
          return {
            title: dataobj.title,
            points: [...dataobj["points"], ""],
          };
        }
        return dataobj;
      });
      return data;
    });
  };

  const handleRemoveAddedDiscription = (index) => {
    setAddedDiscritption((prev) => {
      const data = prev.filter((dataobj, previndex) => previndex !== index);
      return data;
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem",
        }}
      >
        <ArrowCircleLeftOutlinedIcon
          fontSize="large"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/admin-services")}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item md={8} xs={12} sm={12}>
            <Grid item md={12}>
              <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                <form onSubmit={formik.handleSubmit}>
                  <Typography
                    textAlign="center"
                    fontWeight={900}
                    fontSize={28}
                    marginBottom={2}
                    color="black"
                  >
                    {location.state.formName}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Input
                        id="service_name"
                        name="service_name"
                        type="text"
                        maxLength={50}
                        label="Service Name"
                        onChange={formik.handleChange}
                        value={formik.values.service_name}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.service_name &&
                          Boolean(formik.errors.service_name)
                        }
                        helperText={
                          formik.touched.service_name &&
                          formik.errors.service_name ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.service_name}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Input
                        id="service_price"
                        name="service_price"
                        type="number"
                        maxLength={10}
                        label="Service Price"
                        onChange={formik.handleChange}
                        value={formik.values.service_price}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.service_price &&
                          Boolean(formik.errors.service_price)
                        }
                        helperText={
                          formik.touched.service_price &&
                          formik.errors.service_price ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.service_price}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <MuiPhoneNumber
                        sx={{ marginTop: "1em" }}
                        fullWidth
                        defaultCountry={"in"}
                        size="small"
                        variant="outlined"
                        id="contact_number"
                        name="contact_number"
                        label="Contact Number"
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={(value) => {
                          formik.setFieldValue("contact_number", value);
                        }}
                        value={formik.values.contact_number}
                        error={formik.errors.contact_number}
                        helperText={
                          formik.touched.contact_number &&
                          formik.errors.contact_number ? (
                            <div className="err-msg-mobile err-msg-font">
                              {formik.errors.contact_number}
                            </div>
                          ) : null
                        }
                        InputLabelProps={{ sx: { color: "black" } }}
                      />
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                      Service Description:
                    </Typography>
                  </div>

                  {addedDiscritption.map((data, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          style={{
                            border: index !== 0 ? "2px solid lightgray" : "",
                            padding: index !== 0 ? "5px" : "",
                            borderRadius: index !== 0 ? "5px" : "",
                            marginTop: index !== 0 ? "10px" : "",
                            marginBottom: index !== 0 ? "10px" : "",
                          }}
                        >
                          <div
                            key={index}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid container spacing={2}>
                              <Grid item md={12} xs={12} sm={12}>
                                <Input
                                  name="description_title"
                                  value={addedDiscritption[index].title}
                                  type="text"
                                  label="Title"
                                  maxLength={50}
                                  onChange={(e) => {
                                    setAddedDiscritption((prev) => {
                                      const data = prev.map(
                                        (dataobj, indofprev) => {
                                          if (indofprev == index) {
                                            return {
                                              title: e.target.value,
                                              points: dataobj["points"],
                                            };
                                          }
                                          return dataobj;
                                        }
                                      );
                                      return data;
                                    });
                                    formik.setFieldValue("description_title", e.target.value);
                                  }}
                                  onBlur={formik.handleBlur}
                                  error={
                                    formik.touched.description_title &&
                                    Boolean(formik.errors.description_title)
                                  }
                                  helperText={
                                    formik.touched.description_title &&
                                    formik.errors.description_title ? (
                                      <div className="err-msg err-msg-font">
                                        {formik.errors.description_title}
                                      </div>
                                    ) : null
                                  }
                                />
                              </Grid>
                            </Grid>
                          </div>
                          {data["points"].map((field, subIndex) => (
                            <div
                              key={subIndex}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Input
                                maxRows={2}
                                type="text"
                                name="description"
                                label="Description"
                                maxLength={250}
                                value={
                                  addedDiscritption[index]["points"][subIndex]
                                }
                                onChange={(e) => {
                                  setAddedDiscritption((prev) => {
                                    const data = prev.map(
                                      (dataobj, indofprev) => {
                                        if (indofprev == index) {
                                          return {
                                            title: dataobj.title,
                                            points: dataobj["points"].map(
                                              (data, subpoiIndex) => {
                                                if (subpoiIndex == subIndex) {
                                                  return e.target.value;
                                                }
                                                return data;
                                              }
                                            ),
                                          };
                                        }
                                        return dataobj;
                                      }
                                    );
                                    return data;
                                  });
                                  formik.setFieldValue("description", e.target.value);
                                }}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.description &&
                                  Boolean(formik.errors.description)
                                }
                                helperText={
                                  formik.touched.description &&
                                  formik.errors.description ? (
                                    <div className="err-msg err-msg-font">
                                      {formik.errors.description}
                                    </div>
                                  ) : null
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </React.Fragment>
                    );
                  })}
                  {location.state.formName === "Update Service" ? (
                    <Grid container spacing={2}>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        sm={12}
                        sx={{
                          width: "100%",
                          maxHeight: "200px",
                          overflowX: "scroll",
                          scrollBehavior: "smooth",
                          marginTop: "40px",
                        }}
                      >
                        <div style={{ marginTop: "5px" }}>
                          <SelectBox
                            type="text"
                            id="is_active"
                            name="is_active"
                            label="Status"
                            ClassName="range-select-filter"
                            onChange={formik.handleChange}
                            value={formik.values.is_active}
                            options={statusOptions}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}

                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sm={12}
                      sx={{
                        width: "100%",
                        maxHeight: "200px",
                        overflowX: "scroll",
                        scrollBehavior: "smooth",
                        marginTop: "40px",
                      }}
                    >
                      {selectedImage && selectedImage.length > 0
                        ? showImages(selectedImage)
                        : ""}
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                      <p
                        style={{
                          marginBottom: "-1em",
                          marginTop: "-1em",
                          color: "#d32f2f",
                        }}
                      >
                        {imageAllowMsg}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        id="service_images"
                        name="service_images"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleImageUpload(e);
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.service_images &&
                      formik.errors.service_images ? (
                        <div className="err-msg-mobile err-msg-font">
                          {formik.errors.service_images}
                        </div>
                      ) : null}
                      <label htmlFor="service_images">
                        <Button
                          component="span"
                          variant="contained"
                          color="inherit"
                          sx={{ marginTop: "1rem" }}
                        >
                          Service Images
                        </Button>
                      </label>
                      {selectedImageError ? (
                        <div className="err-msg-font">
                          Please select Image
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  {(
                    location.state.formName === "Create Service"
                      ? createLoading
                      : updateLoading
                  ) ? (
                    <Button sx={{ marginTop: 4 }} variant="contained">
                      <CircularProgress
                        style={{ width: "25px", height: "25px", color: "#fff" }}
                      />
                    </Button>
                  ) : (
                    <Button
                      sx={{ marginTop: 4 }}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  )}
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateServices;
