import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, Snackbar, Typography } from "@mui/material";
import Input from "../../common/Input";
import { useFormik } from "formik";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CreateNotifications,
  GetAllNotifications,
  UpdateNotification,
} from "../../redux/action/NotificationActions";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const CreateNotification = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { _id, title, message, notification_image } = location?.state?.userData;
  
  const [selectedImage, setSelectedImage] = useState(notification_image);
  const [selectedImageTouched, setSelectedImageTouched] = useState(false);
  const editorConfiguration = {
    // Specify the configuration options here
    // removePlugins: 'MediaEmbed,ImageUpload', // Remove MediaEmbed and ImageUpload plugins
    // Other configurations as needed
  };


  const [selectedImageError1, setSelectedImageError1] = useState("");

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      "image/JPEG",
      "image/JPG",
      "image/PNG",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/svg",
      "image/webp",
      "image/avi",
      "image/avif",
      "image/avchd",
      "image/flv",
      "image/f4v",
      "image/swf",
      "image/mpeg-2",
    ];
    if (file && allowedTypes.includes(file.type)) {
      setSelectedImage(file);
      setSelectedImageError1("");
    } else {
      setSelectedImageError1("Please select valid image file");
    }
    setSelectedImageTouched(true);
    setSelectedImage(file);
  };

  const UserSchema = Yup.object().shape({
    title: Yup.string()
      .required("Please enter title")
      .matches(/^[A-Za-z][A-Za-z\s.]*$/, "Title must contain at least one alphabet character and without leading spaces"),
    message: Yup.string()
      .required("Please enter message")
      .max(500, "Message should not be greater than 500 characters"),
  });

  const formik = useFormik({
    initialValues: {
      title: title || "",
      message: message || "",
      notification_image: notification_image || "",
    },
    validationSchema: UserSchema,
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("message", values.message);
      formData.append("notification_image", selectedImage);

      const resetInitForm = () => {
        resetForm();
      };

      if (location.state.formName === "Create Notification") {
        dispatch(CreateNotifications(formData));
        resetForm();
        navigate("/admin-notification");
        resetInitForm();
      } else {
        dispatch(UpdateNotification(_id, formData)).then((res) => {
          if (res?.data?.status) {
            navigate("/admin-notification");
            dispatch(GetAllNotifications(1, ""));
          } else {
            Snackbar(res?.data?.message, res?.data?.status)
          }
        });
      }
    },
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem",
          // height: "100vh",
        }}
      >
        <ArrowCircleLeftOutlinedIcon
          fontSize="large"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/admin-notification")}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item md={8} xs={12} sm={12}>
            <Grid item md={12}>
              <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                <form onSubmit={formik.handleSubmit}>
                  <Typography
                    textAlign="center"
                    fontWeight={900}
                    fontSize={28}
                    marginBottom={2}
                    color="black"
                  >
                    {location.state.formName}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Input
                        id="title"
                        name="title"
                        type="text"
                        maxLength={50}
                        label="Title"
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.title && Boolean(formik.errors.title)
                        }
                        helperText={
                          formik.touched.title && formik.errors.title ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.title}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>

                    
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        padding: "16px",
                        // paddingLeft: "34px",
                        paddingRight: "3px",
                      }}
                    >
                      <Grid item md={12} xs={12} sm={12}>
                        <div style={{
                          marginTop: "10px", overflowWrap: "break-word", /* Modern equivalent of word-wrap */
                          wordBreak: "break-word", /* Breaks words at arbitrary points if necessary */
                          whiteSpace: "normal"
                        }}>
                          <CKEditor
                            editor={ClassicEditor}
                            config={{
                              editorConfiguration,
                              placeholder: "Add message",
                            }}
                            data={
                              formik.values.message
                                ? formik.values.message.substring(0, 500)
                                : ""
                            } // Set initial data from formik
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              formik.setFieldValue("message", data); // Update formik value
                            }}
                          />
                          {formik.touched.message && Boolean(formik.errors.message) && <div className="err-msg-font">{formik.errors.message}</div>}
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginLeft: "3px" }}>
                      <Grid item md={12} xs={12} sm={12}>
                        <input
                          type="file"
                          accept="image/*"
                          id="image-upload"
                          style={{ display: "none" }}
                          onChange={handleImageUpload}
                        />
                        {/* <label htmlFor="image-upload">
                          <Button
                            component="span"
                            variant="contained"
                            color="inherit"
                            sx={{ marginTop: "1rem" }}
                          >
                            Upload Image
                          </Button>
                        </label> */}
                        
                      </Grid>
                      {selectedImageError1 ? (
                        <div className="err-msg-font">
                          {selectedImageError1}
                        </div>
                      ) : selectedImage !== 'undefined' &&
                      <Grid item md={9} xs={12} sm={12}>
                        {selectedImage && (
                          <Grid
                            item
                            md={12}
                            xs={12}
                            sm={12}
                            sx={{ marginTop: "-30px" }}
                          >
                            {/* <Typography
                              variant="subtitle1"
                              sx={{ marginTop: "1rem" }}
                            >
                              Selected Image:
                              <br />
                              <a
                                target="_blank"
                                href={
                                  typeof selectedImage !== "object" &&
                                    typeof notification_image === "string"
                                    ? notification_image
                                    : URL.createObjectURL(selectedImage)
                                }
                              >
                                <img
                                  // key={i}
                                  style={{ width: 80 }}
                                  src={
                                    typeof selectedImage !== "object" &&
                                      typeof notification_image === "string"
                                      ? notification_image
                                      : URL.createObjectURL(selectedImage)
                                  }
                                  // alt={`Selected Image ${i + 1}`}
                                  loading="lazy"
                                // onError={(e) => {
                                //   console.error(`Error loading image ${i + 1}:`, e);
                                // }}
                                />
                              </a>
                            </Typography> */}
                          </Grid>
                        )}
                      </Grid>
                      }
                    </Grid>
                  </Grid>

                  <Button
                    sx={{ marginTop: 4 }}
                    type="submit"
                    variant="contained"
                    onClick={() => setSelectedImageTouched(true)}
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateNotification;
