function getPermissions(moduleName, permissionName) {
  const userDataString = localStorage.getItem("user_data");
  if (!userDataString) {
    return false;
  }
  const userData = JSON.parse(userDataString);
  const approval_status =
    userData?.user?.approval_status ||
    userData?.admin?.approval_status ||
    "approved";
  const userRole = userData?.admin?.user_role || userData?.user?.user_role;
  let permissions = {};
  if (approval_status === "approved") {
    permissions = userRole?.permissions;
  } else {
    const keys = Object.keys(userRole?.permissions);
    keys.forEach((data) => {
      permissions[data] = {
        is_read: userRole?.permissions[data]?.is_read,
        is_edit: 0,
        is_delete: 0,
        is_add: 0,
      };
    });
  }
  if (!userRole || !permissions) {
    return false;
  }
  if (permissions && permissions[moduleName]) {
    const modulePermissions = permissions[moduleName];

    if (permissionName && modulePermissions.hasOwnProperty(permissionName)) {
      return modulePermissions[permissionName];
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export { getPermissions };
