import React, { useEffect, useReducer, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Autocomplete,
  TextField,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable.js";
import {
  DeleteIcon,
  EditIcon,
  CloseIcon,
  Visibility,
} from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { Form, useForm } from "../../common/useForms";
import FormDialog from "../../components/dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateUserManage,
  DeleteUserManage,
  GetAllUser,
  GetUserRole,
  UpdateUserManage,
} from "../../redux/action/UserManageAction";
import { useSnackbar } from "notistack";
import DeleteDialog from "../dialog/deletedialog";
import SelectBox from "../../common/SelectBox";
import { FormControl } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import { ApiURL, snackBar } from "../../redux/common";
import axios from "axios";
import useUserRolePermissions from "../../common/UserPermissions";
import { GetAllChapter } from "../../redux/action/ChapterAction";
import { api } from "../../web/config/api";
import Loader from "../loader/Loader";
import { getBusinessCategory } from "../../redux/action/BusinessCategoryAction";
import { GetAllCountry } from "../../redux/action/CountryAction";
import { GetAllStates, cleareAllStates } from "../../redux/action/StateAction";
import { GetAllCities, cleareAllCities } from "../../redux/action/CityAction";
import {
  GetAllPostalCodes,
  clearePostalCodes,
} from "../../redux/action/PostalCodeAction";

const statusOptions = [
  { value: "approved", label: "Approved" },
  { value: "pending", label: "Pending" },
  // { value: "rejected", label: "Rejected" },
];

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
  customCircularProgress: {
    width: "25px !important",
    height: "25px !important",
    color: "#fff !important",
  },
}));

const initialValues = {
  email: "",
  role: "",
  mobile_number: "",
  password: "",
  confirm_password: "",
};

const initialFilterValues = {
  // page: 1,
  email: "",
  mobile_number: "",
  chapterId_refferalType: "",
  role_id: "",
  gender: "",
  approval_status: "",
  business_id: "",
  country_id: "" || null,
  state_id: "",
  city_id: "",
  postal_code: "",
  referral_code: "",
  start_date: "",
  end_date: "",
  // rowsPerPage: 10,
};

const genderOption = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Transgender", value: "transgender" },
  { label: "Other", value: "other" },
];

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
  // { id: 30, title: "30" },
];

const ManageUserList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectedUser, setSelectedUser] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [tempPass, setTempPass] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [downLoadData, setDownLoadData] = useState("");
  const [statusModal, setStatusModal] = useState(false);
  const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);
  const [isFieldFilled, setIsFieldFilled] = useState()
  const [isFilterClicked, setIsFilterClicked] = useState(false);

  const useClasses = useStyles();
  const auth_data = JSON.parse(localStorage.getItem("user_data"));
  const location = useLocation();
  const key = location.state;

  const isFilterFormFilled = () => {
    return Object.keys(initialFilterValues).some((key) => {
      return filterDatas[key] !== initialFilterValues[key];
    });
  };

  useEffect(() => {
    if (filter) {
      const anyFieldHasValue = Object.values(filterDatas).some(val => val !== "");
      setIsFieldFilled(anyFieldHasValue);
    }
  }, [filter, filterDatas])


  const DownLoadPdf = async () => {
    const email = filterDatas.email ? filterDatas.email : "";

    const mobile_number = filterDatas.mobile_number
      ? filterDatas.mobile_number
      : "";

    const chapterId_refferalType = filterDatas.chapterId_refferalType
      ? filterDatas.chapterId_refferalType
      : "";

    const role_id = filterDatas.role_id ? filterDatas.role_id : "";

    const gender = filterDatas.gender ? filterDatas.gender : "";

    const approval_status = filterDatas.approval_status
      ? filterDatas.approval_status
      : "";

    const business_id = filterDatas.business_id
      ? filterDatas.business_id //filterDatas.business_id._id
      : "";

    const country_id = filterDatas.country_id ? filterDatas.country_id._id : "";

    const state_id = filterDatas.state_id ? filterDatas.state_id._id : "";

    const city_id = filterDatas.city_id ? filterDatas.city_id._id : "";

    const postal_code = filterDatas.postal_code
      ? filterDatas?.postal_code?.postal_code
      : "";

    const referral_code = filterDatas.referral_code
      ? filterDatas?.referral_code
      : "";
    try {
      const res = await axios.get(`${ApiURL}user/download-data?email=${email}&mobile_number=${mobile_number}&chapterId_refferalType=${chapterId_refferalType}&role_id=${role_id}&gender=${gender}&approval_status=${approval_status}&business_category_id=${business_id}&country_id=${country_id}&state_id=${state_id}&city_id=${city_id}&postal_code=${postal_code}&referral_code=${referral_code}`);
      setDownLoadData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching role data:", error);
      return {};
    }
  };

  useEffect(() => {
    DownLoadPdf();
  }, [isFilterClicked]);

  const userRoleData = useUserRolePermissions("User");
  const isEditAllowed = getPermissions(permissions.user, "is_edit");
  const isDeleteAllowed = getPermissions(permissions.user, "is_delete");
  const isAddAllowed = getPermissions(permissions.user, "is_add");
  const chapters = useSelector(
    (state) => state?.ChapterReducer?.chapter?.chapterData
  );
  const role = useSelector((state) => state?.UserManageReducer?.roleData);

  const userDataString = localStorage.getItem("user_data");
  const userData = JSON.parse(userDataString);

  const { b_category } = useSelector((state) => state?.BusinessCategoryReducer);

  const { allCountryData } = useSelector((state) => state?.CountryReducer);

  const { allStateData } = useSelector((state) => state.StateReducer);

  const { allCitiesData } = useSelector((state) => state.CityReducer);

  const { allPostalData } = useSelector((state) => state.PostalCodeReducer);

  useEffect(() => {
    dispatch(GetAllCountry(true));
  }, []);

  useEffect(() => {
    if (filterDatas?.country_id?._id || filterDatas?.state_id?._id) {
      dispatch(GetAllStates(filterDatas?.country_id, true));
      dispatch(cleareAllStates());
      dispatch(cleareAllCities());
      dispatch(clearePostalCodes());
    }
  }, [filterDatas?.country_id, filterDatas.state_id]);

  useEffect(() => {
    dispatch(GetAllChapter(undefined, "", "", undefined,"",true));
  }, []);

  useEffect(() => {
    dispatch(getBusinessCategory(undefined, undefined, "", { is_active: true }));
  }, []);

  const columns = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: "Email",
    },
    {
      id: "mobile_number",
      numeric: true,
      disablePadding: false,
      label: "Mobile Number",
    },
    {
      id: "gender",
      numeric: true,
      disablePadding: false,
      label: "Gender",
    },
    {
      id: "role",
      numeric: true,
      disablePadding: false,
      label: "Role",
    },
    userData?.user?.user_role?.role === "Chapter President"
      ? {
        id: "intensive_amount",
        numeric: true,
        disablePadding: false,
        label: "Intensive Amount",
      }
      : null,
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Approval Status",
    },
    {
      id: "view",
      numeric: true,
      disablePadding: false,
      label: "View",
    },
    auth_data && auth_data?.user?.user_role?.role !== "Community Member"
      // isEditAllowed || isDeleteAllowed
      ? {
        id: "action",
        numeric: true,
        disablePadding: false,
        label: "Action",
      }
      : null,
  ].filter((el) => el !== null);

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    setPage(1);
    const email = filterDatas.email ? filterDatas.email : "";

    const mobile_number = filterDatas.mobile_number
      ? filterDatas.mobile_number
      : "";

    const chapterId_refferalType = filterDatas.chapterId_refferalType
      ? filterDatas.chapterId_refferalType
      : "";

    const role_id = filterDatas.role_id ? filterDatas.role_id : "";

    const gender = filterDatas.gender ? filterDatas.gender : "";

    const approval_status = filterDatas.approval_status
      ? filterDatas.approval_status
      : "";

    const business_id = filterDatas.business_id
      ? filterDatas.business_id?.business_category_id
      : "";

    const country_id = filterDatas.country_id ? filterDatas.country_id._id : "";

    const state_id = filterDatas.state_id ? filterDatas.state_id._id : "";

    const city_id = filterDatas.city_id ? filterDatas.city_id._id : "";

    const postal_code = filterDatas.postal_code
      ? filterDatas.postal_code._id
      : "";

    if (key?.start_date && key?.end_date) {
      dispatch(
        GetAllUser(
          1,
          email,
          mobile_number,
          chapterId_refferalType,
          role_id,
          gender,
          approval_status,
          business_id,
          country_id,
          state_id,
          city_id,
          postal_code,
          "",
          "",
          "",
          key?.start_date,
          key?.end_date,
          "",
          rowPerPage
          // rowsPerPage
        )
      );
    } else {
      dispatch(
        GetAllUser(
          1,
          email,
          mobile_number,
          chapterId_refferalType,
          role_id,
          gender,
          approval_status,
          business_id,
          country_id,
          state_id,
          city_id,
          postal_code,
          "",
          "",
          "",
          "",
          "",
          "",
          rowPerPage
          // rowsPerPage
        )
      );
    }
  };

  const {
    userallData,
    loading,
    updateLoading,
    deleteLoading,
    createLoading,
    roleData,
  } = useSelector((state) => state.UserManageReducer);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const tableTitle = "SubscriptionList";

  useEffect(() => {
    const fetchData = async () => {
      if (
        key?.navigated_from_dashboard?.payload?.id &&
        key?.start_date &&
        key?.end_date
      ) {
        dispatch(
          GetAllUser(
            page,
            "",
            "",
            "",
            key?.navigated_from_dashboard?.payload?.id,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            key?.start_date,
            key?.end_date,
            "",
            rowsPerPage
          )
        );
      } else if (
        key?.navigated_from_dashboard?.activeLabel &&
        key?.start_date &&
        key?.end_date
      ) {
        try {
          const response = await dispatch(
            GetAllUser(
              page,
              "",
              "",
              "",
              "",
              "",
              key?.navigated_from_dashboard?.activeLabel,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              key?.start_date,
              key?.end_date,
              "",
              rowsPerPage
            )
          );
          // Handle response if needed
        } catch (error) {
          // Handle error if needed
          console.error("API call error:", error);
        }
      } else if (
        key?.navigated_from_dashboard?.payload?.membership_id &&
        key?.navigated_from_dashboard?.payload?.name &&
        key?.start_date &&
        key?.end_date
      ) {
        try {
          const response = await dispatch(
            GetAllUser(
              page,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              key?.navigated_from_dashboard?.payload?.membership_id,
              "",
              key?.start_date,
              key?.end_date,
              "",
              // key?.navigated_from_dashboard?.payload?.name,
              rowsPerPage
            )
          );
          // Handle response if needed
        } catch (error) {
          // Handle error if needed
          console.error("API call error:", error);
        }
      } else if (
        key?.navigated_from_dashboard?.payload?.payload?.name &&
        key?.start_date &&
        key?.end_date
      ) {
        try {
          const response = await dispatch(
            GetAllUser(
              page,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              key?.navigated_from_dashboard?.payload?.payload?.name,
              "",
              "",
              key?.start_date,
              key?.end_date,
              "",
              rowsPerPage
            )
          );
          // Handle response if needed
        } catch (error) {
          // Handle error if needed
          console.error("API call error:", error);
        }
      } else if (!open) {
        const email = filterDatas.email ? filterDatas.email : "";
        const mobile_number = filterDatas.mobile_number
          ? filterDatas.mobile_number
          : "";
        const chapterId_refferalType = filterDatas.chapterId_refferalType
          ? filterDatas.chapterId_refferalType
          : "";
        const role_id = filterDatas.role_id ? filterDatas.role_id : "";
        const gender = filterDatas.gender ? filterDatas.gender : "";
        const approval_status = filterDatas.approval_status
          ? filterDatas.approval_status
          : "";
        const business_id = filterDatas.business_id
          ? filterDatas.business_id.business_category_id
          : "";
        const country_id = filterDatas.country_id
          ? filterDatas.country_id._id
          : "";
        const state_id = filterDatas.state_id ? filterDatas.state_id._id : "";
        const city_id = filterDatas.city_id ? filterDatas.city_id._id : "";
        const is_active = filterDatas.city_id ? filterDatas.is_active : "";
        const membership_id = filterDatas.city_id
          ? filterDatas.membership_id
          : "";

        const postal_code = filterDatas.postal_code
          ? filterDatas.postal_code._id
          : "";
        const start_date = filterDatas.start_date ? filterDatas.start_date : "";
        const end_date = filterDatas.end_date ? filterDatas.end_date : "";

        const referral_code = filterDatas.referral_code
          ? filterDatas.referral_code
          : "";

        dispatch(
          GetAllUser(
            page,
            email,
            mobile_number,
            chapterId_refferalType,
            role_id,
            gender,
            approval_status,
            business_id,
            country_id,
            state_id,
            city_id,
            postal_code,
            is_active,
            membership_id,
            referral_code,
            start_date,
            end_date,
            "",
            rowsPerPage
          )
        );
      }
    };
    fetchData();
  }, [
    page,
    key?.navigated_from_dashboard,
    key?.start_date,
    open,
    dispatch,
    // filterDatas,
    rowsPerPage,
  ]);

  useEffect(() => {
    dispatch(GetUserRole());
  }, [dispatch]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    navigate("/admin-crete-user", {
      state: { formName: "Create User", userData: {} },
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [filterError, setFilterError] = useState();

  const filterValidate = () => {
    let temp = { ...filterError };
    const numericRegex = /^\d+$/;
    if (
      filterDatas.mobile_number !== "" &&
      filterDatas.mobile_number !== undefined
    ) {
      if (!numericRegex.test(filterDatas.mobile_number)) {
        temp.mobile_number =
          "Invalid Mobile number. Please enter a valid mobile number.";
        return;
      }
    }
    setFilterError({
      ...temp,
    });
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        const emailRegex =
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(com|org|gov|edu|net|mil|in|co)$/;
        const isEmailValid = emailRegex.test(fieldValues.email);
        if (!isEmailValid) {
          temp.email = "Invalid email address. Please enter a valid email.";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    if ("role" in fieldValues)
      temp.role = fieldValues.role ? "" : "This field is required.";
    if ("mobile_number" in fieldValues) {
      if (fieldValues.mobile_number !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.mobile_number)) {
          temp.mobile_number = "";
        } else {
          temp.mobile_number = "Only Number allow";
        }
      } else {
        temp.mobile_number = "This field is required.";
      }
    }
    if (popupName !== "Update User") {
      if ("password" in fieldValues) {
        if (fieldValues.password !== "" && fieldValues.password !== undefined) {
          setTempPass(fieldValues.password);
          if (fieldValues.password.length < 8) {
            temp.password = "Password  must be 8 charecter";
          } else {
            if (!/[0-9]/.test(fieldValues.password)) {
              temp.password = "Password  must be 1 Number";
            } else {
              if (!/[a-z]/.test(fieldValues.password)) {
                temp.password = "Password  must be 1 Lowercase letter";
              } else {
                if (!/[A-Z]/.test(fieldValues.password)) {
                  temp.password = "Password  must be 1 Uppercase letter";
                } else {
                  // setPass(fieldValues.password);
                  temp.password = "";
                }
              }
            }
          }
        } else {
          temp.password = "This field is required.";
        }
      }
      if ("confirm_password" in fieldValues) {
        if (
          fieldValues.confirm_password !== "" &&
          fieldValues.confirm_password !== undefined
        ) {
          if (fieldValues.confirm_password !== tempPass) {
            temp.confirm_password = "Passwords do not match";
          } else {
            temp.confirm_password = "";
          }
        } else {
          temp.confirm_password = "This field is required.";
        }
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleEditClick = (user) => {
    setSelectedUser(user);
    navigate("/admin-update-user", {
      state: { formName: "Update User", userData: user },
    });
    // setPopupName("Update User")
    setValues({
      ...values,
      email: user.email || "",
      mobile_number: user.mobile_number || "",
      role: user.role_id || "",
      business_id: user.business_id || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (subscription) => {
    setOpenDelete(true);
    SetDeleteId(subscription._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    dispatch(GetAllUser(
      page,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ));
  };

  const handleSingleDelete = async () => {
    setOpenDelete(false);
    dispatch(DeleteUserManage(DeleteId, handleCloseDelete));
  };

  const fields = [
    {
      id: "email",
      label: "Email Address",
      type: "email",
      value: values.email,
      name: "email",
      errors: errors.email,
      maxLength: 50,
    },
    {
      id: "role",
      label: "Role",
      type: "select",
      options:
        roleData &&
        roleData?.roleData?.map((el) => {
          return { value: el._id, label: el.role };
        }),
      value: values.role,
      name: "role",
      errors: errors.role,
      maxLength: 100,
    },
    {
      id: "mobile_number",
      label: "Phone Number",
      value: values.mobile_number,
      name: "mobile_number",
      errors: errors.mobile_number,
      maxLength: 10,
    },
    {
      id: "password",
      label: "Password",
      value: values.password,
      name: "password",
      errors: errors.password,
      maxLength: 25,
    },
    {
      id: "confirm_password",
      label: "Confirm Password",
      value: values.confirm_password,
      name: "confirm_password",
      errors: errors.confirm_password,
      maxLength: 25,
    },
  ];

  const isAnyFieldNotEmpty = Object.values(values).some(
    (value) => value.trim() !== ""
  );
  //filter

  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    setIsFilterClicked(false)
    dispatch(GetAllUser(
      1,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ));
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const [isNotValidEmail, setIsNotValidEmail] = useState()
  const [isValidEmail, setIsValidEmail] = useState(true)

  const handleChange = (e) => {
    let temp = { ...errors };
    const { name, value } = e.target;
    const numericRegex = /^\d+$/;
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i

    if (name === "email" && value && !emailRegex.test(value)) {
      setIsNotValidEmail("Please enter valid email")
      setIsValidEmail(false)
    } else {
      setIsNotValidEmail()
      setIsValidEmail(true)
    }
    if (name === "mobile_number" && value && !numericRegex.test(value)) {
      return;
    }
    if (name === "referral_code" && value && !numericRegex.test(value)) {
      return;
    }


    setFilterDatas((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };

  const handleLocationChange = (name, value) => {
    setFilterDatas((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };


  const handleSubmitFilter = async (e) => {
    e.preventDefault();
    setIsFilterClicked(true)
    const body = {
      email: filterDatas.email,
      mobile_number: filterDatas.mobile_number,
      chapterId_refferalType: filterDatas.chapterId_refferalType,
      role_id: filterDatas.role_id,
      gender: filterDatas.gender,
      approval_status: filterDatas.approval_status,
      business_id: filterDatas.business_id,
      country_id: filterDatas.country_id,
      state_id: filterDatas.state_id,
      city_id: filterDatas.city_id,
      postal_code: filterDatas.postal_code,
      referral_code: filterDatas.referral_code,
    };
    setFilterDatas(body);

    const email = filterDatas.email ? filterDatas.email : "";

    const mobile_number = filterDatas.mobile_number
      ? filterDatas.mobile_number
      : "";

    const chapterId_refferalType = filterDatas.chapterId_refferalType
      ? filterDatas.chapterId_refferalType
      : "";

    const role_id = filterDatas.role_id ? filterDatas.role_id : "";

    const gender = filterDatas.gender ? filterDatas.gender : "";

    const approval_status = filterDatas.approval_status
      ? filterDatas.approval_status
      : "";

    const business_id = filterDatas.business_id
      ? filterDatas.business_id //filterDatas.business_id._id
      : "";

    const country_id = filterDatas.country_id ? filterDatas.country_id._id : "";

    const state_id = filterDatas.state_id ? filterDatas.state_id._id : "";

    const city_id = filterDatas.city_id ? filterDatas.city_id._id : "";

    const postal_code = filterDatas.postal_code
      ? filterDatas?.postal_code?.postal_code
      : "";

    const referral_code = filterDatas.referral_code
      ? filterDatas?.referral_code
      : "";
    setPage(1);
    if (isValidEmail) {
      const res = await dispatch(
        GetAllUser(
          page,
          email,
          mobile_number,
          chapterId_refferalType,
          role_id,
          gender,
          approval_status,
          business_id,
          country_id,
          state_id,
          city_id,
          postal_code,
          "",
          "",
          referral_code,
          "",
          "",
          "",
          rowsPerPage
        )
      );
      if (res.data.status === false) {
        snackBar(res.data.message, res.data.status);
      } else {
        closeFilterDrawer1();
      }
    }
  };

  const handleViewClick = (data) => {
    navigate("/admin-user-details", { state: { partnerData: data } });
  };

  const rows =
    userallData !== undefined && Array.isArray(userallData?.userData)
      ? userallData?.userData?.map((user) => {
        const selected = user === selectedUser;
        return {
          _id: user._id,
          name: user?.first_name !== null && user?.last_name !== null ? user.first_name + " " + user.last_name : "-",
          mobile_number: user.mobile_number,
          email: user.email,
          gender: user.gender,
          intensive_amount: user.intensive_amount || "-",
          role: user.role,
          status: user.approval_status || "Pending",
          view: (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton
                onClick={() => handleViewClick(user)}
                sx={{
                  color: "black",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#153e80",
                  },
                  "&:focus": {
                    backgroundColor: "white",
                    color: "#153e80",
                    border: "none",
                  },
                }}
              >
                <Visibility />
              </IconButton>
            </div>
          ),
          action: (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {isEditAllowed ? (
                <IconButton
                  onClick={() => handleEditClick(user)}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <EditIcon />
                </IconButton>
              ) : null}
              {isDeleteAllowed ? (
                <IconButton
                  onClick={() => handleClickOpenDelete(user)}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              ) : null}
            </div>
          ),
          selected: selected,
        };
      })
      : [];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      let body = {};
      if (popupName === "Update User") {
        body = {
          email: values.email,
          role: values.role,
          mobile_number: values.mobile_number,
        };
      } else {
        body = {
          email: values.email,
          role: values.role,
          mobile_number: values.mobile_number,
          password: values.password,
          confirm_password: values.confirm_password,
        };
      }

      if (popupName === "Update User") {
        dispatch(UpdateUserManage(selectedUser._id, body, handleClose));
      } else {
        dispatch(CreateUserManage(body, handleClose));
      }
    }
  };

  const isAnyFieldFilled = Object.values(filterDatas).some((value) =>
    Boolean(value)
  );

  const handleChipClick = (data) => {
    setStatusModal(data);
  };

  const handleModalClose = () => {
    setStatusModal(false);
  };

  const handleStatusUpdate = async (e) => {
    e.preventDefault();
    setStatusUpdateLoading(true);
    try {
      const statusUpdate = e.target[0].value;
      const payload = {
        approval_status: statusUpdate,
      };
      const res = await api.put(
        `${ApiURL}user/update/${statusModal._id}`,
        payload
      );
      if (res?.status) {
        snackBar(res?.message, res?.status);
        dispatch(
          GetAllUser(
            1,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            ""
          )
        );
        handleModalClose();
      }
    } catch (err) {
      dispatch(
        GetAllUser(
          1,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          ""
        )
      );
      handleModalClose();
    } finally {
      setStatusUpdateLoading(false);
      handleModalClose();
    }
  };

  return (
    <Box>
      <PageHeader
        title="User List"
        // onAddClick={handleClickOpen}
        onFilterClick={openFilterDrawer}
        isShowAdd={false}
        onBtnClick={handleClickOpen}
        btnText={isAddAllowed ? "Create User" : ""}
        loading={loading}
        href={downLoadData}
      />

      {loading ? (
        <Loader />
      ) : (
        <EnhancedTable
          rowsPerPage={rowsPerPage}
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={userallData?.userData}
          handleChipClick={auth_data && auth_data?.user?.user_role?.role !== "Community Member" ? handleChipClick : () => { }}
        // handleChipClick={isEditAllowed ? handleChipClick : () => {}}
        />
      )}
      <FormDialog
        open={open}
        fullWidth={true}
        dialogTitle={popupName}
        fields={
          popupName === "Update User"
            ? fields.filter(
              (el) => !["password", "confirm_password"].includes(el.name)
            )
            : fields
        }
        loading={popupName === "Update User" ? updateLoading : createLoading}
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
        isAnyFieldNotEmpty={isAnyFieldNotEmpty}
      />

      <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {userallData?.currentPage} of {userallData?.totalPages}{" "}
            Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ minWidth: "4rem", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={userallData?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>

      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div className="pt-2">
              <Box>
                <Input
                  disabled={isAnyFieldFilled && !filterDatas.email}
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Email"
                  name="email"
                  value={filterDatas.email}
                  onChange={handleChange}
                  maxLength={200}
                />
                <p style={{ marginLeft: "10px", marginTop: "0px", marginBottom: "0px" }} className="err-msg err-msg-font">{isNotValidEmail}</p>
              </Box>
              <Box>
                <Input
                  disabled={isAnyFieldFilled && !filterDatas.mobile_number}
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Mobile"
                  name="mobile_number"
                  value={filterDatas?.mobile_number
                    // filterDatas?.mobile_number || filterDatas?.mobile_number
                  }
                  onChange={handleChange}
                  maxLength={10}
                />
              </Box>

              <Box>
                <Input
                  id="referral_code"
                  name="referral_code"
                  type="text"
                  label="Referral Code"
                  value={filterDatas?.referral_code}
                  onChange={handleChange}
                  maxLength={8}
                />
              </Box>
              {userData?.user?.user_role?.role !== "Community Member" && (
                <>
                  <Box>
                    <SelectBox
                      name="chapterId_refferalType"
                      label="Chapter"
                      // disabled={isAnyFieldFilled && !filterDatas.status}
                      ClassName="status-select-filter"
                      value={filterDatas.chapterId_refferalType}
                      onChange={handleChange}
                      options={chapters}
                    />
                  </Box>
                  <Box>
                    <SelectBox
                      name="role_id"
                      label="Role"
                      // disabled={isAnyFieldFilled && !filterDatas.status}
                      ClassName="status-select-filter"
                      value={filterDatas.role_id}
                      onChange={handleChange}
                      options={role?.roleData}
                    // options={role}
                    />
                  </Box>
                </>
              )}

              <Box>
                <SelectBox
                  value={filterDatas?.gender}
                  name="gender"
                  label="Gender"
                  ClassName="status-select-filter"
                  onChange={handleChange}
                  options={genderOption}
                />
              </Box>

              <Box>
                <SelectBox
                  name="approval_status"
                  label="Status"
                  // disabled={isAnyFieldFilled && !filterDatas.status}
                  ClassName="status-select-filter"
                  value={filterDatas.approval_status}
                  onChange={handleChange}
                  options={statusOptions}
                />
              </Box>

              <Box>
                <SelectBox
                  name="business_id"
                  label="Business Category"
                  // disabled={isAnyFieldFilled && !filterDatas.status}
                  ClassName="status-select-filter"
                  value={filterDatas.business_id}
                  onChange={handleChange}
                  options={b_category && b_category?.categoryData}
                />
              </Box>

              <Box>
                <Autocomplete
                  id="country"
                  name="country_id"
                  value={filterDatas?.country_id || null}
                  // classes={customStyles}
                  onChange={(e, value) => {
                    handleLocationChange("country_id", value);
                    if (value) {
                      dispatch(GetAllStates(value ? value?._id : "", true));
                      dispatch(cleareAllStates());
                    }
                  }}
                  options={allCountryData?.countryData || []}
                  autoHighlight
                  // onBlur={formik.handleBlur}
                  getOptionLabel={(option) => option.country_name}
                  sx={{ marginTop: "15px" }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#000" }}
                      {...params}
                      label="Country"
                      inputProps={{
                        ...params.inputProps,
                        style: { color: "#000" },
                      }}
                    />
                  )}
                />
              </Box>

              <Box>
                <Autocomplete
                  id="state"
                  name="state_id"
                  value={filterDatas.state_id || null}
                  onChange={(e, value) => {
                    handleLocationChange("state_id", value);
                    if (value) {
                      dispatch(GetAllCities(value ? value?._id : "", true));
                      dispatch(cleareAllCities());
                    }
                  }}
                  options={allStateData || []}
                  autoHighlight
                  getOptionLabel={(option) => option.state_name}
                  sx={{ marginTop: "15px" }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#000" }}
                      {...params}
                      label="State"
                      inputProps={{
                        ...params.inputProps,
                        style: { color: "#000" },
                      }}
                    />
                  )}
                />
              </Box>

              <Box>
                <Autocomplete
                  id="city"
                  name="city_id"
                  value={filterDatas.city_id || null}
                  onChange={(e, value) => {
                    handleLocationChange("city_id", value);
                    if (value) {
                      dispatch(
                        GetAllPostalCodes(value ? value?._id : "", true)
                      );
                      dispatch(clearePostalCodes());
                    }
                  }}
                  options={allCitiesData || []}
                  autoHighlight
                  getOptionLabel={(option) => option.city_name}
                  sx={{ marginTop: "15px" }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#000" }}
                      {...params}
                      label="City"
                      inputProps={{
                        ...params.inputProps,
                        style: { color: "#000" },
                      }}
                    />
                  )}
                />
              </Box>

              <Box>
                <Autocomplete
                  id="pincode"
                  name="postal_code"
                  value={filterDatas.postal_code || null}
                  onChange={(e, value) => {
                    handleLocationChange("postal_code", value);
                  }}
                  options={allPostalData || []}
                  autoHighlight
                  getOptionLabel={(option) => option.postal_code}
                  sx={{ marginTop: "15px" }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#000" }}
                      {...params}
                      label="Postal code"
                      inputProps={{
                        ...params.inputProps,
                        style: { color: "#000" },
                      }}
                    />
                  )}
                />
              </Box>
              
              <Stack className={classes.buttonFilter}>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                // disabled={!isFieldFilled}
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  // disabled={!isFieldFilled}
                  style={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "white",
                    },
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
      <Dialog
        open={statusModal}
        fullWidth={false}
        onClose={false}
        disableBackdropClick={true} // Prevent closing when clicking outside
        disableEscapeKeyDown={true}
      >
        <div
          className="mui-dialog-box-popup"
          style={{ backgroundColor: "#fff", color: "#000" }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ color: "#000 !important" }}>
              Update Approval Status
            </Typography>

            <IconButton
              sx={{
                color: "black",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#153e80",
                },
                "&:focus": {
                  backgroundColor: "white",
                  color: "#153e80",
                  border: "none",
                },
              }}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ minWidth: "400px" }}>
            <form onSubmit={handleStatusUpdate}>
              <div style={{ marginTop: "5px" }}>
                <SelectBox
                  type="text"
                  id="approval_status"
                  name="approval_status"
                  label="Approval Status"
                  ClassName="range-select-filter"
                  defaultValue={statusModal?.status || "pending"}
                  options={statusOptions}
                />
              </div>
              <DialogActions sx={{ justifyContent: "center", display: "flex" }}>
                <Button
                  type="submit"
                  disabled={statusUpdateLoading ? true : false}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    border: "0",
                    ":hover": { bgcolor: "#1976d2", border: "0" },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    color: "#fff",
                    textTransform: "none",
                    minWidth: "100px",
                  }}
                >
                  {statusUpdateLoading ? (
                    <CircularProgress
                      className={useClasses.customCircularProgress}
                    />
                  ) : (
                    "Update"
                  )}
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </div>
      </Dialog>
    </Box>
  );
};

export default ManageUserList;
