import { useState, useEffect } from "react";
import axios from "axios";
import { ApiURL } from "../redux/common";

const getRoleDataById = async (userRoleId) => {
  try {
    const userDataString = localStorage.getItem("user_data");
    const userData = JSON.parse(userDataString);
    const approval_status = userData?.user?.approval_status || userData?.admin?.approval_status || "approved";
    const res = await axios.get(`${ApiURL}role/by-id/${userRoleId}`);
    const permission = res?.data?.data?.permissions || {};
    let response = {};
    if (approval_status === "approved") {
      response = permission;
    } else {
      const keys = Object.keys(permission);
      keys.forEach((data) => {
        response[data] = {
          is_read: permission[data]?.is_read || 0,
          is_edit: 0,
          is_delete: 0,
          is_add: 0,
        };
      });
    }
    return response;
  } catch (error) {
    console.error("Error fetching role data:", error);
    return {};
  }
};

const useUserRolePermissions = (moduleName) => {
  const [userRoleData, setUserRoleData] = useState({});
  const userDataString = localStorage.getItem("user_data");
  const userData = JSON.parse(userDataString);

  const userRole = userData?.admin?.role_id || userData?.user?.role_id;

  const fetchData = async () => {
    const roleData = await getRoleDataById(userRole);
    if (userData?.admin) {
      userData.admin.user_role.permissions = roleData;
    }
    if (userData?.user) {
      userData.user.user_role.permissions = roleData;
    }
    localStorage.setItem("user_data", JSON.stringify(userData));
    setUserRoleData(roleData[moduleName] || {});
  };

  useEffect(() => {
    fetchData();
  }, [userRole, moduleName]);

  return userRoleData;
};

export default useUserRolePermissions;
