
const initialState = {
    loading: false,
    createLoading: false,
    updateLoading: false,
    deleteLoading: false,
    binLoading: false,
    restoreLoading: false,
    finalDeleteLoading: false,
    getAllChapter: [],
    binChapter: [],
    chapter: [],
}

function ChapterReducer(state = initialState, action) {
    switch (action.type) {
        case "LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "CREATE_LOADING_CHAPTER":
            return {
                ...state,
                createLoading: action.payload
            }
        case "RESTORE_CHAPTER_LOADING":
            return {
                ...state,
                restoreLoading: action.payload
            }
        case "RESTORE_CHAPTER_SUCCESS":
            return {
                ...state,
            }
        case "FINAL_DELETE_CHAPTER_LOADING":
            return {
                ...state,
                finalDeleteLoading: action.payload
            }
        case "FINAL_DELETE_CHAPTER_SUCCESS":
            return {
                ...state,
            }
        case "GET_BIN_LOADING":
            return {
                ...state,
                binLoading: action.payload
            }
        case "GET_BIN_SUCCESS":
            return {
                ...state,
                binChapter: action.payload
            }
        case "UPDATE_LOADING_CHAPTER":
            return {
                ...state,
                updateLoading: action.payload
            }
        case "DELETE_CHAPTER_LOADING":
            return {
                ...state,
                deleteLoading: action.payload
            }
        case "CHAPTER_SUCCESS":
            return {
                ...state,
                chapter: action.payload,
                loading: false,
            }
        case "GET_ALL_CHAPTER_SUCCESS":
            return {
                ...state,
                getAllChapter: action.payload,
            }
        case "CREATE_CHAPTER_SUCCESS":
            return {
                ...state,
            }
        case "UPDATE_CHAPTER_SUCCESS":
            return {
                ...state,
            }
        case "DELETE_CHAPTER_SUCCESS":
            return {
                ...state,
            }
        default:
            return state;
    }
}

export default ChapterReducer;