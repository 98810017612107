import React, { useEffect, useRef, useState } from "react";
import BlogImage5 from "../../assets/image/blog/image-5.jpg";
import BlogCard from "../blog/BlogCard";
import { Pagination } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import Loader from "../../components/loader/Loader";
import { Link } from "react-router-dom";
import NotDataFound from "../notFound/NotDataFound";
import axios from "axios";
import { ApiURL } from "../../../redux/common";
import { allServices, getAllService } from "../../../redux/action/ServiceAction";
import { useDispatch, useSelector } from "react-redux";
import ServiceCard from "./ServiceCard";
import Login from "../auth/Login";
import { Head } from "../commonDesign/Head";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  pagination: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
      padding: 0,
      margin: 0,
    },
    "& .MuiPaginationItem-root": {
      margin: "0 2px",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#153e80",
      border: "none",
      "&:hover": {
        color: "#153e80",
      },
    },
    "& .MuiPaginationItem-page:hover": {
      color: "#153e80",
    },
  },
}));

const cardPerPage = 9;

const Service = () => {
  
  const classes = useStyles();
  const dispatch = useDispatch();
  const { service, loading, ...rest } = useSelector(
    (state) => state?.ServiceReducer
  );
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [error, seterror] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handleModalOpen = () => {
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    setLoginModalOpen(false);
  };

  useEffect(() => {
    dispatch(getAllService(currentPage, "", cardPerPage));
  }, [currentPage]);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <NotDataFound />;
  }

  const handleCurrentPageChange = (_, val) => {
    setCurrentPage(val);
  };
  return (
    <>
      <Head title="Services" />

      {/*SERVICE CARDS*/}
      <div id="wrapper-content" className="wrapper-content">
        <div className="responsive">
          <div
            style={{
              marginTop: "-60px",
              fontSize: "19px",
              marginBottom: "10px",
            }}
          >
            Showing <b>{service?.serviceData?.length}</b> of <b>{service?.serviceDataCount}</b> Services
          </div>

          <div className="services">
            {service &&
              service?.serviceData?.map((item) => {
                return (
                  <ServiceCard handleModalOpen={handleModalOpen} item={item} />
                );
              })}
          </div>
          
        </div>
        {service?.totalPages > 1 && (
          <Pagination
            style={{marginTop: '15px'}}
            count={service?.totalPages}
            page={currentPage}
            className={classes.pagination}
            shape="rounded"
            onChange={handleCurrentPageChange}
          />
        )}
      </div>
      <Login open={loginModalOpen} handleClose={handleModalClose} />
    </>
  );
};

export default Service;
