import { styled } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const ButtonContainer = styled("div")({
  marginTop: "2rem",
  marginBottom: "2rem",
});

const FindChapterBtn = styled(Link)({
  backgroundColor: "#F07579",
  color: "#fff",
  padding: "1rem 2rem",
  borderRadius: "5px",
  textDecoration: "none",
  transition: "background-color 0.3s ease",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
  "&:hover": {
    backgroundColor: "#a3141f",
  },
});

const Findchapter = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/find-chapter-search");
  };
  return (
    <section>
      <div className="content-wrap">
      <section id="section-01">
        <div
          className="mt-9 sw-container tab-content"
          style={{ minHeight: 530, padding: "40px" }}
        >
          <div
            id="stepper-1"
            className="tab-pane step-content"
            style={{ display: "block" }}
          >
            <div className="row d-flex align-items-center">
              <div className="col-md-6">
                <p>Hello,</p>
                <h1 className="black">
                  It's fantastic that you want to grow your business using
                  proven networking strategies.
                </h1>
                <p>
                  We have thousands of successful business networking groups
                  around the world. We're here to help you find the best one for
                  you.
                </p>
                <ButtonContainer>
                  <FindChapterBtn
                    to="/find-chapter-search"
                    tabIndex="0"
                    onClick={handleButtonClick}
                  >
                    Begin Now
                  </FindChapterBtn>
                </ButtonContainer>
              </div>
              <div className="col-md-6">
                <img
                  src="https://www.bni.com/wp-content/uploads/2021/08/Mobile_slider-1.jpg?x78325"
                  data-src="https://www.bni.com/wp-content/uploads/2021/08/Mobile_slider-1.jpg?x78325"
                  alt="introudction image"
                  className="h-auto lazy loaded"
                  data-was-processed="true"
                  style={{ width: "75%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </section>
  );
};

export default Findchapter;
