import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import EventIcon from "@mui/icons-material/Event";
import { getPermissions } from "./common/permissions";
import { permissions } from "./common/constants";

export const sidebar = {
  dashboard: {
    tital: "Dashboard",
    path: "/admin/dashboard",
    icon: <GridViewIcon />,
  },
  packages: {
    tital: "Packages",
    path: "/admin-packages",
    icon: <CardMembershipOutlinedIcon />,
  },

  manage_subscription: {
    tital: "Manage Subscription",
    path: "/admin-subscription",
    icon: <SubscriptionsOutlinedIcon />,
  },
  manage_user: {
    tital: "Manage User",
    path: "/admin-user",
    icon: <ManageAccountsOutlinedIcon />,
  },
  user_role: {
    tital: "User Role & Permission",
    path: "/admin-role-permission",
    icon: <BadgeOutlinedIcon />,
  },
  chapter: {
    tital: "Chapter",
    path: "/admin-chapter",
    icon: <GroupsOutlinedIcon />,
  },
  blog_post: {
    tital: "Blog Post",
    path: "/admin-blog",
    icon: <ViewQuiltOutlinedIcon />,
  },
  partner_request: {
    tital: "Partner Requests",
    path: "/admin-partner-request",
    icon: <GroupAddOutlinedIcon />,
  },
  memberships: {
    tital: "Memberships",
    path: "/admin-memberships",
    icon: <SubscriptionsOutlinedIcon />,
  },
  events: {
    tital: "Events",
    path: "/admin-events",
    icon: <EventIcon />,
  },
  setPermit_countries: {
    tital: "Permitted Locations",
    path: "/admin-permitted-location",
    hasSubmenu: true,
    submenuItems: [
      !!getPermissions(permissions.country, "is_read")
        ? { tital: "Country", path: "/admin-countries" }
        : null,
      !!getPermissions(permissions.state, "is_read")
        ? { tital: "State", path: "/admin-states" }
        : null,
      !!getPermissions(permissions.city, "is_read")
        ? { tital: "City", path: "/admin-cities" }
        : null,
      !!getPermissions(permissions.postalcode, "is_read")
        ? { tital: "Postal Code", path: "/admin-postal-codes" }
        : null,
    ].filter((el) => el !== null),
    icon: <ViewQuiltOutlinedIcon />,
  },
};

// sidebar selection control

export function setSelectedSidebar(pathname) {
  let selectedSidebar = "";

  switch (pathname) {
    case "/admin/dashboard":
      selectedSidebar = "Dashboard";
      break;
    case "/admin-packages":
      selectedSidebar = "Packages";
      break;
    case "/admin-subscription":
      selectedSidebar = "Manage Subscription";
      break;
    case "/admin-user":
      selectedSidebar = "Manage User";
      break;
    case "/admin-update-user":
      selectedSidebar = "Manage User";
      break;
    case "/admin-lead":
      selectedSidebar = "Lead";
      break;
    case "/admin-crete-user":
      selectedSidebar = "Manage User";
      break;
    case "/admin-role-permission":
      selectedSidebar = "User Role & Permission";
      break;
    case "/admin-update-role-permission":
      selectedSidebar = "User Role & Permission";
      break;
    case "/admin-create-role-permission":
      selectedSidebar = "User Role & Permission";
      break;
    case "/admin-blog":
      selectedSidebar = "Blog Post";
      break;
    case "/admin-blog/create-blog":
      selectedSidebar = "Blog Post";
      break;
    case "/admin-banner":
      selectedSidebar = "Banner Mobile";
      break;
    case "/admin-referral":
      selectedSidebar = "Referral";
      break;
    case "/admin-my-referrals":
      selectedSidebar = "Referral";
      break;
    case "/admin-my-referrals/create-referral":
      selectedSidebar = "Referral";
      break;
    case "/admin-referral/view-referral":
      selectedSidebar = "Referral";
      break;
    case "/admin-chapter":
      selectedSidebar = "Chapter";
      break;
    case "/admin-chapter-bin":
      selectedSidebar = "Chapter";
      break;
    case "/admin-partner-request":
      selectedSidebar = "Partner Requests";
      break;
    case "/admin-partner-request-details":
      selectedSidebar = "Partner Requests";
      break;
    case "/admin-permitted-location":
      selectedSidebar = "Permitted Locations";
      break;
    case "/admin-countries":
      selectedSidebar = "Country";
      break;
    case "/admin-states":
      selectedSidebar = "State";
      break;
    case "/admin-cities":
      selectedSidebar = "City";
      break;
    case "/admin-postal-codes":
      selectedSidebar = "Postal Code";
      break;
    case "/admin-memberships":
      selectedSidebar = "Memberships";
      break;
    case "/admin-events":
      selectedSidebar = "Events";
      break;
    case "/admin-event-details":
      selectedSidebar = "Events";
      break;
    case "/admin-events/create-event":
      selectedSidebar = "Events";
      break;
    case "/admin-events/update-event":
      selectedSidebar = "Events";
      break;
    case "/admin-banner":
      selectedSidebar = "Banner";
      break;
    case "/admin-services":
      selectedSidebar = "Services";
      break;
    case "/admin-services/create-services":
      selectedSidebar = "Services";
      break;
    case "/admin-services/update-services":
      selectedSidebar = "Services";
      break;
    case "/admin-services/schedule-service-list":
      selectedSidebar = "Services";
      break;
    case "/admin-clientreview":
      selectedSidebar = "Client Review";
      break;
    case "/commentlist":
      selectedSidebar = "Comment List";
      break;
    case "/admin/business-category":
      selectedSidebar = "Business Category";
      break;
    case "/admin-transactions":
      selectedSidebar = "Transactions";
      break;
    case "/admin-privacy-policy":
      selectedSidebar = "Privacy Policy";
      break;
    case "/admin-terms-and-conditions":
      selectedSidebar = "Terms & Conditions";
      break;
    case "/admin-pages":
      selectedSidebar = "Pages";
      break;
    case "/admin-page-banners":
      selectedSidebar = "Page Banners";
      break;
    case "/admin-notification":
      selectedSidebar = "Send Notifications";
      break;
    case "/admin-Createnotification":
      selectedSidebar = "Send Notifications";
      break;
    case "/admin-update-notification":
      selectedSidebar = "Send Notifications";
      break;
    case "/admin-faqs-category":
      selectedSidebar = "FAQs Category";
      break;
    case "/admin-faqs":
      selectedSidebar = "FAQs";
      break;
    // case "/admin-terms-and-conditions":
    //   selectedSidebar = "Terms & Condition";
    //   break;
    case "/admin-about-us":
      selectedSidebar = "About us";
      break;
    case "/admin-wallet":
      selectedSidebar = "Wallet Transactions";
      break;
    case "/admin-withdraw":
      selectedSidebar = "Withdraw Request";
      break;
    default:
      selectedSidebar = "";
  }
  return selectedSidebar;
}

// datatable chip contol for variant

export function getVariantChip(type) {
  let variant = "";

  switch (type) {
    case "approved":
      variant = "success";
      break;
    case "ongoing":
      variant = "info";
      break;
    case "delivered":
      variant = "success";
      break;
    case "pending":
      variant = "warning";
      break;
    case "rejected":
      variant = "error";
      break;
    case "Active":
      variant = "success";
      break;
    case "inActive":
      variant = "warning";
      break;

    default:
      variant = "warning";
  }
  return variant;
}
