import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiURL, snackBar } from "../../../redux/common";
import axios from "axios";
import { DisplayHTMLString } from "../../components/htmlToString/HtmlToString";

const TermsAndCondition = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [getAllTnc, setGetAllTnC] = useState()

  useEffect(() => {
    getTermsAndCondition()
  }, [])

  const getTermsAndCondition = async () => {
    try {
      setLoading(true)
      const getTnC = await axios.get(`${ApiURL}terms-and-conditions`).then((res) => {
        if (res?.data?.status) {
          setGetAllTnC(res.data.data)
        }
      })
      setLoading(false)
      return getTnC;
    } catch (error) {
      snackBar(error, false)
      setLoading(false)
    }
  }


  return (
    <>
      <div>
        <div id="page-title" class="page-title pt-12 text-center">
          <div class="container">
            <div class="h-100 ">
              <h1 class="mb-0 fadeInDown animated" data-animate="fadeInDown">
                Terms &amp; Condition
              </h1>
              <ul
                class="breadcrumb justify-content-center fadeInUp animated"
                data-animate="fadeInUp"
              >
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item">
                  <span>Terms &amp; Condition</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* #page-title end */}
        {/* #wrapper-content start */}
        <div id="wrapper-content" className="wrapper-content pb-0 pt-11">
          <div className="container">
            {/* #term-condition START */}
            <div id="term-condition" className="pb-9">
              <div className="mb-7">
                {DisplayHTMLString(getAllTnc && getAllTnc?.termsAndConditions)}
              </div>
             
            </div>
            {/* #term-condition END */}
          </div>
          {/* #section cta start */}
          <div id="cta" className="bg-primary py-7">
            <div className="container">
              <div className="row align-items-center">
                <div className="text-white col-xl-9">
                  <h2 className="mb-1 text-white">
                    Have a question? Contact us now !
                  </h2>
                  <p className="mb-0 font-size-md">
                    We're here to help. Check out our FAQs, send us an email or
                    call us at 1 (800) 555-5555
                  </p>
                </div>
                <div className="col-xl-3 mt-4 mt-xl-0 text-left text-xl-right">
                  <a
                    className="btn btn-white btn-icon-right font-size-h5 px-6 py-3 lh-1 text-primary"
                    onClick={()=>navigate("/contact-us")}
                  >
                    Contact US
                    <i className="fal fa-chevron-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* /#section cta end */}
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;
