const initialState = {
    loading: false,
    getServiceByIdLoading: false,
    getAllScheduleServiceRequestsLoading: false,
    addScheduleServiceLoading: false,
    editScheduleServiceLoading: false,
    deleteScheduleServiceLoading: false,

    createLoading: false,
    updateLoading: false,
    deleteLoading: false,

    service: [],
    serviceError: [],
}

export default function ServiceReducer(state = initialState, action) {
    switch (action.type) {
        case "GET_SERVICE_LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "GET_SERVICES":
            return{
                ...state,
            }
        case "SERVICE_SUCCESS":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case "CREATE_SERVICE_LOADING":
            return {
                ...state,
                createLoading: action.payload
            }
        case "CREATE_SERVICE_SUCCESS":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case "UPDATE_SERVICE_LOADING":
            return {
                ...state,
                updateLoading: action.payload
            }
        case "UPDATE_SERVICE_SUCCESS":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case "DELETE_SERVICE_LOADING":
            return {
                ...state,
                deleteLoading: false
            }
        case "DELETE_SERVICE_SUCCESS":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case "SERVICE_FAIL":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case "CREATE_SERVICE_FAIL":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case "UPDATE_SERVICE_FAIL":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case "DELETE_SERVICE_FAIL":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case "GET_SERVICE_BY_ID_LOADING":
            return {
                ...state,
                getServiceByIdLoading: action.payload
            }
        case "GET_SERVICE_BY_ID_SUCCESS":
            return {
                ...state,
                service: action.payload,
                getServiceByIdLoading: false
            }
        case "GET_SERVICE_BY_ID_FAIL":
            return {
                ...state,
                service: action.payload,
                getServiceByIdLoading: false
            }

        /// ------------- get All Schedule Service Requests  ---------- /////

        case "SCHEDULE_SERVICE_LOADING":
            return {
                ...state,
                getAllScheduleServiceRequestsLoading: action.payload
            }
        case "SCHEDULE_SERVICE_SUCCESS":
            return {
                ...state,
                service: action.payload,
                getAllScheduleServiceRequestsLoading: false
            }
        case "SCHEDULE_SERVICE_FAIL":
            return {
                ...state,
                service: action.payload,
                getAllScheduleServiceRequestsLoading: false
            }

        /// ------------- Add Schedule Service  ---------- /////

        case "ADD_SCHEDULE_SERVICE_LOADING":
            return {
                ...state,
                addScheduleServiceLoading: action.payload
            }
        case "ADD_SCHEDULE_SERVICE_SUCCESS":
            return {
                ...state,
                addScheduleServiceLoading: false
            }
        case "ADD_SCHEDULE_SERVICE_FAIL":
            return {
                ...state,
                serviceError: action.payload,
                addScheduleServiceLoading: false
            }

        /// ------------- Edit Schedule Service  ---------- /////

        case "EDIT_SCHEDULE_SERVICE_LOADING":
            return {
                ...state,
                editScheduleServiceLoading: action.payload
            }
        case "EDIT_SCHEDULE_SERVICE_SUCCESS":
            return {
                ...state,
                editScheduleServiceLoading: false
            }
        case "EDIT_SCHEDULE_SERVICE_FAIL":
            return {
                ...state,
                serviceError: action.payload,
                editScheduleServiceLoading: false
            }

        /// ------------- Delete Schedule Service  ---------- /////

        case "DELETE_SCHEDULE_SERVICE_LOADING":
            return {
                ...state,
                deleteScheduleServiceLoading: action.payload
            }
        case "DELETE_SCHEDULE_SERVICE_SUCCESS":
            return {
                ...state,
                deleteScheduleServiceLoading: false
            }
        case "DELETE_SCHEDULE_SERVICE_FAIL":
            return {
                ...state,
                serviceError: action.payload,
                deleteScheduleServiceLoading: false
            }

        default:
            return state;
    }
}

