const initialState = {
  loading: false,
  pages: [],
  createLoading: false,
  updateLoading: false,
  deleteLoading: false,
};

function PagesReducer(state = initialState, action) {
  switch (action.type) {
    case "PAGES_GET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };

    case "PAGES_GET_SUCCESS":
      return {
        ...state,
        pages: action.payload,
        loading: false,
      };

    case "CREATE_LOADING_PAGE":
      return {
        ...state,
        createLoading: action.payload,
      };

    case "CREATE_PAGE_SUCCESS":
      return {
        ...state,
      };

    case "UPDATE_LOADING_PAGE":
      return {
        ...state,
        updateLoading: action.payload,
      };
    case "UPDATE_PAGE_SUCCESS":
      return {
        ...state,
      };

    case "DELETE_PAGE_LOADING":
      return {
        ...state,
        deleteLoading: action.payload,
      };

    case "DELETE_PAGE_SUCCESS":
      return {
        ...state,
      };

    default:
      return state;
  }
}

export default PagesReducer;
