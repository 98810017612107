
import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

const axiosApiCall = () => axios.create({
  headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
    },
  });


export const GetAllClient = (page, rowsPerPage) => async (dispatch) => {
    try {
      dispatch({
        type: "LOADING",
        payload: true,
      });
  
      const res = await axios.get(`${ApiURL}review/all-review?limit=${rowsPerPage}&page=${page}`);
  
      if (res.data.status === true) {
        const responseData = res.data.data || res.data.data.length > 0 ? res.data.data : [];

        dispatch({
          type: "CLIENT_SUCCESS",
          payload: responseData,
        });
        return res;
      } else {
        dispatch({
          type: "CLIENT_SUCCESS",
          payload: [],
        });
        return res;
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "LOADING",
        payload: false,
      });
    }
  };




  export const CreateClient = (data, handleClose) => async (dispatch) => {
    try {
      dispatch({
        type: "CREATE_LOADING_CLIENT",
        payload: true,
      });
  
      const res = await axiosApiCall().post(`${ApiURL}review/post-review`, data);
  
      if (res.data.status === true) {
        dispatch({
          type: "CREATE_CLIENT_SUCCESS",
          payload: res.data.data,
        });
        snackBar(res?.data?.message, res?.data?.status)
        handleClose()
        return res;
      }
    } catch (err) {
      snackBar(err?.response?.data?.message, err?.response?.data?.status)
      console.error(err);
    } finally {
      dispatch({
        type: "CREATE_LOADING_CLIENT",
        payload: false,
      });
    }
  };
  
  
  export const UpdateClient = (id, data, handleClose) => async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_LOADING_CLIENT",
        payload: true,
      });
  
      const res = await axiosApiCall().put(`${ApiURL}review/update/${id}`, data);
  
      if (res.data.status === true) {
        dispatch({
          type: "UPDATE_CLIENT_SUCCESS",
        });
        snackBar(res?.data?.message, res?.data?.status)
        handleClose()
        return res;
      }
      snackBar(res?.data?.message, res?.data?.status)
    } catch (err) {
      snackBar(err?.response?.data?.message, err?.response?.data?.status)
      console.error(err);
    } finally {
      dispatch({
        type: "UPDATE_LOADING_CLIENT",
        payload: false,
      });
    }
  };
  
  export const DeleteClient = (id, handleClose) => async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_CLIENT_LOADING",
        payload: true,
      });
      const res = await axios.delete(`${ApiURL}review/delete/${id}`);
  
      if (res.data.status === true) {
        dispatch({
          type: "DELETE_CLIENT_LOADING",
          payload: false,
        });
        dispatch({
          type: "DELETE_CLIENT_SUCCESS",
          payload: res.data.data,
        });
        snackBar(res?.data?.message, res?.data?.status)
        handleClose();
        return res;
      }
      snackBar(res?.data?.message, res?.data?.status)
    } catch (err) {
      snackBar(err?.response?.data?.message, err?.response?.data?.status)
      console.error(err);
      dispatch({
        type: "DELETE_CLIENT_LOADING",
        payload: false,
      });
    }
  };