import React from 'react';
import { Link } from 'react-router-dom'; // If using React Router
import { Typography, Button, Container, CssBaseline } from '@mui/material';

const InternalServerError = () => {
  return (
    <Container component="main" maxWidth="xs" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <CssBaseline />
      <div>
        <Typography variant="h1" align="center" color="textPrimary" gutterBottom>
         500
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
         Internal Server Error
        </Typography>
        <Typography variant="body1" align="center" color="textSecondary" paragraph>
          The page you are looking for might be in another castle.
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button component={Link} to="/admin/dashboard" variant="contained" color="primary">
            Go to Home
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default InternalServerError;