import React from 'react'
import Pricing from '../../pages/pricing/Pricing'
import { Box, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const PricingPlan = () => {
    return (
        <>
            <Box>
                <Grid container>
                    <Grid item xs={12} sx={{ display: "flex", alignItems: "center", flexDirection:"column", justifyContent: "center", marginTop: { xs: "20px", md: "42px", lg: "20px", xl: "42px" }, marginBottom: { xs: "20px", md: "25px", lg: "30px", xl: "30px" } }}>
                        <Typography sx={{ fontSize: { xs: "30px", md: "40px", lg: "40px", xl: "40px" }, fontWeight: "bold" }}>Pricing Plan</Typography>
                        <ul
                            className="breadcrumb breadcrumb-style-01 fadeInUp animated"
                            data-animate="fadeInUp"
                        >
                            <li className="breadcrumb-item">
                                <Link to="/" className="link-hover-dark-primary">
                                    Home
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span>Pricing Plan</span>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12}></Grid>
                </Grid>
                <Pricing />
            </Box >
        </>
    )
}

export default PricingPlan