import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  TextField,
} from "@mui/material";
import PageHeader from "../pageHeader/PageHeader.js";
import EnhancedTable from "../table/EnhancedTable.js";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons.js";
import "../../components/table/table.css";
import { useForm } from "../../common/useForms.js";
import FormDialog from "../dialog/dialog.js";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import DeleteDialog from "../dialog/deletedialog.js";
import { useLocation, useNavigate } from "react-router-dom";
import useUserRolePermissions from "../../common/UserPermissions.js";

import Loader from "../loader/Loader.js";
import {
  CreateWithdraws,
  DeleteWithdraw,
  GetAllRequestUserWithdraw,
  GetAllWithdraw,
} from "../../redux/action/WithdrawAction.js";
import { ApiURL, snackBar } from "../../redux/common.js";
import axios from "axios";
import { api } from "../../web/config/api.js";
import SelectBox from "../../common/SelectBox.js";
import { GetUserProfile } from "../../redux/action/UserManageAction.js";
import { UpdateUserManage } from "../../redux/action/UserManageAction.js";
import { getPermissions } from "../../common/permissions.js";
import { permissions } from "../../common/constants.js";
import dayjs from "dayjs";

const statusOptions = [
  { value: "approved", label: "Approved" },
  { value: "pending", label: "Pending" },
  { value: "rejected", label: "Rejected" },
];

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));
const initialValues = {
  amount: "",
};
const initialFilterValues = {
  status: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
  // { id: 30, title: "30" },
];

const WithDrawList = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectPostal, setselectPostal] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [downLoadData, setDownLoadData] = useState("");
  const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [bankErrors, setBankErrors] = useState({});
  const [isFilterClicked, setIsFilterClicked] = useState(false);

  const isFilterFormFilled = filterDatas.status.trim().length !== 0;

  const UserProfile = useSelector(
    (state) => state?.UserManageReducer?.userProfileData?.data
  );

  const [valuess, setValuess] = useState({
    bank_name: "",
    acc_number: "",
    ifsc_code: "",
    acc_holder_name: "",
  });

  useEffect(() => {
    if (UserProfile?.bank_details) {
      setValuess({
        ...valuess,
        bank_name: UserProfile?.bank_details?.bank_name,
        acc_number: UserProfile?.bank_details?.acc_number,
        ifsc_code: UserProfile?.bank_details?.ifsc_code,
        acc_holder_name: UserProfile?.bank_details?.acc_holder_name,
      });
    }
  }, [UserProfile?.bank_details]);

  const useClasses = useStyles();
  const userDataString = localStorage.getItem("user_data");
  const userData = JSON.parse(userDataString);
  const userRole =
    userData?.admin?.user_role?.role || userData?.user?.user_role?.role;

  const handleBankModal = () => {
    setEditModal(true);
    setBankErrors({});
  };

  const handleBankCloseModal = () => {
    setEditModal(false);
    setBankErrors({});
  };
  useEffect(() => {
    dispatch(GetUserProfile());
  }, []);

  const validateBankDetails = () => {
    let tempErrors = { ...bankErrors };
    // Validate bank_name
    if (
      valuess.bank_name === "" ||
      valuess.bank_name === undefined ||
      valuess.bank_name === null ||
      valuess?.bank_name?.trim() === ""
    ) {
      tempErrors = {
        ...tempErrors,
        bank_name: "Please enter bank name",
      };
    } else if (!/^[a-zA-Z0-9.]+([a-zA-Z\s.,/]*[a-zA-Z.,/]+)?$/.test(valuess.bank_name)) {
      tempErrors = {
        ...tempErrors,
        bank_name: "Bank name should only contain alphabets and without leading spaces",
      };
    } else {
      tempErrors = {
        ...tempErrors,
        bank_name: "",
      };
    }

    // Validate acc_number
    if (
      valuess.acc_number === "" ||
      valuess.acc_number === undefined ||
      valuess.acc_number === null ||
      valuess?.acc_number?.trim() === ""
    ) {
      tempErrors = {
        ...tempErrors,
        acc_number: "Please enter account number",
      };
    } else if (isNaN(valuess.acc_number)) {
      tempErrors = {
        ...tempErrors,
        acc_number: "Please enter valid account number",
      };
    } else {
      tempErrors = {
        ...tempErrors,
        acc_number: "",
      };
    }

    // Validate ifsc_code
    if (
      valuess.ifsc_code === "" ||
      valuess.ifsc_code === undefined ||
      valuess.ifsc_code === null ||
      valuess?.ifsc_code?.trim() === ""
    ) {
      tempErrors = {
        ...tempErrors,
        ifsc_code: "Please enter IFSC code",
      };
    } else if (valuess.ifsc_code && valuess.ifsc_code.length !== 11) {
      if (!/^[A-Z0-9]+$/.test(valuess.ifsc_code)) {
        tempErrors = {
          ...tempErrors,
          ifsc_code: "Please enter valid IFSC code",
        };
      } else {
        tempErrors = {
          ...tempErrors,
          ifsc_code: "Please enter a valid 11-character IFSC code",
        };
      }
    } else {
      tempErrors = {
        ...tempErrors,
        ifsc_code: "",
      };
    }

    // Validate acc_holder_name
    if (
      valuess.acc_holder_name === "" ||
      valuess.acc_holder_name === undefined ||
      valuess.acc_holder_name === null ||
      valuess?.acc_holder_name?.trim() === ""
    ) {
      tempErrors = {
        ...tempErrors,
        acc_holder_name: "Please enter account holder name",
      };
    } else if (!/^[A-Za-z ]+$/.test(valuess.acc_holder_name)) {
      tempErrors = {
        ...tempErrors,
        acc_holder_name:
          "Account holder name should only contain alphabets and spaces",
      };
    } else {
      tempErrors = {
        ...tempErrors,
        acc_holder_name: "",
      };
    }
    setBankErrors(tempErrors);
    // Check if has any errors
    const hasErrors = Object.values(tempErrors).some((value) => value !== "");

    if (hasErrors) {
      return false;
    } else {
      return true;
    }
  };

  const { getLoading, withdraw, createLoading, updateLoading } = useSelector(
    (state) => state?.WithdrawReducer
  );

  const withDrawUser = useSelector(
    (state) => state?.WithdrawReducer?.withdrawuser
  );

  const DownLoadPdf = async () => {
    const status = filterDatas.status || "";
    try {
      const res = await axios.get(`${ApiURL}withdraw-request/generate-excel?status=${status}`);

      setDownLoadData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching role data:", error);
      return {};
    }
  };

  useEffect(() => {
    DownLoadPdf();
  }, [isFilterClicked]);

  const userRoleData = useUserRolePermissions("Withdraw Request");

  const isEditAllowed = userRoleData && userRoleData.is_edit === 1;
  const isDeleteAllowed = userRoleData && userRoleData.is_delete === 1;
  const isAddAllowed = userRoleData && userRoleData.is_add === 1;

  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  //userdetails

  const fetchServiceDetail = async () => {
    await dispatch(GetAllWithdraw(rowsPerPage, page, ""));
  };

  //user withdrawlist

  const fetchUserWithdraw = async () => {
    await dispatch(GetAllRequestUserWithdraw(rowsPerPage, page, ""));
  };

  useEffect(() => {
    const allValuesSet = Object.values(filterDatas).every(
      (value) => value !== undefined && value !== ''
    )
    if (userRole !== "Community Super Admin" || UserProfile?.role == 'Community Member') {
      if (allValuesSet) {
        const status = filterDatas.status || "";
        dispatch(GetAllRequestUserWithdraw(rowsPerPage, page, status))
      } else {
        fetchUserWithdraw();
      }
    } else {
      if (allValuesSet) {
        const status = filterDatas.status || "";
        dispatch(GetAllWithdraw(rowsPerPage, page, status));
      } else {
        fetchServiceDetail();
      }
    }
  }, [userRole, rowsPerPage, page]);

  const columns = [
    {
      id: "user_name",
      numeric: false,
      disablePadding: true,
      label: "User",
    },

    {
      id: "amount",
      numeric: false,
      disablePadding: true,
      label: "Amount",
    },

    {
      id: "wallet_amount",
      numeric: false,
      disablePadding: true,
      label: "Wallet Amount",
    },

    {
      id: "createdAt",
      numeric: false,
      disablePadding: true,
      label: "Date",
    },
    {
      id: "status",
      label: "Status",
    },
    // !!getPermissions(permissions.business_category, "is_delete") ||
    // !!getPermissions(permissions.business_category, "is_edit")
    userRole === "Community Super Admin"
      ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
      : null,
    // )
    // : null,
  ].filter((el) => el !== null);

  const tableTitle = "WithDrawList";

  const handleChangeRow = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const UserId =
    localStorage.getItem("user_data") &&
    JSON.parse(localStorage.getItem("user_data"));

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if bank details exist in userData
    const hasBankDetails =
      UserProfile?.bank_details !== undefined &&
      UserProfile?.bank_details.bank_name !== undefined &&
      UserProfile?.bank_details.acc_number !== undefined &&
      UserProfile?.bank_details.ifsc_code !== undefined &&
      UserProfile?.bank_details.acc_holder_name !== undefined &&
      UserProfile?.bank_details !== null &&
      UserProfile?.bank_details.bank_name !== null &&
      UserProfile?.bank_details.acc_number !== null &&
      UserProfile?.bank_details.ifsc_code !== null &&
      UserProfile?.bank_details.acc_holder_name !== null;

    if (validate()) {
      if (dialogTitle === "Add Withdraw Request") {
        if (hasBankDetails) {
          const body = {
            user_id: UserId?.user?._id,
            amount: values.amount,
          };

          await dispatch(CreateWithdraws(body, handleClose));
          handleClose();
          dispatch(GetAllRequestUserWithdraw(rowsPerPage, page, ""));
          dispatch(GetUserProfile());
        } else {
          handleBankModal();
          dispatch(GetUserProfile());
        }
      }
    } else {
      // Handle validation failure if needed
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleBtnClick = () => {
    // navigate("/services/create-services", {
    //     state: { formName: "Create Service", serviceData: {} },
    // });
    setOpen(true);
    setDialogTitle("Add Withdraw Request");
    setValues(initialValues);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelected = rows.map((n) => n.id);
      // setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if (dialogTitle === "Add Withdraw Request") {
      if ("amount" in fieldValues) {
        if (
          fieldValues.amount === "" ||
          fieldValues.amount === undefined ||
          fieldValues.amount === null ||
          fieldValues.amount?.trim() === ""
        ) {
          temp.amount = "Please enter amount";
        } else if (!/^\d+$/.test(fieldValues.amount)) {
          temp.amount = "Please enter a valid number";
        } else if (
          parseInt(fieldValues.amount) <= 0 // Check if amount is less than or equal to 0
        ) {
          temp.amount = "Please enter an amount greater than 0";
        } else if (
          parseInt(fieldValues?.amount) > (UserProfile?.wallet_amount ?? 0)
        ) {
          temp.amount = "Withdrawal amount exceeds available balance";
        } else {
          temp.amount = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    } else {
      return false;
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleEditClick = (category) => {
    setOpen(true);
    setDialogTitle("Update Business Category");
    setValues({
      ...values,
      _id: category._id,
      category_name: category.category_name,
      status: category.is_active,
    });
    setErrors({});
  };

  const handleClickOpenDelete = (category) => {
    setOpenDelete(true);
    SetDeleteId(category._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    handleCloseDelete();
    await dispatch(DeleteWithdraw(DeleteId));
    await dispatch(GetAllWithdraw(rowsPerPage, page, ""));
  };

  const fields = [
    {
      id: "amount",
      label: "Amount",
      type: "text",
      value: values.amount,
      name: "amount",
      errors: errors.amount,
      maxLength: 20,
    },
  ];

  //filter
  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(GetAllWithdraw(rowsPerPage, page, ""));
    dispatch(GetAllRequestUserWithdraw(rowsPerPage, page, ""))
    setIsFilterClicked(false)
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterDatas({
      ...filterDatas,
      [name]: value,
    });
  };


  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setIsFilterClicked(true)
    const body = {
      status: filterDatas.status,
    };
    setFilterDatas(body);
    const status = filterDatas.status || "";
    setPage(1);
    if (UserProfile?.role == 'Community Super Admin') {
      dispatch(GetAllWithdraw(rowsPerPage, page, status));
    } else {
      dispatch(GetAllRequestUserWithdraw(rowsPerPage, page, status))
    }

    closeFilterDrawer1();
  };

  let tableData = [];
  if (userRole === "Community Super Admin") {
    tableData = withdraw?.requestData || [];
  } else {
    tableData = withDrawUser?.requestData || [];
  }

  const rows = tableData?.map((drawData) => ({
    _id: drawData?._id,
    user_name: drawData?.user_name || "-",
    amount: drawData?.amount || "-",
    wallet_amount: drawData?.closing_balance || "-",
    // wallet_amount: drawData?.wallet_amount || "-",
    createdAt: drawData?.createdAt
      ? dayjs(drawData.createdAt).format("DD/MM/YYYY")
      : "-",
    status: drawData.status || "Pending",
    action: (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {userRole === "Community Super Admin" && (
          <IconButton
            onClick={() => handleClickOpenDelete(drawData)}
            sx={{
              color: "black",
              "&:hover": {
                backgroundColor: "white",
                color: "#153e80",
              },
              "&:focus": {
                backgroundColor: "white",
                color: "#153e80",
                border: "none",
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    ),
  }));

  const handleChipClick = (data) => {
    setStatusModal(data);
  };

  const handleModalClose = () => {
    setStatusModal(false);
  };

  const handleStatusUpdate = async (e) => {
    e.preventDefault();
    setStatusUpdateLoading(true);
    try {
      const statusUpdate = e.target[0].value;
      const payload = {
        status: statusUpdate,
      };
      const res = await api.put(
        `${ApiURL}withdraw-request/update/${statusModal._id}`,
        payload
      );
      if (res?.status) {
        snackBar(res?.message, res?.status);
        dispatch(GetAllWithdraw(rowsPerPage, page, ""));
        handleModalClose();
        navigate("/admin-withdraw");
      } else {
        snackBar(res?.message, res?.status);
        dispatch(GetAllWithdraw(rowsPerPage, page, ""));
        handleModalClose();
        navigate("/admin-withdraw");
      }
    } catch (err) {
      dispatch(GetAllWithdraw(rowsPerPage, page, ""));
      handleModalClose();
    } finally {
      setStatusUpdateLoading(false);
      handleModalClose();
    }
  };

  //bankupdate
  const handleBankUpdate = async (e) => {
    e.preventDefault();

    if (!validateBankDetails()) {
      return;
    } else {
      setStatusUpdateLoading(true);

      const payload = {
        bank_name: valuess.bank_name,
        acc_number: valuess.acc_number,
        ifsc_code: valuess.ifsc_code,
        acc_holder_name: valuess.acc_holder_name,
      };

      const formData = new FormData();
      formData.append("bank_details", JSON.stringify(payload));
      try {
        await dispatch(UpdateUserManage(UserProfile?._id, formData));
        handleBankCloseModal();
        dispatch(GetUserProfile());
        handleClose();
        setOpen(true);
        setDialogTitle("Add Withdraw Request");
        dispatch(GetAllRequestUserWithdraw(rowsPerPage, page, ""));
      } catch (error) {
        snackBar("Error updating bank account holder:", error);
      } finally {
        setStatusUpdateLoading(false);
      }
    }
  };

  const handleChangess = (e) => {
    const { name, value } = e.target;
    setValuess({ ...valuess, [name]: value });
  };

  return (
    <div>
      <Box>
        <PageHeader
          title="Withdraw Request"
          wallet={UserProfile?.wallet_amount}
          isShowAdd={false}
          onBtnClick={handleBtnClick}
          btnText={
            getPermissions(permissions.withdraw, "is_add")
              ? "Create Withdraw Request"
              : ""
          }
          // btnText="Create WithDraw"
          onFilterClick={openFilterDrawer}
          loading={getLoading}
          href={downLoadData}
        />

        {getLoading ? (
          <Loader />
        ) : (
          <EnhancedTable
            rowsPerPage={rowsPerPage}
            key={5}
            columns={columns}
            rows={rows}
            order={order}
            orderBy={orderBy}
            selected={selected}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            title={tableTitle}
            tableList={tableData} // Pass the correct data to the tableList prop
            handleChipClick={handleChipClick}
          />
        )}
        {/* )} */}
        <FormDialog
          open={open}
          dialogTitle={dialogTitle}
          fields={
            dialogTitle === "Add Withdraw Request"
              ? fields.filter((el) => ["amount"].includes(el.name))
              : fields.filter((el) => ["amount"].includes(el.name))
          }
          submitText="Submit"
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          onChange={handleInputChange}
        />
        <DeleteDialog
          open={openDelete}
          // loading={deleteLoading}
          dialogTitle="Are you sure to want to delete this record?"
          handleClose={handleCloseDelete}
          handleDelete={() => handleSingleDelete(DeleteId)}
        />
        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
              gap: 2,
              width: { sm: "100%", md: "50%" },
            }}
            display="flex"
            alignItems="center"
          >
            <Box>
              Showing{" "}
              {userRole === "Community Super Admin"
                ? withdraw && withdraw?.currentPage
                : withDrawUser && withDrawUser?.currentPage}
              of{" "}
              {userRole === "Community Super Admin"
                ? withdraw && withdraw?.totalPages
                : withDrawUser && withDrawUser?.totalPages}{" "}
              Pages
            </Box>
            <Box display="flex" sx={{ gap: 1 }} alignItems="center">
              <Typography>Records per page</Typography>

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  id="limites"
                  name="limites"
                  value={rowsPerPage}
                  onChange={handleChangeRow}
                  displayEmpty
                  sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {RecordPerPage?.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name || item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Pagination
            count={
              userRole === "Community Super Admin"
                ? withdraw && withdraw?.totalPages
                : withDrawUser && withDrawUser?.totalPages
            }
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
            <Box className={classes.mainFilterBox}>
              <Stack className={classes.stackFilter}>
                <Typography className={classes.typographyFilter}>
                  Filter
                </Typography>

                <CloseIcon
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>
              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
              </Box>

              <div>
                <Box>
                  <SelectBox
                    name="status"
                    label="Status"
                    ClassName="status-select-filter"
                    value={filterDatas.status}
                    onChange={handleChange}
                    options={statusOptions}
                  />
                </Box>
                <Stack className={classes.buttonFilter}>
                  <Button
                    color="secondary"
                    size="large"
                    variant="contained"
                    className="cancel-filter"
                    onClick={resetFormFilter}
                    disabled={!isFilterFormFilled}
                  >
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    className="order-filter-button"
                    type="submit"
                    disabled={!isFilterFormFilled}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Filter
                  </Button>
                </Stack>
              </div>
            </Box>
          </form>
        </Drawer>
        {userRole === "Community Super Admin" && (
          <Dialog
            open={statusModal}
            fullWidth={false}
            onClose={false}
            disableBackdropClick={true} // Prevent closing when clicking outside
            disableEscapeKeyDown={true}
          >
            <div
              className="mui-dialog-box-popup"
              style={{ backgroundColor: "#fff", color: "#000" }}
            >
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" sx={{ color: "#000 !important" }}>
                  Update Status
                </Typography>

                <IconButton
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <CloseIcon onClick={handleModalClose} />
                </IconButton>
              </DialogTitle>

              <DialogContent sx={{ minWidth: "400px" }}>
                <form onSubmit={handleStatusUpdate}>
                  <div style={{ marginTop: "5px" }}>
                    <SelectBox
                      type="text"
                      id="approval_status"
                      name="approval_status"
                      label="Status"
                      ClassName="range-select-filter"
                      defaultValue={statusModal?.status || "pending"}
                      options={statusOptions}
                    />
                  </div>
                  <DialogActions
                    sx={{ justifyContent: "center", display: "flex" }}
                  >
                    <Button
                      type="submit"
                      // disabled={!isAnyFieldNotEmpty}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        border: "0",
                        ":hover": { bgcolor: "#1976d2", border: "0" },
                        backgroundColor: "#153e80",
                        marginLeft: {
                          xs: "0px",
                          sm: "15px",
                          md: "15px",
                          lg: "15px",
                        },
                        color: "#fff",
                        textTransform: "none",
                        minWidth: "100px",
                      }}
                    >
                      {statusUpdateLoading ? (
                        <CircularProgress
                          className={useClasses.customCircularProgress}
                        />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </DialogActions>
                </form>
              </DialogContent>
            </div>
          </Dialog>
        )}
      </Box>

      {/* bank details */}
      <Dialog
        open={EditModal}
        fullWidth={false}
        onClose={false}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <div
          className="mui-dialog-box-popup"
          style={{ backgroundColor: "#fff", color: "#000" }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ color: "#000 !important" }}>
              Bank Details
            </Typography>

            <IconButton
              sx={{
                color: "black",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#153e80",
                },
                "&:focus": {
                  backgroundColor: "white",
                  color: "#153e80",
                  border: "none",
                },
              }}
            >
              <CloseIcon onClick={handleBankCloseModal} />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ minWidth: "400px" }}>
            <form onSubmit={handleBankUpdate}>
              <div style={{ marginTop: "5px" }}>
                <TextField
                  sx={{ input: { color: "#000" } }}
                  id="bank_name"
                  label="Bank Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={valuess.bank_name}
                  name="bank_name"
                  onChange={handleChangess}
                  inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                  error={Boolean(bankErrors?.bank_name)}
                  helperText={bankErrors?.bank_name}
                  onKeyDown={(event) => {
                    if (
                      !/[a-z]/i.test(event.key) &&
                      event.key !== "Backspace" &&
                      event.key !== "Tab" &&
                      event.key !== " "
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
              <div style={{ marginTop: "5px" }}>
                <TextField
                  sx={{ input: { color: "#000" } }}
                  margin="dense"
                  id="acc_number"
                  label="Bank Account Number"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={valuess.acc_number}
                  name="acc_number"
                  onChange={handleChangess}
                  inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                  error={Boolean(bankErrors?.acc_number)}
                  helperText={bankErrors?.acc_number}
                />
              </div>
              <div style={{ marginTop: "5px" }}>
                <TextField
                  sx={{ input: { color: "#000" } }}
                  margin="dense"
                  id="ifsc_code"
                  label="Bank IFSC Code"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={valuess.ifsc_code}
                  name="ifsc_code"
                  onChange={handleChangess}
                  inputProps={{ maxLength: 11 }} // Adjust the max length as needed
                  error={Boolean(bankErrors?.ifsc_code)}
                  helperText={bankErrors?.ifsc_code}
                />
              </div>
              <div style={{ marginTop: "5px" }}>
                <TextField
                  sx={{ input: { color: "#000" } }}
                  margin="dense"
                  id="acc_holder_name"
                  label="Bank Account Holder"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={valuess.acc_holder_name}
                  name="acc_holder_name"
                  onChange={handleChangess}
                  inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                  error={Boolean(bankErrors?.acc_holder_name)}
                  helperText={bankErrors?.acc_holder_name}
                />
              </div>
              <DialogActions sx={{ justifyContent: "center", display: "flex" }}>
                <Button
                  type="submit"
                  // disabled={!isAnyFieldNotEmpty}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    border: "0",
                    ":hover": { bgcolor: "#1976d2", border: "0" },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    color: "#fff",
                    textTransform: "none",
                    minWidth: "100px",
                  }}
                >
                  {statusUpdateLoading ? (
                    <CircularProgress
                      className={useClasses.customCircularProgress}
                    />
                  ) : (
                    "Update"
                  )}
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default WithDrawList;
