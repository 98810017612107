import React, { useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "../../context/LocationContext";
import Input from "../../components/Input";

const validationSchemaForm = Yup.object().shape({
  address: Yup.string()
    .required("Please enter address")
    .matches(/^[a-zA-Z0-9\s,.'-]{3,}$/, "Please enter a valid address and without leading spaces"),
  country: Yup.object().required("Please select country"),
  state: Yup.object().required("Please select state"),
  city: Yup.object().required("Please select city"),
  postal: Yup.object().required("Please select postal code"),
});

const Step3 = ({ handleBack, handleNext, activeStep, value }) => {
  const {
    country,
    getAllCountry,
    getState,
    getRole,
    state,
    role,
    getCity,
    city,
    getPostalCode,
    postal,
    setState,
    setCity,
    setPostal,
  } = useLocation();

  useEffect(() => {
    getAllCountry(true);
  }, []);

  const formik = useFormik({
    initialValues: {
      address: value.address || "",
      postalcode_id: value.postalcode_id || "",
      state: value.state || "",
      city: value.city || "",
      country: value.country || "",
      postal: value.postal || "",
    },
    validationSchema: validationSchemaForm,
    onSubmit: (values) => {
      // You can perform form submission here
      handleNext(values);
    },
  });
  return (
    <>
      <Box
        component={Paper}
        sx={{
          padding: {
            xs: "30px",
            sm: "30px",
            md: "40px",
            lg: "50px",
            xl: "60px",
          },
        }}
      // sx={{ backgroundColor: "green" }}
      >
        <Typography
          textAlign="center"
          fontWeight={500}
          fontSize={28}
          marginBottom={2}
          color="black"
          font-family="Nunito,Inter var,Roboto,Helvetica Neue,Arial,sans-serif"
        >
          Contact Information
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12} sm={12}>
              <Input
                id="address"
                name="address"
                type="text"
                label="Address"
                onChange={formik.handleChange}
                value={formik.values.address}
                onBlur={formik.handleBlur}
                maxLength={500}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={
                  formik.touched.address && formik.errors.address ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.address}
                    </div>
                  ) : null
                }
                maxRows={2}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <div style={{ marginTop: "1rem" }}>
                <Autocomplete
                  id="country"
                  name="country"
                  value={formik.values.country || null}
                  // classes={customStyles}
                  onChange={(e, value) => {
                    formik.setFieldValue("country", value);
                    if (value?._id) getState(value?._id);
                    setState([]);
                    setCity([]);
                    setPostal([]);
                    formik.setFieldValue("state", "");
                    formik.setFieldValue("city", "");
                    formik.setFieldValue("postal", "");
                  }}
                  options={country?.countryData || []}
                  autoHighlight
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={
                    formik.touched.country && formik.errors.country ? (
                      <div className="err-msg-font">
                        {formik.errors.country}
                      </div>
                    ) : null
                  }
                  getOptionLabel={(option) => option.country_name}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#000" }}
                      {...params}
                      label="Country"
                      inputProps={{
                        ...params.inputProps,
                        style: { color: "#000" },
                      }}
                    />
                  )}
                />
                {formik.touched.country && formik.errors.country && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ fontSize: "12px" }}
                  >
                    {formik.errors.country}
                  </Typography>
                )}
              </div>
            </Grid>

            <Grid item md={6} xs={12} sm={12}>
              <div style={{ marginTop: "1rem" }}>
                <Autocomplete
                  id="state"
                  name="state"
                  value={formik.values.state || null}
                  onChange={(e, value) => {
                    formik.setFieldValue("state", value);
                    if (value?._id) getCity(value?._id);
                    setCity([]);
                    setPostal([]);
                    formik.setFieldValue("city", "");
                    formik.setFieldValue("postal", "");
                  }}
                  options={state || []}
                  autoHighlight
                  getOptionLabel={(option) => option.state_name}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#000" }}
                      {...params}
                      label="State"
                      inputProps={{
                        ...params.inputProps,
                        style: { color: "#000" },
                      }}
                    />
                  )}
                />
                {formik.touched.state && formik.errors.state && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ fontSize: "12px" }}
                  >
                    {formik.errors.state}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <div style={{ marginTop: "1rem" }}>
                <Autocomplete
                  id="city"
                  name="city"
                  value={formik.values.city || null}
                  onChange={(e, value) => {
                    formik.setFieldValue("city", value);
                    if (value?._id) getPostalCode(value?._id);
                    setPostal([]);
                    formik.setFieldValue("postal", "");
                  }}
                  options={city || []}
                  autoHighlight
                  getOptionLabel={(option) => option.city_name}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#000" }}
                      {...params}
                      label="City"
                      inputProps={{
                        ...params.inputProps,
                        style: { color: "#000" },
                      }}
                    />
                  )}
                />
                {formik.touched.city && formik.errors.city && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ fontSize: "12px" }}
                  >
                    {formik.errors.city}
                  </Typography>
                )}
              </div>
            </Grid>

            <Grid item md={6} xs={12} sm={12}>
              <div style={{ marginTop: "1rem" }}>
                <Autocomplete
                  id="postal"
                  name="Postal code"
                  value={formik.values.postal || null}
                  onChange={(e, value) => formik.setFieldValue("postal", value)}
                  options={postal || []}
                  autoHighlight
                  getOptionLabel={(option) => option.postal_code?.toString()}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#000" }}
                      {...params}
                      label="Postal code"
                      inputProps={{
                        ...params.inputProps,
                        style: { color: "#000" },
                      }}
                    />
                  )}
                />
                {formik.touched.postal && formik.errors.postal && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ fontSize: "12px" }}
                  >
                    {formik.errors.postal}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "center", mt: 1 }}
          >
            <Grid item>
              <Button
                sx={{ margin: 1 }}
                type="button"
                variant="contained"
                // disabled={activeStep === 0}
                onClick={() => handleBack(formik.values)}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{ margin: 1 }}
                type="submit"
                // onClick={handleSubmit}
                variant="contained"
              >
                {!activeStep <= 2 ? "Next" : "Submit"}
              </Button>
            </Grid>
          </Grid>
          {/* </Grid> */}
        </form>
      </Box>
    </>
  );
};

export default Step3;
