import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessCategory } from "../../redux/action/BusinessCategoryAction";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import SelectBox from "../../common/SelectBox";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetAllPostalCodes,
  clearePostalCodes,
} from "../../redux/action/PostalCodeAction";
import { GetAllCities, cleareAllCities } from "../../redux/action/CityAction";
import { GetAllStates, cleareAllStates } from "../../redux/action/StateAction";
import Input from "../../common/Input";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { GetAllCountry } from "../../redux/action/CountryAction";
import PlacesAutoComplete from "../googleautocomplete/PlacesAutoComplete";
import { useLoadScript } from "@react-google-maps/api";
import {
  createReferral,
  getAllReferral,
  updateReferral,
} from "../../redux/action/ReferralAction";
import moment from "moment";

const statusOptions = [
  { value: "true", label: "Active" },
  { value: "false", label: "Inactive" },
];

const initialFilterValues = {
  page: 1,
  business_category_id: { _id: "", category_name: "" },
  address: "",
  country_id: { _id: "", country_name: "" },
  state_id: "",
  city_id: "",
  closing_date: "",
  location: "",
  description: "",
  rowsPerPage: 10,
};

const CreateReferralList = () => {
  const {
    getLoading,
    createReferralLoading,
    updateReferralLoading,
    b_category,
  } = useSelector((state) => state?.BusinessCategoryReducer);

  const referralLocation = useLocation();
  const {
    _id,
    business_category_id,
    business_category,
    address,
    country_id,
    country_name,
    state_id,
    state_name,
    city_id,
    city_name,
    closing_date,
    location,
    longitude,
    latitude,
    description,
  } = referralLocation?.state?.referralData;

  const today = dayjs();
  const today1 = dayjs().startOf('day');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allCountryData } = useSelector((state) => state?.CountryReducer);

  const { allStateData } = useSelector((state) => state.StateReducer);

  const { allCitiesData } = useSelector((state) => state.CityReducer);

  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedDirection, setSelectedDirection] = useState();
  const [page, setPage] = useState(1);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAQa-wJEHiHascwWe3H7Xp19B1klmTeN8w",
    libraries: ["places"],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilterDatas((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };

  const handleLocationChange = (name, value) => {
    setFilterDatas((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };
  useEffect(() => {
    dispatch(getBusinessCategory("", "", "", { is_active: true }));
  }, []);
  useEffect(() => {
    dispatch(GetAllCountry(true));
  }, []);
  useEffect(() => {
    if (filterDatas?.country_id._id) {
      dispatch(GetAllStates(filterDatas?.country_id._id, true));
      dispatch(cleareAllStates());
      dispatch(cleareAllCities());
      dispatch(clearePostalCodes());
    }
  }, [filterDatas?.country_id]);

  const UserSchema = Yup.object().shape({
    business_category_id: Yup.object().required(
      "Please select business category"
    ),
    selectedDirection: selectedDirection?.results[0].formatted_address
      ? Yup.object().required("Please enter address")
      : Yup.object().required("Please enter address"),
    description: Yup.string().required("Please enter description"),
    location: Yup.string().required("Please enter location"),
    country_id: Yup.object().required("Please select country"),
    state_id: Yup.object().required("Please select state"),
    city_id: Yup.object().required("Please select city"),
    closing_date: Yup.date()
    .required("Please enter end date")
    .min(today1, "Please enter present or future date"),
  });

  const handleClose = () => {
    navigate("/admin-my-referrals");
  };

  const [createReferralSubmitting, setCreateReferralSubmitting] =
    useState(false);

  const formik = useFormik({
    initialValues: {
      business_category_id:
        business_category_id && business_category
          ? {
              _id: business_category_id || "",
              category_name: business_category || "",
            }
          : null,
      selectedDirection: address || "",
      country_id:
        country_id && country_name
          ? { _id: country_id || "", country_name: country_name || "" }
          : null,
      state_id:
        state_id && state_name
          ? { _id: state_id || "", state_name: state_name || "" }
          : null,
      city_id:
        city_id && city_name
          ? { _id: city_id || "", city_name: city_name || "" }
          : null,
      closing_date: closing_date || "",
      location: location || "",
      longitude: longitude ? longitude : selectedDirection?.lng || "",
      latitude: latitude ? latitude : selectedDirection?.lat || "",
      description: description || "",
    },
    validationSchema: UserSchema,
    onSubmit: async (values, { resetForm }) => {
      const formattedDate = new Date(values.closing_date);
      const body = {
        business_category_id: values.business_category_id?._id,
        address:
          selectedDirection?.results[0].formatted_address || address || "",
        country_id: values?.country_id?._id,
        state_id: values.state_id?._id,
        city_id: values.city_id?._id,
        // closing_date: formattedDate,
        closing_date: moment(formattedDate).format("YYYY-MM-DD"),
        location: values.location,
        longitude: selectedDirection?.lng ? selectedDirection?.lng : longitude,
        latitude: selectedDirection?.lat
          ? selectedDirection?.lat
          : latitude || "",
        description: values.description,
      };

      const formData = new FormData();

      if (referralLocation.state.formName === "Create Referral") {
        formData.append("business_category_id", body.business_category_id);
        formData.append("address", body.address);
        formData.append("country_id", body.country_id);
        formData.append("state_id", body.state_id);
        formData.append("city_id", body.city_id);
        formData.append("closing_date", body.closing_date);
        formData.append("location", body.location);
        formData.append("longitude", body.longitude);
        formData.append("latitude", body.latitude);
        formData.append("description", body.description);
        // if (body.description)
        //   formData.append("description", JSON.stringify(body.description));
      } else {
        formData.append("business_category_id", body.business_category_id);
        formData.append("address", body.address);
        formData.append("country_id", body.country_id);
        formData.append("state_id", body.state_id);
        formData.append("city_id", body.city_id);
        formData.append("closing_date", body.closing_date);
        formData.append("location", body.location);
        formData.append("longitude", body.longitude);
        formData.append("latitude", body.latitude);
        formData.append("description", body.description);
      }
      // for (let pair of formData.entries()) {
      // }
      if (referralLocation.state.formName === "Create Referral") {
        setCreateReferralSubmitting(true);
        await dispatch(createReferral(formData)).then((res) => {
          if (res?.data?.status === true) {
            setCreateReferralSubmitting(false);
            navigate("/admin-my-referrals");
            dispatch(getAllReferral(1, "", rowsPerPage));
          } else {
            setCreateReferralSubmitting(false);
          }
        });
        handleClose();
      } else {
        setCreateReferralSubmitting(true);
        await dispatch(updateReferral(_id, formData)).then((res) => {
          if (res?.data?.status === true) {
            setCreateReferralSubmitting(false);
            navigate("/admin-my-referrals");
            dispatch(getAllReferral(1, "", rowsPerPage));
          }
          handleClose();
        });
      }
    },
  });

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem",
          // height: "100vh",
        }}
      >
        <ArrowCircleLeftOutlinedIcon
          fontSize="large"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/admin-my-referrals")}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item md={8} xs={12} sm={12}>
            <Grid item md={12}>
              <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                <form onSubmit={formik.handleSubmit}>
                  <Typography
                    textAlign="center"
                    fontWeight={900}
                    fontSize={28}
                    marginBottom={2}
                    color="black"
                  >
                    {referralLocation.state.formName}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Box>
                        <Autocomplete
                          id="business_category_id"
                          name="business_category_id"
                          value={formik.values?.business_category_id}
                          // classes={customStyles}
                          onChange={(e, value) => {
                            formik.setFieldValue("business_category_id", value);
                          }}
                          options={b_category?.categoryData || []}
                          autoHighlight
                          // onBlur={formik.handleBlur}
                          getOptionLabel={(option) => option.category_name}
                          sx={{ marginTop: "15px" }}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="Business Category"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                              error={
                                formik.touched.business_category_id &&
                                Boolean(formik.errors.business_category_id)
                              }
                            />
                          )}
                        />
                        {formik.touched.business_category_id &&
                          formik.errors.business_category_id && (
                            <div className="err-msg-font">
                              {formik.errors.business_category_id}
                            </div>
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <div className="places-container">
                        <PlacesAutoComplete
                          id="selected"
                          name="selectedDirection"
                          selected={formik.values.selectedDirection}
                          setSelected={(value, a) => {
                            setSelectedDirection(value);
                            if (value?.results?.length > 0) {
                              formik.setFieldValue("selectedDirection", value);
                            } else {
                              formik.setFieldValue("selectedDirection", "");
                            }
                          }}
                          formikProps={formik}
                          isLoaded={isLoaded}
                        />
                        {formik.touched.selectedDirection &&
                          formik.errors.selectedDirection && (
                            <div style={{ color: "red", fontSize: "13px" }}>
                              {formik.errors.selectedDirection}
                            </div>
                          )}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Box>
                        <Autocomplete
                          id="country"
                          name="country_id"
                          value={formik.values.country_id}
                          // classes={customStyles}
                          onChange={(e, value) => {
                            formik.setFieldValue("country_id", value);
                            dispatch(GetAllStates(value?._id, true));
                            dispatch(cleareAllStates());
                          }}
                          options={allCountryData?.countryData || []}
                          autoHighlight
                          // onBlur={formik.handleBlur}
                          getOptionLabel={(option) => option.country_name}
                          sx={{ marginTop: "15px" }}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="Country"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                              error={
                                formik.touched.country_id &&
                                Boolean(formik.errors.country_id)
                              }
                            />
                          )}
                        />
                        {formik.touched.country_id &&
                          formik.errors.country_id && (
                            <div className="err-msg-font">
                              {formik.errors.country_id}
                            </div>
                          )}
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Box>
                        <Autocomplete
                          id="state"
                          name="state_id"
                          value={formik.values.state_id || null}
                          onChange={(e, value) => {
                            formik.setFieldValue("state_id", value);
                            dispatch(GetAllCities(value?._id, true));
                            dispatch(cleareAllCities());
                          }}
                          options={allStateData || []}
                          autoHighlight
                          getOptionLabel={(option) => option.state_name}
                          sx={{ marginTop: "15px" }}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="State"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                              error={
                                formik.touched.state_id &&
                                Boolean(formik.errors.state_id)
                              }
                            />
                          )}
                        />
                        {formik.touched.state_id && formik.errors.state_id && (
                          <div className="err-msg-font">
                            {formik.errors.state_id}
                          </div>
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Box>
                        <Autocomplete
                          id="city"
                          name="city_id"
                          value={formik.values.city_id || null}
                          onChange={(e, value) => {
                            formik.setFieldValue("city_id", value);
                            dispatch(GetAllPostalCodes(value?._id, true));
                            dispatch(clearePostalCodes());
                          }}
                          options={allCitiesData || []}
                          autoHighlight
                          getOptionLabel={(option) => option.city_name}
                          sx={{ marginTop: "15px" }}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="City"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                              error={
                                formik.touched.city_id &&
                                Boolean(formik.errors.city_id)
                              }
                            />
                          )}
                        />
                        {formik.touched.city_id && formik.errors.city_id && (
                          <div className="err-msg-font">
                            {formik.errors.city_id}
                          </div>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["DatePicker", "DatePicker"]}
                          >
                            <DatePicker
                              id="closing_date"
                              style={{ border: "1px solid black !important" }}
                              sx={{ width: "100%", border: "none !important" }}
                              name="closing_date"
                              fullWidth
                              label="End Date"
                              // value={formik.values.closing_date}}
                              // value={"12-12-1987"}
                              value={
                                formik.values.closing_date
                                  ? dayjs(formik.values.closing_date)
                                  : null
                              }
                              format="DD/MM/YYYY"
                              minDate={today}
                              onChange={(newValue) =>
                                formik.setFieldValue("closing_date", newValue)
                              }
                              slotProps={{
                                textField: {
                                  variant: "outlined",
                                  error: Boolean(
                                    formik.touched.closing_date &&
                                      formik.errors.closing_date
                                  ), //error handling
                                  helperText: formik.touched.closing_date &&
                                    formik.errors.closing_date && (
                                      <div
                                        style={{ marginLeft: "-12px" }}
                                        className="err-msg-font"
                                      >
                                        {formik.errors.closing_date}
                                      </div>
                                    ),
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Typography
                        textAlign="left"
                        fontWeight={450}
                        fontSize={18}
                        marginTop={1.5}
                        marginLeft={1}
                        marginBottom={-1}
                        color="#666666"
                      >
                        Location
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                      <FormControl sx={{ marginLeft: "9px" }}>
                        <RadioGroup
                          id="location"
                          name="location"
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          onChange={(e, value) => {
                            formik.setFieldValue("location", value);
                          }}
                          value={formik.values.location}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="for within chapter"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="for city"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="for state"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="for global"
                          />
                        </RadioGroup>
                        {formik.touched.location && formik.errors.location && (
                          <div className="err-msg-font">
                            {formik.errors.location}
                          </div>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                      <Input
                        name={`description`}
                        type="text"
                        value={formik.values.description}
                        label="Description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        maxLength={500}
                        error={
                          formik.touched.description &&
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                            ? formik.errors.description
                            : ""
                        }
                      />
                      {formik.touched.description &&
                        formik.errors.description && (
                          <div className="err-msg-font">
                            {formik.errors.description}
                          </div>
                        )}
                    </Grid>
                  </Grid>
                  {/* (
                    referralLocation.state.formName === "Create Referral"
                      ? createReferralSubmitting
                    ) */}
                  {createReferralSubmitting ? (
                    <Button sx={{ marginTop: 4 }} variant="contained">
                      <CircularProgress
                        style={{ width: "25px", height: "25px", color: "#fff" }}
                      />
                    </Button>
                  ) : (
                    <Button
                      sx={{ marginTop: 4 }}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  )}
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CreateReferralList;
