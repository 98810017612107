import React, { useEffect, useState } from "react";
import { Typography, makeStyles, Container, Button } from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { ApiURL } from "../../redux/common";
import Loader from "../../web/components/loader/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    textAlign: "center",
    maxWidth: 400,
    margin: "auto",
    marginTop: theme.spacing(8),
  },
  icon: {
    color: theme.palette.success.main,
    fontSize: "64px !important",
    marginBottom: theme.spacing(3),
  },
  iconattended: {
    color: theme.palette.warning.main,
    fontSize: "64px !important",
    marginBottom: theme.spacing(3),
  },
  iconfailed: {
    color: theme.palette.error.main,
    fontSize: "64px !important",
    marginBottom: theme.spacing(3),
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  bodyText: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 3),
  },
  message: {
    marginTop: theme.spacing(2),
  },
  homeButton: {
    marginTop: theme.spacing(3),
  },
}));

const EventAttendedSuccessfully = ({ name }) => {
  const classes = useStyles();

  const nav = useNavigate();

  const handleHomeButtonClick = () => {
    nav("/");
  };

  return (
    <Container className={classes.root}>
      <CheckCircleIcon className={classes.icon} />
      <Typography variant="h5" className={classes.title} gutterBottom>
        Event Successfully Attended!
      </Typography>
      <Typography variant="body1" className={classes.bodyText}>
        Thanks {name} for attending the event. We hope you had a great time!
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.homeButton}
        onClick={handleHomeButtonClick}
      >
        Go to Home
      </Button>
    </Container>
  );
};

const EventAlreadyAttended = () => {
  const classes = useStyles();

  const nav = useNavigate();

  const handleHomeButtonClick = () => {
    nav("/");
  };

  return (
    <Container className={classes.root}>
      <ErrorOutlineIcon className={classes.iconattended} />
      <Typography variant="h5" gutterBottom>
        Event Already Attended
      </Typography>
      <Typography variant="body1" className={classes.message}>
        You have already attended this event. Thank you for your participation!
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.homeButton}
        onClick={handleHomeButtonClick}
      >
        Go to Home
      </Button>
    </Container>
  );
};

const EventURLNotFound = () => {
  const classes = useStyles();

  const nav = useNavigate();

  const handleHomeButtonClick = () => {
    nav("/");
  };

  return (
    <Container className={classes.root}>
      <ErrorOutlineIcon className={classes.iconattended} />
      <Typography variant="h5" gutterBottom>
        Event URL Not found
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.homeButton}
        onClick={handleHomeButtonClick}
      >
        Go to Home
      </Button>
    </Container>
  );
};

const EventAttendanceFailed = () => {
  const classes = useStyles();

  const nav = useNavigate();

  const handleHomeButtonClick = () => {
    nav("/");
  };

  return (
    <Container className={classes.root}>
      <CancelIcon className={classes.iconfailed} />
      <Typography variant="h5" gutterBottom>
        Event Attendance Failed
      </Typography>
      <Typography variant="body1" className={classes.message}>
        Oops! Something went wrong during the attendance process. Please try
        again later.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.homeButton}
        onClick={handleHomeButtonClick}
      >
        Go to Home
      </Button>
    </Container>
  );
};

const EventAttended = () => {
  const [searchParams] = useSearchParams();
  const event_id = searchParams.get("event_id");
  const user_id = searchParams.get("user_id");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setdata] = useState({});
  const [alreadyAttended, setAlreadyAttended] = useState(false);
  const [isURL, setIsURL] = useState(false);

  const markAttendance = async () => {
    setIsURL(true)
    setLoading(false);
    try {
      // const res = await axios.post(`${ApiURL}event/event-attendence`, {
      //   user_id: user_id,
      //   event_id: event_id,
      // });
      // if (res?.data?.status) {
      //   setdata(res.data.data);
      // } else {
      //   setAlreadyAttended(true);
      // }
    } catch (error) {
      setError(true);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (event_id && user_id) {
      markAttendance();
    } else {
      markAttendance()
      setLoading(false);
    }
  }, [event_id, user_id]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <EventAttendanceFailed />;
  }

  // if (alreadyAttended) {
  //   return <EventAlreadyAttended />;
  // }

  if(isURL){
    return <EventURLNotFound />;
  }

  // return <EventAttendedSuccessfully name={data?.user_name || ""} />;
};

export default EventAttended;
