const initialState = {
    getLoading: false,
    createLoading: false,
    updateLoading: false,
    deleteLoading: false,
    b_category: [],
    message: ""
}


const BusinessCategoryReducer = (state = initialState, action) => {
    switch (action.type) {


        // -----------  Get Portion -----------------

        case "LOADING_GET_BUSINESS_CATEGORY":
            return {
                ...state,
                getLoading: action.payload
            }
        case "GET_BUSINESS_CATEGORY":
            return {
                ...state,
                b_category: action.payload,
            }
        case "FAIL_GET_BUSINESS_CATEGORY":
            return {
                ...state,
                b_category: action.payload,
            }


        // -----------  Create Portion -----------------


        case "LOADING_CREATE_BUSINESS_CATEGORY":
            return {
                ...state,
                createLoading: action.payload
            }
        case "CREATE_BUSINESS_CATEGORY":
            return {
                ...state,
                b_category: action.payload,
            }
        case "FAIL_CREATE_BUSINESS_CATEGORY":
            return {
                ...state,
                message: action.payload,
            }


        // -----------  Update Portion -----------------

        case "LOADING_UPDATE_BUSINESS_CATEGORY":
            return {
                ...state,
                updateLoading: action.payload
            }
        case "UPDATE_BUSINESS_CATEGORY":
            return {
                ...state,
            }
        case "FAIL_UPDATE_BUSINESS_CATEGORY":
            return {
                ...state,
            }


        // -----------  Delete Portion -----------------

        case "LOADING_DELETE_BUSINESS_CATEGORY":
            return {
                ...state,
                deleteLoading: action.payload
            }
        case "DELETE_BUSINESS_CATEGORY":
            return {
                ...state,
            }
        case "FAIL_DELETE_BUSINESS_CATEGORY":
            return {
                ...state,
            }

        default:
            return state;
    }
}

export default BusinessCategoryReducer