import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import styled from "@emotion/styled";
import moment from "moment/moment";
import SelectBox from "../../common/SelectBox";
import axios from "../../redux/ApiConfig";
import { ApiURL, snackBar } from "../../redux/common";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import BusinessDocCard from "../businessDocCard/BusinessDocCard";

const statusOptions = [
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
];

const ViewReferralDetails = () => {
  const navigate = useNavigate("");

  const state = useLocation();

  const data =
    localStorage.getItem("user_data") &&
    JSON.parse(localStorage.getItem("user_data"));
  const referralDetail = state?.state?.referralDetailData;
  const [status, setStatus] = useState(
    referralDetail?.approval_status || "pending"
  );
  const formattedDate = moment(referralDetail?.closing_date).format(
    "DD/MM/YYYY"
  );

  function subStr(txt, len) {
    let acceptedText =
      txt.length < len ? txt : txt.substring(0, len - 1) + "...";
    return acceptedText;
  }
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const handleRefferalInterest = async () => {
    setLoading(true);
    try {
      await axios
        .put(`${ApiURL}referral/raise-interest/${referralDetail._id}`)
        .then((res) => {
          if (res.data.status) {
            setLoading(false);
            snackBar(res.data.message, res.data.status);
            setIsApproved(true);
          } else {
            setLoading(false);
            snackBar(res.data.message, res.data.status);
          }
        });
    } catch (error) {
      setLoading(false);
      snackBar(error, false);
    }
  };

  return (
    <div>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          // backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <ArrowCircleLeftOutlinedIcon
            fontSize="large"
            onClick={() => navigate("/admin-referral")}
            sx={{ cursor: "pointer", marginTop: "5px", marginBottom: "5px" }}
          />
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              paddingBottom: "25px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                width: "90%",
                marginTop: "0px",
                height: "100%",
                boxShadow: "1px 4px 9px -3px",
                borderRadius: "10px",
                paddingLeft: "50px",
                paddingRight: "50px",
                // backgroundColor: "#d1d1d17a",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "20px",
                  color: "black",
                  marginTop: "-11px",
                  padding: "22px 15px",
                }}
              >
                Refferal Details
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  marginTop: "10px",
                }}
              >
                {/* sx={{marginLeft:"16px",width:"95%",marginBottom:"15px"}} */}
                <Grid container spacing={2} sx={{ marginBottom: "15px" }}>
                  <Grid
                    item
                    xs={12}
                    sx={{ boxShadow: "1px 4px 9px -3px", borderRadius: "10px" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Created
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {referralDetail?.created_by_user || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Category
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {referralDetail?.business_category || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Chapter Name
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {referralDetail?.chapter_name || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Address
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {referralDetail?.address || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Closing Date
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {formattedDate || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Description
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                        overflowWrap: "break-word", /* Modern equivalent of word-wrap */
                        wordBreak: "break-word", /* Breaks words at arbitrary points if necessary */
                        whiteSpace: "normal"
                      }}
                    >
                      {referralDetail?.description || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                {/* <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Amount
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {referralDetail?.amount || "-"}
                    </Typography>
                  </Grid>
                </Grid> */}
                <div
                  style={{
                    border: "2px solid dimgray",
                    borderRadius: "9px",
                    marginBottom: "1em",
                  }}
                >
                  {data?.user?.user_role?.role == "Community Super Admin" ||
                    data?.user?.user_role?.role == "Community Admin" ? (
                    <>
                      {referralDetail["interested_candidates"]?.length > 0 &&
                        referralDetail?.is_approved ? (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "800",
                                fontSize: "20px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Approved Candidate
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "800",
                                fontSize: "20px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Interested Candidates
                            </Typography>
                          </Grid>
                        </Grid>
                      )}

                      <Table size="medium" aria-label="purchases">
                        {referralDetail["interested_candidates"]?.length > 0 ? (
                          <>
                            <TableHead>
                              <TableRow>
                                {referralDetail?.is_approved ? (
                                  <>
                                    <TableCell sx={{ fontSize: "16px" }}>
                                      #
                                    </TableCell>
                                    <TableCell sx={{ fontSize: "16px" }}>
                                      Approved to User
                                    </TableCell>
                                    <TableCell sx={{ fontSize: "16px" }}>
                                      Approved by User
                                    </TableCell>
                                    <TableCell sx={{ fontSize: "16px" }}>
                                      Amount
                                    </TableCell>
                                    <TableCell sx={{ fontSize: "16px" }}>
                                      Description
                                    </TableCell>
                                  </>
                                ) : (
                                  <>
                                    <TableCell sx={{ fontSize: "16px" }}>
                                      #
                                    </TableCell>
                                    <TableCell sx={{ fontSize: "16px" }}>
                                      Name
                                    </TableCell>
                                    <TableCell sx={{ fontSize: "16px" }}>
                                      Contact
                                    </TableCell>
                                    <TableCell sx={{ fontSize: "16px" }}>
                                      Email
                                    </TableCell>
                                  </>
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {referralDetail?.is_approved ? (
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    <label
                                      style={{
                                        border: "1.5px solid green",
                                        padding: "10px",
                                        borderRadius: "10px",
                                        background: "lightgreen",
                                      }}
                                    >
                                      Approved
                                    </label>
                                  </TableCell>
                                  <TableCell
                                    sx={{ fontSize: "15px" }}
                                    component="th"
                                    scope="row"
                                  >
                                    {referralDetail?.approved_to_user}
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "15px" }}>
                                    {referralDetail?.approved_by_user}
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "15px" }}>
                                    {referralDetail?.amount}
                                  </TableCell>
                                  <TableCell sx={{
                                    fontSize: "15px", overflowWrap: "break-word", /* Modern equivalent of word-wrap */
                                    wordBreak: "break-word", /* Breaks words at arbitrary points if necessary */
                                    whiteSpace: "normal"
                                  }}>
                                    {referralDetail?.description}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                referralDetail["interested_candidates"]?.map(
                                  (interested_candidates, i) => (
                                    <TableRow>
                                      <TableCell
                                        sx={{ fontSize: "15px" }}
                                        component="th"
                                        scope="row"
                                      >
                                        {i + 1}
                                      </TableCell>
                                      <TableCell
                                        sx={{ fontSize: "15px" }}
                                        component="th"
                                        scope="row"
                                      >
                                        {interested_candidates.user_name}
                                      </TableCell>
                                      <TableCell sx={{ fontSize: "15px" }}>
                                        {interested_candidates.mobile_number}
                                      </TableCell>
                                      <TableCell sx={{ fontSize: "15px" }}>
                                        {interested_candidates.email}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )
                              )}
                            </TableBody>
                          </>
                        ) : (
                          <TableRow>
                            <TableCell
                              style={{ fontSize: "15px" }}
                              align="left"
                              component="th"
                              scope="row"
                            >
                              <b>No any interested candidates</b>
                            </TableCell>
                          </TableRow>
                        )}
                      </Table>
                      {/* {referralDetail['interested_candidates']?.map((item) => {
                      return (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Typography
                                sx={{
                                  fontWeight: "100",
                                  fontSize: "17px",
                                  marginTop: "-11px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "#605a5a",
                                }}
                              >
                                User Name
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                }}
                              >
                                {item.user_name || "-"}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                sx={{
                                  fontWeight: "100",
                                  fontSize: "17px",
                                  marginTop: "-11px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "#605a5a",
                                }}
                              >
                                Mobile
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                }}
                              >
                                {item.mobile_number || "-"}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                sx={{
                                  fontWeight: "100",
                                  fontSize: "17px",
                                  marginTop: "-11px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "#605a5a",
                                }}
                              >
                                Email
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                }}
                              >
                                {item.email || "-"}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                sx={{
                                  fontWeight: "100",
                                  fontSize: "17px",
                                  marginTop: "-11px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "#605a5a",
                                }}
                              >
                                Amount
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                }}
                              >
                                {item.email || "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                        </>
                      )
                    })} */}
                    </>
                  ) : null}
                </div>
                {data?.user?.user_role?.role !== "Community Super Admin" &&
                  data?.user?.user_role?.role !== "Community Admin" ? (
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} sx={{ marginBottom: "15px" }}>
                      {loading ? (
                        <Button variant="contained" style={{ width: "130px" }}>
                          <CircularProgress
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "8px",
                              color: "#fff",
                            }}
                          />
                        </Button>
                      ) : !referralDetail?.interested_candidates?.includes(
                        data?.user?._id
                      ) ? (
                        <Button
                          variant="contained"
                          onClick={handleRefferalInterest}
                          disabled={
                            referralDetail?.interested_candidates?.includes(
                              data?.user?._id
                            ) || isApproved
                          }
                        >
                          I am Interested
                        </Button>
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: "18px",
                            // marginTop: "-31px",
                            padding: " 15px",
                            display: "flex",
                            color: "black",
                            backgroundColor: "#7FFFD4",
                            borderRadius: "17px",
                            border: "1px solid lightgray",
                          }}
                        >
                          You already showed interest in this.
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ) : null}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ViewReferralDetails;
