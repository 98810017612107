import { Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { memo } from "react";

const GuestDetailsForm = ({
  index,
  value,
  names,
  onchange,
  onBlur,
  touched,
  errors,
}) => {
  return (
    <Card key={index} style={{ marginBottom: "20px" }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Guest {index + 1}:
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={touched?.name && errors?.name}
              label="Name"
              name={names?.name}
              variant="outlined"
              fullWidth
              required
              value={value?.name}
              onChange={onchange?.nameChange}
              onBlur={onBlur}
              inputProps={{ maxLength: 50 }}
              helperText={touched?.name && errors?.name ? errors.name : ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiPhoneNumber
              error={touched?.phone && errors?.phone}
              label="Phone"
              name={names?.phone}
              defaultCountry={"in"}
              variant="outlined"
              fullWidth
              required
              value={value.phone}
              onChange={(val) => {
                const cleanedValue = val.replace(/\D/g, "");
                onchange?.phoneChange(val);
              }}
              onBlur={onBlur}
              helperText={touched?.phone && errors?.phone ? errors.phone : ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              error={touched?.email && errors?.email}
              label="Email"
              variant="outlined"
              name={names?.email}
              fullWidth
              required
              value={value.email}
              onChange={onchange?.emailChange}
              onBlur={onBlur}
              inputProps={{ maxLength: 150 }}
              helperText={touched?.email && errors?.email ? errors.email : ""}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default memo(GuestDetailsForm);
