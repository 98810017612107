import React, { useEffect, useState } from "react";
import { Formik, useFormik } from "formik";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import Input from "../../common/Input";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import SelectBox from "../../common/SelectBox";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  addPayment,
  getPaymentRequestId,
} from "../../redux/action/PartnerRequestAction";
import moment from "moment";

const rangeOptions = [];

const PaymentType = [
  { id: 1, name: "Cash" },
  { id: 2, name: "Cheque" },
  { id: 3, name: "UPI" },
];

const PaymentStatus = [
  { id: 1, name: "Done" },
  { id: 2, name: "Pending" },
];

const PartnerReqDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();



  const [payment, setPayment] = useState("");
  const [paymentStatus, setpaymentStatus] = useState("");
  const [paymentData, setPaymentData] = useState("");

  const handleChangeStatus = (event) => {
    formik.setValues({...formik.values, payment_status: event.target.value})
    setpaymentStatus(event.target.value);
  };

  const handleChange = (event) => {
    setPayment(event.target.value);
  };

  const {
    _id,
    first_name,
    last_name,
    email,
    mobile_number,
    postalcode,
    country_name,
    state_name,
    city_name,
    status,
    amount
  } = location?.state?.partnerData;

  console.log("location?.state?.partnerData",location?.state?.partnerData)

  const today = dayjs()
  const today1 = dayjs().startOf('day');

  const Validation = Yup.object().shape({
    payment_by: Yup.string().required("Please select your payment mode"),
    payment_status: Yup.string().required("Please select your payment status")
  });
  const PartnerReqSchema = Yup.object().shape({
    payment_status: Yup.string().required("Please the enter payment status"),
    amount: Yup.string().required("Please the enter amount"),
    ...(payment === "Cheque" ? {cheque_number: Yup.string()
      .required("Please enter the cheque number", function (value) {
        if (payment === "Cheque") {
          return value && value.trim().length > 0;
        }
        return true;
      })
      .test('len', 'Cheque number needs to be excatly 6 digits', val => val.toString().length === 6)} : {} ),
    ...(payment === "Cheque" ? {cheque_date: Yup.date().required("Please enter the cheque date", function (value) {
      if (payment === "Cheque") {
        return value && value.trim().length > 0;
      }
      return true;
    }).min(today1, "Please enter present or future date")} : {}),
    ...(payment === "Cheque" ? {account_holder_bank: Yup.string().required(
      "Please enter account the holder bank", function (value) {
        if (payment === "Cheque") {
          return value && value.trim().length > 0;
        }
        return true;
      })} : {}),
    ...(payment === "Cheque" ? {account_holder_name: Yup.string().required(
      "Please enter account the holder name", function (value) {
        if (payment === "Cheque") {
          return value && value.trim().length > 0;
        }
        return true;
      })} : {}),
    ...(payment === "Cheque" ? {bank_branch: Yup.string().required(
      "Please enter the bank branch", function (value) {
        if (payment === "Cheque") {
          return value && value.trim().length > 0;
        }
        return true;
      })} : {}),
    ...(payment === "UPI" ? {upi: Yup.string()
      .matches(/^\w+@\w+$/, "Invalid UPI ID format")
      .required("Please enter the UPI ID")} : {})
  });

  const formik = useFormik({
    initialValues: {
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone_no: mobile_number,
      postalcode: postalcode,
      country_name: country_name,
      state_name: state_name,
      city_name: city_name,
      payment_status: paymentStatus || "",
      amount: amount || "",
      payment_by: payment || "",
      upi: "",
      cheque_date: "",
      cheque_number: "",
      account_holder_bank: "",
      account_holder_name: "",
      bank_branch: "",
    },
    validationSchema: (payment === "Cheque" || payment === "UPI" || payment === "Cash") ? PartnerReqSchema : Validation,
    onSubmit: async (values) => {
      let body = {
        payment_by: payment,
        status: paymentStatus,
        amount: values.amount,
        request_id: _id,
        currency: "INR",
      };

      if (payment === "Cheque") {
        body = {
          ...body,
          cheque_date: values.cheque_date,
          cheque_number: values.cheque_number,
          account_holder_bank: values.account_holder_bank,
          account_holder_name: values.account_holder_name,
          bank_branch: values.account_holder_name,
        };
      } else if (payment === "UPI") {
        body = {
          ...body,
          upi: values.upi,
        };
      }
      try {
        const res = await addPayment(body);
        if (res && res.status === true) {
          navigate("/admin-partner-request");
        } else {
          navigate("/admin-partner-request");
        }
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    },
  });

  const paymentRequestIdGet = async (id) => {
    try {
      const response = await getPaymentRequestId(id);
      setPaymentData(response.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    paymentRequestIdGet(_id);
  }, [_id]);
  const currentYear = dayjs().year(); // Get the current year
  const startOfCurrentYear = dayjs().startOf(currentYear);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem",
          // height: "100vh",
        }}
      >
        <ArrowCircleLeftOutlinedIcon
          fontSize="large"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/admin-partner-request")}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item md={8} xs={12} sm={12}>
            <Grid item md={12}>
              <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                <Typography
                  textAlign="center"
                  fontWeight={900}
                  fontSize={21}
                  marginBottom={2}
                  color="black"
                >
                  Partner Request Details
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Input
                        id="first_name"
                        name="first_name"
                        disable={true}
                        type="text"
                        label="First Name"
                        value={formik.values.first_name}
                      />
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <Input
                        id="last_name"
                        name="last_name"
                        disable={true}
                        type="text"
                        label="Last Name"
                        maxLength={20}
                        value={formik.values.last_name}
                      />
                    </Grid>
                  </Grid>
                  <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                    Email:
                  </Typography>
                  <Input
                    id="email"
                    name="email"
                    disable={true}
                    type="text"
                    label="Email"
                    value={formik.values.email}
                  />

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                        Phone Number:
                      </Typography>
                      <Input
                        id="phone_no"
                        name="phone_no"
                        type="text"
                        label="Phone Number"
                        disable={true}
                        value={formik.values.phone_no}
                      />
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                        PostalCode:
                      </Typography>
                      <Input
                        id="postalcode"
                        name="postalcode"
                        type="text"
                        label="PostalCode"
                        disable={true}
                        value={formik.values.postalcode}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                        Country Name:
                      </Typography>
                      <Input
                        id="country_name"
                        name="country_name"
                        type="text"
                        label="Country Name"
                        disable={true}
                        value={formik.values.country_name}
                      />
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                        State Name:
                      </Typography>
                      <Input
                        id="state_name"
                        name="state_name"
                        disable={true}
                        type="text"
                        label="State Name"
                        value={formik.values.state_name}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                        City Name:
                      </Typography>
                      <Input
                        id="city_name"
                        name="city_name"
                        type="text"
                        disable={true}
                        label="City Name"
                        value={formik.values.city_name}
                      />
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                        Status:
                      </Typography>
                      <Input
                        id="status"
                        name="status"
                        type="text"
                        label="Status"
                        disable={true}
                        value={
                          formik.values.status &&
                          formik.values.status[0] &&
                          formik.values.status[0].toUpperCase() +
                          formik.values.status.slice(1)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Typography variant="h5" sx={{ marginTop: "0.5rem" }}>
                    Payment Details:
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sm={12}
                      sx={{ marginTop: "-8px" }}
                    >
                      <div style={{ marginTop: "20px" }}>
                        <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                          Payment Type:
                        </Typography>
                        <Select
                          id="payment_by"
                          name="payment_by"
                          value={paymentData ? paymentData.payment_by : payment}
                          disabled={paymentData ? true : false}
                          onChange={handleChange}
                          displayEmpty
                          sx={{ width: "100%" }}
                          onBlur={formik.handleBlur}
                          touched={formik.touched.payment_by}
                          error={
                            formik.touched.payment_by && Boolean(formik.errors.payment_by)
                          }
                        >
                          {PaymentType?.map((item) => {
                            return (
                              <MenuItem key={item.id} value={item.name}>
                                {item.name || item.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {formik.touched.payment_by &&
                          formik.errors.payment_by && (
                            <div
                              style={{
                                color: "rgb(211, 47, 47)",
                                fontWeight: "400",
                                fontSize: "0.7428571428571428rem",
                                lineHeight: "1.66",
                                textAlign: "left",
                              }}
                            >
                              {formik.errors.payment_by}
                            </div>
                          )}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sm={12}
                      sx={{ marginTop: "-8px" }}
                    >
                      <div style={{ marginTop: "20px" }}>
                        <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                          Payment Status:
                        </Typography>

                        <Select
                          id="payment_status"
                          name="payment_status"
                          value={
                            paymentData ? paymentData.status : paymentStatus
                          }
                          onChange={handleChangeStatus}
                          displayEmpty
                          sx={{ width: "100%" }}
                          disabled={paymentData ? true : false}
                          inputProps={{ "aria-label": "Without label" }}
                          onBlur={formik.handleBlur}
                          touched={formik.touched.payment_status}
                          error={
                            formik.touched.payment_status && Boolean(formik.errors.payment_status)
                          }
                        >
                          {PaymentStatus?.map((item) => {
                            return (
                              <MenuItem key={item.id} value={item.name}>
                                {item.name || item.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {formik.touched.payment_status &&
                          formik.errors.payment_status && (
                            <div
                              style={{
                                color: "rgb(211, 47, 47)",
                                fontWeight: "400",
                                fontSize: "0.7428571428571428rem",
                                lineHeight: "1.66",
                                textAlign: "left",
                              }}
                            >
                              {formik.errors.payment_status}
                            </div>
                          )}
                      </div>
                    </Grid>
                  </Grid>

                  {payment === "Cash" ||
                    payment === "UPI" ||
                    payment === "Cheque" ||
                    paymentData ? (
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                          Amount:
                        </Typography>
                        <Input
                          id="amount"
                          name="amount"
                          type="number"
                          label="Amount"
                          onChange={formik.handleChange}
                          value={
                            paymentData?.amount
                              ? paymentData?.amount
                              : 
                            formik.values.amount
                          }
                          maxLength={10}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.amount &&
                            Boolean(formik.errors.amount)
                          }
                          helperText={
                            formik.touched.amount && formik.errors.amount ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.amount}
                              </div>
                            ) : null
                          }
                          disable={paymentData?.amount ? true : false}
                          inputProps={{ readOnly: true, type: "number" }}
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  {payment === "UPI" || paymentData?.payment_by === "UPI" ? (
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                          UPI:
                        </Typography>
                        <Input
                          id="upi"
                          name="upi"
                          type="text"
                          label="UPI"
                          onChange={formik.handleChange}
                          value={
                            paymentData ? paymentData?.upi : formik.values.upi
                          }
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.upi && Boolean(formik.errors.upi)
                          }
                          helperText={
                            formik.touched.upi && formik.errors.upi ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.upi}
                              </div>
                            ) : null
                          }
                          disabled={paymentData ? true : false}
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  {payment === "Cheque" ||
                    paymentData?.payment_by === "Cheque" ? (
                    <>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={6} sm={6}>
                          <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                            Cheque Number:
                          </Typography>
                          <Input
                            id="cheque_number"
                            name="cheque_number"
                            type="number"
                            label="Cheque Number"
                            onChange={formik.handleChange}
                            value={
                              paymentData?.cheque_number
                                ? paymentData?.cheque_number
                                : formik.values.cheque_number
                            }
                            onBlur={formik.handleBlur}
                            maxLength={6}
                            disabled={paymentData ? true : false}
                            error={
                              formik.touched.cheque_number &&
                              Boolean(formik.errors.cheque_number)
                            }
                            helperText={
                              formik.touched.cheque_number &&
                                formik.errors.cheque_number ? (
                                <div className="err-msg err-msg-font">
                                  {formik.errors.cheque_number}
                                </div>
                              ) : null
                            }
                          />
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                          <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                            Cheque Date:
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={["DatePicker", "DatePicker"]}
                            >
                              <DatePicker
                                id="cheque_date"
                                sx={{ width: "100%" }}
                                name="cheque_date"
                                fullWidth
                                label="Cheque Date"
                                defaultValue={formik.values.cheque_date || today}
                                inputFormat="DD/MM/YYYY"
                                minDate={today}
                                // minDate={startOfCurrentYear}
                                onChange={(newValue) =>
                                  formik.setFieldValue("cheque_date", newValue)
                                }
                                format="DD/MM/YYYY"
                                // disabled={paymentData ? true : false}
                                slotProps={{
                                  textField: {
                                    variant: "outlined",
                                    error: Boolean(
                                      formik.touched.cheque_date &&
                                        formik.errors.cheque_date
                                    ), //error handling
                                    helperText: formik.touched.cheque_date &&
                                      formik.errors.cheque_date && (
                                        <div
                                          style={{ marginLeft: "-12px" }}
                                          className="err-msg-font"
                                        >
                                          {formik.errors.cheque_date}
                                        </div>
                                      ),
                                  },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}

                  {payment === "Cheque" ||
                    paymentData?.payment_by === "Cheque" ? (
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={6} sm={6}>
                        <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                          Account Holder Name:
                        </Typography>
                        <Input
                          id="account_holder_name"
                          name="account_holder_name"
                          type="text"
                          label="Account Holder Name"
                          onChange={formik.handleChange}
                          value={
                            paymentData?.account_holder_name !== ""
                              ? paymentData?.account_holder_name
                              : formik.values.account_holder_name
                          }
                          disabled={paymentData ? true : false}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.account_holder_name &&
                            Boolean(formik.errors.account_holder_name)
                          }
                          maxLength={50}
                          helperText={
                            formik.touched.account_holder_name &&
                              formik.errors.account_holder_name ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.account_holder_name}
                              </div>
                            ) : null
                          }
                        />
                      </Grid>
                      <Grid item md={6} xs={6} sm={6}>
                        <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                          Account Holder Bank:
                        </Typography>
                        <Input
                          id="account_holder_bank"
                          name="account_holder_bank"
                          type="text"
                          label="Account Holder bank"
                          onChange={formik.handleChange}
                          maxLength={50}
                          value={
                            paymentData?.account_holder_bank !== ""
                              ? paymentData?.account_holder_bank
                              : formik.values.account_holder_bank
                          }
                          disabled={paymentData ? true : false}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.account_holder_bank &&
                            Boolean(formik.errors.account_holder_bank)
                          }
                          helperText={
                            formik.touched.account_holder_bank &&
                              formik.errors.account_holder_bank ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.account_holder_bank}
                              </div>
                            ) : null
                          }
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  {payment === "Cheque" ||
                    paymentData?.payment_by === "Cheque" ? (
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                          Bank Branch:
                        </Typography>
                        <Input
                          id="bank_branch"
                          name="bank_branch"
                          type="text"
                          label="Bank Branch"
                          onChange={formik.handleChange}
                          value={
                            paymentData?.bank_branch !== ""
                              ? paymentData?.bank_branch
                              : formik.values.bank_branch
                          }
                          disabled={paymentData ? true : false}
                          maxLength={70}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.bank_branch &&
                            Boolean(formik.errors.bank_branch)
                          }
                          helperText={
                            formik.touched.bank_branch &&
                              formik.errors.bank_branch ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.bank_branch}
                              </div>
                            ) : null
                          }
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  {!paymentData ? (
                    <Button
                      sx={{ marginTop: 4 }}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      sx={{ marginTop: 4 }}
                      variant="contained"
                      onClick={() =>
                        navigate("/admin-crete-user", {
                          state: {
                            formName: "Create User",
                            userData: location?.state?.partnerData || {},
                          },
                        })
                      }
                    >
                      Create User
                    </Button>
                  )}
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PartnerReqDetails;
