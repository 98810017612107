import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("access_token") ?? "";
  // const user_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTAzZmQwZmQzNjkyNzU4Yzc4YTk4ZDgiLCJlbWFpbCI6ImplcnJ5QHlvcG1haWwuY29tIiwibW9iaWxlX251bWJlciI6OTg5ODk3OTc5NiwiaWF0IjoxNjk0NzcyNjM3fQ.4GXSG0dGUoUhsXeic72w8kU2dbA4LSx8fASR0uGdKo4'
  if(token){
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers["Content-Type"] = "application/json";

  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response } = error;
    if (response) {
      if (response.status === 500) {
        window.location.href = "/500"
      }
    } else {
      console.error("Error message:", error.message);
    }
    return response;
  }
);

export default axios;
