
const initialState = {
    loading: false,
    createLoading: false,
    updateLoading: false,
    deleteLoading: false,
    client: [],
}

function ClientReviewReducer(state = initialState, action) {
    switch (action.type) {
        case "LOADING":
            return {
                ...state,
                loading: action.payload
            }

        case "CLIENT_SUCCESS":
            return {
                ...state,
                client: action.payload,
                loading: false,
            }

       

        case "CREATE_LOADING_CLIENT":
            return {
                ...state,
                createLoading: action.payload
            }

        case "CREATE_CLIENT_SUCCESS":
            return {
                ...state,
            }

        case "UPDATE_LOADING_CLIENT":
            return {
                ...state,
                updateLoading: action.payload
            }
        case "UPDATE_CLIENT_SUCCESS":
            return {
                ...state,
            }

        case "DELETE_CLIENT_LOADING":
            return {
                ...state,
                deleteLoading: action.payload
            }

        case "DELETE_CLIENT_SUCCESS":
            return {
                ...state,
            }


        default:
            return state;
    }
}

export default ClientReviewReducer;