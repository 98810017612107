import axios from "axios";
import { ApiURL, snackBar } from "../common";

export const getFAQCategory = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_FAQs_CATEGORY_LOADING",
      payload: true,
    });

    await axios.get(`${ApiURL}faq-category/get-all-category`).then((res) => {
      if (res.data.status) {
        dispatch({
          type: "GET_FAQs_CATEGORY_LOADING",
          payload: false,
        });
        dispatch({
          type: "GET_FAQs_CATEGORY_SUCCESS",
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: "GET_FAQs_CATEGORY_LOADING",
          payload: false,
        });
        snackBar(res.data.message, res.data.status);
      }
    });
  } catch (error) {
    dispatch({
      type: "GET_FAQs_CATEGORY_LOADING",
      payload: false,
    });
    dispatch({
      type: "GET_FAQs_CATEGORY_FAIL",
      payload: error,
    });
  }
};

export const getAllFAQs = (rowsPerPage, page, category_name) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_ALL_FAQs_LOADING",
      payload: true,
    });

    await axios
      .get(`${ApiURL}faq/get-all-faqs?limit=${rowsPerPage}&page=${page}&category_name=${category_name}`)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: "GET_ALL_FAQs_LOADING",
            payload: false,
          });
          dispatch({
            type: "GET_ALL_FAQs_SUCCESS",
            payload: res.data.data,
          });
        } else {
          dispatch({
            type: "GET_ALL_FAQs_LOADING",
            payload: false,
          });
          snackBar(res.data.message, res.data.status);
        }
      });
  } catch (error) {
    dispatch({
      type: "GET_ALL_FAQs_LOADING",
      payload: false,
    });
    dispatch({
      type: "GET_ALL_FAQs_FAIL",
      payload: error,
    });
  }
};

export const createFAQ = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_FAQs_LOADING",
      payload: true,
    });

    await axios.post(`${ApiURL}faq/create-faq`, data).then((res) => {
      if (res.data.status) {
        dispatch({
          type: "CREATE_FAQs_LOADING",
          payload: false,
        });
        dispatch({
          type: "CREATE_FAQs_SUCCESS",
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: "CREATE_FAQs_LOADING",
          payload: false,
        });
        snackBar(res.data.message, res.data.status);
      }
    });
  } catch (error) {
    dispatch({
      type: "CREATE_FAQs_LOADING",
      payload: false,
    });
    dispatch({
      type: "CREATE_FAQs_FAIL",
      payload: error,
    });
  }
};

export const updateFAQ = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_FAQs_LOADING",
      payload: true,
    });

    await axios.put(`${ApiURL}faq/update-faq/${id}`, data).then((res) => {
      if (res.data.status) {
        dispatch({
          type: "UPDATE_FAQs_LOADING",
          payload: false,
        });
        dispatch({
          type: "UPDATE_FAQs_SUCCESS",
          payload: res.data.data,
        });
        snackBar(res.data.message, res.data.status);
      } else {
        dispatch({
          type: "UPDATE_FAQs_LOADING",
          payload: false,
        });
        snackBar(res.data.message, res.data.status);
      }
    });
  } catch (error) {
    dispatch({
      type: "UPDATE_FAQs_LOADING",
      payload: false,
    });
    dispatch({
      type: "UPDATE_FAQs_FAIL",
      payload: error,
    });
  }
};

export const deleteFAQ = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_FAQs_LOADING",
      payload: true,
    });

    await axios.delete(`${ApiURL}faq/delete-faq/${id}`).then((res) => {
      if (res.data.status) {
        dispatch({
          type: "DELETE_FAQs_LOADING",
          payload: false,
        });
        dispatch({
          type: "DELETE_FAQs_SUCCESS",
          payload: res.data.data,
        });
        snackBar(res.data.message, res.data.status);
      } else {
        dispatch({
          type: "DELETE_FAQs_LOADING",
          payload: false,
        });
        snackBar(res.data.message, res.data.status);
      }
    });
  } catch (error) {
    dispatch({
      type: "DELETE_FAQs_LOADING",
      payload: false,
    });
    dispatch({
      type: "DELETE_FAQs_FAIL",
      payload: error,
    });
  }
};
