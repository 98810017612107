const initialState = {
    getAllReferralLoading: false,
    createReferralLoading: false,
    updateReferralLoading: false,
    deleteReferralLoading: false,
    getMyReferralLoading: false,
    allReferral: [],
    allMyReferral: []
}

const ReferralReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_REFERRAL_LOADING":
            return {
                ...state,
                getAllReferralLoading: action.payload
            }
        case "GET_ALL_REFERRAL_SUCCESS":
            return {
                ...state,
                allReferral:action.payload, 
            }
        case "GET_ALL_REFERRAL_FAIL":
            return {
                ...state,
                allReferral:action.payload, 
            }
        
        /////// Create Part /////////////////
        
        case "CREATE_REFERRAL_LOADING":
            return {
                ...state,
                createReferralLoading: action.payload
            }
        case "CREATE_REFERRAL_SUCCESS":
            return {
                ...state,
                allReferral:action.payload, 
            }
        case "CREATE_REFERRAL_FAIL":
            return {
                ...state,
                allReferral:action.payload, 
            }
        
        /////// Update Part /////////////////
        
        case "UPDATE_REFERRAL_LOADING":
            return {
                ...state,
                updateReferralLoading: action.payload
            }
        case "UPDATE_REFERRAL_SUCCESS":
            return {
                ...state,
                allReferral:action.payload, 
            }
        case "UPDATE_REFERRAL_FAIL":
            return {
                ...state,
                allReferral:action.payload, 
            }
        
        /////// Delete Part /////////////////
        
        case "DELETE_REFERRAL_LOADING":
            return {
                ...state,
                deleteReferralLoading: action.payload
            }
        case "DELETE_REFERRAL_SUCCESS":
            return {
                ...state,
                allReferral:action.payload, 
            }
        case "DELETE_REFERRAL_FAIL":
            return {
                ...state,
                allReferral:action.payload, 
            }
        
        /////// Get My Refferals Part /////////////////
        
        case "GET_MY_REFERRAL_LOADING":
            return {
                ...state,
                getMyReferralLoading: action.payload
            }
        case "GET_MY_REFERRAL_SUCCESS":
            return {
                ...state,
                allMyReferral:action.payload, 
            }
        case "GET_MY_REFERRAL_FAIL":
            return {
                ...state,
                allMyReferral:action.payload, 
            }

        default:
            return state;
    }
}


export default ReferralReducer