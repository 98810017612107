import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

const axiosApiCall = () => axios.create({
  headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
    },
  });

export const GetBlogs =
  (page, title = "", rowsPerPage) =>
    async (dispatch) => {
      try {
        dispatch({
          type: "LOADING",
          payload: true,
        });

        const res = await axios.get(
          `${ApiURL}blog/all-blog?limit=${rowsPerPage}&page=${page}&title=${title}`
        );

        if (res.data.status === true) {
          dispatch({
            type: "LOADING",
            payload: false,
          });

          const responseData = res.data.data || res.data.data.length > 0 ? res.data.data : [];
          dispatch({
            type: "BLOG_SUCCESS",
            payload: responseData,
          });
        } else {
          dispatch({
            type: "LOADING",
            payload: false,
          });
          dispatch({
            type: "BLOG_SUCCESS",
            payload: [],
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "LOADING",
          payload: false,
        });
      }
    };

export const CreateBlog = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_BLOG_LOADING",
      payload: true,
    });
    const res = await axiosApiCall().post(`${ApiURL}blog/create-blog`, data);

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_BLOG_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
  } finally {
    dispatch({
      type: "CREATE_BLOG_LOADING",
      payload: false,
    });
  }
};

export const UpdateBlog = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_BLOG_LOADING",
      payload: true,
    });
    const res = await axiosApiCall().put(`${ApiURL}blog/update-blog/${id}`, data);

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_BLOG_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
  } finally {
    dispatch({
      type: "UPDATE_BLOG_LOADING",
      payload: false,
    });
  }
};

export const DeleteBlog = (id, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_BLOG_LOADING",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}blog/delete-blog/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_BLOG_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "DELETE_BLOG_LOADING",
      payload: false,
    });
  }
};
