import { Box, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import React from "react";
import { useNavigate } from "react-router-dom";
import bannerBg from "../../assets/image/banner/banner_1.png";
import bannerBg2 from "../../assets/image/banner/banner_2.png";
import bannerBg3 from "../../assets/image/banner/banner_3.png";
import BeCard from "../../components/beCard/BeCard";
import { beMember1, beMember2 } from "../../constants/beMemberContent";
import BePartnerPageNavigation from "../../components/BePartnerPageNavigation/BePartnerPageNavigation";
import { Deals } from "../../components/Deals/Deals";
import SearchComponent from "../../components/search/SerachComponent";

const StyledContainer = styled("div")({
  background: "linear-gradient(45deg, #1F4182, #223366)", // Gradient background
  width: "100vw",
  marginTop: "40px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const ButtonContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "2rem",
  cursor: "pointer",
});

const FindChapterBtn = styled("div")({
  width: "200px",
  backgroundColor: "#F07579",
  color: "#fff",
  padding: "1rem 2rem",
  borderRadius: "5px",
  textDecoration: "none",
  transition: "background-color 0.3s ease",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
  "&:hover": {
    backgroundColor: "#a3141f",
  },
});

const BePartner = () => {
  const navigate = useNavigate();

  const goToForm = () => {
    navigate("/be-partner-form");
  };

  return (
    <>
      {/*Banner*/}
      <section
        id="section-01"
        className="responsive"
        style={{
          display: "flex",
          alignItem: "center",
          justifyContent: "center",
          borderRadius: "10px",
          marginTop: "15px",
          maxHeight: "380px",
          // backgroundColor: '#fff'
        }}
      >
        <img
          src={bannerBg}
          style={{ borderRadius: "10px", maxHeight: "380px" }}
        />
      </section>

      <section className="responsive">
        {/*Cards*/}
        <div style={{width: '100%', margin: '90px auto'}}>
              <div className="row d-flex" >
                <p className="lead mb-7 font-size-lg font-weight-normal lh-18 fadeInDown animated text-center">
                  Are you ready to elevate your business to new heights? Join
                  the Global Garner Community, where local meets global, and
                  success knows no bounds. As the proud initiative of Global
                  Garner Sales Service Limited, a trailblazer that has achieved
                  a remarkable 6650 crore valuation in just 7 years, we bring
                  you a unique platform that transcends geographical boundaries
                  and propels your business towards unprecedented growth.
                </p>
                {beMember1.map((item) => (
                  <BeCard {...item} />
                ))}
                {beMember2.map((item) => (
                  <BeCard {...item} />
                ))}
              </div>
        </div>

        <section
          id="section-01"
          className="be-partner-main-intro"
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: "10px",
            marginTop: "60px",
            width: "100%",
          }}
        >
          <img
            src={bannerBg3}
            style={{ borderRadius: "10px", width: "100%" }}
          />
        </section>

        {/*Market Size*/}
        <div style={{ marginTop: "80px" }}>
          <h3>Market Size Impact</h3>
          <p>
            The market size of the Global Garner Community in India not only
            reflects its extensive reach but also amplifies the potential impact
            of partnerships. With a community spanning diverse sectors, the
            cumulative market influence is substantial. Partners can leverage
            this collective strength to negotiate better deals, access
            preferential rates, and collaborate on large-scale projects that
            might be challenging for individual businesses. The shared market
            intelligence and collective bargaining power create a formidable
            force that enhances the competitiveness of each partner in the
            broader market. In essence, being a part of Global Garner Community
            is not just about networking—it's about tapping into a comprehensive
            ecosystem that addresses challenges, fuels innovation, and propels
            businesses toward unprecedented success. Whether you're a Chapter
            President, Regional Chapter President, or Zonal Chapter President,
            your partnership with Global Garner Community is a strategic
            investment in the present and future of your business.
          </p>
        </div>

        <SearchComponent title="Be a Partner" func={goToForm} />
      </section>
    </>
  );
};

export default BePartner;
