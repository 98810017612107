import { Grid } from "@mui/material";
import React from "react";

const JoinWithUs = ({ joinWithUs, navigateToMember, navigateToPartner }) => {
  return (
    <div>
      {joinWithUs &&
        joinWithUs?.map((item, i) => {
          if (i % 2 == 0) {
            return (
              <div className="beA">
                <figure style={{width:'100%', display: 'flex', alignItems: 'start', justifyContent: 'start'}}>
                  <img
                    style={{
                      maxWidth: "100%",
                      minHeight: "100px",
                      maxHeight: "310px",
                    }}
                    src={item.img}
                    alt=""
                  />
                </figure>

                <div style={{width: '100%'}}>
                  <h5 style={{ marginBottom: "1em" }}>{item.title}</h5>
                  <p style={{ color: "gray", textAlign: "justify" }}>
                    {item.desc}
                  </p>
                  {item.btn_name ? (
                    <button
                      className="btnAll"
                      style={{border: 'none', padding: '4px 0', borderRadius: '7px', width: '180px'}}
                      onClick={() => navigateToMember()}
                    >
                      {item.btn_name}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          } else {
            return (
                <div className="beA" >
                  <div style={{width: '100%'}}>
                    <h5 style={{ marginBottom: "1em" }}>{item.title}</h5>
                    <p style={{ color: "gray",  }}>
                      {item.desc}
                    </p>
                    {item.btn_name ? (
                      <button
                        className="btnAll"
                        style={{border: 'none', padding: '4px 0', borderRadius: '7px', width: '180px'}}
                        onClick={() => navigateToPartner()}
                      >
                        {item.btn_name}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>

                  <figure style={{width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'end'}}>
                  <img
                    style={{
                      maxWidth: "100%",
                      minHeight: "100px",
                      maxHeight: "310px",
                    }}
                    src={item.img}
                    alt=""
                  />
                  </figure>
                </div>
            );
          }
        })}
    </div>
  );
};

export default JoinWithUs;
