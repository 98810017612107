import React from 'react';
import leaderShip from '../../GGC_LendingPage/png/LeadershipBanner.png';
import serviceBanner from '../../assets/GG_C_Services/servicesBanner.png';
import aboutBanner from '../../assets/GG_C_About/aboutBanner.png';
import blogBanner from '../../assets/GG_C_Blog/blogBanner.png';
import eventBanner from '../../assets/GG_C_Events/eventBanner.png';
import rectBanner from '../../assets/GG_C_Events/RectangleBanner.png';

const bannerData = [
    {
        title : "Leader Ship",
        img: leaderShip,
    },
    {
        title : "Services",
        img: serviceBanner,
    },
    {
        title : "About Us",
        img: aboutBanner,
    },
    {
        title : "Events",
        img: eventBanner,
    },
    {
        title : "Some Tips & Articles",
        img: blogBanner,
    },
    {
        title : "Rectangle",
        img: rectBanner,
    },
];

export const Banner = ({bannerTitle}) => {
    return(
        <section style={{height: 'auto'}}>
           {bannerData?.map((data, index) => (
              <figure key={index} style={{height: 'auto'}} >
                {data.title === bannerTitle ? (
                    <img src={data?.img} alt={data?.title} style={{width: "100%", objectFit: 'cover'}} />
                ) : null }
              </figure>
            ))}
        </section>
    )
};