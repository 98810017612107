import React from "react";
import ellipse from "../../GGC_LendingPage/svg/Ellipse.svg";

const steps = [
      {
        number: "01.",
        title: "Submission",
        description: "Verify your membership eligibility by submitting application",
      },
      {
        number: "02.",
        title: "Activation",
        description: "Activate your membership and get Dedicated RM appointment",
      },
      {
        number: "03.",
        title: "Acceleration",
        description: "Enjoy your membership offerings with community Networking",
      },
];


const QuickSteps = () => {
  return (
    <>
      <section style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', marginTop: '28px', marginBottom: '90px', width:'100%', margin: '0 auto', }}>
        {steps?.map((d, i) => {
          return (
            <section
            //   style={{displ ay: 'flex', flexDirection: 'flex-col' , alignItems: 'center', justifyContent: 'start'}}
              key={i}
            >
              <p style={{padding: '0px 0', fontSize: 'larger', textAlign: 'center', color: '#252525', fontWeight: 'bold'}}>{d?.number}</p>
              <section style={{height: '80px', width: '100%', display: 'flex', alignItems:'center', justifyContent: 'center', position: 'relative', marginBottom: '-25px'}}>
                <p
                //   className={`hidden md:block w-full relative border-t-2  border-t-btnBgLoan border-dashed"`}
                  style={{display: 'hidden', width: '100%', position: 'relative', borderTop: '2px dashed lightgray',}}>
                  <p></p>
                </p>
                <figure style={{width: '14%', height: '14%', position: 'absolute', top: '12%', zIndex: '10'}}>
                  <img
                    src={ellipse}
                    alt=""
                    style={{width: '100%', height: '100%', objectFit: 'contain'}}
                  />
                </figure>
                <p 
                // className={`hidden md:block relative w-full border-t-2  border-t-btnBgLoan border-dashed`}
                style={{display: 'hidden', width: '100%', position: 'relative', borderTop: '2px dashed lightgray',}}>
                  <p></p>
                </p>
              </section>
              <p style={{padding: '0, px 0', textAlign: 'center', fontWeight: 'bold', color: '#252525'}}>
                {d?.title}
              </p>

              {/* added height */}
              <p style={{margin: '0px auto', fontSize: 'medium', textAlign: 'center', height: '60px', color: '#626161', width: '75%'}}>
                {d?.description}
              </p>
            </section>
          );
        })}
      </section>
    </>
  );
};

export default QuickSteps;
