export const otpTimer = 150;

export const permissions = {
  blog: "Blog",
  chapter: "Chapter",
  banner: "Banner",
  partnerRequest: "Partner Request",
  role: "Role",
  module: "Module",
  membership: "Membership",
  country: "Country",
  state: "State",
  city: "City",
  postalcode: "Postalcode",
  user: "User",
  business: "Business",
  business_category: "Business Category",
  business_document: "Business Document",
  event: "Event",
  payment: "Payment",
  wallet: "Wallet",
  turn_over_range: "Turn Over Range",
  Service: "Service",
  ClientReview: "Client Review",
  transaction: "Transaction",
  notification: "Notification",
  privacy_policy: "Privacy Policy",
  terms_codition: "Terms & Condition",
  faqs: "FAQs",
  trusted_by_About_us: "About us",
  withdraw: "Withdraw Request",
  page_banners: "Page Banner",
  referral: "Referral",
};
