import React from "react";

import stemEdu from "../../assets/GG_C_Events/stemEdu.png";
import summit from "../../assets/GG_C_Events/summit.png";
import { Link } from "react-router-dom";

export const Deals = ({ title, handleBtn }) => {
  return (
    <section style={{ width: "100%", marginBottom: "8px", marginTop: "50px" }}>
      {/*Head*/}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          backgroundColor: "#f1f5f6",
          borderRadius: "5px",
          padding: "12px 16px",
        }}
      >
        <h4 style={{ margin: "auto 0" }}>{title}</h4>

        {!handleBtn ? (
          <Link
            to="/events"
            className="link-hover-dark-primary ml-0 ml-sm-auto w-100 w-sm-auto"
          >
            <span
              className="font-size-md d-inline-block mr-1"
              style={{ fontSize: "12px", color: '#252525', textDecoration: 'none', }}
            >
              View all
            </span>
            <i className="fal fa-chevron-right" style={{ fontSize: "12px", color: '#252525', textDecoration: 'none', }} />
          </Link>
        ) : (
              <div style={{ cursor: "pointer" }}>
                <span
                  onClick={handleBtn}
                  className="font-size-md d-inline-block mr-1"
                  style={{ fontSize: "12px", color: '#252525', textDecoration: 'none',  }}
                >
                  View all
                </span>
                <span>
                  <i className="fal fa-chevron-right" />
                </span>
              </div>
        )}
      </div>
    </section>
  );
};
