import React, { useEffect, useRef, useState } from "react";
import main_blog_1 from "../../assets/image/blog/main-blog-1.jpg";
import main_blog_2 from "../../assets/image/blog/main-blog-2.jpg";
import main_blog_3 from "../../assets/image/blog/main-blog-3.jpg";
import { GetAllBlog } from "../../redux/blogAction";
import moment from "moment/moment";

import BlogCard from "../../pages/blog/BlogCard";
import { Link, useNavigate } from "react-router-dom";
import { removeHtmlTags } from "../htmlToString/HtmlToString";
import SliderImage from "../slider/SliderImage";
import NoBlogContent from "../NoBlogContent/NoBlogContent";
import BaseBlogCard from "./BaseBlogCard";
import { Deals } from "../Deals/Deals";
import { BlogCards } from "./BlogCard";
import arrow from '../../GGC_LendingPage/svg/arrowLeft.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const HomeArticles = () => {
  const [allGetBlog, setAllGetBlog] = useState([]);
  const navigate = useNavigate();
  const currentPage = 1;
  const cardsPerPage = 4;
  const sliderRef = useRef();

  const getBlog = async () => {
    const res = await GetAllBlog();
    if (res) {
      setAllGetBlog(res.data);
    }
  };

  useEffect(() => {
    getBlog();
  }, [currentPage, cardsPerPage]);

  const handleButtonClick = () => {
    navigate("/blog");
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ],
    // afterChange: (index) => {
    //   setCurrentIndex(index);
    // },
  }

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const firstThreeBlogs = allGetBlog?.blogData?.slice(0, 4);

  return (
    <>
      {firstThreeBlogs?.length > 0 ? (
        <section style={{ marginBottom: '100px', position: 'relative' }}>
          <div className="responsive" style={{ marginTop: "100px", marginBottom: "110px" }}>
            <Deals title="Some Tips & Articles" handleBtn={handleButtonClick} />
            <div style={{ marginTop: "15px", width: "100%" }}>
              <Slider ref={sliderRef} {...settings} style={{paddingBottom: '30px'}}>
                {firstThreeBlogs?.map((item) => {
                  return (
                    <>
                      <BaseBlogCard item={item} key={item._id} />
                    </>
                  );
                })}
              </Slider>
            </div>
          </div>

          {/*Arrows*/}
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              border: "1px solid #d1d1d1",
              cursor: "pointer",
              top: "50%",
              padding: "10px",
            }}
            className="arwRight"
          >
            <img
              src={arrow}
              style={{ width: "80%", height: "80%" }}
              onClick={goToNextSlide}
            />
          </div>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              border: "1px solid #d1d1d1",
              cursor: "pointer",
              top: "50%",
              padding: "10px",
            }}
            className="arwLeft"
          >
            <img
              src={arrow}
              style={{ width: "80%", height: "80%", rotate: "180deg" }}
              onClick={goToPrevSlide}
            />
          </div>
        </section>
      ) : (
        <section id="section-05" className="pt-4">
          <div className="container">
            <div className="d-flex align-items-center mb-2 flex-wrap flex-sm-nowrap">
              <h2>
                <span className="font-weight-semibold">Some </span>
                <span className="font-weight-light">Tips &amp; Articles</span>
              </h2>
            </div>
            <NoBlogContent />
          </div>
        </section>
      )}
    </>
  );
};

const ArticlesDetails = [
  {
    img: main_blog_1,
    card_body: "Tips, Travel",
    card_title: "10 best homestay in florencia that you don't miss out",
    card_date: "Aug 28th, 2017 by",
    user: "Admin",
  },
  {
    img: main_blog_2,
    card_body: "Culture",
    card_title: "Coffee On Street & Look Super Car",
    card_date: "Aug 25th, 2017 by",
    user: "David",
  },
  {
    img: main_blog_3,
    card_body: "Location",
    card_title: "MadCap Coffee At Brooklyn Town For Who Love Black Coffee",
    card_date: "Aug 19th, 2017 by",
    user: "LoganCee",
  },
];
export default HomeArticles;
