
const initialState = {
    loading: false,
    createLoading: false,
    deleteLoading: false,
    updateLoading: false,
    roleListLoading: false,
    roleList: [],
    citiesData: {},
    allCitiesData: [],
}

function RoleandPermissionReducer(state = initialState, action) {
    switch (action.type) {
        case "LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "LOADING_USER_ROLE_LIST":
            return {
                ...state,
                roleListLoading: action.payload
            }
        case "CREATE_ROLE_LOADING":
            return {
                ...state,
                createLoading: action.payload
            }
        case "DELETE_CITY_LOADING":
            return {
                ...state,
                deleteLoading: action.payload
            }
        case "UPDATE_CITY_LOADING":
            return {
                ...state,
                updateLoading: action.payload
            }
        case "DELETE_CITY_SUCCESS":
            return {
                ...state,
            }
        case "CITY_SUCCESS":
            return {
                ...state,
                citiesData: action.payload,
            }
        case "ALL_CITY_SUCCESS":
            return {
                ...state,
                allCitiesData: action.payload,
            }
        case "UPDATE_CITY_SUCCESS":
            return {
                ...state,
            }
        case "CREATE_ROLE_SUCCESS":
            return {
                ...state,
            }

        case "GET_USER_ROLE_LIST_SUCCESS":
            return {
                ...state,
                roleList: action.payload
            }

        default:
            return state;
    }
}

export default RoleandPermissionReducer;