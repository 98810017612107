import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { DeleteIcon, EditIcon, CloseIcon } from "../../../assets/icons/Icons";
import "../../../components/table/table.css";
import { useForm } from "../../../common/useForms";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useUserRolePermissions from "../../../common/UserPermissions";
import axios from "axios";
import { ApiURL } from "../../../redux/common";
import {
  deleteService,
  getAllService,
} from "../../../redux/action/ServiceAction";
import { getPermissions } from "../../../common/permissions";
import { permissions } from "../../../common/constants";
import Loader from "../../../components/loader/Loader";
import PageHeader from "../../../components/pageHeader/PageHeader";
import EnhancedTable from "../../../components/table/EnhancedTable.js";
import DeleteDialog from "../../../components/dialog/deletedialog";
import { deleteFAQ, getAllFAQs } from "../../../redux/action/FaqsAction";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const initialValues = {
  country: "",
  state: "",
  city: "",
  postalcode: "",
  is_active: "",
};
const initialFilterValues = {
  category_name: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  // { id: 15, title: "15" },
  // { id: 20, title: "20" },
  // { id: 30, title: "30" },
];

const AdminFAQs = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [DeleteId, SetDeleteId] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { getFaqLoading, FaqData } = useSelector((state) => state?.FaqsReducer);

  const isFilterFormFilled = filterDatas.category_name.trim().length !== 0;

  const userRoleData = useUserRolePermissions("Membership");

  const isEditAllowed = userRoleData && userRoleData.is_edit === 1;
  const isDeleteAllowed = userRoleData && userRoleData.is_delete === 1;
  const isAddAllowed = !!getPermissions(permissions.faqs, "is_add");

  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const fetchAllFAQs = async () => {
    await dispatch(getAllFAQs(rowsPerPage, page, ""));
  };
  useEffect(() => {
    fetchAllFAQs();
  }, [page, rowsPerPage]);

  const columns = [
    {
      id: "category_name",
      numeric: false,
      disablePadding: true,
      label: "Category Name",
    },
    {
      id: "question",
      numeric: true,
      disablePadding: false,
      label: "Question",
    },
    {
      id: "answer",
      numeric: true,
      disablePadding: false,
      label: "Answer",
    },
    // (isEditAllowed || isDeleteAllowed) ?
    !!getPermissions(permissions.faqs, "is_delete") ||
    !!getPermissions(permissions.faqs, "is_edit")
      ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
      : null,
  ].filter((el) => el !== null);

  const tableTitle = "ServicesList";

  const handleBtnClick = () => {
    navigate("/admin-faqs/create-faqs", {
      state: { formName: "Create Faqs", faqsData: {} },
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelected = rows.map((n) => n.id);
      // setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true);

  const handleEditClick = (faqs, catName) => {
    navigate("/admin-faqs/create-faqs", {
      state: {
        formName: "Update Faqs",
        faqsData: { ...faqs, ...catName, faqid: faqs._id },
      },
    });
    setErrors({});
  };

  const handleClickOpenDelete = (faqs) => {
    setOpenDelete(true);
    SetDeleteId(faqs._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    handleCloseDelete();
    await dispatch(deleteFAQ(DeleteId));
    dispatch(getAllFAQs(rowsPerPage, page, ""));
  };

  //filter
  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(getAllFAQs(rowsPerPage, page, ""));
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericRegex = /^\d+$/;

    if (name === "postalcode" && !numericRegex.test(value)) {
      return;
    }

    setFilterDatas({
      ...filterDatas,
      [name]: value,
    });
  };
  const handleChangeRow = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const category_name = filterDatas.category_name;
    setPage(1);
    dispatch(getAllFAQs(rowsPerPage, page, category_name));
    closeFilterDrawer1();
  };
  function subStr(txt, len) {
    let acceptedText =
      txt.length < len ? txt : txt.substring(0, len - 1) + "...";
    return acceptedText;
  }
  const rows =
    FaqData && Array.isArray(FaqData.faqData)
      ? FaqData?.faqData.map((faq) => {
          return {
            _id: faq?._id,
            category_name: faq?.category_name,
            question: faq?.faqs?.map((qna) => {
              return (
                <ul
                  style={{
                    listStyleType: "none",
                    borderBottom: "1px solid #e3dddd",
                    lineHeight: "30px",
                  }}
                >
                  <li style={{ marginLeft: "-37px" }}>
                    {subStr(qna?.question, 50)}
                  </li>
                </ul>
              );
            }),
            answer: faq?.faqs?.map((qna) => {
              return (
                <ul
                  style={{
                    listStyleType: "none",
                    borderBottom: "1px solid #e3dddd",
                    lineHeight: "30px",
                  }}
                >
                  <li style={{ marginLeft: "-37px" }}>
                    {subStr(qna?.answer, 50)}
                  </li>
                </ul>
              );
            }),
            is_active: faq?.faqs?.map((qna) => {
              return qna?.is_active ? "Active" : "inActive";
            }),
            action: faq?.faqs?.map((qna) => {
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {!!getPermissions(permissions.faqs, "is_edit") && (
                    <IconButton
                      onClick={() => handleEditClick(qna, faq)}
                      sx={{
                        color: "black",
                        "&:hover": {
                          backgroundColor: "white",
                          color: "#153e80",
                        },
                        "&:focus": {
                          backgroundColor: "white",
                          color: "#153e80",
                          border: "none",
                        },
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                  {!!getPermissions(permissions.faqs, "is_delete") && (
                    <IconButton
                      onClick={() => handleClickOpenDelete(qna)}
                      sx={{
                        color: "black",
                        "&:hover": {
                          backgroundColor: "white",
                          color: "#153e80",
                        },
                        "&:focus": {
                          backgroundColor: "white",
                          color: "#153e80",
                          border: "none",
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              );
            }),
            // selected: selected,
          };
        })
      : [];

  const isAnyFieldFilled = Object.values(filterDatas).some((value) =>
    Boolean(value)
  );

  return (
    <div>
      <Box>
        <PageHeader
          title="FAQs"
          isShowAdd={false}
          onBtnClick={handleBtnClick}
          btnText={isAddAllowed ? "Create FAQs" : ""}
          // onAddClick={handleClickOpen}
          onFilterClick={openFilterDrawer}
        />
        {getFaqLoading ? (
          <Loader />
        ) : (
          <EnhancedTable
            key={5}
            columns={columns}
            rows={rows}
            order={order}
            orderBy={orderBy}
            selected={selected}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            title={tableTitle}
            tableList={FaqData && FaqData?.faqData}
          />
        )}
        {/* )} */}

        <DeleteDialog
          open={openDelete}
          // loading={deleteLoading}
          dialogTitle="Are you sure to want to delete this record?"
          handleClose={handleCloseDelete}
          handleDelete={() => handleSingleDelete(DeleteId)}
        />
        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
              gap: 2,
              width: { sm: "100%", md: "50%" },
            }}
            display="flex"
            alignItems="center"
          >
            <Box>
              {" "}
              Showing {FaqData?.currentPage} of {FaqData?.totalPages} Results
            </Box>
            <Box display="flex" sx={{ gap: 1 }} alignItems="center">
              <Typography>Records per page</Typography>

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  id="limites"
                  name="limites"
                  value={rowsPerPage}
                  onChange={handleChangeRow}
                  displayEmpty
                  sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {RecordPerPage?.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name || item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Pagination
            count={FaqData && FaqData?.totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
            <Box className={classes.mainFilterBox}>
              <Stack className={classes.stackFilter}>
                <Typography className={classes.typographyFilter}>
                  Filter
                </Typography>

                <CloseIcon
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>
              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
              </Box>

              <div>
                <Box>
                  <Input
                    variant="outlined"
                    color="primary"
                    label="Category Name"
                    name="category_name"
                    value={filterDatas.category_name}
                    onChange={handleChange}
                    maxLength={200}
                  />
                </Box>
                <Stack className={classes.buttonFilter}>
                  <Button
                    color="secondary"
                    size="large"
                    variant="contained"
                    className="cancel-filter"
                    disabled={!isFilterFormFilled}
                    onClick={resetFormFilter}
                  >
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    className="order-filter-button"
                    type="submit"
                    disabled={!isFilterFormFilled}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Filter
                  </Button>
                </Stack>
              </div>
            </Box>
          </form>
        </Drawer>
      </Box>
    </div>
  );
};

export default AdminFAQs;
