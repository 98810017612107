import LoginPage from "./pages/LoginPage";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/layout/Layout";
import { ColorModeContext, useMode } from "./theme";
import { ThemeProvider } from "@mui/material";
import SigninPage from "./pages/SigninPage";
import Otp from "./pages/otp/Otp";
import User from "./pages/user/User";
import Package from "./pages/Package";
import Dashboard from "./components/dashboard/Dashboard";
import ManageSubscription from "./pages/ManageSubscription";
import ManageUser from "./pages/ManageUser";
import ManagePermission from "./pages/ManagePermission";
import AdminProtected from "./components/privateRoute/PrivateRoutes";
import Blog from "./components/blog-post/Blog";
import ChapterList from "./components/chapter/ChapterList";
import PartnerRequestList from "./components/partnerRequest/PartnerRequestList";
import CountriesList from "./components/country-state-city-postalcode/CountryList";
import StateList from "./components/country-state-city-postalcode/StateList";
import CityList from "./components/country-state-city-postalcode/CityList";
import PostalCodeList from "./components/country-state-city-postalcode/PostalCodeList";
import MembershipList from "./components/manageMembership/MembershipListing";
import CreateMembership from "./components/manageMembership/CreateMembership";
import PartnerReqDetails from "./components/partnerRequest/PartnerReqDetails";
import CreateUserForm from "./components/manageUser/CreateUser";
import Profile from "./pages/Profile";
import BeMemberPages from "./pages/BeMemberPages";
import UpdatePermission from "./components/managePermission/UpdatePermission";
import CheckEligibility from "./components/user/checkEligibility/checkEligibility";
import ChapterBinList from "./components/chapter/ChapterBinList";
import EventsList from "./components/events/EventsList";
import CreateEventForm from "./components/events/CreateEvent";
import { getPermissions } from "./common/permissions";
import { permissions } from "./common/constants";
import NotFoundPage from "./pages/NotFoundPage";
import { useSelector } from "react-redux";
import PublicRoute from "./components/privateRoute/PublicRoute";
import InternalServerError from "./pages/InternalServerError.js";
import ManageUserDetails from "./components/manageUser/ManageUserDetails.js";
import BannerList from "./components/banner/BannerList.js";
import CreateBlogDetails from "./components/blog-post/CreateBlog.js";
import ServicesListing from "./components/services/ServicesListing.js";
import CreateServices from "./components/services/CreateServices.js";
import ClientReviewList from "./components/clientReview/ClientReviewList.js";
import Home from "./web/pages/home/Home.js";
import Contactus from "./web/pages/contactUs/Contactus.js";
import BePartner from "./web/pages/beNewPartner/BePartner.js";
import BeMember from "./web/pages/bemember/BeMember.js";
import BeMemberNew from "./web/pages/bememberNew/BeMemberNew.js";
import BeMemberForm from "./web/pages/bememberNew/BeMemberForm.js";
import About from "./web/pages/about/About.js";
import FaqPage from "./web/pages/FaqPage/FaqPage.js";
import PrivacyPolicy from "./web/pages/privacyPolicy/PrivacyPolicy.js";
import WebBlog from "./web/pages/blog/WebBlog.js";
import Events from "./web/pages/eventDetails/Events.js";
import EventDetails from "./web/pages/eventDetails/EventDetails.js";
import BlogDetails from "./web/pages/blog/BlogDetails.js";
import Findchapter from "./web/pages/findChapter/Findchapter.js";
import FindChapterSerach from "./web/pages/findChapter/FindChapterSerach.js";
import BusinessCategoryList from "./components/businessCategory/BusinessCategoryList.js";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./styles/about.css";
import "./web/assets/vendors/font-awesome/css/fontawesome.css";
import "./web/assets/vendors/magnific-popup/magnific-popup.css";
import "./web/assets/vendors/slick/slick.css";
import "./web/assets/vendors/animate.css";
import "./web/assets/vendors/jquery-ui/jquery-ui.css";
import "./web/assets/vendors/jquery-ui/jquery-ui.min.css";
import "./web/assets/vendors/animate.css";
import "./web/assets/vendors/prismjs/prism.css";
import "./web/assets/vendors/air-datepicker/css/datepicker.min.css";
import "./web/assets/css/style.css";
import "./web/assets/css/style.min.css";
import "./web/assets/css/style.css.map";
import "./web/assets/css/style.scss";

import WebLayout from "./web/components/layout/WebLayout.js";
import NotFound from "./web/pages/notFound/NotFound.js";
import { LocationProvider } from "./web/context/LocationContext.js";
import { useEffect } from "react";
import PaymentGateway from "./web/components/PaymentGateway.js";
import NotDataFound from "./web/pages/notFound/NotDataFound.js";
import TransactionList from "./components/transaction/TransactionList.js";
import ViewTransaction from "./components/transaction/ViewTransaction.js";
import NotificationList from "./components/notiofication/NotificationList.js";
import WalletList from "./components/wallet/WalletList.js";
import WalletView from "./components/wallet/WalletView.js";
import CreateNotification from "./components/notiofication/CreateNotification.js";
import WithdrawList from "./components/withdraw/withdrawList.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TermsAndCondition from "./web/pages/termsAndCondition/TermsAndCondition.js";
import AdminPrivacyPolicy from "./pages/adminCmsContent/AdminPrivacyPolicy.js";
import AdminTermsCondition from "./pages/adminCmsContent/AdminTermsCondition.js";
import AdminFAQs from "./pages/adminCmsContent/FAQs/AdminFAQs.js";
import AdminAboutUs from "./pages/adminCmsContent/AdminAboutUs.js";
import CreateFAQs from "./pages/adminCmsContent/FAQs/CreateFAQs.js";
import AdminFAQsCategory from "./pages/adminCmsContent/AdminFAQsCategory.js";
import EventAdminDetails from "./components/events/EventAdminDetails.js";
import ReferralList from "./components/referral/ReferralList.js";
import CreateReferralList from "./components/referral/CreateReferralList.js";
import ServiceDetails from "./components/services/ServiceDetails.js";
import AdminPageBanners from "./pages/adminCmsContent/AdminPageBanners.js";
import AdminPages from "./pages/adminCmsContent/AdminPages.js";
import AdminMyRefferralList from "./components/referral/AdminMyRefferralList.js";
import ViewReferralDetails from "./components/referral/ViewReferralDetails.js";
import BusinessUser from "./components/businessUser/BusinessUser.js";
import UserDetailsShow from "./components/UserInfoListing/UserDetailsShow.js";
import ResetPassword from "./components/auth/ResetPassword.js";
import ConfirmPassword from "./components/auth/ConfirmPassword.js";
import EventAttended from "./components/EventAttended/EventAttended.js";
import Service from "./web/pages/service/Service.js";
import ServiceView from "./web/pages/service/ServiceDetails.js";
import ScheduleServiceList from "./components/services/ScheduleServiceList.js";
import LeadList from "./components/lead/LeadList.js";
import PricingPlan from "./web/components/pricingPlan/PricingPlan.js";
import ApprovedRoute from "./web/components/approvedRoute/ApprovedRoute.js";
import CommentList from "./pages/CommentList.js";

function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    const element = document.scrollingElement || document.documentElement;
    if (!hash) {
      element.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      const targetElement = document.querySelector(hash);

      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
        });
      } else {
      }
    }
  }, [pathname]);

  return null;
}

function App() {
  const [theme, colorMode] = useMode();
  const state = useSelector((state) => state);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <LocationProvider>
        <ScrollToTop />
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/event-attendance" element={<EventAttended />} />
            <Route path="/business-card/:id" element={<BusinessUser />} />
            <Route element={<WebLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/pricing-plan" element={<PricingPlan />} />
              <Route path="/contact-us" element={<Contactus />} />
              <Route path="/be-partner" element={<BePartner />} />
              <Route path="/be-partner-form" element={<BeMember />} />
              <Route path="/be-member" element={<BeMemberNew />} />
              <Route path="/be-member-form" element={<BeMemberForm />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/faq" element={<FaqPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/blog" element={<WebBlog />} />
              <Route path="/events" element={<Events />} />
              <Route path="/event/:id" element={<EventDetails />} />
              <Route path="/service" element={<Service />} />
              <Route path="/service/:id" element={<ServiceView />} />
              <Route path="/blog/:id" element={<BlogDetails />} />
              <Route path="/find-chapter" element={<Findchapter />} />
              <Route
                path="/terms-and-condition"
                element={<TermsAndCondition />}
              />
              <Route
                path="/find-chapter-search"
                element={<FindChapterSerach />}
              />
            </Route>
            <Route path="/" element={<PublicRoute />}>
              <Route path="/admin" element={<LoginPage />} />
              <Route path="/admin/login" element={<SigninPage />} />
              <Route path="/admin/resetpassword" element={<ResetPassword />} />
              <Route
                path="/admin/confirmpassword"
                element={<ConfirmPassword />}
              />
              <Route path="/otp" element={<Otp />} />
            </Route>
            <Route path="/" element={<AdminProtected />}>
              <Route path="/" element={<Layout />}>
                <Route path="/admin-profile" element={<Profile />} />
                <Route path="/" element={<ApprovedRoute />}>
                  <Route path="/admin/dashboard" element={<Dashboard />} />
                  {/* <Route path="/admin-packages" element={<Package />} /> */}
                  <Route
                    path="/admin-subscription"
                    element={<ManageSubscription />}
                  />
                  <Route path="/commentlist" element={<CommentList />} />
                  <Route path="/user-details" element={<UserDetailsShow />} />
                  {!!getPermissions(permissions.user, "is_read") && (
                    <>
                      <Route
                        path="/admin-user-details"
                        element={<ManageUserDetails />}
                      />
                      <Route path="/admin-user" element={<ManageUser />} />
                      <Route path="/admin-lead" element={<LeadList />} />
                      {!!getPermissions(permissions.user, "is_add") && (
                        <Route
                          path="/admin-crete-user"
                          element={<CreateUserForm />}
                        />
                      )}
                      {!!getPermissions(permissions.user, "is_edit") && (
                        <Route
                          path="/admin-update-user"
                          element={<CreateUserForm />}
                        />
                      )}
                    </>
                  )}
                  {!!getPermissions(permissions.role, "is_read") && (
                    <>
                      <Route
                        path="/admin-role-permission"
                        element={<ManagePermission />}
                      />
                      {!!getPermissions(permissions.role, "is_edit") && (
                        <Route
                          path="/admin-update-role-permission"
                          element={<UpdatePermission />}
                        />
                      )}
                      {!!getPermissions(permissions.role, "is_add") && (
                        <Route
                          path="/admin-create-role-permission"
                          element={<UpdatePermission />}
                        />
                      )}
                    </>
                  )}
                  {!!getPermissions(permissions.event, "is_read") && (
                    <>
                      <Route path="/admin-events" element={<EventsList />} />
                      <Route
                        path="/admin-event-details"
                        element={<EventAdminDetails />}
                      />

                      {!!getPermissions(permissions.event, "is_add") && (
                        <Route
                          path="/admin-events/create-event"
                          element={<CreateEventForm />}
                        />
                      )}
                      {!!getPermissions(permissions.event, "is_edit") && (
                        <Route
                          path="/admin-events/update-event"
                          element={<CreateEventForm />}
                        />
                      )}
                    </>
                  )}
                  {!!getPermissions(permissions.chapter, "is_read") && (
                    <>
                      <Route path="/admin-chapter" element={<ChapterList />} />
                      <Route
                        path="/admin-chapter-bin"
                        element={<ChapterBinList />}
                      />
                    </>
                  )}
                  {!!getPermissions(permissions.partnerRequest, "is_read") && (
                    <Route
                      path="/admin-partner-request"
                      element={<PartnerRequestList />}
                    />
                  )}
                  {!!getPermissions(permissions.blog, "is_read") && (
                    <>
                      <Route path="/admin-blog" element={<Blog />} />
                      <Route
                        path="/admin-blog/create-blog"
                        element={<CreateBlogDetails />}
                      />
                    </>
                  )}
                  {!!getPermissions(permissions.banner, "is_read") && (
                    <Route path="/admin-banner" element={<BannerList />} />
                  )}
                  {!!getPermissions(permissions.referral, "is_read") && (
                    <>
                      <Route path="/admin-referral" element={<ReferralList />} />
                      <Route
                        path="/admin-my-referrals"
                        element={<AdminMyRefferralList />}
                      />
                      <Route
                        path="/admin-my-referrals/:id"
                        element={<CreateReferralList />}
                      />
                      <Route
                        path="/admin-referral/view-referral"
                        element={<ViewReferralDetails />}
                      />
                    </>
                  )}
                  {!!getPermissions(permissions.wallet, "is_read") && (
                    <>
                      <Route path="/admin-wallet" element={<WalletList />} />
                      <Route path="/admin-wallet/view" element={<WalletView />} />
                    </>
                  )}
                  {!!getPermissions(permissions.transaction, "is_read") && (
                    <>
                      <Route
                        path="/admin-transactions"
                        element={<TransactionList />}
                      />
                      <Route
                        path="/admin-transactions/view"
                        element={<ViewTransaction />}
                      />
                    </>
                  )}
                  {!!getPermissions(permissions.notification, "is_read") && (
                    <>
                      <Route
                        path="/admin-notification"
                        element={<NotificationList />}
                      />
                      {!!getPermissions(permissions.notification, "is_add") && (
                        <Route
                          path="/admin-Createnotification"
                          element={<CreateNotification />}
                        />
                      )}
                      {!!getPermissions(permissions.notification, "is_edit") && (
                        <Route
                          path="/admin-update-notification"
                          element={<CreateNotification />}
                        />
                      )}
                    </>
                  )}

                  <Route
                    path="/admin-service-details"
                    element={<ServiceDetails />}
                  />
                  {!!getPermissions(permissions.Service, "is_read") && (
                    <Route path="/admin-services" element={<ServicesListing />} />
                  )}

                  {!!getPermissions(permissions.Service, "is_read") && (
                    <Route
                      path="/admin-services/:services"
                      element={<CreateServices />}
                    />
                  )}
                  {!!getPermissions(permissions.Service, "is_read") && (
                    <Route
                      path="/admin-services/schedule-service-list"
                      element={<ScheduleServiceList />}
                    />
                  )}
                  {!!getPermissions(permissions.business_category, "is_read") && (
                    <Route
                      path="/admin/business-category"
                      element={<BusinessCategoryList />}
                    />
                  )}

                  {!!getPermissions(permissions.ClientReview, "is_read") && (
                    <Route
                      path="/admin-clientreview"
                      element={<ClientReviewList />}
                    />
                  )}

                  {!!getPermissions(permissions.country, "is_read") && (
                    <Route path="/admin-countries" element={<CountriesList />} />
                  )}
                  {!!getPermissions(permissions.state, "is_read") && (
                    <Route path="/admin-states" element={<StateList />} />
                  )}
                  {!!getPermissions(permissions.city, "is_read") && (
                    <Route path="/admin-cities" element={<CityList />} />
                  )}
                  {!!getPermissions(permissions.postalcode, "is_read") && (
                    <Route
                      path="/admin-postal-codes"
                      element={<PostalCodeList />}
                    />
                  )}
                  {!!getPermissions(permissions.membership, "is_read") && (
                    <>
                      <Route
                        path="/admin-memberships"
                        element={<MembershipList />}
                      />
                      <Route
                        path="/admin-memberships/create-membership"
                        element={<CreateMembership />}
                      />
                      <Route
                        path="/admin-memberships/update-membership"
                        element={<CreateMembership />}
                      />
                    </>
                  )}
                  {!!getPermissions(permissions.partnerRequest, "is_read") && (
                    <Route
                      path="/admin-partner-request-details"
                      element={<PartnerReqDetails />}
                    />
                  )}
                  {!!getPermissions(permissions.privacy_policy, "is_read") && (
                    <Route
                      path="/admin-privacy-policy"
                      element={<AdminPrivacyPolicy />}
                    />
                  )}
                  {!!getPermissions(permissions.terms_codition, "is_read") && (
                    <Route
                      path="/admin-terms-and-conditions"
                      element={<AdminTermsCondition />}
                    />
                  )}
                  {!!getPermissions(permissions.faqs, "is_read") && (
                    <>
                      <Route path="/admin-faqs" element={<AdminFAQs />} />
                      <Route
                        path="/admin-faqs/create-faqs"
                        element={<CreateFAQs />}
                      />
                      <Route
                        path="/admin-faqs-category"
                        element={<AdminFAQsCategory />}
                      />
                    </>
                  )}
                  {!!getPermissions(
                    permissions.trusted_by_About_us,
                    "is_read"
                  ) && (
                      <Route path="/admin-about-us" element={<AdminAboutUs />} />
                    )}
                  {!!getPermissions(permissions.page_banners, "is_read") && (
                    <Route path="/admin-pages" element={<AdminPages />} />
                  )}
                  {!!getPermissions(permissions.page_banners, "is_read") && (
                    <Route
                      path="/admin-page-banners"
                      element={<AdminPageBanners />}
                    />
                  )}

                  {!!getPermissions(permissions.withdraw, "is_read") && (
                    <Route path="/admin-withdraw" element={<WithdrawList />} />
                  )}
                  <Route path="/500" element={<InternalServerError />} />
                  <Route path="/admin/*" element={<NotFoundPage />} />
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
            <Route path="/web/*" element={<NotDataFound />} />
          </Routes>
        </ThemeProvider>
      </LocationProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
