function compareDates(date1, date2) {
    // alert(`${date1} ${date2}`)
    // Convert dates to milliseconds since Epoch
    const time1 = new Date(date1).getTime();
    const time2 = new Date(date2).getTime();

    // Compare the milliseconds
    if (time1 > time2) {
        return true;
    } else {
        return false;
    }
}

export { compareDates };