
const initialState = {
    loading: false,
    updateLoading: false,
    deleteLoading: false,
    noteUpdateLoading: false,
    partnerRequests: [],
}

function PartnerRequestReducer(state = initialState, action) {
    switch (action.type) {
        case "LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "UPDATE_LOADING_PARTNER_REQ":
            return {
                ...state,
                updateLoading: action.payload
            }
        case "DELETE_PARTNER_REQ_LOADING":
            return {
                ...state,
                deleteLoading: action.payload
            }
            case "PARTNER_REQUEST_SUCCESS":
                return {
                  ...state,
                  partnerRequests: action.payload,
                };
        case "LOADING_PARTNER_REQ_NOTE":
            return {
                ...state,
                noteUpdateLoading: action.payload
            }
        case "UPDATE_PARTNER_REQ_NOTE_SUCCESS":
            return {
                ...state,
            }
        case "UPDATE_PARTNER_REQ_SUCCESS":
            return {
                ...state,
            }
        case "DELETE_PARTNER_REQ_SUCCESS":
            return {
                ...state,
            }
        default:
            return state;
    }
}


export default PartnerRequestReducer;
