const initalState = {
    getFaqLoading: false,
    createFaqLoading: false,
    updateFaqLoading: false,
    deleteFaqLoading: false,
    FaqData: []
}


export default function FaqsReducer(state = initalState, action) {
    switch (action.type) {
        case "GET_ALL_FAQs_LOADING":
            return {
                ...state,
                getFaqLoading: action.payload
            };
        case "GET_ALL_FAQs_SUCCESS":
            return {
                ...state,
                FaqData: action.payload
            };
        case "GET_ALL_FAQs_FAIL":
            return {
                ...state,
                FaqData: []
            };
 
 
        case "GET_FAQs_CATEGORY_LOADING":
            return {
                ...state,
                getFaqLoading: action.payload
            };
        case "GET_FAQs_CATEGORY_SUCCESS":
            return {
                ...state,
                FaqData: action.payload
            };
        case "GET_FAQs_CATEGORY_FAIL":
            return {
                ...state,
                FaqData: []
            };
                

        case "CREATE_FAQs_LOADING":
            return {
                ...state,
                createFaqLoading: action.payload
            };
        case "CREATE_FAQs_SUCCESS":
            return {
                ...state,
                FaqData: action.payload
            };
        case "CREATE_FAQs_FAIL":
            return {
                ...state,
                FaqData: []
            };
        
        
        case "UPDATE_FAQs_LOADING":
            return {
                ...state,
                updateFaqLoading: action.payload
            };
        case "UPDATE_FAQs_SUCCESS":
            return {
                ...state,
                FaqData: action.payload
            };
        case "UPDATE_FAQs_FAIL":
            return {
                ...state,
                FaqData: []
            };
        
        
        case "DELETE_FAQs_LOADING":
            return {
                ...state,
                deleteFaqLoading: action.payload
            };
        case "DELETE_FAQs_SUCCESS":
            return {
                ...state,
                FaqData: action.payload
            };
        case "DELETE_FAQs_FAIL":
            return {
                ...state,
                FaqData: []
            };

        default:
            return state
    }

}