import React, { useRef, useState, useEffect } from "react";
import CommonSlide from "./CommonSlide";
import { GetAllEvent } from "../../../redux/eventAction";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import SliderImage from "../../slider/SliderImage";
import noeventimage from "../../../assets/image/home/Noevent.png";
import { Deals } from "../../Deals/Deals";
import Slider from "react-slick";

import arrow from "../../../GGC_LendingPage/svg/arrowLeft.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";

const HomeOurDirectory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const sliderRef = useRef(null);
  const [allGetEvent, setAllGetEvent] = useState([]);

  const [rightDot, setRightDot] = useState(true);
  const [leftDot, setLeftDot] = useState(false);

  const getEvent = async () => {
    const res = await GetAllEvent();
    if (res) {
      setAllGetEvent(res.data?.eventData);
    }
  };

  useEffect(() => {
    getEvent();
  }, []);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const cardVariants = {
    initial: { opacity: 0, y: 100 },
    animate: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: "easeOut" },
    },
  };

  const containerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    afterChange: (index) => {
      setCurrentPage(index)
    },responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ],
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
    setLeftDot(true);
    setRightDot(false);
  };
  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
    setLeftDot(false);
    setRightDot(true);
  };

  return (
    <>
      {allGetEvent?.length > 0 ? (
        <section style={{ position: "relative" }}>
          <div className="responsive">
            <Deals title="Events" />

            <div style={{ marginTop: "15px", width: "99%" }}>
              <Slider
                ref={sliderRef}
                {...settings}
                style={{ paddingBottom: "35px", gap:'10px'}}
              >
                {allGetEvent &&
                allGetEvent.filter((itemEvent) => {
                  return (
                    itemEvent.is_active &&
                    itemEvent?.approval_status !== "rejected" &&
                    itemEvent?.approval_status !== "pending" &&
                    itemEvent?.schedule_status !== "cancelled"
                  );
                }).length > 0 ? (
                  allGetEvent
                    .filter((itemEvent) => {
                      return (
                        itemEvent.is_active &&
                        itemEvent?.approval_status !== "rejected" &&
                        itemEvent?.approval_status !== "pending" &&
                        itemEvent?.schedule_status !== "cancelled"
                      );
                    })
                    .map((item) => {
                      return (
                          <CommonSlide variants={cardVariants} item={item} />
                      );
                    })
                ) : (
                  <h3
                    style={{
                      fontSize: "40px !important",
                      color: "black !important",
                    }}
                  >
                    No event available
                  </h3>
                )}
              </Slider>
            </div>
          </div>

          {/*Arrows*/}
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              border: "1px solid #d1d1d1",
              cursor: "pointer",
              top: "50%",
              padding: "10px",
            }}
            className="arwRight"
          >
            <img
              src={arrow}
              style={{ width: "80%", height: "80%" }}
              onClick={goToNextSlide}
            />
          </div>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              border: "1px solid #d1d1d1",
              cursor: "pointer",
              top: "50%",
              padding: "10px",
            }}
            className="arwLeft"
          >
            <img
              src={arrow}
              style={{ width: "80%", height: "80%", rotate: "180deg" }}
              onClick={goToPrevSlide}
            />
          </div>
        </section>
      ) : (
        <section
          id="section-01"
          className="be-partner-main-intro"
          style={{
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <img src={noeventimage} alt="No events" />
        </section>
      )}
    </>
  );
};

const directoryMenu = [
  {
    menu_name: "all",
  },
  {
    menu_name: "featured",
  },
  {
    menu_name: "best rate",
  },
  {
    menu_name: "most view",
  },
  {
    menu_name: "popular",
  },
  {
    menu_name: "ad directory",
  },
];

export default HomeOurDirectory;
