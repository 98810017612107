import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import PageHeader from "../pageHeader/PageHeader";
import EnhancedTable from "../table/EnhancedTable.js";
import {
  DeleteIcon,
  EditIcon,
  CloseIcon,
  Visibility,
} from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { Form, useForm } from "../../common/useForms";
import FormDialog from "../dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import DeleteDialog from "../dialog/deletedialog";
import SelectBox from "../../common/SelectBox";
import { GetAllCountry } from "../../redux/action/CountryAction";
import { GetAllStates } from "../../redux/action/StateAction";
import { GetAllCities } from "../../redux/action/CityAction";
import {
  CreatePostal,
  UpdatePostal,
} from "../../redux/action/PostalCodeAction";
import { useNavigate } from "react-router-dom";
import {
  DeleteEvent,
  GetEvent,
  UpdateEvent,
} from "../../redux/action/EventAction";
import useUserRolePermissions from "../../common/UserPermissions";
import { ApiURL } from "../../redux/common";
import axios from "axios";
import ShowFirstImage from "../showFirstImage/ShowFirstImage";
import Loader from "../loader/Loader";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const events = [
  { value: "all", label: "All" },
  { value: "scheduled", label: "Scheduled" },
  { value: "attendeed", label: "Attendeed" },
  { value: "created", label: "Create" },
];

function convertDateFormat(dateString) {
  // Parse the date string using the ISO 8601 format
  const dateObject = new Date(dateString);

  // Format the date object to the desired format
  const formattedDate = dateObject.toLocaleDateString("en-IN", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    //   second: "2-digit",
  });

  return formattedDate;
}

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const initialValues = {
  country: "",
  state: "",
  city: "",
  postalcode: "",
  is_active: "",
};
const initialFilterValues = {
  membership_name: "",
  events: "all",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
];

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "blockQuote",
      "|",
      "bulletedList",
      "numberedList",
      "outdent",
      "indent",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};

const EventsList = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectPostal, setselectPostal] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [noteOpen, setNoteOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isFilterClicked, setIsFilterClicked] = useState(false);


  const [data, setData] = useState("");
  const [noteId, setNoteId] = useState("");
  const userRoleData = useUserRolePermissions("Event");

  const isEditAllowed = getPermissions(permissions.event, "is_edit");
  const isDeleteAllowed = getPermissions(permissions.event, "is_delete");
  const isAddAllowed = getPermissions(permissions.event, "is_add");

  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const UserId =
    localStorage.getItem("user_data") &&
    JSON.parse(localStorage.getItem("user_data"));

  const [downLoadData, setDownLoadData] = useState("");

  const DownLoadPdf = async () => {
    const event_name = filterDatas.membership_name;
    try {
      const res = await axios.get(`${ApiURL}event/download-data?event_name=${event_name}`);
      setDownLoadData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching role data:", error);
      return {};
    }
  };

  useEffect(() => {
    DownLoadPdf();
  }, [isFilterClicked]);

  const columns = [
    {
      id: "event_name",
      numeric: false,
      disablePadding: true,
      label: "Event Name",
    },
    {
      id: "event_type",
      numeric: false,
      disablePadding: true,
      label: "Event Type",
    },
    {
      id: "venue",
      numeric: false,
      disablePadding: true,
      label: "Venue",
    },
    {
      id: "start_time",
      numeric: false,
      disablePadding: true,
      label: "Start Time",
    },
    {
      id: "end_time",
      numeric: false,
      disablePadding: true,
      label: "End Time",
    },
    {
      id: "event_image",
      numeric: false,
      disablePadding: true,
      label: "Event Image",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
    },
    {
      id: "view",
      numeric: true,
      disablePadding: false,
      label: "View",
    },
    isEditAllowed || isDeleteAllowed
      ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
      : null,
  ].filter((el) => el !== null);

  const { allStateData } = useSelector((state) => state.StateReducer);
  const { allCountryData } = useSelector((state) => state.CountryReducer);
  const { allCitiesData } = useSelector((state) => state.CityReducer);
  const { loading, deleteLoading, eventData } = useSelector(
    (state) => state.EventReducer
  );
  const state = useSelector((state) => state.EventReducer);

  const handleViewClick = (event) => {
    navigate("/admin-event-details", { state: { eventData: event } });
  };

  const tableTitle = "MembershipList";

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    setPage(1);
    const event_name = filterDatas.membership_name;
    dispatch(GetEvent(1, event_name, rowPerPage));
  };

  useEffect(() => {
    if (!open) {
      const event_name = filterDatas.membership_name;
      dispatch(GetEvent(page, event_name, rowsPerPage));
    }
    dispatch(GetEvent(page, "", rowsPerPage));
  }, [page, open]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setDialogTitle("Add PostalCode");
    setValues(initialValues);
  };

  const handleBtnClick = () => {
    navigate("/admin-events/create-event", {
      state: { formName: "Create Event", eventData: {} },
    });
  };

  const handleNoteClose = () => {
    setNoteOpen(false);
  };

  const handleNoteClick = async (data) => {
    setSelectedRequest(data);
    setNoteOpen(true);
    setData(data.note);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if (dialogTitle === "Update PostalCode") {
      if ("postalcode" in fieldValues) {
        if (
          fieldValues.postalcode === "" ||
          fieldValues.postalcode === undefined ||
          fieldValues.postalcode === null
        ) {
          temp.postalcode = "This field is required.";
        } else {
          temp.postalcode = "";
        }
      }
      if ("is_active" in fieldValues) {
        if (
          fieldValues.is_active === "" ||
          fieldValues.is_active === undefined ||
          fieldValues.is_active === null
        ) {
          temp.is_active = "This field is required.";
        } else {
          temp.is_active = "";
        }
      }
    } else {
      if ("country" in fieldValues) {
        if (
          fieldValues.country === "" ||
          fieldValues.country === undefined ||
          fieldValues.country === null
        ) {
          temp.country = "This field is required.";
        } else {
          temp.country = "";
        }
      }

      if ("state" in fieldValues) {
        if (
          fieldValues.state === "" ||
          fieldValues.state === undefined ||
          fieldValues.state === null
        ) {
          temp.state = "This field is required.";
        } else {
          temp.state = "";
        }
      }

      if ("city" in fieldValues) {
        if (
          fieldValues.city === "" ||
          fieldValues.city === undefined ||
          fieldValues.city === null
        ) {
          temp.city = "This field is required.";
        } else {
          temp.city = "";
        }
      }

      if ("postalcode" in fieldValues) {
        if (
          fieldValues.postalcode === "" ||
          fieldValues.postalcode === undefined ||
          fieldValues.postalcode === null
        ) {
          temp.postalcode = "This field is required.";
        } else {
          temp.postalcode = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleEditClick = (event) => {
    setselectPostal(event);
    // setDialogTitle("Update PostalCode");
    navigate("/admin-events/update-event", {
      state: { formName: "Update Event", eventData: event },
    });
    // setValues({
    //     ...values,
    //     postalcode: postal.postal_code || "",
    //     is_active: postal.is_active ? 'true' : 'false' || "",
    // });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (postal) => {
    setOpenDelete(true);
    SetDeleteId(postal._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    await dispatch(DeleteEvent(DeleteId, handleCloseDelete)).then((res) => {
      if (res && res.data.status === true) {
        dispatch(GetEvent(page, "", rowsPerPage));
      }
    });
  };
  const fields = [
    {
      id: "country",
      label: "Country",
      type: "autocomplete",
      autoCompleteData: allCountryData?.countryData,
      value: values.country,
      name: "country",
      errors: errors.country,
    },
    {
      id: "state",
      label: "State",
      type: "autocomplete",
      autoCompleteData: allStateData?.stateData,
      value: values.state,
      labelAccess: "state_name",
      name: "state",
      errors: errors.state,
    },
    {
      id: "city",
      label: "City",
      type: "autocomplete",
      autoCompleteData: allCitiesData?.cityData,
      labelAccess: "city_name",
      value: values.city,
      name: "city",
      errors: errors.city,
    },
    {
      id: "postalcode",
      label: "Postal Code",
      type: "number",
      value: values.postalcode,
      name: "postalcode",
      errors: errors.postalcode,
    },
    {
      id: "is_active",
      label: "Is Active",
      type: "select",
      options: [
        { value: "true", label: "ACTIVE" },
        { value: "false", label: "INACTIVE" },
      ],
      value: values.is_active,
      name: "is_active",
      errors: errors.is_active,
    },
  ];

  //filter
  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(GetEvent(1, "", rowsPerPage));
    setIsFilterClicked(false)
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericRegex = /^\d+$/;

    if (name === "postalcode" && !numericRegex.test(value)) {
      return;
    }

    setFilterDatas({
      ...filterDatas,
      [name]: value,
    });
  };

  const isFilterFormFilled =
    filterDatas.membership_name.trim() !== "" || filterDatas.events !== "all";

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setIsFilterClicked(true)
    const event_name = filterDatas.membership_name;
    const events = filterDatas.events;
    setPage(1);
    dispatch(GetEvent(page, event_name, rowsPerPage, events));
    closeFilterDrawer1();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      let body = {};
      if (dialogTitle === "Update PostalCode") {
        body = {
          is_active: values.is_active,
          postal_code: +values.postalcode,
        };
      } else {
        body = {
          country_id: values.country._id,
          state_id: values.state._id,
          city_id: values.city._id,
          postal_code: +values.postalcode,
        };
      }

      if (dialogTitle === "Update PostalCode") {
        dispatch(UpdatePostal(selectPostal._id, body, handleClose));
      } else {
        dispatch(CreatePostal(body, handleClose));
      }
    }
  };
  function subStr(txt, len) {
    let acceptedText =
      txt.length < len ? txt : txt.substring(0, len - 1) + "...";
    return acceptedText;
  }
  const rows =
    eventData && Array.isArray(eventData?.eventData)
      ? eventData?.eventData.map((event) => {
          const selected = event === selectPostal;
          return {
            _id: event._id,
            event_name: subStr(event.event_name, 20) || "-",
            event_type: event.event_type || "-",
            venue: subStr(event.venue, 50) || "-",
            start_time: event.start_time
              ? convertDateFormat(event.start_time)
              : "-",
            end_time: event.end_time ? convertDateFormat(event.end_time) : "-",
            event_image: (
              <div
                style={{ width: "100px", height: "100px", overflow: "hidden" }}
              >
                <ShowFirstImage
                  width="auto"
                  height="auto"
                  padding="4px 24px 0px 10px"
                  images={event?.event_images}
                />
              </div>
            ),
            status : event.approval_status,
            view: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IconButton
                  onClick={() => handleViewClick(event)}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <Visibility />
                </IconButton>
              </div>
            ),
            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {UserId?.user?._id === event?.user?._id ||
                UserId?.user?.user_role?.role === "Community Super Admin" ||
                UserId?.user?.user_role?.role === "Community Admin" ? (
                  <IconButton
                    onClick={() => handleNoteClick(event)}
                    sx={{
                      color: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#153e80",
                      },
                      "&:focus": {
                        backgroundColor: "white",
                        color: "#153e80",
                        border: "none",
                      },
                    }}
                  >
                    <SummarizeOutlinedIcon />
                  </IconButton>
                ) : null}

                {isEditAllowed ? (
                  <IconButton
                    onClick={() => handleEditClick(event)}
                    sx={{
                      color: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#153e80",
                      },
                      "&:focus": {
                        backgroundColor: "white",
                        color: "#153e80",
                        border: "none",
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                ) : null}
                {isDeleteAllowed ? (
                  <IconButton
                    onClick={() => handleClickOpenDelete(event)}
                    sx={{
                      color: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#153e80",
                      },
                      "&:focus": {
                        backgroundColor: "white",
                        color: "#153e80",
                        border: "none",
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : null}
              </div>
            ),
            selected: selected,
          };
        })
      : [];

  const isAnyFieldFilled = Object.values(filterDatas).some((value) =>
    Boolean(value)
  );

  const [notesSubmitting, setNotesSubmitting] = useState(false);

  const handleSubmitNote = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("note", data);
    try {
      setNotesSubmitting(true);
      const res = await dispatch(UpdateEvent(selectedRequest?._id, formData));
      handleNoteClose();
      if (res && res.data.status === true) {
        dispatch(GetEvent(1, "", ""));
        setNotesSubmitting(false);
      } else {
        setNotesSubmitting(false);
      }
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };
  const maxLength = 500; // Set your maximum length here

  const handleEditorChange = (event, editor) => {
    const newData = editor.getData();

    if (newData.length <= maxLength) {
      setData(newData);
    } else {
      // Optionally, you can also show a warning to the user here
      editor.setData(data); // Revert to the previous data
    }
  };
  return (
    <Box>
      <PageHeader
        title="Events"
        isShowAdd={false}
        onBtnClick={handleBtnClick}
        btnText={isAddAllowed ? "Create Event" : ""}
        // onAddClick={handleClickOpen}
        onFilterClick={openFilterDrawer}
        loading={loading}
        href={downLoadData}
      />
      {loading ? (
        <Loader />
      ) : (
        <EnhancedTable
          rowsPerPage={rowsPerPage}
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={eventData?.eventData}
        />
      )}

      <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        isDisable={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Dialog
        open={noteOpen}
        onClose={handleNoteClose}
        PaperProps={{
          component: "div",
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ color: "#000 !important" }}>
            Note
          </Typography>
          <IconButton
            sx={{
              color: "black",
              "&:hover": {
                backgroundColor: "white",
                color: "#153e80",
              },
              "&:focus": {
                backgroundColor: "white",
                color: "#153e80",
                border: "none",
              },
            }}
          >
            <CloseIcon onClick={handleNoteClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitNote}>
            <CKEditor
              // disabled={!getPermissions(permissions.terms_codition, "is_edit")}
              editor={ClassicEditor}
              data={data == undefined ? data : data.substring(0, 100)}
              config={{
                placeholder: "Add Terms and Conditions",
              }}
              onChange={handleEditorChange}
              // onChange={(event, editor) => {
              //   const newData = editor.getData();
              //   setData(newData);
              // }}
            />
            <DialogActions sx={{ justifyContent: "center", display: "flex" }}>
              <Button
                type="submit"
                // onClick={handleSubmitNote}
                disabled={notesSubmitting}
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  border: "0",
                  ":hover": { bgcolor: "#1976d2", border: "0" },
                  backgroundColor: "#153e80",
                  marginLeft: {
                    xs: "0px",
                    sm: "15px",
                    md: "15px",
                    lg: "15px",
                  },
                  color: "#fff",
                  textTransform: "none",
                  minWidth: "100px",
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {eventData?.currentPage} of {eventData?.totalPages} Pages
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={eventData?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>

      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div>
              <Box>
                <Input
                  variant="outlined"
                  color="primary"
                  label="Event Name"
                  name="membership_name"
                  value={filterDatas.membership_name}
                  onChange={handleChange}
                  maxLength="200"
                />
              </Box>
              <Stack className={classes.buttonFilter}>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                  disabled={!isFilterFormFilled}
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  disabled={!isFilterFormFilled}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
    </Box>
  );
};

export default EventsList;
