const initialState = {
  loading: false,
  transaction: [],
};

function TransactionReducer(state = initialState, action) {
  switch (action.type) {
    case "TRANSACTION_GET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };

    case "TRANSACTION_GET_SUCCESS":
      return {
        ...state,
        transaction: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}

export default TransactionReducer;
