import React, { useEffect, useState } from "react";
import hotel5 from "../../assets/image/shop/hotel-5.jpg";
import hotel4 from "../../assets/image/shop/hotel-4.jpg";
import hotel2 from "../../assets/image/shop/hotel-2.jpg";
import Slider from "react-slick";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../config/api";
import NotFound from "../notFound/NotFound";
import Loader from "../../components/loader/Loader";
import moment from "moment/moment";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Login from "../auth/Login";
import { DisplayHTMLString } from "../../components/htmlToString/HtmlToString";
import PaymentGateway from "../../components/PaymentGateway";
import GuestDetailsModal from "../../components/guestDetailsModal/GuestDetailsModal";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { base_Url, snackBar } from "../../utils/common";
import RegistrationLoader from "../../components/loader/RegisterLoader";
import ComCard from "../../../common/ComCard";
import GpsFixedTwoToneIcon from "@mui/icons-material/GpsFixedTwoTone";
import SelectBox from "../../../common/SelectBox";
import { Container } from "@material-ui/core";
import { compareDates } from "../../../utils/dateUtility";
import closed from "../../assets/GG_C_Events/booked.svg";
import bookingClosed from "../../assets/GG_C_Events/BOOKING-CLOSED.svg";
import eventCancled from "../../assets/GG_C_Events/eventCancled.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "2px",
  },
  "& .MuiDialogActions-root": {
    padding: "2px",
  },
}));

const guestNumber = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

const EventDetails = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const nav = useNavigate();
  const [successModal, setSuccessModal] = useState({
    data: {},
    success: false,
    open: false,
  });

  const handleGuestModalOpen = () => {
    setIsGuestModalOpen(true);
  };
  const handleGuestModalClose = () => {
    setIsGuestModalOpen(false);
  };

  const handleModalOpen = () => {
    setLoginModalOpen(true);
  };

  const handleModalClose = () => {
    setLoginModalOpen(false);
  };

  const [formData, setFormData] = useState({
    persons: "" || "1",
    guest: "" || "0",
    type: "" || "1",
    date: "" || "",
  });
  const token = localStorage.getItem("access_token");

  const {
    event_name,
    event_description,
    chapter_name,
    start_time,
    schedule_status,
    end_time,
    price,
    event_images,
    qr_code,
    venue_link,
    venue,
    note,
  } = data;

  const userDataString = JSON.parse(localStorage.getItem("user_data"));

  const [isEventBooked, setIsEventBooked] = useState();

  console.log("userDataString?.user?._id", userDataString?.user?._id);

  useEffect(() => {
    const checkEventBooked = data?.booked_users?.filter((check) => {
      return check?.user_id == userDataString?.user?._id;
    });

    setIsEventBooked(checkEventBooked);
  }, [data, token]);

  const basePricePerPerson = price;

  const calculateTotalPrice = (data) => {
    const totalPersons = parseInt(data.persons, 10) + parseInt(data.guest, 10);
    return basePricePerPerson * totalPersons;
  };

  const [totalPrice, setTotalPrice] = useState(calculateTotalPrice(formData));
  const [guestDetails, setGuestDetails] = useState([]);
  const [eventRegistrationLoading, setEventRegistrationLoading] =
    useState(false);

  useEffect(() => {
    setTotalPrice(calculateTotalPrice(formData));
  }, [formData]);

  const handleInputChange = (event) => {
    // const { id, value } = event.target;
    const { name, value } = event.target;
    if (value <= 10) {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleGuestBlur = (event) => {
    // const { id, value } = event.target;
    const { name, value } = event.target;
    if (value <= 10) {
      if (name == "guest") {
        setGuestDetails(
          Array.from({ length: value }).fill({
            name: "",
            email: "",
            mobile_number: "",
          })
        );
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!token) {
      handleModalOpen();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { id } = useParams();

  const getEventById = () => {
    api
      .get(`event/event-by-id/${id}`)
      .then((res) => {
        if (res.status) {
          const data = res.data;
          if (Array.isArray(data) && data.length) {
            setData(res.data[0]);
          } else {
            setError(true);
          }
        } else {
          setError(true);
        }
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  const [startPaymentProcess, setStartPaymentProcess] = useState(false);

  const handlePaymentSuccess = async (response) => {
    setStartPaymentProcess(true);
    try {
      setEventRegistrationLoading(true);
      const payload = {
        event_id: id,
        razorpay_payment_id: response?.razorpay_payment_id,
        guest_users: guestDetails,
      };
      const res = await api.post(
        `${base_Url}event/event-registration`,
        payload
      );
      if (res?.status) {
        snackBar(res?.message, res?.status);
        setGuestDetails([]);
        setIsGuestModalOpen(false);
        setSuccessModal({
          success: true,
          open: true,
          data: {
            event_id: id,
            razorpay_payment_id: response?.razorpay_payment_id,
          },
        });
        setStartPaymentProcess(false);
        nav("/");
      } else {
        setSuccessModal({
          success: false,
          open: true,
          data: {
            event_id: id,
            razorpay_payment_id: response?.razorpay_payment_id,
          },
        });
        snackBar(res?.message, res?.status);
      }
    } catch {
      setSuccessModal({
        success: false,
        open: true,
        data: {
          event_id: id,
          razorpay_payment_id: response?.razorpay_payment_id,
        },
      });
      snackBar("Please try again!", false);
    } finally {
      setEventRegistrationLoading(false);
    }
  };

  const handlePaymentModalClose = () => {
    setSuccessModal({ success: false, open: false, data: {} });
  };

  useEffect(() => {
    getEventById();
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    className: "hero-banner-arrow",
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: "0%",
        },
      },
    ],
    centerMode: true,
    centerPadding: "0%",
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <NotFound />;
  }

  function subStr(txt, len) {
    let acceptedText =
      txt.length < len ? txt : txt.substring(0, len - 1) + "...";
    return acceptedText;
  }

  const handleStartPaymentProcess = () => {
    setStartPaymentProcess(true);
  };
  const handleClosePaymentProcess = () => {
    setStartPaymentProcess(false);
  };

  return (
    <>
      <div className="responsive">
        {/*HEAD*/}
        <ul
          className="breadcrumb breadcrumb-style-03 my-2"
          style={{
            overflowWrap: "break-word",
            wordBreak: "break-word",
            whiteSpace: "normal",
          }}
        >
          <li className="breadcrumb-item">
            <Link
              to="/"
              style={{
                color: "#000",
                fontSize: "18px",
                letterSpacing: "0.6px",
                cursor: "pointer",
              }}
            >
              Home
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to="/events"
              style={{
                color: "#000",
                fontSize: "18px",
                letterSpacing: "0.6px",
                cursor: "pointer",
              }}
            >
              Events{" "}
            </Link>
          </li>
          <li
            className="breadcrumb-item"
            style={{
              color: "#000",
              fontSize: "18px",
              letterSpacing: "0.6px",
              cursor: "pointer",
            }}
          >
            {event_name}
          </li>
        </ul>
      </div>

      <div className="bg-gray-06" style={{ margin: "15px 0 50px" }}>
        {eventRegistrationLoading && <RegistrationLoader />}
        <div className="responsive">
          <div className="eventData">
            <div className="eventDataLeft">
              {/*Verified Title*/}
              <Grid item md={12} xs={12} sm={12}>
                <Grid md={8} xs={12} sm={12}>
                  <div className="d-flex flex-wrap mb-3">
                    <h2
                      className="text-dark mr-3 mb-0"
                      style={{
                        display: "flex",
                        overflowWrap: "break-word",
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      {event_name}
                    </h2>
                    <span className="check font-weight-semibold text-green my-auto">
                      <svg className="icon icon-check-circle">
                        <use xlinkHref="#icon-check-circle" />
                      </svg>
                      Verified
                    </span>
                  </div>
                </Grid>
              </Grid>

              {/*Time / Location*/}
              <Grid
                item
                md={9}
                xs={12}
                sm={12}
                sx={{
                  margin: "15px 0 25px",
                }}
                className="eventTimeLoca"
              >
                <div style={{ width: "100%", margin: "15px auto" }}>
                  <Grid
                    md={12}
                    xs={12}
                    sm={12}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      height: "113px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div className="mr-1">
                      <i
                        className="fal fa-clock"
                        style={{
                          fontSize: "27px",
                          color: "#153e80",
                        }}
                      />
                    </div>
                    <div>
                      <b>Start : </b>
                      {moment(start_time).format("DD-MM-YYYY, h:mm:ss a")}
                    </div>
                    <div>
                      <b>End : </b>
                      {moment(end_time).format("DD-MM-YYYY, h:mm:ss a")}
                    </div>
                  </Grid>
                </div>
                <div style={{ width: "100%", margin: "15px auto" }}>
                  <Grid
                    md={12}
                    xs={12}
                    sm={12}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      height: "113px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      className="mr-1"
                      style={{ marginTop: "15px", color: "#153e80" }}
                    >
                      <span className="">
                        <i
                          className="fal fa-map-marker-alt large-icon"
                          style={{ fontSize: "25px" }}
                        ></i>
                      </span>
                    </span>
                    <span
                      style={{
                        padding: "0px 10px 0px 10px",
                        textAlign: "center",
                        fontSize: "18px",
                        lineHeight: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {subStr(venue, 40)}
                    </span>
                  </Grid>
                </div>
              </Grid>

              {/*Direction*/}
              <Grid item md={12} xs={12} sm={12}>
                <Grid
                  md={9}
                  xs={12}
                  sm={12}
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "15px",
                    height: "66px",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="eventTimeLoca"
                >
                  <span className="mr-1">
                    <GpsFixedTwoToneIcon
                      sx={{
                        fontSize: "30px",
                        color: "#153e80",
                      }}
                    />
                  </span>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    style={{
                      color: "#153e80",
                      textDecoration: "underline",
                    }}
                    href={
                      !venue_link?.startsWith("https://") &&
                      !venue_link?.startsWith("http://")
                        ? "https://" + venue_link
                        : venue_link
                    }
                  >
                    Get Direction Now
                  </a>
                </Grid>
              </Grid>
            </div>

            <div className="eventDataRight">
              {/*FORM*/}
              {token ? (
                end_time &&
                compareDates(
                  new Date(end_time).toUTCString(),
                  new Date().toUTCString()
                ) ? (
                  isEventBooked?.length ? (
                    <>
                      {/*BOOKED*/}
                      <div style={{ width: "100%"}}>
                        <div
                          style={{
                            borderRadius: "10px",
                            width: "90%",
                            height: "300px",
                            margin: "0px auto",
                          }}
                        >
                          <img
                            src={closed}
                            style={{
                              borderRadius: "10px",
                              width: "100%",
                              height: "100%",
                              margin: "0px auto",
                            }}
                          />
                        </div>
                        <div style={{ width: "75%", margin: "20px auto 0" }}>
                          <button disabled className="disableBtn" type="button" style={{ width: "100%"}}>
                            Event Booked
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Grid
                      item
                      md={5}
                      xs={12}
                      sm={12}
                      style={{ boxShadow: "none" }}
                    >
                      {/*FORM Book Now*/}
                      <Grid
                        md={10}
                        sm={12}
                        sx={{
                          backgroundColor: "white !important",
                          borderRadius: "31px",
                          boxShadow: "none",
                        }}
                      >
                        <Box
                          component={Paper}
                          padding={{ xs: 2, sm: 3 }}
                          sx={{
                            borderRadius: "30px",
                            "&.css-11a8l5m-MuiPaper-root": {
                              boxShadow: "none",
                            },
                          }}
                        >
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                              <Grid item md={12} xs={12} sm={12}>
                                <Typography
                                  fontSize={17}
                                  marginBottom={2}
                                  color="black"
                                >
                                  Persons:
                                </Typography>
                                <TextField
                                  className="borderRadius"
                                  sx={{
                                    width: "100%",
                                    ".css-wtvcxf-MuiInputBase-root-MuiOutlinedInput-root":
                                      {
                                        borderRadius: "13px",
                                      },
                                  }}
                                  variant="outlined"
                                  disabled={true}
                                  type="number"
                                  id="persons"
                                  name="persons"
                                  min="1"
                                  value={formData.persons}
                                  onChange={handleInputChange}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item md={12} xs={12} sm={12}>
                                <Typography
                                  marginTop={2}
                                  marginBottom={1}
                                  fontSize={17}
                                  color="black"
                                >
                                  Guest:
                                </Typography>
                                <SelectBox
                                  type="text"
                                  id="guest"
                                  name="guest"
                                  label="Guest"
                                  ClassName="range-select-filter"
                                  onChange={handleInputChange}
                                  onBlur={handleGuestBlur}
                                  value={formData.guest}
                                  // defaultValue={statusModal?.status || "pending"}
                                  options={guestNumber}
                                />
                                <div
                                  className="font-weight-semibold mt-2 mb-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <span>
                                    Total Amount: ₹
                                    {isNaN(totalPrice)
                                      ? basePricePerPerson
                                      : totalPrice}
                                  </span>
                                </div>
                                {token ? (
                                  formData.guest > 0 ? (
                                    <button
                                      className="btnAll"
                                      style={{ width: "100%" }}
                                      type="button"
                                      onClick={handleGuestModalOpen}
                                      disabled={formData.guest > 100}
                                    >
                                      Book Now
                                    </button>
                                  ) : (
                                    <PaymentGateway
                                      amount={
                                        isNaN(totalPrice)
                                          ? basePricePerPerson
                                          : totalPrice
                                      }
                                      afterPayment={handlePaymentSuccess}
                                      enablePayementProcess={
                                        handleClosePaymentProcess
                                      }
                                    >
                                      <button
                                        className="btnAll"
                                        style={{ width: "100%" }}
                                        type="button"
                                        onClick={handleStartPaymentProcess}
                                        disabled={startPaymentProcess}
                                      >
                                        Book Now
                                      </button>
                                    </PaymentGateway>
                                  )
                                ) : (
                                  <button
                                    className="btnAll"
                                    style={{ width: "100%" }}
                                    type="button"
                                    onClick={handleModalOpen}
                                  >
                                    Book Now
                                  </button>
                                )}
                              </Grid>
                            </Grid>
                          </form>
                        </Box>
                      </Grid>
                    </Grid>
                  )
                ) : schedule_status !== "cancelled" ? (
                  <>
                    {/*Booking Closed*/}
                      <Grid md={10} sm={12}>
                        <div
                          style={{
                            borderRadius: "10px",
                            width: "90%",
                            height: "300px",
                            margin: "0px auto",
                          }}
                        >
                          <img
                            src={bookingClosed}
                            style={{
                              borderRadius: "10px",
                              width: "100%",
                              height: "100%",
                              margin: "0px auto",
                            }}
                          />
                        </div>
                        <div style={{width: '70%', margin: '20px auto 0'}}>
                          <button
                            disabled
                            className="disableBtn"
                            type="button"
                            style={{ width: "100%", cursor: 'not-allowed' }}
                          >
                            Booking Closed
                          </button>
                        </div>
                      </Grid>
                  </>
                ) : (
                  <>
                    {/*Event Cancled*/}
                    <Grid item md={5} xs={12} sm={12}>
                      <Grid md={10} sm={12}>
                        <div
                          style={{
                            borderRadius: "10px",
                            width: "90%",
                            height: "300px",
                            margin: "0px auto",
                          }}
                        >
                          <img
                            src={eventCancled}
                            style={{
                              borderRadius: "10px",
                              width: "100%",
                              height: "100%",
                              margin: "0px auto",
                            }}
                          />
                        </div>
                        <Box padding={{ xs: 2, sm: 3 }}>
                          <button
                            disabled
                            className="disableBtn"
                            type="button"
                            style={{ width: "100%" }}
                          >
                            Event Cancled
                          </button>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                )
              ) : end_time &&
                compareDates(
                  new Date(end_time).toUTCString(),
                  new Date().toUTCString()
                ) ? (
                <Grid item md={5} xs={12} sm={12}>
                  {/*Form*/}
                  <Grid
                    md={10}
                    sm={12}
                    sx={{
                      backgroundColor: "white !important",
                      borderRadius: "31px",
                    }}
                  >
                    <Box
                      component={Paper}
                      padding={{ xs: 2, sm: 3 }}
                      sx={{
                        borderRadius: "30px",
                        "&.css-11a8l5m-MuiPaper-root": {
                          boxShadow:
                            "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12) !important",
                        },
                      }}
                    >
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                          {/*Form*/}
                          <Grid item md={12} xs={12} sm={12}>
                            <Typography
                              fontSize={17}
                              marginBottom={2}
                              color="black"
                            >
                              Persons:
                            </Typography>
                            <TextField
                              className="borderRadius"
                              sx={{
                                width: "100%",
                                ".css-wtvcxf-MuiInputBase-root-MuiOutlinedInput-root":
                                  {
                                    borderRadius: "13px",
                                  },
                              }}
                              variant="outlined"
                              disabled={true}
                              type="number"
                              id="persons"
                              name="persons"
                              min="1"
                              value={formData.persons}
                              onChange={handleInputChange}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          {/*BOOK NOW Form*/}
                          <Grid item md={12} xs={12} sm={12}>
                            <Typography
                              marginTop={2}
                              marginBottom={1}
                              fontSize={17}
                              color="black"
                            >
                              Guest:
                            </Typography>
                            <SelectBox
                              type="text"
                              id="guest"
                              name="guest"
                              label="Guest"
                              ClassName="range-select-filter"
                              onChange={handleInputChange}
                              onBlur={handleGuestBlur}
                              value={formData.guest}
                              // defaultValue={statusModal?.status || "pending"}
                              options={guestNumber}
                            />
                            <div
                              className="font-weight-semibold mt-2 mb-2"
                              style={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <span>
                                Total Amount: ₹
                                {isNaN(totalPrice)
                                  ? basePricePerPerson
                                  : totalPrice}
                              </span>
                            </div>
                            {token ? (
                              formData.guest > 0 ? (
                                <button
                                  className="btnAll"
                                  style={{ width: "100%" }}
                                  type="button"
                                  onClick={handleGuestModalOpen}
                                  disabled={formData.guest > 100}
                                >
                                  Book Now
                                </button>
                              ) : (
                                <PaymentGateway
                                  amount={
                                    isNaN(totalPrice)
                                      ? basePricePerPerson
                                      : totalPrice
                                  }
                                  afterPayment={handlePaymentSuccess}
                                  enablePayementProcess={
                                    handleClosePaymentProcess
                                  }
                                  style={{ width: "100%", border: "1px solid" }}
                                >
                                  <button
                                    className="btnAll"
                                    style={{ width: "100%" }}
                                    type="button"
                                    onClick={handleStartPaymentProcess}
                                    disabled={startPaymentProcess}
                                  >
                                    Book Now
                                  </button>
                                </PaymentGateway>
                              )
                            ) : (
                              <button
                                className="btnAll"
                                style={{ width: "100%", border: "1px solid" }}
                                type="button"
                                onClick={handleModalOpen}
                              >
                                Book Now
                              </button>
                            )}
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </Grid>
                </Grid>
              ) : schedule_status !== "cancelled" ? (
                <>
                  {/*Booking Closed*/}
                  <Grid md={10} sm={12}>
                        <div
                          style={{
                            borderRadius: "10px",
                            width: "90%",
                            height: "300px",
                            margin: "0px auto",
                          }}
                        >
                          <img
                            src={bookingClosed}
                            style={{
                              borderRadius: "10px",
                              width: "100%",
                              height: "100%",
                              margin: "0px auto",
                            }}
                          />
                        </div>
                        <div style={{width: '75%', margin: '20px auto 0'}}>
                          <button
                            disabled
                            className="disableBtn"
                            type="button"
                            style={{ width: "100%", cursor: 'not-allowed' }}
                          >
                            Booking Closed
                          </button>
                        </div>
                      </Grid>
                </>
              ) : (
                <>
                  {/*Event Cancled*/}
                  <Grid item md={5} xs={12} sm={12}>
                    <Grid md={10} sm={12}>
                      <div
                        style={{
                          borderRadius: "10px",
                          width: "90%",
                          height: "300px",
                          margin: "0px auto",
                        }}
                      >
                        <img
                          src={eventCancled}
                          style={{
                            borderRadius: "10px",
                            width: "100%",
                            height: "100%",
                            margin: "0px auto",
                          }}
                        />
                      </div>
                      <Box padding={{ xs: 2, sm: 3 }}>
                        <button
                          disabled
                          className="disableBtn"
                          type="button"
                          style={{ width: "100%" }}
                        >
                          Event Cancled
                        </button>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="responsive" style={{ margin: "70px auto" }}>
        {/* <Grid container spacing={1} style={{display: 'flex'}}> */}
        <div className="eventDetail">
          {/* <Slider {...settings} className=""> */}
          <div style={{ width: "100%" }}>
            {event_images.map((link, index) => {
              // Check media type based on the link (assuming link includes file extensions)
              const fileType = link.substring(link.lastIndexOf(".") + 1);
              const isImage = fileType.match(
                /(JPEG|JPG|PNG|jpeg|jpg|png|svg|webp|avi|avif|avchd|flv|f4v|swf|mpeg-2)/i
              );

              // Render image or video based on media type
              return (
                <div
                  key={index}
                  className="eventDetailImg"
                >
                  {isImage ? (
                    <img
                      style={{
                        // minHeight: "40%",
                        // maxHeight: "40%",
                        width: "100%",
                        height: "100%",
                      }}
                      // height: "568.188px"
                      src={link}
                      alt={`Image ${index}`}
                    />
                  ) : (
                    <video
                      style={{
                        minHeight: "40%",
                        maxHeight: "40%",
                        width: "100%",
                      }}
                      autoPlay
                      loop
                      muted
                      playsInline
                    >
                      <source src={link} type={`video/${fileType}`} />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              );
            })}
          </div>
          {/* </Slider> */}

          <div style={{ marginTop: "10px", width: "100%" }}>
            <h3 style={{ fontWeight: "bold" }}>Description</h3>
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "#d1d1d1",
                marginBottom: "10px",
              }}
            ></div>
            <div className="mb-7">
              <p
                className="mb-6"
                style={{
                  overflowWrap:
                    "break-word" /* Modern equivalent of word-wrap */,
                  wordBreak:
                    "break-word" /* Breaks words at arbitrary points if necessary */,
                  whiteSpace: "normal",
                }}
              >
                {DisplayHTMLString(event_description)}
              </p>
            </div>
          </div>
        </div>
        {/* </Grid> */}

        {end_time &&
        compareDates(
          new Date(end_time).toUTCString(),
          new Date().toUTCString()
        ) ? (
          <></>
        ) : (
          <div>
            {" "}
            {/*NOTES*/}
            <div className="mb-9">
              <h3 style={{ fontWeight: "bold" }}>Notes</h3>
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "#d1d1d1",
                  marginBottom: "10px",
                }}
              ></div>
              <div className="mb-7">
                <p className="mb-6">{DisplayHTMLString(note || "")}</p>
              </div>
            </div>
          </div>
        )}

        {end_time &&
        compareDates(
          new Date(end_time).toUTCString(),
          new Date().toUTCString()
        ) ? (
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              QR Code
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Stack direction="row" spacing={2}>
                <img
                  style={{ maxWidth: "400px", width: "100%" }}
                  src={qr_code}
                  alt=""
                />
              </Stack>
            </DialogContent>
          </BootstrapDialog>
        ) : (
          <></>
        )}
        <Login open={loginModalOpen} handleClose={handleModalClose} />
        <GuestDetailsModal
          guest={formData.guest}
          open={isGuestModalOpen}
          handleClose={handleGuestModalClose}
          guestDetails={guestDetails}
          setGuestDetails={setGuestDetails}
          checkoutButton={
            <PaymentGateway
              amount={isNaN(totalPrice) ? basePricePerPerson : totalPrice}
              afterPayment={handlePaymentSuccess}
              enablePayementProcess={handleClosePaymentProcess}
            >
              <Button variant="contained" type="button">
                <button type="button" className="btnAll">
                  Check Out
                </button>
              </Button>
            </PaymentGateway>
          }
        />

        <ComCard
          success={successModal?.success}
          open={successModal?.open}
          // success={true}
          // open={true}
          handleClose={handlePaymentModalClose}
        >
          <p>
            <b>Event Id: </b>
            {successModal?.data?.event_id}
          </p>
          <p>
            <b>Razorpay Payment Id: </b>
            {successModal?.data?.razorpay_payment_id}
          </p>
        </ComCard>
      </div>
    </>
  );
};

export default EventDetails;
