
const initialState = {
    loading: false,
    createLoading: false,
    deleteLoading: false,
    updateLoading: false,
    postalCodeData: null,
    blogData: [],
}

function BlogReducer(state = initialState, action) {
    switch (action.type) {
        case "LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "CREATE_BLOG_LOADING":
            return {
                ...state,
                createLoading: action.payload
            }
        case "DELETE_BLOG_LOADING":
            return {
                ...state,
                deleteLoading: action.payload
            }
        case "UPDATE_BLOG_LOADING":
            return {
                ...state,
                updateLoading: action.payload
            }
        case "DELETE_BLOG_SUCCESS":
            return {
                ...state,
            }
        case "BLOG_SUCCESS":
            return {
                ...state,
                blogData: action.payload,
            }
        case "UPDATE_BLOG_SUCCESS":
            return {
                ...state,
            }
        case "CREATE_BLOG_SUCCESS":
            return {
                ...state,
            }
        default:
            return state;
    }
}

export default BlogReducer;