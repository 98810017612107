import React from "react";
import Faqs from "../../components/Faqs/Faqs";
import { Link } from "react-router-dom";

const FaqPage = () => {
  return (
    <>
      <div id="site-wrapper" className="site-wrapper page-faqs py-3">
          <div class="responsive" style={{ textAlign: 'center'}}>
            <div class="h-100">
              <h1
                style={{fontSize:'40px', fontWeight:'bold'}}
              >
                Frequently Asked Questions
              </h1>
              <ul
                class="breadcrumb justify-content-center fadeInUp animated"
                data-animate="fadeInUp"
              >
                <li class="breadcrumb-item" >
                  <Link style={{color: '#252525', fontSize:'20px', letterSpacing: '0.5px', textDecoration:'none'}} to="/">Home </Link>
                </li>
                <li class="breadcrumb-item" style={{color: '#252525', fontSize:'20px', letterSpacing: '0.5px'}}>
                  <span>FAQs</span>
                </li>
              </ul>
            </div>
          </div>

        {/* <div id="wrapper-content" className="wrapper-content py-11"> */}
          <div className="responsive" style={{margin: '15px auto'}}>
            {/* <div id="section-accordion" className="faqs mb-11">
              <div className="container"> */}
                <div className="col-md-12" style={{width:'90%', margin: '0 auto'}}>
                  <Faqs />
                </div>
              {/* </div>
            </div> */}

            {/*Join Us*/}
            <div class="text-center pb-6">
              <div class="mb-6">
                <h4 class="mb-1 mt-8">
                  Join Us Today and Get More Profit From Your Business
                </h4>
                <p class="mb-0 font-size-md">
                  The easiest way to get more interest in your place
                </p>
              </div>
              <Link
                to="/be-partner"
                class="font-weight-bold px-5 py-2 btnAll"
                style={{fontSize: '21px', textDecoration: 'none'}}
              >
                Get Start Now!
              </Link>
            </div>
          </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default FaqPage;
