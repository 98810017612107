import React, { useState } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import Pricing from "../pricing/Pricing";
import BeaMemberPricing from "./BeaMemberPricing";

const CommonStyles = {
  "& .MuiOutlinedInput-root:hover": {
    "& > fieldset": {
      borderColor: "black",
    },
  },
  "&.Mui-focused .MuiOutlinedInput-root": {
    borderColor: "#4f46e5!important",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#4f46e5!important",
    },
    "& fieldset": {
      borderColor: "black!important",
    },
    color: "black!important",
    mb: 1,
  },
  mt: 1,
  borderRadius: "12px",
  "& .MuiOutlinedInput-root.Mui-error": {
    "& > fieldset": {
      borderColor: "#d32f2f!important",
    },
  },
};

const Step4 = ({ handleBack, handleNext, activeStep, value }) => {
  return (
    <div>
      <Box component={Paper} padding={5}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Typography
              textAlign="center"
              fontWeight={900}
              fontSize={28}
              marginBottom={2}
              color="black"
            >
              Payment
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <BeaMemberPricing value={value} handleNext={handleNext}/>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "center", mt: 1 }}
          >
            <Grid item>
              <Button
                sx={{ margin: 1 }}
                type="button"
                variant="contained"
                // disabled={activeStep === 0}
                onClick={handleBack}
              >
                Back
              </Button>
            </Grid>
          </Grid>

          {/* </form> */}

        </Grid>
      </Box>
    </div >
  );
};

export default Step4;
