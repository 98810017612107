import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import Input from "../../common/Input";
import { useFormik } from "formik";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import * as Yup from "yup";
import SelectBox from "../../common/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { GetAllCountry } from "../../redux/action/CountryAction";
import {
  GetAllStates,
  cleareAllStates,
} from "../../redux/action/RoleandPermissionAction";
import { GetAllCities, cleareAllCities } from "../../redux/action/CityAction";
import {
  GetAllPostalCodes,
  clearePostalCodes,
} from "../../redux/action/PostalCodeAction";
import { GetAllChapter, getChapterAll } from "../../redux/action/ChapterAction";
import { CreateEvent, UpdateEvent } from "../../redux/action/EventAction";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { PlayArrow } from "@mui/icons-material";
import moment from "moment";
import { snackBar } from "../../redux/common";

const editorConfiguration = {};

const eventOptions = [
  { label: "GGC", value: "GGC" },
  { label: "Chapter", value: "Chapter" },
];

function MyCustomPlugin(editor) {
  editor.model.document.on('change:data', () => {
    const MAX_CHAR_COUNT = 2000;
    const data = editor.getData();
    const charCount = data.replace(/<[^>]*>/g, '').length;

    if (charCount > MAX_CHAR_COUNT) {
      // Trim data to MAX_CHAR_COUNT
      const trimmedData = data.substring(0, MAX_CHAR_COUNT);
      editor.setData(trimmedData);

      // Optionally, show an alert or message
      alert('Character limit reached!');
    }
  });
}

const CreateEventForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [imageAllowMsg, setImageAllowMsg] = useState();

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImageError(false);
    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "image/avif",
      "image/avchd",
      "image/flv",
      "image/f4v",
      "image/swf",
      "mp4",
      "webm",
      "avi",
      "mov",
      "mkv",
      "wmv",
      "video/mp4", // MIME type for MP4 videos
      "video/webm", // MIME type for WebM videos
      "video/avi", // MIME type for AVI videos
      "video/quicktime", // MIME type for MOV videos
      "video/x-matroska", // MIME type for MKV videos
      // Add more video types as needed
    ];

    const invalidFiles = files.filter((file) => {
      const fileType = file.type.split("/")[1];
      return !allowedTypes.includes(fileType);
    });

    if (invalidFiles.length > 0) {
      setImageAllowMsg(
        `Selected ${invalidFiles.length === 1
          ? `${invalidFiles.length} file is`
          : `${invalidFiles.length} files are`
        } not image type, Please selete only image type file`
      );
      return;
    }

    if (selectedImage && selectedImage.length) {
      const totalImages = selectedImage.length + files.length;
      if (totalImages > 10) {
        const remainingSlots = 10 - selectedImage.length;
        const filesToAdd = files.slice(0, remainingSlots);
        setSelectedImage([...selectedImage, ...filesToAdd]);
        setImageAllowMsg(
          `You can only upload a maximum of 10 images. ${files.length - remainingSlots
          } image(s) were not added.`
        );
      } else {
        setSelectedImage([...selectedImage, ...files]);
        setImageAllowMsg("");
      }
    } else {
      if (files.length > 10) {
        setSelectedImage(files.slice(0, 10));
        setImageAllowMsg(
          `You can only upload a maximum of 10 images. ${files.length - 10
          } image(s) were not added.`
        );
      } else {
        setSelectedImage(files);
        setImageAllowMsg("");
      }
    }
    e.target.value = "";
  };

  const { allStateData } = useSelector((state) => state.StateReducer);
  const { allCountryData } = useSelector((state) => state.CountryReducer);
  const { allCitiesData } = useSelector((state) => state.CityReducer);
  const { allPostalData } = useSelector((state) => state.PostalCodeReducer);
  const { getAllChapter, chapter } = useSelector((state) => state.ChapterReducer);
  const { createLoading, updateLoading } = useSelector(
    (state) => state.EventReducer
  );

  const allChapterData =
  chapter &&
  chapter?.chapterData?.map((el) => {
      return { value: el._id, label: el.chapter_name };
    });

  const isActiveOptions = [
    { value: "true", label: "ACTIVE" },
    { value: "false", label: "INACTIVE" },
  ];
  const isScheduledOptions = [
    { value: "scheduled", label: "Scheduled" },
    { value: "rescheduled", label: "Rescheduled" },
    { value: "completed", label: "Completed" },
    { value: "cancelled", label: "Cancelled" },
  ];
  const isApprovalOptions = [
    { value: "pending", label: "PENDING" },
    { value: "approved", label: "APPROVED" },
    { value: "rejected", label: "REJECTED" },
  ];

  useEffect(() => {
    dispatch(GetAllCountry(true));
    // dispatch(getChapterAll());
    dispatch(GetAllChapter("", "", "", "", "",true));
  }, []);

  const {
    chapter_id,
    _id,
    state_name,
    city_name,
    country_name,
    postalcode_id,
    postalcode,
    city_id,
    state_id,
    country_id,
    event_images,
    event_name,
    event_type,
    price,
    start_time,
    end_time,
    venue,
    venue_link,
    event_description,
    is_active,
    schedule_status,
    approval_status,
  } = location?.state?.eventData;

  const [selectEvent_type, setSelectEvent_type] = useState(event_type);

  const [selectedImage, setSelectedImage] = useState(event_images);
  const [selectedImageError, setSelectedImageError] = useState(false);

  const removeSelectedImage = (indexToRemove) => {
    let imagesArray = [];
    if (selectedImage instanceof FileList) {
      imagesArray = Array.from(selectedImage);
    } else {
      imagesArray = Array.from(selectedImage);
    }

    const remainingImg = imagesArray.filter((item, i) => {
      return i !== indexToRemove;
    });
    setSelectedImage(remainingImg);
    setImageAllowMsg("");
  };

  const changeSelectedImage = (e, changeIndex) => {
    const files = e.target.files;
    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "avif",
      "avchd",
      "flv",
      "f4v",
      "swf",
      "mp4",
      "webm",
      "avi",
      "mov",
      "mkv",
      "wmv",
      "video/mp4", // MIME type for MP4 videos
      "video/webm", // MIME type for WebM videos
      "video/avi", // MIME type for AVI videos
      "video/quicktime", // MIME type for MOV videos
      "video/x-matroska", // MIME type for MKV videos
      // Add more video types as needed
    ];

    const file = files[0];
    const fileType = file.type.split("/").pop().toLowerCase();

    if (allowedTypes.includes(fileType)) {
      const updatedImages = [...selectedImage];
      updatedImages[changeIndex] = file;
      setSelectedImage(updatedImages);
    } else {
      snackBar("File type not allowed. Please select a valid image file.", false)
    }
  };

  const showImages = (selectedImage) => {
    let imagesArray = [];

    if (selectedImage instanceof FileList) {
      imagesArray = Array.from(selectedImage);
    } else {
      imagesArray = Array.from(selectedImage);
    }

    return (
      <>
        {imagesArray.map((item, i) => {
          let isImage = true;
          let fileType = "";

          const item_url = item instanceof File ? item?.name : item;
          if (typeof item_url === "string") {
            fileType = item_url.substring(item_url.lastIndexOf(".") + 1);
            isImage = fileType.match(
              /(JPEG|JPG|PNG|jpeg|jpg|png|svg|webp|avi|avif|avchd|flv|f4v|swf|mpeg-2)/i
            );
          }
          return (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid lightgray",
                  padding: "5px",
                }}
              >
                <div
                  style={{ display: "flex", flex: "1", alignItems: "center" }}
                >
                  {isImage ? (
                    <>
                      <a
                        target="_blank"
                        href={
                          typeof item === "string"
                            ? item
                            : URL.createObjectURL(item)
                        }
                      >
                        <ImageIcon sx={{ marginRight: "0.5rem" }} />
                        <img
                          key={i}
                          style={{ width: 80 }}
                          src={
                            typeof item === "string"
                              ? item
                              : URL.createObjectURL(item)
                          }
                          alt={`Selected Image ${i + 1}`}
                          loading="lazy"
                          onError={(e) => {
                            console.error(`Error loading image ${i + 1}:`, e);
                          }}
                        />
                      </a>
                    </>
                  ) : (
                    <a
                      target="_blank"
                      href={
                        typeof item === "string"
                          ? item
                          : URL.createObjectURL(item)
                      }
                    >
                      <PlayArrow sx={{ marginRight: "0.5rem" }} />
                      <video style={{ width: 80 }} loop muted playsInline>
                        <source
                          src={
                            typeof item === "string"
                              ? item
                              : URL.createObjectURL(item)
                          }
                          type={`video/${fileType}`}
                        />
                        Your browser does not support the video tag.
                      </video>
                    </a>
                  )}
                </div>
                <div style={{ marginLeft: "auto", marginTop: "-0.5em" }}>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => removeSelectedImage(i)}
                  >
                    X
                  </Button>
                  <br />
                </div>
                {location.state.formName === "Update Event" ? (
                  <div style={{ marginLeft: "auto", paddingLeft: "5px" }}>
                    <input
                      type="file"
                      accept="image/*, video/*"
                      id={`change_images_${i}`}
                      name={`change_images_${i}`}
                      style={{ display: "none" }}
                      onChange={(e) => changeSelectedImage(e, i)}
                    />
                    <label htmlFor={`change_images_${i}`}>
                      <Button
                        component="span"
                        color="success"
                        variant="outlined"
                      >
                        Change
                      </Button>
                    </label>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          );
        })}
      </>
    );
  };
  const dateRegExp = /^\d{2}\/\d{2}\/\d{4}$/;
  const urlRegex = /^(https?|ftp|www):\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+(?:[a-zA-Z]|[0-9]|[-._~:/?#[\]@!$&'()*+,;=])*(?:[a-zA-Z]|[0-9]|[-._~:/?#[\]@!$&'()*+,;=])$/;

  const UserSchema = Yup.object().shape({
    event_name: Yup.string()
      .required("Please enter event name")
      .matches(/^[^\d]+$/, "Event name cannot contain numbers")
      .matches(
        /^[a-zA-Z]+([a-zA-Z\s]*[a-zA-Z]+)?$/,
        "Event name can only contain letters and spaces"
      )
      .matches(/^\s*\S.*/, "Please enter event name"),
    event_type: Yup.string().required("Please select event type"),
    chapter:
      (selectEvent_type === "Chapter" || event_type === "Chapter") &&
      Yup.string().required("Please select chapter"),
    start_time:
      location.state.formName === "Create Event"
        ? Yup.date()
          .typeError("Please select start time")
          .required("Please select start time")
          .min(new Date(), "Start time cannot be in the past")
        : Yup.date()
          .typeError("Please select start time")
          .required("Please select start time")
          .min(new Date(), "Start time cannot be in the past"),
    end_time: Yup.date()
      .typeError("Please select end time")
      .required("Please select end time")
      .min(Yup.ref("start_time"), "End time should be greater than start time")
      .min(new Date(), "End time cannot be in the past"),
    country: Yup.object().required("Please select country"),
    state: Yup.object().required("Please select state"),
    city: Yup.object().required("Please select city"),
    pincode: Yup.object().required("Please select pincode"),
    price: Yup.string().trim().required("Please enter price"),
    venue: Yup.string().trim().required("Please enter venue"),
    venue_link: Yup.string().trim().required("Please enter venue link").matches(urlRegex, "Please enter a valid URL (ex.: http://www.url.com)"),
    event_description: Yup.string()
      .trim()
      .max(2000, "Description must be at most 2000 characters"),
  });

  const formik = useFormik({
    initialValues: {
      event_name: event_name || "",
      event_type: event_type || "",
      chapter: chapter_id || "",
      start_time: start_time || "",
      end_time: end_time || "",
      country:
        country_id && country_name
          ? { _id: country_id || "", country_name: country_name || "" }
          : null,
      state:
        state_id && state_name
          ? { _id: state_id || "", state_name: state_name || "" }
          : null,
      city:
        city_id && city_name
          ? { _id: city_id || "", city_name: city_name || "" }
          : null,
      pincode:
        postalcode_id && postalcode
          ? { _id: postalcode_id || "", postal_code: postalcode || "" }
          : null,
      price: price || "",
      venue: venue || "",
      venue_link: venue_link || "",
      event_description: event_description || "",
      approval_status: approval_status || "",
      schedule_status: schedule_status || "",
      is_active: is_active ? "true" : "false" || "",
    },
    validationSchema: UserSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!selectedImage?.length) {
        setSelectedImageError(true);
        return;
      }

      const body = {
        event_name: values.event_name,
        event_type: values.event_type,
        chapter_id: values.chapter,
        start_time: values.start_time,
        end_time: values.end_time,
        price: values.price,
        venue: values.venue,
        venue_link: values.venue_link,
        city_id: values?.city?._id,
        state_id: values?.state?._id,
        postalcode_id: values?.pincode?._id,
        country_id: values?.country?._id,
        event_description: values.event_description,
        event_images: selectedImage,
        schedule_status: values.schedule_status,
        approval_status: values.approval_status,
        is_active: values.is_active,
      };
      const formData = new FormData();

      if (location.state.formName === "Create Event") {
        formData.append("event_name", body.event_name);
        formData.append("event_type", body.event_type);
        if (selectEvent_type === "Chapter")
          formData.append("chapter_id", body.chapter_id);
        formData.append("start_time", new Date(body.start_time));
        formData.append("end_time", new Date(body.end_time));
        formData.append("price", body.price);
        formData.append("venue", body.venue);
        formData.append("venue_link", body.venue_link);
        formData.append("city_id", body.city_id);
        formData.append("state_id", body.state_id);
        formData.append("postalcode_id", body.postalcode_id);
        formData.append("country_id", body.country_id);
        if (body.event_description)
          formData.append("event_description", body.event_description);
        let imagesArray = [];
        imagesArray = Array.from(selectedImage);
        imagesArray.forEach((image, index) => {
          formData.append("event_images", image);
        });
      } else {
        formData.append("event_name", body.event_name);
        formData.append("event_type", body.event_type);
        if (selectEvent_type === "Chapter")
          formData.append("chapter_id", body.chapter_id);
        formData.append("start_time", body.start_time);
        formData.append("end_time", body.end_time);
        formData.append("price", body.price);
        formData.append("venue", body.venue);
        formData.append("venue_link", body.venue_link);
        formData.append("city_id", body.city_id);
        formData.append("state_id", body.state_id);
        formData.append("postalcode_id", body.postalcode_id);
        formData.append("country_id", body.country_id);
        formData.append("event_description", body.event_description);
        let imagesArray = [];
        if (selectedImage instanceof FileList) {
          imagesArray = Array.from(selectedImage);
        } else {
          selectedImage.forEach(function (element) {
            if (typeof element == "object") {
              imagesArray.push(element);
            }
          });
        }
        imagesArray.forEach((image, index) => {
          formData.append(`event_images`, image);
        });
        let existImage = [];
        for (let i = 0; i < selectedImage.length; i++) {
          if (typeof selectedImage[i] == "string") {
            existImage.push(selectedImage[i]);
          }
        }
        if (existImage.length > 0) {
          formData.append(`event_images`, JSON.stringify(existImage));
        }
        formData.append("approval_status", body.approval_status);
        formData.append("schedule_status", body.schedule_status);
        formData.append("is_active", body.is_active);
      }

      const resetInitForm = () => {
        resetForm();
        setSelectedImage(null);
      };

      if (location.state.formName === "Create Event") {
        await dispatch(CreateEvent(formData)).then((res) => {
          if (res && res.data.status === true) {
            navigate("/admin-events");
            resetInitForm();
          }
        });
      } else {
        await dispatch(UpdateEvent(_id, formData)).then((res) => {
          if (res && res.data.status === true) {
            navigate("/admin-events");
          }
        });
      }
    },
  });

  ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "blockQuote",
        "|",
        "bulletedList",
        "numberedList",
        "outdent",
        "indent",
        "|",
        "insertTable",
        "|",
        "undo",
        "redo",
      ],
    },
    image: {
      toolbar: [
        "imageStyle:full",
        "imageStyle:side",
        "|",
        "imageTextAlternative",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "en",
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem",
        }}
      >
        <ArrowCircleLeftOutlinedIcon
          fontSize="large"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/admin-events")}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item md={8} xs={12} sm={12}>
            <Grid item md={12}>
              <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                <form onSubmit={formik.handleSubmit}>
                  <Typography
                    textAlign="center"
                    fontWeight={900}
                    fontSize={28}
                    marginBottom={2}
                    color="black"
                  >
                    {location.state.formName}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Input
                        id="event_name"
                        name="event_name"
                        type="text"
                        maxLength={50}
                        label="Event Name"
                        onChange={formik.handleChange}
                        value={formik.values.event_name}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.event_name &&
                          Boolean(formik.errors.event_name)
                        }
                        helperText={
                          formik.touched.event_name &&
                            formik.errors.event_name ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.event_name}
                            </div>
                          ) : null
                        }
                        onKeyDown={(event) => {
                          if (
                            !/[a-z]/i.test(event.key) &&
                            event.key !== "Backspace" &&
                            event.key !== "Tab" &&
                            event.key !== " "
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={formik.values.event_type === "Chapter" ? 6 : 12}
                      xs={12}
                      sm={12}
                    >
                      <SelectBox
                        // mt={1}
                        id="event_type"
                        name="event_type"
                        label="Event Type"
                        ClassName="range-select-filter"
                        value={formik.values.event_type}
                        onChange={(e) => {
                          formik.setFieldValue("event_type", e.target.value);
                          setSelectEvent_type(e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                        options={eventOptions}
                        error={
                          formik.touched.event_type &&
                          Boolean(formik.errors.event_type)
                        }
                        touched={formik.touched.event_type}
                      />
                      {formik.touched.event_type &&
                        formik.errors.event_type && (
                          <div className="err-msg-font">
                            {formik.errors.event_type}
                          </div>
                        )}
                    </Grid>
                    {formik.values.event_type === "Chapter" && (
                      <Grid item md={6} xs={12} sm={12}>
                        <SelectBox
                          id="chapter"
                          name="chapter"
                          label="Chapter"
                          ClassName="range-select-filter"
                          value={formik.values.chapter}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          options={allChapterData || []}
                          error={
                            formik.touched.chapter &&
                            Boolean(formik.errors.chapter)
                          }
                          touched={formik.touched.chapter}
                        />
                        {formik.touched.chapter && formik.errors.chapter && (
                          <div className="err-msg-font">
                            {formik.errors.chapter}
                          </div>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DateTimePicker"]}>
                            <DateTimePicker
                              disablePast
                              // disabled={
                              //   // location.state.formName === "Update Event" &&
                              //   (formik.values.start_time
                              //     ? dayjs(formik.values.start_time).isBefore(dayjs(), "day")
                              //     : false)
                              // }
                              fullWidth
                              id="start_time"
                              label="Start Time"
                              // views={["year","month","date"]}
                              format="DD/MM/YYYY hh:mm A"
                              style={{ border: "1px solid black !important" }}
                              sx={{ width: "100%", border: "none !important" }}
                              name="start_time"
                              // value={formik.values.start_time}
                              value={
                                formik.values.start_time
                                  ? dayjs(formik.values.start_time)
                                  : null
                              }
                              // format="DD/MM/YYYY"
                              onChange={(newValue) => {
                                if (
                                  new Date(newValue) >=
                                  new Date(formik.values.end_time)
                                ) {
                                  formik.setFieldValue("end_time", newValue);
                                }
                                formik.setFieldValue("start_time", newValue);
                              }}
                              slotProps={{
                                textField: {
                                  variant: "outlined",
                                  error: Boolean(
                                    formik.touched.start_time &&
                                    formik.errors.start_time
                                  ), //error handling
                                  helperText: formik.touched.start_time &&
                                    formik.errors.start_time && (
                                      <div
                                        style={{ marginLeft: "-12px" }}
                                        className="err-msg-font"
                                      >
                                        {formik.errors.start_time}
                                      </div>
                                    ),
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12} sm={12}>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DateTimePicker"]}>
                            <DateTimePicker
                              disablePast
                              fullWidth
                              id="end_time"
                              label="End Time"
                              style={{ border: "1px solid black !important" }}
                              sx={{ width: "100%", border: "none !important" }}
                              name="end_time"
                              format="DD/MM/YYYY hh:mm A"
                              // value={formik.values.birth_date}}
                              // value={"12-12-1987"}
                              value={
                                formik.values.end_time
                                  ? dayjs(formik.values.end_time)
                                  : null
                              }
                              // format="DD/MM/YYYY"
                              onChange={(newValue) => {
                                formik.setFieldValue("end_time", newValue);
                              }}
                              minDateTime={dayjs(formik.values.start_time)}
                              slotProps={{
                                textField: {
                                  variant: "outlined",
                                  error: Boolean(
                                    formik.touched.end_time &&
                                    formik.errors.end_time
                                  ), //error handling
                                  helperText: formik.touched.end_time &&
                                    formik.errors.end_time && (
                                      <div
                                        style={{ marginLeft: "-12px" }}
                                        className="err-msg-font"
                                      >
                                        {formik.errors.end_time}
                                      </div>
                                    ),
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <div style={{ marginTop: "3px" }}>
                        <Input
                          id="price"
                          name="price"
                          type="number"
                          label="Price"
                          onChange={formik.handleChange}
                          maxLength={10}
                          value={formik.values.price}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.price && Boolean(formik.errors.price)
                          }
                          helperText={
                            formik.touched.price && formik.errors.price ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.price}
                              </div>
                            ) : null
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <div style={{ marginTop: "3px" }}>
                        <Input
                          id="venue"
                          name="venue"
                          type="text"
                          label="Venue"
                          onChange={formik.handleChange}
                          value={formik.values.venue}
                          onBlur={formik.handleBlur}
                          maxLength={300}
                          error={
                            formik.touched.venue && Boolean(formik.errors.venue)
                          }
                          helperText={
                            formik.touched.venue && formik.errors.venue ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.venue}
                              </div>
                            ) : null
                          }
                          maxRows={2}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <div style={{ marginTop: "3px" }}>
                        <Input
                          id="venue_link"
                          name="venue_link"
                          type="venue_link"
                          label="Venue Link"
                          onChange={formik.handleChange}
                          value={formik.values.venue_link}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.venue_link &&
                            Boolean(formik.errors.venue_link)
                          }
                          maxLength={100}
                          helperText={
                            formik.touched.venue_link &&
                              formik.errors.venue_link ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.venue_link}
                              </div>
                            ) : null
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "1rem" }}>
                        <Autocomplete
                          id="country"
                          name="country"
                          value={formik.values.country}
                          onChange={(e, value) => {
                            formik.setFieldValue("country", value);
                            dispatch(GetAllStates(value?._id, true));
                            dispatch(cleareAllStates());
                            formik.setFieldValue("state", "");
                            formik.setFieldValue("city", "");
                            formik.setFieldValue("pincode", "");
                          }}
                          options={allCountryData?.countryData || []}
                          autoHighlight
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.country &&
                            Boolean(formik.errors.country)
                          }
                          helperText={
                            formik.touched.country && formik.errors.country ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.country}
                              </div>
                            ) : null
                          }
                          getOptionLabel={(option) => option.country_name}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="Country"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                            />
                          )}
                        />
                        {formik.touched.country && formik.errors.country && (
                          <div className="err-msg-font">
                            {formik.errors.country}
                          </div>
                        )}
                      </div>
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "1rem" }}>
                        <Autocomplete
                          id="state"
                          name="state"
                          value={formik.values.state || null}
                          onChange={(e, value) => {
                            formik.setFieldValue("state", value);
                            dispatch(GetAllCities(value?._id, true));
                            dispatch(cleareAllCities());
                            formik.setFieldValue("city", "");
                            formik.setFieldValue("pincode", "");
                          }}
                          options={allStateData || []}
                          autoHighlight
                          getOptionLabel={(option) => option.state_name}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="State"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                            />
                          )}
                        />
                        {formik.touched.state && formik.errors.state && (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ fontSize: "12px" }}
                          >
                            {formik.errors.state}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "1rem" }}>
                        <Autocomplete
                          id="city"
                          name="city"
                          value={formik.values.city || null}
                          onChange={(e, value) => {
                            formik.setFieldValue("city", value);
                            dispatch(GetAllPostalCodes(value?._id, true));
                            dispatch(clearePostalCodes());
                            formik.setFieldValue("pincode", "");
                          }}
                          options={allCitiesData || []}
                          autoHighlight
                          getOptionLabel={(option) => option.city_name}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="City"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                            />
                          )}
                        />
                        {formik.touched.city && formik.errors.city && (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ fontSize: "12px" }}
                          >
                            {formik.errors.city}
                          </Typography>
                        )}
                      </div>
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "1rem" }}>
                        <Autocomplete
                          id="pincode"
                          name="Postal code"
                          value={formik.values.pincode || null}
                          onChange={(e, value) =>
                            formik.setFieldValue("pincode", value)
                          }
                          options={allPostalData || []}
                          autoHighlight
                          getOptionLabel={(option) =>
                            option.postal_code?.toString()
                          }
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="Postal code"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                            />
                          )}
                        />
                        {formik.touched.pincode && formik.errors.pincode && (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ fontSize: "12px" }}
                          >
                            {formik.errors.pincode}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <div style={{
                        marginTop: "25px",
                        overflowWrap: "break-word", /* Modern equivalent of word-wrap */
                        wordBreak: "break-word", /* Breaks words at arbitrary points if necessary */
                        whiteSpace: "normal"
                      }}>
                        <CKEditor
                          editor={ClassicEditor}
                          // config={{
                          //   editorConfiguration,
                          //   placeholder: "Add Description",
                          // }}
                          data={
                            formik.values.event_description
                              ? formik.values.event_description.substring(
                                0,
                                2000
                              )
                              : ""
                          }
                          config={{
                            // editorConfiguration,
                            placeholder: "Add Description",
                            extraPlugins: [MyCustomPlugin],
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            formik.setFieldValue("event_description", data);
                          }}
                        />
                      </div>
                      {formik.errors.event_description ? (
                        <Box ml={0} mt={1} className="err-msg err-msg-font">
                          {formik.errors.event_description}
                        </Box>
                      ) : null}
                    </Grid>
                  </Grid>

                  {location.state.formName !== "Create Event" && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12} sm={12}>
                          <SelectBox
                            id="is_active"
                            name="is_active"
                            label="Is Active"
                            ClassName="range-select-filter"
                            value={formik.values.is_active}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            options={isActiveOptions || []}
                            error={
                              formik.touched.is_active &&
                              Boolean(formik.errors.is_active)
                            }
                            touched={formik.touched.is_active}
                          />
                          {formik.touched.is_active &&
                            formik.errors.is_active && (
                              <div className="err-msg-font">
                                {formik.errors.is_active}
                              </div>
                            )}
                        </Grid>
                        <Grid item md={6} xs={12} sm={12}>
                        <SelectBox
                            id="approval_status"
                            name="approval_status"
                            label="Status"
                            ClassName="range-select-filter"
                            value={formik.values.approval_status}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            options={isApprovalOptions || []}
                            error={
                              formik.touched.approval_status &&
                              Boolean(formik.errors.approval_status)
                            }
                            touched={formik.touched.approval_status}
                          />
                          {formik.touched.approval_status &&
                            formik.errors.approval_status && (
                              <div className="err-msg-font">
                                {formik.errors.approval_status}
                              </div>
                            )}
                          {/* <SelectBox
                            id="schedule_status"
                            name="schedule_status"
                            label="Schedule Status"
                            ClassName="range-select-filter"
                            value={formik.values.schedule_status}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            options={isScheduledOptions || []}
                            error={
                              formik.touched.schedule_status &&
                              Boolean(formik.errors.schedule_status)
                            }
                            touched={formik.touched.schedule_status}
                          />
                          {formik.touched.schedule_status &&
                            formik.errors.schedule_status && (
                              <div className="err-msg-font">
                                {formik.errors.schedule_status}
                              </div>
                            )} */}
                        </Grid>
                      </Grid>
                      {/* <Grid container spacing={2}>
                        <Grid item md={12} xs={12} sm={12}>
                          <SelectBox
                            id="approval_status"
                            name="approval_status"
                            label="Status"
                            ClassName="range-select-filter"
                            value={formik.values.approval_status}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            options={isApprovalOptions || []}
                            error={
                              formik.touched.approval_status &&
                              Boolean(formik.errors.approval_status)
                            }
                            touched={formik.touched.approval_status}
                          />
                          {formik.touched.approval_status &&
                            formik.errors.approval_status && (
                              <div className="err-msg-font">
                                {formik.errors.approval_status}
                              </div>
                            )}
                        </Grid>
                      </Grid> */}
                    </>
                  )}

                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sm={12}
                      sx={{
                        width: "100%",
                        maxHeight: "200px",
                        overflowX: "scroll",
                        scrollBehavior: "smooth",
                        marginTop: "25px",
                      }}
                    >
                      {selectedImage && selectedImage.length > 0
                        ? showImages(selectedImage)
                        : ""}
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                      <p
                        style={{
                          marginBottom: "-1em",
                          marginTop: "-1em",
                          color: "#d32f2f",
                        }}
                      >
                        {imageAllowMsg}
                      </p>
                    </Grid>
                    <Grid item md={4} xs={12} sm={12}>
                      <input
                        type="file"
                        multiple
                        accept="image/*, video/*"
                        id="service_images"
                        name="service_images"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                      <label htmlFor="service_images">
                        <Button
                          component="span"
                          variant="contained"
                          color="inherit"
                          sx={{ marginTop: "1rem" }}
                        >
                          <ImageIcon sx={{ marginRight: "0.5rem" }} />
                          Upload Media
                        </Button>
                      </label>

                      {selectedImageError ? (
                        <div className="err-msg-font">
                          Please upload media files.
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  {(
                    location.state.formName === "Create Event"
                      ? createLoading
                      : updateLoading
                  ) ? (
                    <Button sx={{ marginTop: 4 }} variant="contained">
                      <CircularProgress
                        style={{ width: "25px", height: "25px", color: "#fff" }}
                      />
                    </Button>
                  ) : (
                    <Button
                      sx={{ marginTop: 4 }}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  )}
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateEventForm;
