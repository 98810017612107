import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Pagination, Typography } from "@mui/material";

import { Deals } from "../Deals/Deals";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loader/Loader";
import NotDataFound from "../../pages/notFound/NotDataFound";
import { getAllService } from "../../../redux/action/ServiceAction";
import ServiceCard from "../../pages/service/ServiceCard";
import { makeStyles } from "@material-ui/core";
import Slider from "react-slick";
import Login from "../../pages/auth/Login";

import arrow from '../../GGC_LendingPage/svg/arrowLeft.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles((theme) => ({
  pagination: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
      padding: 0,
      margin: 0,
    },
    "& .MuiPaginationItem-root": {
      margin: "0 2px",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#153e80",
      border: "none",
      "&:hover": {
        color: "#153e80",
      },
    },
    "& .MuiPaginationItem-page:hover": {
      color: "#153e80",
    },
  },
}));

const cardPerPage = 4;

const ButtonContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "1rem", // Smaller margin top for mobile view
});

const FindChapterBtn = styled(Link)(({ theme }) => ({
  backgroundColor: "#F07579",
  color: "#fff",
  borderRadius: "5px",
  textDecoration: "none",
  transition: "background-color 0.3s ease",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
  "&:hover": {
    backgroundColor: "#e7484d !important",
    color: "white !important",
  },
  fontSize: "80%", // Smaller font size for mobile view
  [theme.breakpoints.up("md")]: {
    fontSize: "90%", // Adjusted font size for larger screens
  },
}));

const StyledContainer = styled("div")({
  background: "linear-gradient(45deg, #1F4182, #223366)", // Gradient background
  width: "100%", // Full width
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: "20px 0px",
});

const BePartnerPageNavigation = ({ text, link, buttonTitle }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { service, loading, ...rest } = useSelector(
    (state) => state?.ServiceReducer
  );
  const [error, seterror] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const currentPage = 1;
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    dispatch(getAllService(currentPage, "", cardPerPage));
  }, [currentPage]);

  const handleButtonClick = () => {
    navigate("/service");
  };
  const handleModalOpen = () => {
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    setLoginModalOpen(false);
  };

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <NotDataFound />;
  }

  // const handleCurrentPageChange = (_, val) => {
  //   setCurrentPage(val);
  // };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ],
    afterChange: (index) => {
      setCurrentIndex(index);
    },
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <div style={{position: 'relative'}}>
      <div
        className="responsive"
        style={{ marginTop: "100px", marginBottom: "110px" }}
      >
        <Deals title={text} handleBtn={handleButtonClick} />
        <div style={{ marginTop: "15px", width: "100%" }}>
          <Slider ref={sliderRef} {...settings} style={{paddingBottom: '35px'}}>
            {service &&
              service?.serviceData?.map((item) => {
                return (
                  <ServiceCard handleModalOpen={handleModalOpen} item={item} />
                );
              })}
          </Slider>
        </div>

        {/* <div className="services" style={{ marginTop: "20px" }}>
        {service &&
          service?.serviceData?.map((item) => {
            return (
              <ServiceCard handleModalOpen={handleModalOpen} item={item} />
            );
          })}
      </div> */}
        <Login open={loginModalOpen} handleClose={handleModalClose} />
      </div>

      {/*Arrows*/}
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          border: "1px solid #d1d1d1",
          cursor: "pointer",
          top: "50%",
          padding: "10px",
        }}
            className="arwRight"
      >
        <img
          src={arrow}
          style={{ width: "80%", height: "80%" }}
          onClick={goToNextSlide}
        />
      </div>

      <div
        style={{
          backgroundColor: "white",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          border: "1px solid #d1d1d1",
          cursor: "pointer",
          top: "50%",
          padding: "10px",
        }}
            className="arwLeft"
      >
        <img
          src={arrow}
          style={{ width: "80%", height: "80%", rotate: "180deg" }}
          onClick={goToPrevSlide}
        />
      </div>
    </div>
  );
};

export default BePartnerPageNavigation;
