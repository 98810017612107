import {
  Box,
  CardMedia,
  Grid,
  Typography,
  useTheme,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useForm } from "../../common/useForms";
import Input from "../../common/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonCom from "../../common/Button";
import Logo from "../../assets/images/logo-home.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "../../assets/icons/Icons";
import Checkbox from "../../common/CheckBox";
import { loginUser } from "../../redux/action/AuthAction";
import { useSnackbar } from "notistack";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import { snackBar } from "../../redux/common";
import axios from "axios";
import { base_Url } from "../../web/utils/common";
import { Form, Field, Formik } from "formik";
import * as Yup from "yup";
import MuiPhoneNumber from "material-ui-phone-number";
import parsePhoneNumberFromString from "libphonenumber-js";

const useStyles = makeStyles((theme) => ({
  main: {
    background:
      "linear-gradient(317deg, rgba(254,118,120,1) 9%, rgba(21,62,128,1) 100%)",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      height: "100vh",
      padding: "10px",
    },

    [theme.breakpoints.up("md")]: {
      height: "100vh",
    },

    display: "flex",
    alignItems: "center",
  },
  subMain: {
    width: "450px",
    margin: "0 auto",
    backgroundColor: "#fff",
    borderRadius: "20px",
  },

  logo: {
    marginBottom: "20px",
    width: "60% !important",
    display: "block",
    margin: "0 auto",
  },
  mainCard: {
    boxShadow: "none !important",
    color: "#000 !important",
    display: "block",
    textAlign: "center",
    minWidth: 275,
  },
  boxCard: {
    [theme.breakpoints.up("xs")]: {
      padding: "20px",
    },

    [theme.breakpoints.up("md")]: {
      padding: "40px",
    },
  },

  typography: {
    fontSize: "24px !important",
    fontWeight: "bold !important",
    color: "#000",
  },

  send: {
    ":hover": { bgcolor: "#153E80", color: "#FFFFFF" },
    marginTop: "20px !important",
    textTransform: "none !important",
    fontSize: "16px !important",
    color: "#FFFFFF !important",
    width: "100%",
    backgroundColor: "#153E80 !important",
    border: "2px solid #153E80 !important",
    borderRadius: "15px !important",
    padding: "10px 40px !important",
    "&.Mui-disabled": {
      backgroundColor: "#153E80 !important",
      color: "#fff !important",
    },
    marginLeft: "3px !important",
  },

  mainPassword: {
    marginTop: "10px",
    width: "100%",
  },

  outlinePassword: {
    "& .MuiInputBase-input": {
      padding: "12px 14px !important",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      paddingTop: "10px!important",
      borderColor: "rgba(107, 114, 128, .5)",

      borderRadius: "7px!important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "blue",
    },
    paddingTop: "0px",
    color: "#000 !important",
  },
}));

const phoneValidation = (value) => {
  if (!value) {
    return false;
  }
  const phoneNumber = parsePhoneNumberFromString(value);
  return phoneNumber && phoneNumber.isValid();
};

function extractPhoneNumber(input) {
  const phoneNumber = parsePhoneNumberFromString(input);

  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber.nationalNumber;
  }

  return null;
}

const initialValues = { country_code: 91 };

const ResetPassword = () => {
  const uuid = uuidv4();
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [otpSent, setOtpSent] = useState(false)

  const handleRememberMeChange = (event) => {
    const isChecked = event.target.checked;
    setRememberMe(isChecked);

    // Cookies.set("rememberMe", isChecked.toString(), { expires: 7 });
  };

  useEffect(() => {
    try {
      const rememberMeCookie = JSON.parse(Cookies.get("rememberMe")) ?? {};
      setRememberMe(rememberMeCookie);
      setValues({
        email: rememberMeCookie?.email,
        mobile: rememberMeCookie?.mobile_number,
        password: rememberMeCookie?.password,
      });
      setPassword(rememberMeCookie?.password);
    } catch (err) { }
    // setShow(true);
    // const rememberMeValue = rememberMeCookie === "true";
  }, []);

  const validate = (fieldValues = values) => {
    let emailError = "";
    let mobileError = "";
    let passwordError = "";

    if (!fieldValues.mobile && !fieldValues.email) {
      emailError = "Please enter email";
      mobileError = "Please enter mobile";
    }
    if (!fieldValues.mobile && "email" in fieldValues) {
      const emailRegex =
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(com|org|gov|edu|net|mil|in)$/;
      const isEmailValid =
        emailRegex.test(fieldValues.email) && fieldValues.email.endsWith("");
      if (!isEmailValid) {
        emailError = "Invalid Email";
      }
    }

    if (!fieldValues.email && "mobile" in fieldValues) {
      const regex = /^\d{10}$/;
      if (!regex.test(fieldValues.mobile)) {
        mobileError = "Invalid phone number";
      }
    }

    if (!password) {
      passwordError = "Please enter password";
    } else {
      passwordError = "";
    }

    setErrors({
      email: emailError,
      mobile: mobileError,
      password: passwordError,
    });

    return !(emailError || mobileError || passwordError);
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const validationSchema = Yup.object({
    ...(!data?.mobile_number && {
      email: Yup.string()
        .email("Invalid email address")
        .required("Please enter email"),
    }),
    ...(!data?.email && {
      mobile_number: Yup.string()
      .required("Please enter mobile number")
      .test("is-valid-phone", "Mobile number is not valid", phoneValidation),
    }),
  });
  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setLoading(true);
  setOtpSent(true)
    try {
      const loginData = values.email
        ? { email: values.email }
        : { phone_code: Number(values?.country_code || 91), mobile_number: Number(extractPhoneNumber(values.mobile_number)) };
      const res = await axios.post(
        `${base_Url}user/forgot-password`,
        loginData
      );
      if (res.data.status === true) {
        setLoading(false);
        resetForm();
        snackBar(res?.data?.message, res?.data?.status);
        navigate("/otp", {
          state: {
            credential: loginData,
          },
        });
      } else {
        setLoading(false);
        snackBar(res?.data?.message, res?.data?.status);
      }
    } catch (err) {
      snackBar("Please enter valid mobile number", false);
    } finally {
      setSubmitting(false);
      setOtpSent(false)
    }
  };

  const isAnyFieldFilled = Object.values(values).some((value) =>
    Boolean(value)
  );
  return (
    <Box className={classes.main}>
      <Box className={classes.subMain}>
        <Grid
          container
          item
          columns={{ xs: 4, sm: 12, md: 12 }}
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={12}>
            <Box className={classes.boxCard}>
              <Link to="/">
                <CardMedia
                  className={classes.logo}
                  component="img"
                  image={Logo}
                  alt="Paella dish"
                />
              </Link>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  isSubmitting,
                  values,
                  handleChange,
                  setFieldError,
                  errors,
                  setErrors,
                  setFieldValue,
                  ...rest
                }) => {
                  setData(values);
                  return (
                    <Form>
                      <Box sx={{ marginBottom: "10px" }}>
                        <Field
                          type="text"
                          // id="email"
                          name="email"
                          placeholder="Email"
                          className="email-input"
                          style={{
                            width: "100%",
                            height: "40px",
                            borderRadius: "5px",
                            border: "1px solid #dee2e6",
                            paddingLeft: "10px",
                          }}
                          disabled={values.mobile_number}
                          maxLength={50}
                        />

                        {Boolean(errors.email) ? (
                          <p
                            style={{
                              color: "rgb(255 0 0)",
                              marginTop: "4px",
                              marginBottom: "4px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.email}
                          </p>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: 600,
                        }}
                      >
                        OR
                      </Typography>
                      <Box sx={{ marginBottom: "10px" }}>
                        <MuiPhoneNumber
                          type="text"
                          fullWidth
                          defaultCountry={"in"}
                          size="small"
                          variant="outlined"
                          id="mobile_number"
                          name="mobile_number"
                          placeholder="Mobile Number"
                          className="mobile_number-input"
                          disabled={values.email}
                          onChange={(e, value) => {
                            setFieldValue("country_code", value.dialCode);
                            setFieldValue("mobile_number", e);
                          }}
                        />

                        {Boolean(errors.mobile_number) ? (
                          <p
                            style={{
                              color: "rgb(255 0 0)",
                              marginTop: "4px",
                              marginBottom: "4px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.mobile_number}
                          </p>
                        ) : (
                          ""
                        )}
                      </Box>
                      <ButtonCom
                        className={classes.send}
                        type="submit"
                        text={
                          loading ? (
                            <CircularProgress
                              sx={{
                                color: "#fff",
                                width: "28px !important",
                                height: "28px !important",
                              }}
                            />
                          ) : (
                            "Send OTP"
                          )
                        }
                   disabled={otpSent}
                      ></ButtonCom>
                    </Form>
                  );
                }}
              </Formik>
              <Button className={classes.send} onClick={() =>navigate("/", {state: { formName: "Reset password" },})}>Login</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ResetPassword;
