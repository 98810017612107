import React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Store } from "./redux/store";
import { SnackbarProvider } from "notistack";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <Provider store={Store}>
    <BrowserRouter>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        autoHideDuration={3000}
      >
        <App />
        {/* <WebApp /> */}
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>
);
