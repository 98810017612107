import { Button } from "@mui/material";
import React, { useState } from "react";

const PricingDesc = ({ desc, descIndex, i }) => {
  const [readMore, setReadMore] = useState(false);
  return (
    <li key={descIndex} className="list-group-item bg-transparent p-0 mb-1">
      <span
        className={`${
          i == 1
            ? "font-size-md d-inline-block mr-3"
            : "text-green font-size-md d-inline-block mr-3"
        }`}
      >
        <i className="fal fa-check" />
      </span>
      <span>{desc.title}</span>
      <Button
        className="mx-1"
        style={{ color: "red" }}
        onClick={() => {
          setReadMore(!readMore);
        }}
      >
        {readMore ? "-" : "+"}
      </Button>
      {readMore &&
        desc["sub-points"]?.map((subPoint, index) => {
          return (
            <li
              key={index}
              className="list-group-item bg-transparent p-0 mb-1 pl-4"
            >
              <span
                className={`${
                  i === 1
                    ? "font-size-md d-inline-block mr-3"
                    : "text-gray font-size-md d-inline-block mr-3"
                }`}
              >
                -
              </span>
              <span className={`${i !== 1 ? "text-gray mb-6" : ""}`}>
                {subPoint}
              </span>
            </li>
          );
        })}
    </li>
  );
};

export default PricingDesc;
