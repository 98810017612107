import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable.js";
import { DeleteIcon, EditIcon } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { useForm } from "../../common/useForms";
import FormDialog from "../../components/dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import DeleteDialog from "../../components/dialog/deletedialog";
import Loader from "../../components/loader/Loader";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import {
  CreatePageBanner,
  DeletePageBanner,
  GetAllPageBanners,
  UpdatePageBanner,
} from "../../redux/action/PageBannersAction";
import { GetAllPages } from "../../redux/action/PagesAction";
import { snackBar } from "../../redux/common.js";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));


const initialValues = {
  banner_image: "",
  banner_page: "",
  page_image: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
];

const AdminPageBanners = () => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editImageValue, setEditImageValue] = useState();
  const [pageImage, setPageImage] = useState();


  const bannerData = useSelector(
    (state) => state?.PageBannersReducer?.pageBanners?.bannerList
  );
  const bannerPagesData = useSelector(
    (state) => state?.PagesReducer?.pages?.pageList
  );

  const { loading, pageBanners, createLoading, updateLoading, deleteLoading } =
    useSelector((state) => state.PageBannersReducer);

  useEffect(() => {
    dispatch(GetAllPageBanners(page, rowsPerPage));
    dispatch(GetAllPages(page, rowsPerPage));
  }, [page, dispatch]);

  const handleChangeRow = (event) => {
    const newRowPerPage = event.target.value;
    const newPageNumber = 1;
    setRowsPerPage(event.target.value);
    setPage(newPageNumber);

    dispatch(GetAllPageBanners(newPageNumber, newRowPerPage));
  };

  const tableTitle = "SubscriptionList";

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setPopupName("Create Page Banner");
    setOpen(true);
    setPageImage();
    setEditImageValue()
    resetForm();
  };

  const handleClose = () => {
    setOpen(false);
    setPageImage();
    setEditImageValue()
    resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let hasValidFile = false;
    let isImage = true;
    let fileTypeArray =
      fieldValues.banner_image && fieldValues.banner_image.split(".");
    let fileType = fileTypeArray && fileTypeArray[fileTypeArray.length - 1];
    if (fileTypeArray && fileTypeArray.length >= 1) {
      fileType = fileTypeArray && fileTypeArray[fileTypeArray.length - 1];
    }


    const allowedTypes = [
      "JPEG",
      "JPG",
      "PNG",
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "avi",
      "avif",
      "avchd",
      "flv",
      "f4v",
      "swf",
      "mpeg-2",
    ];
    if (popupName === "Edit Page Banner") {
      if ("banner_image" in fieldValues) {
        if (fieldValues.banner_image || editImageValue) {
          hasValidFile = true;
        } 
        if (fileType && !allowedTypes.includes(fileType)) {
          temp.banner_image = "Please select valid image file";
        } else {
          temp.banner_image = "";
        }
      }
      if (pageImage) {
        let pageImageType = pageImage.name ? pageImage.name.split(".") : pageImage.split(".");
        let fileExtension = pageImageType[pageImageType.length - 1];
        if (pageImage) {
          hasValidFile = true;
        } 
        if (!allowedTypes.includes(fileExtension)) {
          temp.page_image = "Please select a valid image file";
        } else {
          temp.page_image = "";
        }
      }
    } else {
      if ("banner_image" in fieldValues) {
        if (fieldValues.banner_image) {
          hasValidFile = true;
        } 
        if (fileType && !allowedTypes.includes(fileType)) {
          temp.banner_image = "Please select valid image file";
        } else {
          temp.banner_image = "";
        }
      }
      if (pageImage) {
        let pageImageType = pageImage.name ? pageImage.name.split(".") : pageImage.split(".");
        let fileExtension = pageImageType[pageImageType.length - 1];
        if (pageImage) {
          hasValidFile = true;
        } 
         if (!allowedTypes.includes(fileExtension)) {
          temp.page_image = "Please select a valid image file";
        } else {
          temp.page_image = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (!hasValidFile) {
      temp.general = "Please upload at least one valid image file.";
      setErrors({
        ...temp,
      });
      return false;
    } else {
      temp.general = "";   
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");

  };



  const handlePageImageChange = (event) => {
    let temp = { ...errors };
    const image = event.target.files[0];

    const allowedTypes = [
      "JPEG",
      "JPG",
      "PNG",
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "avi",
      "avif",
      "avchd",
      "flv",
      "f4v",
      "swf",
      "mpeg-2",
    ];

    let pageImageType = image && image.name.split(".");
    if (pageImageType && pageImageType.length > 0 && !allowedTypes.includes(pageImageType[pageImageType.length - 1])) {
      temp.page_image = "Please select valid image file";
    } else {
      temp.page_image = ""
    }

    setErrors({
      ...temp,
    });

    setPageImage(image);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  console.log("Errors_7987989", errors)

  const handleEditClick = async (data) => {
    setSelectedBanner(data);
    setErrors({});
    setPopupName("Edit Page Banner");
    if (data?.banner_image) {
      setEditImageValue(data?.banner_image);
    }
    if (data?.page_image) {
      setPageImage(data?.page_image)
    }
    setValues({
      ...values,
      banner_page: data?.banner_page || "",
      page_image: data?.page_image || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (data) => {
    setOpenDelete(true);
    SetDeleteId(data._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    await dispatch(DeletePageBanner(DeleteId, handleCloseDelete)).then(
      (res) => {
        if (res && res.data.status === true) {
          dispatch(GetAllPageBanners(page, rowsPerPage));
        }
      }
    );
  };


  const fields = [
    {
      id: "banner_image",
      type: "file",
      // labelAccess: "banner_image",
      // value:
      //   (typeof values?.banner_image === "string"
      //     ? ""
      //     : URL.createObjectURL(values?.banner_image)),
      name: "banner_image",
      errors: errors.banner_image,
      multiple: false,
      src: values.file ? values.file : editImageValue,
      names: "Banner Image",
    },

    {
      id: "page_image",
      type: "file",
      labelAccess: "page_image",
      name: "page_image",
      multiple: false,
      names: "Page Image",
      src: pageImage,
      errors: errors.page_image,
    },
  ];

  const columns = [
    {
      id: "banner_image",
      numeric: true,
      disablePadding: false,
      label: "Banner Image",
    },
  
    {
      id: "banner_page",
      numeric: false,
      disablePadding: true,
      label: "Banner Page",
    },
  
    {
      id: "page_image",
      numeric: false,
      disablePadding: true,
      label: "Page Image",
    },
  
    !!getPermissions(permissions.page_banners, "is_edit") ||
      !!getPermissions(permissions.page_banners, "is_delete")
      ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
      : null,
  ].filter((el) => el !== null);

  const rows =
    bannerData && Array.isArray(bannerData)
      ? bannerData?.map((data) => {
        const selected = data === selectedBanner;
        let isImage = true;
        let fileType = "";
        const item = data.banner_image;

        const item_url = item instanceof File ? item?.name : item;
        if (typeof item_url === "string") {
          fileType = item_url.substring(item_url.lastIndexOf(".") + 1);
          isImage = fileType.match(
            /(JPEG|JPG|PNG|jpeg|jpg|png|svg|webp|avi|avif|avchd|flv|f4v|swf|mpeg-2)/i
          );
        }
        console.log("data?.banner_image",data?.banner_image)
        return {
          _id: data._id,
          banner_image: data?.banner_image ? isImage ? (
            <img
              style={{ width: "70px", height: "50px", objectFit: "cover" }}
              src={data.banner_image}
              alt="GGC"
            />
          ) : (
            <OndemandVideoIcon />
          ) : "-",
          banner_page: data.banner_page || "-",
          page_image:  data?.page_image ? ( 
            <img
              style={{ width: "70px", height: "50px", objectFit: "cover" }}
              src={data.page_image}
              alt="GGC"
            />
          ) : "-",

          // status: data.is_active ? "approved" : 'pending',
          action: (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {!!getPermissions(permissions.page_banners, "is_edit") && (
                <IconButton
                  onClick={() => handleEditClick(data)}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
              {!!getPermissions(permissions.page_banners, "is_delete") && (
                <IconButton
                  onClick={() => handleClickOpenDelete(data)}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          ),
          selected: selected,
        };
      })
      : [];


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      const formData = new FormData();
      let image = null;

      if (values.file) {
        image = values.file;
        // formData.append(`page_banner_image`, image);
        formData.append(`page_banner_image`, image);
      } else {
        formData.append(`page_banner_image`, editImageValue);
      }
      const [pageData] = bannerPagesData.filter(
        (item) => item.page_name === values.banner_page
      );
      // if (pageData?._id) {
      formData.append("banner_page", "667935fdc13e0fd3cd840563");
      // }
      if (pageImage) {
        formData.append("page_image", pageImage);
      } else {
        formData.append("page_image", values.page_image);
      }
      if (popupName === "Edit Page Banner") {
        await dispatch(UpdatePageBanner(selectedBanner._id, formData)).then((res) => {
          if (res?.data?.status) {
            dispatch(GetAllPageBanners(page, rowsPerPage));
            handleClose();
          }
        });
      } else {
        await dispatch(CreatePageBanner(formData)).then((res) => {
          if (res?.data?.status) {
            snackBar(res?.data?.message, res?.data?.status);
            dispatch(GetAllPageBanners(page, rowsPerPage));
            handleClose();
          }
        });
      }
    }
  };

  const classes = useStyles();

  return (
    <Box>
      <PageHeader
        title="Page Banners"
        onAddClick={handleClickOpen}
        isShowAdd={!!getPermissions(permissions.page_banners, "is_add")}
      />

      {loading ? (
        <Loader />
      ) : (
        <EnhancedTable
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={bannerData}
        />
      )}
      <FormDialog
        open={open}
        general={errors.general}
        dialogTitle={popupName}
        fields={
          popupName === "Edit Page Banner"
            ? fields.filter((el, index) =>
              ["banner_image", "page_image"].includes(el.name)
            )
            : fields.filter((el) => !["status"].includes(el.name))
        }
        loading={
          popupName === "Edit Page Banner" ? updateLoading : createLoading
        }
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={(event) => {
          const fieldName = event.target.name;
          if (fieldName === "page_image") {
            handlePageImageChange(event);
          } else {
            handleInputChange(event);
          }
        }}
      // isAnyFieldNotEmpty={isAnyFieldNotEmpty}
      />

      <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {pageBanners?.currentPage} of {pageBanners?.totalPages}{" "}
            Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={pageBanners?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default AdminPageBanners;
