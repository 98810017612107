import React from "react";
import bannerBg from "../../assets/image/banner/banner_2.png";
import how1 from "../../assets/image/other/how-it-work-1.png";
import how2 from "../../assets/image/other/how-it-work-2.png";
import how3 from "../../assets/image/other/how-it-work-3.png";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import Pricing from "../pricing/Pricing";
import { beMemberForm } from "../../constants/beMemberContent";
import BeCard from "../../components/beCard/BeCard";
import BePartnerPageNavigation from "../../components/BePartnerPageNavigation/BePartnerPageNavigation";
import { Deals } from "../../components/Deals/Deals";
import SearchComponent from "../../components/search/SerachComponent";

const StyledContainer = styled("div")({
  background: "linear-gradient(45deg, #1F4182, #223366)", // Gradient background
  width: "100vw",
  marginTop: "40px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const ButtonContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "2rem",
  cursor: "pointer",
});

const FindChapterBtn = styled("div")({
  width: "200px",
  backgroundColor: "#F07579",
  color: "#fff",
  padding: "1rem 2rem",
  borderRadius: "5px",
  textDecoration: "none",
  transition: "background-color 0.3s ease",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
  "&:hover": {
    backgroundColor: "#a3141f",
  },
});

const BeMemberNew = () => {
  const navigate = useNavigate("");
  const handleBeMember = () => {
    navigate("/be-member-form");
  };

  const UserData =
    localStorage.getItem("user_data") &&
    JSON.parse(localStorage.getItem("user_data"));

  const goToForm = () => {
    navigate("/be-member-form");
  };

  const token = localStorage.getItem("token");
  return (
    <>
      <section
        id="section-01"
        className="responsive"
        style={{
          display: "flex",
          alignItem: "center",
          justifyContent: "center",
          borderRadius: '10px',
          marginTop: '15px',
          maxHeight: '380px',
        }}
      >
        <img src={bannerBg} style={{borderRadius: '10px', maxHeight: '380px'}} />
      </section>

      <section className="responsive">
          <div className="row d-flex">
            <p style={{margin: '80px auto'}} className="lead mb-7 font-size-lg font-weight-normal lh-18 fadeInDown animated text-center">
              Are you ready to elevate your business to new heights? Join the
              Global Garner Community, where local meets global, and success
              knows no bounds. As the proud initiative of Global Garner Sales
              Service Limited, a trailblazer that has achieved a remarkable 6650
              crore valuation in just 7 years, we bring you a unique platform
              that transcends geographical boundaries and propels your business
              towards unprecedented growth.
            </p>
            {beMemberForm.map((item) => (
              <BeCard {...item} />
            ))}
          </div>
        
        <SearchComponent title="Be a Member" func={goToForm} />

        {!token ? (
          UserData?.user?.user_role?.role === "Community Member" &&
          UserData?.user?.membership_id &&
          new Date(UserData?.user?.membership_validity?.expire_at) <
            new Date() ? (
            <div id="upgrade_now">
              <Pricing />
            </div>
          ) : null
        ) : null}
      </section>
    </>
  );
};

export default BeMemberNew;
