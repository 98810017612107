import React, { useRef } from "react";
import { snackBar } from "../redux/common";

const CopyToClipboardButton = ({ textToCopy }) => {
  const textAreaRef = useRef(null);

  const copyToClipboard = () => {
    textAreaRef.current.select();
    document.execCommand("copy");
    snackBar("Copied to clipboard!", true);
  };

  return (
    <div style={{ display: "flex", justifyContent: "end" }}>
      <textarea
        ref={textAreaRef}
        value={textToCopy}
        readOnly
        style={{ position: "absolute", left: "-9999px" }}
      />

      <button
        onClick={copyToClipboard}
        style={{
          marginBottom: "10px",
          width: "20%",
          borderRadius: "25px",
        }}
      >
        Copy Business Card
      </button>
    </div>
  );
};

export default CopyToClipboardButton;
