import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../pageHeader/PageHeader";
import EnhancedTable from "../table/EnhancedTable.js";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { Form, useForm } from "../../common/useForms";
import FormDialog from "../dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSubscription,
  UpdateSubscription,
} from "../../redux/action/SubscriptionAction";
import DeleteDialog from "../dialog/deletedialog";
import SelectBox from "../../common/SelectBox";
import {
  CreateCountry,
  DeleteCountry,
  GetCountry,
  UpdateCountry,
} from "../../redux/action/CountryAction";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import axios from "axios";
import { ApiURL } from "../../redux/common";
import Loader from "../loader/Loader";
import useUserRolePermissions from "../../common/UserPermissions";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const initialValues = {
  country: "",
};
const initialFilterValues = {
  countryName: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
  // { id: 30, title: "30" },
];

const CountryList = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectedCountry, setselectedCountry] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [downLoadData, setDownLoadData] = useState("");
  const role = useUserRolePermissions();
  const [countryCreateLoading, setCountryCreateLoading] = useState(false)
  const [countryDeleteLoading, setCountryDeleteLoading] = useState(false)
  const [isFilterClicked, setIsFilterClicked] = useState(false);

  const columns = [
    {
      id: "country_name",
      numeric: false,
      disablePadding: true,
      label: "Country Name",
    },
    {
      id: "is_active",
      numeric: true,
      disablePadding: false,
      label: "Is Active",
    },
    !!getPermissions(permissions.country, "is_edit") ||
      !!getPermissions(permissions.country, "is_delete")
      ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
      : null,
  ].filter((el) => el !== null);

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    setPage(1);
    const country_name = filterDatas.countryName;
    dispatch(GetCountry(1, country_name, rowPerPage));
  };

  const filterMenuItem = [
    { value: "Basic", label: "Basic" },
    { value: "Advanced", label: "Advanced" },
    { value: "Enterprise", label: "Enterprise" },
  ];
  const classes = useStyles();
  const dispatch = useDispatch();

  const { countryData, loading, updateLoading, createLoading, deleteLoading } =
    useSelector((state) => state.CountryReducer);

  const tableTitle = "CountryList";

  useEffect(() => {
    if (!open) {
      const country_name = filterDatas.countryName;
      dispatch(GetCountry(page, country_name, rowsPerPage));
    }
  }, [page, open]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setDialogTitle("Add Country");
    setValues(initialValues);
    setErrors({});
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let alphaRegex = /^[A-Za-z]+$/;
    if ("country" in fieldValues) {
      if (
        fieldValues.country === "" ||
        fieldValues.country === undefined ||
        fieldValues.country === null ||
        fieldValues.country?.trim() === ""
      ) {
        temp.country = "Please enter country name";
        setCountryCreateLoading(false)
      }
      else if (fieldValues.country?.length < 3) {
        temp.country = "Please enter country name at least 3 character";
        setCountryCreateLoading(false)
      }
      else if (!alphaRegex.test(fieldValues.country)) {
        temp.country = "Please enter aplhabetic";
        setCountryCreateLoading(false)
      }

      else {
        temp.country = "";
      }
    }

    if ("is_active" in fieldValues) {
      if (
        fieldValues.is_active === "" ||
        fieldValues.is_active === undefined ||
        fieldValues.is_active === null
      ) {
        temp.is_active = "This field is required.";
      } else {
        temp.is_active = "";
      }
    }

    setErrors({
      ...temp,
    });
 
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleEditClick = (country) => {
    setselectedCountry(country);
    setDialogTitle("Update Country");
    setValues({
      ...values,
      country: country.country_name || "",
      is_active: country.is_active ? "true" : "false" || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (country) => {
    setOpenDelete(true);
    SetDeleteId(country._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    setCountryDeleteLoading(true)
    await dispatch(DeleteCountry(DeleteId, handleCloseDelete)).then((res) => {
      if (res && res.data.status === true) {
        setCountryDeleteLoading(false)
        dispatch(GetCountry(page, "", rowsPerPage));
      }
    });
  };

  const fields = [
    {
      id: "country",
      label: "Country",
      type: "text",
      value: values.country,
      name: "country",
      errors: errors.country,
      maxLength: 20,
    },
    {
      id: "is_active",
      label: "Is Active",
      type: "select",
      options: [
        { value: "true", label: "ACTIVE" },
        { value: "false", label: "INACTIVE" },
      ],
      value: values.is_active,
      name: "is_active",
    },
  ];

  //filter

  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(GetCountry(1, "", rowsPerPage));
    setIsFilterClicked(false)
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilterDatas({
      ...filterDatas,
      [name]: value,
    });
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setIsFilterClicked(true)
    const country_name = filterDatas.countryName;
    setPage(1);
    dispatch(GetCountry(page, country_name, rowsPerPage));
    closeFilterDrawer1();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCountryCreateLoading(true)
    if (validate()) {
      let body = {};
      if (dialogTitle === "Update Country") {
        body = {
          country_name: values.country,
          is_active: values.is_active,
        };
      } else {
        body = {
          country_name: values.country,
        };
      }

      if (dialogTitle === "Update Country") {
        const res = await dispatch(UpdateCountry(selectedCountry._id, body, handleClose));

        if (res?.data?.status) {
          handleClose()
          setCountryCreateLoading(false)
        } else {
          setCountryCreateLoading(false)
        }
      } else {
        const res = await dispatch(CreateCountry(body, handleClose));
        if (res?.data?.status) {
          handleClose()
          setCountryCreateLoading(false)
        } else {
          setCountryCreateLoading(false)
        }
      }
    }
  };



  const rows =
    countryData && Array.isArray(countryData?.countryData)
      ? countryData?.countryData.map((country) => {
        const selected = country === selectedCountry;
        return {
          _id: country._id,
          country_name: country.country_name || "-",
          is_active: country.is_active ? "Active" : "inActive",
          action: (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {!!getPermissions(permissions.country, "is_edit") && (
                <IconButton
                  onClick={() => handleEditClick(country)}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
              {!!getPermissions(permissions.country, "is_delete") && (
                <IconButton
                  onClick={() => handleClickOpenDelete(country)}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          ),
          selected: selected,
        };
      })
      : [];

  const DownLoadPdf = async () => {
    const membership_name = filterDatas.countryName;
    try {
      const res = await axios.get(`${ApiURL}country/download-data?search=${membership_name}`);
      setDownLoadData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching role data:", error);
      return {};
    }
  };

  useEffect(() => {
    DownLoadPdf();
  }, [isFilterClicked]);

  return (
    <Box>
      <PageHeader
        title="Countries"
        isShowAdd={!!getPermissions(permissions.country, "is_add")}
        onAddClick={handleClickOpen}
        onFilterClick={openFilterDrawer}
        loading={loading}
        href={downLoadData}
      />
      {loading ? (
        <Loader />
      ) : (
        <EnhancedTable
          rowsPerPage={rowsPerPage}
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={countryData?.countryData}
        />
      )}

      <FormDialog
        open={open}
        dialogTitle={dialogTitle}
        fields={
          dialogTitle === "Add Country"
            ? fields.filter((el) => ["country"].includes(el.name))
            : fields
        }
        loading={
          dialogTitle === "Update Country" ? updateLoading : createLoading
        }
        disableButton={countryCreateLoading}
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
      // isAnyFieldNotEmpty={isAnyFieldNotEmpty}
      />
      <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        isDisable={countryDeleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {countryData?.currentPage} of {countryData?.totalPages}{" "}
            Pages
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={countryData?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>

      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div>
              <Box>
                <Input
                  variant="outlined"
                  color="primary"
                  label="Country Name"
                  name="countryName"
                  value={filterDatas.countryName}
                  onChange={handleChange}
                  maxLength={50}
                />
              </Box>
              <Stack className={classes.buttonFilter}>
                <Button
                  disabled={filterDatas.countryName ? false : true}
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                >
                  Reset
                </Button>
                <Button
                  disabled={filterDatas.countryName ? false : true}
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
    </Box>
  );
};

export default CountryList;
