import { api } from "../config/api";

export const GetClientReview = async () => {
  try {
    const response = await api.get(
      `/review/all-review`
    );
    const responseData = response;
    return responseData;
  } catch (error) {
    throw error;
  }
};