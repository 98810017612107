import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Home1 from "../../assets/image/banner/banner_1.png";
import Home2 from "../../assets/image/banner/banner_2.png";
import axios from "axios";
import { ApiURL } from "../../../redux/common";
import Loader from "../loader/Loader";

import video from '../../assets/video/GGCommunityVideo.mp4';
const data = [
  {
    bgImage: Home1,
  },
  {
    bgImage: Home2,
  },
];

const HomeCarousel = () => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);

  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    className: "hero-banner-arrow",
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const getBanners = async () => {
    setLoading(true)
    const response = await axios.get(
      `${ApiURL}page-banner/get-all-page-banners?page_name=home`
    );

    
    if (response?.data?.data?.bannerList) {
      setLoading(false)
      setBanners(response?.data?.data?.bannerList);
    } else {
      setLoading(false)
    }
  };
  

  useEffect(() => {
    getBanners();
  }, []);
  
  
  return (
    <div className="home-slider">
      <video style={{width:'100%'}} loop autoPlay muted>
        <source src={video} type="video/mp4" />
      </video>

      {/* {loading ?
        <div style={{ display: "flex", justifyContent: "center", alignItem: "center", width: "100%", height: "100vh" }}>
          <Loader />
        </div>
        :
        banners.length ?
        <Slider {...settings}>
          {banners.map((item) => {
            let isImage = true;
            let fileType = "";

            if (typeof item?.banner_image === "string") {
              fileType = item?.banner_image.substring(item?.banner_image?.lastIndexOf(".") + 1);
              isImage = fileType.match(/(JPEG|JPG|PNG|jpeg|jpg|png|svg|webp|avi|avif|avchd|flv|f4v|swf|mpeg-2)/i);
            }
            return (
              <div>
                <div className="box" style={{ width: "100%", display: "flex",height:"100vh" }}>
                  {isImage ? (
                    <img
                      style={{ width: "100%", height:"100%" }}
                      src={item?.banner_image}
                      alt="Client logo"
                    />
                  ) : (
                    <video width={"100%"} autoPlay loop muted>
                      <source src={item?.banner_image} type="video/mp4" />
                    </video>
                  )}
                </div>
              </div>
            );
          })}
        </Slider>
        :
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"50vh"}}>
          <h3>No any banner images</h3>
          </div>
      } */}
    </div>
  );
};

export default HomeCarousel;
