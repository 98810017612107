const initialState = {
  loading: false,
  updateLoading: false,
  deleteLoading: false,
  createLoading: false,
  userallData: {},
  userProfileData: {},
  updatedUser: [],
  roleData: [],
};

function UserManageReducer(state = initialState, action) {
  switch (action.type) {
    case "LOADING_USERMANAGE":
      return {
        ...state,
        loading: action.payload,
      };

    case "CLEAR_USER_DATA":
      return {
        ...initialState,
      };

    case "USERMANAGE_SUCCESS":
      return {
        ...state,
        userallData: action.payload || {},
      };

    case "LOADING_PROFILE":
      return {
        ...state,
        loading: action.payload,
      };
    case "PROFILE_SUCCESS":
      return {
        ...state,
        userProfileData: action.payload || {},
      };

    case "UPDATE_LOADING_PROFILE":
      return {
        ...state,
        updateLoading: action.payload,
      };
    case "UPDATE_LOADING_USERMANAGE":
      return {
        ...state,
        updateLoading: action.payload,
      };
    case "UPDATE_USERMANAGE_SUCCESS":
      return {
        ...state,
      };

    case "UPDATE_LOADING_USERBUSINESSMANAGE":
      return {
        ...state,
        updateLoading: action.payload,
      };
    case "UPDATE_USERBUSINESSMANAGE_SUCCESS":
      return {
        ...state,
      };
    case "DELETE_LOADING_USERMANAGE":
      return {
        ...state,
        deleteLoading: action.payload,
      };
    case "DELETE_USERMANAGE_SUCCESS":
      return {
        ...state,
      };
    case "CREATE_LOADING_USERMANAGE":
      return {
        ...state,
        createLoading: action.payload,
      };
    case "CREATE_USERMANAGE_SUCCESS":
      return {
        ...state,
      };
    case "GET_ROLE_SUCCESS":
      return {
        ...state,
        roleData: action.payload,
      };
    default:
      return state;
  }
}

export default UserManageReducer;
