import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

export const GetAllCountry = (is_active) => async (dispatch) => {
  try {
    dispatch({
      type: "LOADING",
      payload: true,
    });

    let url = `${ApiURL}country/all-country`;

    if (is_active) {
      url = `${ApiURL}country/all-country?is_active=true`;
    }

    const res = await axios.get(url);

    if (res.data.status === true) {
      dispatch({
        type: "LOADING",
        payload: false,
      });

      dispatch({
        type: "ALL_COUNTRIES_SUCCESS",
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: "LOADING",
      payload: false,
    });
  }
};

export const cleareAllCountry = () => async (dispatch) => {
  dispatch({
    type: "ALL_COUNTRIES_SUCCESS",
    payload: [],
  });
};

export const GetCountry =
  (page, country_name = "", rowsPerPage) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "LOADING",
        payload: true,
      });

      const res = await axios.get(
        `${ApiURL}country/all-country?limit=${rowsPerPage}&page=${page}&country_name=${country_name}`
      );

      if (res.data.status === true) {
        dispatch({
          type: "LOADING",
          payload: false,
        });
        const responseData =
          res.data.data || res.data.data.length > 0 ? res.data.data : [];
        dispatch({
          type: "COUNTRIES_SUCCESS",
          payload: responseData,
        });
      } else {
        dispatch({
          type: "LOADING",
          payload: false,
        });
        dispatch({
          type: "COUNTRIES_SUCCESS",
          payload: [],
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "LOADING",
        payload: false,
      });
    }
  };

export const CreateCountry = (data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_COUNTRY_LOADING",
      payload: true,
    });
    const res = await axios.post(`${ApiURL}country/create-country`, data);

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_COUNTRY_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "CREATE_COUNTRY_LOADING",
      payload: false,
    });
  }
};

export const UpdateCountry = (id, data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_COUNTRY_LOADING",
      payload: true,
    });
    const res = await axios.put(`${ApiURL}country/update-country/${id}`, data);
    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_COUNTRY_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "UPDATE_COUNTRY_LOADING",
      payload: false,
    });
  }
};

export const DeleteCountry = (id, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_COUNTRY_LOADING",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}country/delete-country/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_COUNTRY_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "DELETE_COUNTRY_LOADING",
      payload: false,
    });
  }
};

export const GetStateByCountry = (countryId) => async (dispatch) => {
  try {
    dispatch({
      type: "LOADING",
      payload: true,
    });

    const res = await axios.get(`${ApiURL}states-by-countryId/${countryId}`);

    if (res.data.status === true) {
      dispatch({
        type: "LOADING",
        payload: false,
      });
      dispatch({
        type: "STATE_SUCCESS",
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: "LOADING",
      payload: false,
    });
  }
};

export const GetCityByState = (countryId, stateId) => async (dispatch) => {
  try {
    dispatch({
      type: "LOADING",
      payload: true,
    });

    const res = await axios.get(
      `${ApiURL}cities-by-id/${countryId}/${stateId}`
    );

    if (res.data.status === true) {
      dispatch({
        type: "LOADING",
        payload: false,
      });
      dispatch({
        type: "CITY_SUCCESS",
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: "LOADING",
      payload: false,
    });
  }
};
