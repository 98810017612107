import React, { useEffect, useState } from "react";
import { ApiURL, snackBar } from "../../redux/common";
import axios from "../../redux/ApiConfig";
import Loader from "../../components/loader/Loader";
import InternalServerError from "../InternalServerError.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import ImageIcon from "@mui/icons-material/Image";
import { getPermissions } from "../../common/permissions.js";
import { permissions } from "../../common/constants.js";
import useUserRolePermissions from "../../common/UserPermissions.js";

const initialValues = {
  title1: "",
  subtitle1: "",
  description1: "",
  aboutus_image1: "",
  title2: "",
  subtitle2: "",
  subpoints1: "",
  subpoints_description1: "",
  subpoints2: "",
  subpoints_description2: "",
  subpoints3: "",
  subpoints_description3: "",
  title3: "",
  subtitle3: "",
  description3: "",
  aboutus_image3: "",
  title4: "",
  subtitle4: "",
  description4: "",
  aboutus_image4: "",
  trusted_images: "",
};

export const axiosApiCall = () =>
  axios.create({
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });

const AdminAboutUs = () => {
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [data, setData] = useState("");
  const [error, setError] = useState(false);
  const [values, setValues] = useState(initialValues);
  const role = useUserRolePermissions();

  const [selectedImage1, setSelectedImage1] = useState();
  const [selectedImage3, setSelectedImage3] = useState();
  const [selectedImage4, setSelectedImage4] = useState(data.aboutus_image1);
  const [selectedImage, setSelectedImage] = useState([]);

  const removeSelectedImage = (indexToRemove) => {
    const remainingImg = Array.isArray(selectedImage)
      ? selectedImage.filter((item, i) => i !== indexToRemove)
      : [];
    setSelectedImage(remainingImg);
  };

  const showImages = () => {
    let imagesArray = Array.isArray(selectedImage) ? [...selectedImage] : [];

    // if (data.trusted_images && data.trusted_images.length > 0) {
    //   imagesArray = [...imagesArray];
    // }

    return imagesArray.map((item, i) => (
      <div
        key={i}
        style={{
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid lightgray",
          padding: "5px",
        }}
      >
        <div style={{ flex: "1" }}>
          <ImageIcon sx={{ marginRight: "0.5rem" }} />
          <img
            style={{ width: 80 }}
            src={typeof item === "string" ? item : URL.createObjectURL(item)}
            alt={`Selected Image ${i + 1}`}
            loading="lazy"
            onError={(e) => {
              console.error(`Error loading image ${i + 1}:`, e);
            }}
          />
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Button
            color="error"
            variant="outlined"
            onClick={() => removeSelectedImage(i)}
          >
            X
          </Button>
          <br />
        </div>
      </div>
    ));
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;
    setSelectedImage([...selectedImage, ...files]);
  };

  const handleChanges = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const [selectedImageError1, setSelectedImageError1] = useState('')
  const [selectedImageError2, setSelectedImageError2] = useState('')
  const [selectedImageError3, setSelectedImageError3] = useState('')


  const handleImageUpload1 = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/svg", "image/webp", "image/avi", "image/avif", "image/avchd", "image/flv", "image/f4v", "image/swf", "image/mpeg-2"];
    if (file && allowedTypes.includes(file.type)) {
      setSelectedImageError1('')
      setSelectedImage1(file);
    } else {
      setSelectedImageError1("Please select valid image file");
    }
  };

  const handleImageUpload3 = (e) => {
    const file3 = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/svg", "image/webp", "image/avi", "image/avif", "image/avchd", "image/flv", "image/f4v", "image/swf", "image/mpeg-2"];
    if (file3 && allowedTypes.includes(file3.type)) {
      setSelectedImageError2('')
      setSelectedImage3(file3);
    } else {
      setSelectedImageError2("Please select valid image file");
    }
  };
  const handleImageUpload4 = (e) => {
    const file4 = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/svg", "image/webp", "image/avi", "image/avif", "image/avchd", "image/flv", "image/f4v", "image/swf", "image/mpeg-2"];
    if (file4 && allowedTypes.includes(file4.type)) {
      setSelectedImageError3('')
      setSelectedImage4(file4);
    } else {
      setSelectedImageError3("Please select valid image file");
    }
  };

  const getAboutUsData = async () => {
    try {
      const res = await axios.get(`${ApiURL}about-us`);
      if (res?.data?.status) {
        setData(res?.data?.data);
        setValues(res?.data?.data);
        setSelectedImage4(res?.data?.data?.aboutus_image4);
        setSelectedImage1(res?.data?.data?.aboutus_image1);
        setSelectedImage3(res?.data?.data?.aboutus_image3);
        setSelectedImage(res?.data?.data?.trusted_images);
      } else {
        setData("");
      }
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitAbout = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("title1", values.title1);
      formData.append("subtitle1", values.subtitle1);
      formData.append("description1", values.description1);
      formData.append("title2", values.title2);
      formData.append("subtitle2", values.subtitle2);
      formData.append("subpoints1", values.subpoints1);
      formData.append("subpoints_description1", values.subpoints_description1);
      formData.append("subpoints2", values.title1);
      formData.append("subpoints_description2", values.subpoints_description2);
      formData.append("subpoints3", values.subpoints3);
      formData.append("subpoints_description3", values.subpoints_description3);
      formData.append("title3", values.title3);
      formData.append("subtitle3", values.subtitle3);
      formData.append("description3", values.description3);
      formData.append("title4", values.title4);
      formData.append("subtitle4", values.subtitle4);
      formData.append("description4", values.description4);
      formData.append("aboutus_image1", selectedImage1);
      formData.append("aboutus_image3", selectedImage3);
      formData.append("aboutus_image4", selectedImage4);


      let imagesArray = [];
      if (selectedImage instanceof FileList) {
        imagesArray = Array.from(selectedImage);
      } else {
        selectedImage.forEach(function (element) {
          if (typeof element == "object") {
            imagesArray.push(element);
          }
        });
      }

      imagesArray.forEach((image, index) => {
        formData.append("trusted_images", image);
      });
      let existImage = [];
      for (let i = 0; i < selectedImage.length; i++) {
        if (typeof selectedImage[i] == "string") {
          existImage.push(selectedImage[i]);
        }
      }
      if (existImage.length > 0) {
        formData.append(`trusted_images`, JSON.stringify(existImage));
      }
      setUpdateLoading(true);
      const res = await axiosApiCall().put(`${ApiURL}about-us`, formData);
      snackBar(res?.data?.message, res?.data?.status);
    } catch {
      snackBar("Error updating status", "error");
    } finally {
      setUpdateLoading(false);
    }
  };

  useEffect(() => {
    getAboutUsData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <InternalServerError />;
  }

  return (
    <>
      <PageHeader title="About Us" isShowAdd={false} />
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={handleSubmitAbout}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            paddingTop={2}
            sx={{ padding: "20px" }}
          >
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="title1"
                label="Title1"
                type="text"
                fullWidth
                variant="outlined"
                value={values.title1}
                name="title1"
                onChange={handleChanges}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="subtitle1"
                label="SubTitle1"
                type="text"
                fullWidth
                variant="outlined"
                value={values.subtitle1}
                name="subtitle1"
                onChange={handleChanges}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="description1"
                label="Description1"
                type="text"
                fullWidth
                variant="outlined"
                value={values.description1}
                name="description1"
                onChange={handleChanges}
                inputProps={{ maxLength: 2000 }} // Adjust the max length as needed
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Grid item md={3} xs={12} sm={12}>
                <label>Image1</label>
                <input
                  type="file"
                  accept="image/*"
                  id="image-upload1"
                  style={{ display: "none" }}
                  onChange={handleImageUpload1}
                />
                <label htmlFor="image-upload1">
                  <Button
                    component="span"
                    variant="contained"
                    color="inherit"
                    sx={{ marginTop: "-9px" }}
                  >
                    Upload Image1
                  </Button>
                </label>
                {/* {selectedImage1 ? null : (
                <div className="err-msg-font">
                  Please select an image to upload.
                </div>
              )} */}
              </Grid>
              <Grid item md={9} xs={12} sm={12}>
                {selectedImageError1 ?
                  <div className="err-msg-font">
                    {selectedImageError1}
                  </div>
                  :
                  selectedImage1 && data && (
                    <Grid item md={12} xs={12} sm={12}>
                      <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
                        Selected Image:
                        <br />
                        <a
                          target="_blank"
                          href={
                            typeof selectedImage1 !== "object" && typeof values.aboutus_image1 === "string"
                              ? values.aboutus_image1
                              : URL.createObjectURL(selectedImage1)
                          }
                        >
                          <img
                            // key={i}
                            style={{ width: 80 }}
                            src={
                              typeof selectedImage1 !== "object" && typeof values.aboutus_image1 === "string"
                                ? values.aboutus_image1
                                : URL.createObjectURL(selectedImage1)
                            }
                            // alt={`Selected Image ${i + 1}`}
                            loading="lazy"
                          // onError={(e) => {
                          //   console.error(`Error loading image ${i + 1}:`, e);
                          // }}
                          />
                        </a>
                      </Typography>
                    </Grid>
                  )}
              </Grid>
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="title2"
                label="Title2"
                type="text"
                fullWidth
                variant="outlined"
                value={values.title2}
                name="title2"
                onChange={handleChanges}
                inputProps={{ maxLength: 500 }} // Adjust the max length as needed
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="subtitle2"
                label="SubTitle2"
                type="text"
                fullWidth
                variant="outlined"
                value={values.subtitle2}
                name="subtitle2"
                onChange={handleChanges}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="subpoints1"
                label="Subpoints1"
                type="text"
                fullWidth
                variant="outlined"
                value={values.subpoints1}
                name="subpoints1"
                onChange={handleChanges}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="subpoints_description1"
                label="SubpointsDescription1"
                type="text"
                fullWidth
                variant="outlined"
                value={values.subpoints_description1}
                name="subpoints_description1"
                onChange={handleChanges}
                inputProps={{ maxLength: 2000 }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="subpoints2"
                label="Subpoints2"
                type="text"
                fullWidth
                variant="outlined"
                value={values.subpoints2}
                name="subpoints2"
                onChange={handleChanges}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="subpoints_description2"
                label="subpointsdescription2"
                type="text"
                fullWidth
                variant="outlined"
                value={values.subpoints_description2}
                name="subpoints_description2"
                onChange={handleChanges}
                inputProps={{ maxLength: 2000 }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="subpoints3"
                label="subpoints3"
                type="text"
                fullWidth
                variant="outlined"
                value={values.subpoints3}
                name="subpoints3"
                onChange={handleChanges}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="subpoints_description3"
                label="SubpointsDescription3"
                type="text"
                fullWidth
                variant="outlined"
                value={values.subpoints_description3}
                name="subpoints_description3"
                onChange={handleChanges}
                inputProps={{ maxLength: 2000 }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="title3"
                label="Title3"
                type="text"
                fullWidth
                variant="outlined"
                value={values.title3}
                name="title3"
                onChange={handleChanges}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="subtitle3"
                label="SubTitle3"
                type="text"
                fullWidth
                variant="outlined"
                value={values.subtitle3}
                name="subtitle3"
                onChange={handleChanges}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="description3"
                label="Description3"
                type="text"
                fullWidth
                variant="outlined"
                value={values.description3}
                name="description3"
                onChange={handleChanges}
                inputProps={{ maxLength: 2000 }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Grid item md={3} xs={12} sm={12}>
                <label>Image3</label>
                <input
                  type="file"
                  accept="image/*"
                  id="image-upload3"
                  style={{ display: "none" }}
                  onChange={handleImageUpload3}
                />
                <label htmlFor="image-upload3">
                  <Button
                    component="span"
                    variant="contained"
                    color="inherit"
                    sx={{ marginTop: "-9px" }}
                  >
                    Upload Image3
                  </Button>
                </label>
                {/* {selectedImage1 ? null : (
                <div className="err-msg-font">
                  Please select an image to upload.
                </div>
              )} */}
              </Grid>
              <Grid item md={9} xs={12} sm={12}>
                {selectedImageError2 ?
                  <div className="err-msg-font">
                    {selectedImageError2}
                  </div>
                  :
                  selectedImage3 && data && (
                    <Grid item md={12} xs={12} sm={12}>
                      <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
                        Selected Image:
                        <br />
                        <a
                          target="_blank"
                          href={
                            typeof selectedImage3 !== "object" && typeof values.aboutus_image3 === "string"
                              ? values.aboutus_image3
                              : URL.createObjectURL(selectedImage3)
                          }
                        >
                          <img
                            // key={i}
                            style={{ width: 80 }}
                            src={
                              typeof selectedImage3 !== "object" && typeof values.aboutus_image3 === "string"
                                ? values.aboutus_image3
                                : URL.createObjectURL(selectedImage3)
                            }
                            // alt={`Selected Image ${i + 1}`}
                            loading="lazy"
                          // onError={(e) => {
                          //   console.error(`Error loading image ${i + 1}:`, e);
                          // }}
                          />
                        </a>
                      </Typography>
                    </Grid>
                  )}
              </Grid>
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="title4"
                label="Title4"
                type="text"
                fullWidth
                variant="outlined"
                value={values.title4}
                name="title4"
                onChange={handleChanges}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="subtitle4"
                label="Subtitle4"
                type="text"
                fullWidth
                variant="outlined"
                value={values.subtitle4}
                name="subtitle4"
                onChange={handleChanges}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                sx={{ input: { color: "#000" } }}
                id="description4"
                label="Description4"
                type="text"
                fullWidth
                variant="outlined"
                value={values.description4}
                name="description4"
                onChange={handleChanges}
                inputProps={{ maxLength: 2000 }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Grid item md={3} xs={12} sm={12}>
                <label>Image4</label>
                <input
                  type="file"
                  accept="image/*"
                  id="image-upload4"
                  style={{ display: "none" }}
                  onChange={handleImageUpload4}
                />
                <label htmlFor="image-upload4">
                  <Button
                    component="span"
                    variant="contained"
                    color="inherit"
                    sx={{ marginTop: "-9px" }}
                  >
                    Upload Image4
                  </Button>
                </label>
                {/* {selectedImage1 ? null : (
                <div className="err-msg-font">
                  Please select an image to upload.
                </div>
              )} */}
              </Grid>
              <Grid item md={9} xs={12} sm={12}>
                {selectedImageError3 ?
                  <div className="err-msg-font">
                    {selectedImageError3}
                  </div>
                  :
                  selectedImage4 && data && (
                    <Grid item md={12} xs={12} sm={12}>
                      <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
                        Selected Image:
                        <br />
                        <a
                          target="_blank"
                          href={
                            typeof selectedImage4 !== "object" && typeof values.aboutus_image4 === "string"
                              ? values.aboutus_image4
                              : URL.createObjectURL(selectedImage4)
                          }
                        >
                          <img
                            // key={i}
                            style={{ width: 80 }}
                            src={
                              typeof selectedImage4 !== "object" && typeof values.aboutus_image4 === "string"
                                ? values.aboutus_image4
                                : URL.createObjectURL(selectedImage4)
                            }
                            // alt={`Selected Image ${i + 1}`}
                            loading="lazy"
                          // onError={(e) => {
                          //   console.error(`Error loading image ${i + 1}:`, e);
                          // }}
                          />
                        </a>
                      </Typography>
                    </Grid>
                  )}
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <Grid
                item
                md={12}
                xs={12}
                sm={12}
                sx={{
                  width: "100%",
                  maxHeight: "200px",
                  overflowX: "scroll",
                  scrollBehavior: "smooth",
                  marginTop: "25px",
                }}
              >
                {showImages()}
              </Grid>
              <Grid item md={4} xs={12} sm={12}>
                <input
                  type="file"
                  multiple
                  accept="image/*, video/*"
                  id="service_images"
                  name="service_images"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
                <label htmlFor="service_images">
                  <Button
                    component="span"
                    variant="contained"
                    color="inherit"
                    sx={{ marginTop: "1rem" }}
                  >
                    <ImageIcon sx={{ marginRight: "0.5rem" }} />
                    Upload Media
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Grid>
          {!!getPermissions(permissions.trusted_by_About_us, "is_edit") ? (
            <Button
              type="submit"
              disabled={updateLoading}
              // disabled={!isAnyFieldNotEmpty}
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                border: "0",
                ":hover": { bgcolor: "#1976d2", border: "0" },
                backgroundColor: "#153e80",
                marginLeft: {
                  xs: "0px",
                  sm: "15px",
                  md: "15px",
                  lg: "15px",
                },
                color: "#fff",
                textTransform: "none",
                minWidth: "100px",
              }}
            >
              Submit
            </Button>
          ) : null}
        </form>
      </Box>
    </>
  );
};

export default AdminAboutUs;
