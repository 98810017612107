import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { compareDates } from "../../../utils/dateUtility";
import { removeHtmlTags } from "../htmlToString/HtmlToString";

const fixDescription = (data) => {
  if (data?.length < 50) {
    return data;
  }
  return data?.slice(0, 50) + "...";
};

const EventCard = ({ item, variants }) => {
  console.log('Event Card', item);
  return (
    <>
      <motion.div alt
        variants={variants}
        className="store card border-0 rounded-0"
        style={{ border: "1px solid" }}
      >
        {/* {item.is_active &&
        item?.approval_status !== "rejected" &&
        item?.approval_status !== "pending" &&
        item?.schedule_status !== "cancelled" ? ( */}
          <section
            className="eventCard"
            // style={{
            //   border: "1px solid #d1d1d1",
            //   width: "100%",
            //   borderRadius: "10px",
            //   marginTop: "8px",
            //   padding: '3px'
            // }}
          >
            {/*IMAGES*/}
              <div style={{borderRadius: '10px',}}>
                {item?.event_images && item.event_images.length > 0 && (
                  <Link to={`/event/${item._id}`}>
                    <div className="serviceImg" style={{marginTop: '13px', borderRadius:'10px',}}>
                      <img src={item.event_images[0]} alt={`Event: ${item.event_name}`} className="allImg" />
                    </div>
                  </Link>
                )}
              </div>

            {/*DESCRIPTION*/}
            <div style={{ margin: "8px 13px" }}>
              {/*Title*/}
              <Link
                to={`/event/${item._id}`}
                className="card-title h5 text-dark d-inline-block mb-2"
                tabIndex={0}
                style={{ width: "100%" }}
              >
                <span
                  style={{
                    display: "block",
                    fontSize: "20px",
                    fontWeight: "700",
                    width: "90%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item?.event_name &&
                    (item?.event_name.length > 40
                      ? item?.event_name?.substring(0, 40) + "..."
                      : item?.event_name)}
                </span>
              </Link>

              {/*Description*/}
              <div className="media" style={{ marginBottom: "8px" }}>
                <div
                  className="media-body lh-14 font-size-md card-text-hide card-text-hide"
                  style={{ color: "#707070" }}
                >
                  {item.event_description && removeHtmlTags(item.event_description)}
                </div>
              </div>

              {/*Price / Chapter*/}
              <ul className="list-inline store-meta font-size-lg d-flex align-items-center flex-wrap">
                <li className="list-inline-item">
                  <span className="font-weight-semibold">₹{item.price}</span>
                </li>
                <li className="list-inline-item separate" />
                <li className="list-inline-item">
                  <a className="link-hover-secondary-primary" tabIndex={0}>
                    <span className="font-weight-semibold">
                      {item.chapter_name}
                    </span>
                  </a>
                </li>
              </ul>

              {/*Location*/}
              <div style={{ marginBottom: "8px", marginTop: "-5px" }}>
                <span className="d-inline-block mr-1">
                  <i className="fal fa-map-marker-alt"></i>
                </span>
                <a
                  className="text-secondary font-size-lg text-decoration-none link-hover-secondary-blue"
                  tabIndex={0}
                >
                  {item.city_name}, {item.country_name},{" "}
                </a>
              </div>

              {/*Book*/}
              <div>
                {item?.end_time &&
                compareDates(
                  new Date(item?.end_time).toUTCString(),
                  new Date().toUTCString()
                ) ? (
                  item?.event_registered ? (
                    <>
                      <Box padding={{ xs: 0, sm: 0 }}>
                        <button
                          disabled
                          className="disableBtn"
                          type="button"
                          style={{
                            cursor: 'not-allowed',
                            width: "100%",
                          }}
                        >
                          Booked
                        </button>
                      </Box>
                    </>
                  ) : (
                    <Link
                      to={`/event/${item._id}`}
                      // className="card-title h5 text-dark d-inline-block mb-2"
                      tabIndex={0}
                    >
                      <button
                        className="btnAll font-size-lg"
                        type="button"
                        style={{
                          border: "0px",
                          padding: "4px 0",
                          borderRadius: "5px",
                          textTransform: "mome",
                          width: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        Book Now
                      </button>
                    </Link>
                  )
                ) : item?.schedule_status !== "cancelled" ? (
                  <button
                    disabled
                    className="disableBtn"
                    type="button"
                    style={{border: "0px",
                      padding: "4px 0",
                      borderRadius: "5px",
                      textTransform: "mome",
                      width: "100%",
                      marginBottom: "10px",
                      cursor: 'not-allowed'
                    }}
                  >
                    Booking closed
                  </button>
                ) : (
                  <button
                    className="disableBtn"
                    disabled
                    type="button"
                    style={{
                      width: "100%",
                      cursor: 'not-allowed',
                    }}
                  >
                    Event cancelled
                  </button>
                )}
              </div>
            </div>
          </section>
        {/* // ) : null} */}
      </motion.div>
    </>
  );
};

export default EventCard;
