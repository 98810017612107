import React from "react";
import {
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Box,
  Autocomplete,
  // Input,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Updated the import of CloseIcon
import { makeStyles } from "@material-ui/core/styles";
import { TextareaAutosize } from "@mui/base/TextareaAutosize"; // Import TextareaAutosize
import Input from "../../common/Input";

const useStyles = makeStyles((theme) => ({
  customCircularProgress: {
    width: "25px !important",
    height: "25px !important",
    color: "#fff !important",
  },
}));

const styles = {
  root: {
    borderBottom: "1px solid black",
  },
  icon: {
    color: "black",
    fill: "black",
  },
};

const FormDialog = ({
  dialogTitle,
  dialogText,
  fields,
  submitText,
  open,
  handleClose,
  handleSubmit,
  onChange,
  loading,
  disableButton,
  general,
  fullWidth = false,
}) => {
  const classes = styles;
  const useClasses = useStyles();

  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      onClose={false}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <div
        className="mui-dialog-box-popup"
        style={{ backgroundColor: "#fff", color: "#000" }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ color: "#000 !important" }}>
            {dialogTitle}
          </Typography>

          <IconButton
            sx={{
              color: "black",
              "&:hover": {
                backgroundColor: "white",
                color: "#153e80",
              },
              "&:focus": {
                backgroundColor: "white",
                color: "#153e80",
                border: "none",
              },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ minWidth: "400px" }}>
          <DialogContentText>{dialogText}</DialogContentText>
          {fields.map((field, index) => (
            <div key={index}>
              {field.type === "select" ? (
                <FormControl
                  fullWidth
                  sx={{ color: "#000", marginTop: "10px" }}
                >
                  <InputLabel
                    labelId="test-select-label"
                    sx={{ fontSize: "0.9rem" }}
                  >
                    {field.label}
                  </InputLabel>
                  <Select
                    inputProps={{
                      classes: {
                        root: classes.border,
                        icon: classes.icon,
                      },
                    }}
                    labelId="test-select-label"
                    value={field.value}
                    sx={{
                      input: { color: "#000" },
                      svg: { color: "#000" },
                      color: "#000",
                      backgroundColor: "#fff",
                    }}
                    label={field.label}
                    name={field.name}
                    variant="outlined"
                    onChange={(e) => onChange(e, field.name)}
                    error={Boolean(field.errors)}
                  >
                    {field?.options?.map((option, optionIndex) => (
                      <MenuItem key={optionIndex} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {field.errors && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ fontSize: "12px" }}
                    >
                      <div className="err-msg-font">{field.errors}</div>
                    </Typography>
                  )}
                </FormControl>
              ) : field.type === "autocomplete" ? (
                <FormControl
                  fullWidth
                  sx={{ color: "#000 !important", marginTop: "1rem" }}
                >
                  <Autocomplete
                    inputProps={{
                      classes: {
                        root: classes.border,
                        icon: classes.icon,
                      },
                    }}
                    options={field?.autoCompleteData || []}
                    id={field?.label}
                    onChange={(event, value) =>
                      onChange(
                        (event = {
                          target: { name: field?.name, value: value },
                        }),
                        field?.name
                      )
                    }
                    value={field?.value || null}
                    sx={{
                      "&.css-1jr8uhn-MuiAutocomplete-root:hover .MuiAutocomplete-clearIndicator":
                      {
                        color: "#000 !important",
                      },
                    }}
                    autoHighlight
                    getOptionLabel={(option) => field?.labelAccess ? option[field?.labelAccess] : option?.country_name}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {field?.labelAccess ? option[field?.labelAccess] : option?.country_name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        sx={{ color: "#000" }}
                        {...params}
                        label={field.label}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        variant="outlined"
                        error={Boolean(field.errors)}
                      />
                    )}
                  />
                  {field.errors && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ fontSize: "12px" }}
                    >
                      <div className="err-msg-font">{field.errors}</div>
                    </Typography>
                  )}
                </FormControl>
              ) : field.type === "textarea" ? (
                <>
                  <Input
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    fullWidth
                    variant="outlined"
                    value={field.value}
                    name={field.name}
                    onChange={(e) => onChange(e, field.name)}
                    inputProps={{ maxLength: 200 }} // Adjust the max length as needed
                    maxRows={field.rows}
                    minRows={field.rows}
                    maxLength={field.length}
                    error={Boolean(field.errors)}
                  />
                  {field.errors && (
                    <div className="err-msg-font">{field.errors}</div>
                  )}
                </>
              ) : (
                <>
                  <Typography>{field.names}</Typography>
                  {field.type === "file" && (
                    <>
                      {field.errors ? null : field.src && (
                        <img
                          src={
                            typeof field.src !== "object"
                              ? field.src
                              : URL.createObjectURL(field.src)}
                          alt="wallpaper"
                          style={{ marginTop: "8px" }}
                          width={50}
                          height={50}
                        />
                      )}
                      <TextField
                        sx={{ input: { color: "#000" } }}
                        autoFocus={index === 0}
                        margin="dense"
                        id={field.id}
                        label={field.label}
                        type="file"
                        fullWidth
                        variant="outlined"
                        name={field.name}
                        onChange={(e) => onChange(e, field.name)}
                        error={Boolean(field.errors)}
                        inputProps={{
                          multiple: field.multiple,
                        }}
                        helperText={Boolean(field.errors) && field.errors}
                      />
                    </>
                  )}
                  {field.type !== "file" && (
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      autoFocus={index === 0}
                      disabled={field.disable}
                      multiline={field.type !== "file"}
                      rows={field.type !== "file" && field.rows}
                      margin="dense"
                      id={field.id}
                      label={field.label}
                      type={field.type || "text"}
                      fullWidth
                      variant="outlined"
                      value={field.value}
                      name={field.name}
                      maxLength={field.length}
                      onChange={(e) => {
                        const { value, name } = e.target;
                        if (field.name === "postalcode") {
                          if (/^\d*$/.test(value)) {
                            onChange(e, name);
                          }
                        } else {
                          onChange(e, name);
                        }
                      }}
                      error={Boolean(field.errors)}
                      helperText={
                        <div style={{ marginLeft: "-12px", fontSize: "12px" }}>
                          {field.errors || field.helperText}
                        </div>
                      }
                      inputProps={{
                        maxLength: field.maxLength || undefined,
                      }}
                    />
                  )}
                </>
              )}
            </div>

          ))}
          {general && (<div className="err-msg-font">{general}</div>)}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", display: "flex" }}>
          <Button
            onClick={handleSubmit}
            disabled={loading || disableButton}
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              border: "0",
              ":hover": { bgcolor: "#1976d2", border: "0" },
              backgroundColor: "#153e80",
              marginLeft: {
                xs: "0px",
                sm: "15px",
                md: "15px",
                lg: "15px",
              },
              color: "#fff",
              textTransform: "none",
              minWidth: "100px",
            }}
          >
            {loading ? (
              <CircularProgress className={useClasses.customCircularProgress} />
            ) : (
              submitText
            )}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default FormDialog;
