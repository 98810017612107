import React, { useEffect } from "react";
import { Typography, Backdrop, CircularProgress } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 9999,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  loader: {
    marginBottom: 16,
  },
  message: {
    color: "#fff",
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginBottom: 8,
    textAlign: "center",
  },
  subMessage: {
    color: "#fff",
    fontSize: "1rem",
    textAlign: "center",
  },
}));

const RegistrationLoader = () => {
  const classes = useStyles();

  useEffect(() => {
    // Disable body scrolling
    document.body.style.overflow = "hidden";

    // Re-enable body scrolling when component unmounts
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" className={classes.loader} />
      <Typography variant="h6" className={classes.message}>
        Your purchase is in progress.
      </Typography>
      <Typography variant="subtitle1" className={classes.subMessage}>
        Please do not refresh the page.
      </Typography>
    </Backdrop>
  );
};

export default RegistrationLoader;
