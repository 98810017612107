import { combineReducers } from "redux";

import AuthReducer from "./AuthReducer";
import SubscriptionReducer from "./SubscriptionReducer";
import UserManageReducer from "./UserManegeReducer";
import ChapterReducer from "./ChapterReducer";
import CountryReducer from "./CountryReducer";
import StateReducer from "./StateReducer";
import CityReducer from "./CityReducer";
import PartnerRequestReducer from "./PartnerRequestReducer";
import PostalCodeReducer from "./PostalCodeReducer";
import MembershipReducer from "./MembershipReducer";
import EventReducer from "./EventReducer";
import RoleandPermissionReducer from "./RoleandPermissionReducer";
import BlogReducer from "./BlogReducer";
import BannerReducer from "./Bannerreducer";
import ServiceReducer from "./ServiceReducer";
import ClientReviewReducer from "./ClientReviewReducer";
import BusinessCategoryReducer from "./BusinessCategoryReducer";
import TransactionReducer from "./Transactionreducer";
import NotificationReducer from "./NotificationReducer";
import WithdrawReducer from "./WithdrawReducer";
import FAQCategoryReducer from "./FAQCategoryReducer";
import WalletReducer from "./WalletReducer";
import FaqsReducer from "./FaqsReducer";
import ReferralReducer from "./ReferralReducer";
import PagesReducer from "./PagesReducer";
import PageBannersReducer from "./PageBannersReducer";

const rootReducer = combineReducers({
  auth: AuthReducer,
  SubscriptionReducer,
  UserManageReducer,
  ChapterReducer,
  CountryReducer,
  PartnerRequestReducer,
  StateReducer,
  CityReducer,
  PostalCodeReducer,
  MembershipReducer,
  RoleandPermissionReducer,
  BlogReducer,
  EventReducer,
  BannerReducer,
  ServiceReducer,
  ClientReviewReducer,
  BusinessCategoryReducer,
  TransactionReducer,
  NotificationReducer,
  WithdrawReducer,
  WalletReducer,
  FaqsReducer,
  FAQCategoryReducer,
  ReferralReducer,
  PagesReducer,
  PageBannersReducer,
});

export default rootReducer;
