import React, { useEffect, useState } from "react";
import bannerBg from "../../assets/image/banner/about-banner.jpg";
import Slider from "react-slick";
import axios from "axios";
import { ApiURL, snackBar } from "../../../redux/common";
import Loader from "../../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import { Head } from "../commonDesign/Head";
import SearchComponent from "../../components/search/SerachComponent";

import whiteWA from '../../assets/GG_C_About/whiteWA.svg'
import intro from '../../assets/GG_C_About/innovative.png';
import welcome from '../../assets/GG_C_About/grpPpl.png';
import vision from '../../assets/GG_C_About/businessman.png';
import journey from '../../assets/GG_C_About/Journey.png';


var settings = {
  dots: false,
  infinite: true,
  arrows: false,
  // className: "hero-banner-arrow",
  autoplay: true,
  autoplaySpeed: 2000,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        arrows: false,
        slidesToShow: 1,
      },
    },
  ],
};
const About = () => {
  const [aboutDetail, setAboutDetail] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getAboutUsDetail()
  }, [])
  const getAboutUsDetail = async () => {
    setLoading(true)
    try {
      await axios.get(`${ApiURL}about-us`).then((res) => {
        if (res.data.status) {
          setLoading(false)
          setAboutDetail(res.data.data)
        } else {
          setLoading(false)
          snackBar(res.data.message, res.data.status)
        }
      })
    } catch (error) {
      setLoading(false)
      snackBar(error, false)
    }
  }

  return (
    <div>
      <div id="site-wrapper" className="site-wrapper page-about">
        <div id="wrapper-content" className="wrapper-content pt-0 pb-0">
          <Head title="About Us" />
          {loading ?
            <div className="mt-10 mb-10">
              <Loader />
            </div>
            :
            <>
               <div className="responsive">

              {/*Welcome to GG*/}
              <div className="div-2 pt-0 pb-0" style={{width: '80%', margin: '60px auto 0px'}}>
                <h3 style={{fontWeight: 'bold', fontSize: '34px', textAlign: 'center'}}>{aboutDetail && aboutDetail?.title1} - {aboutDetail && aboutDetail?.subtitle1}</h3>
                {/* <div className="div-3">
                  <div className="div-4">
                    <div className="div-5 ">
                      <div className="column">
                        <div className="div-6">
                          <img loading="lazy" src={aboutDetail && aboutDetail?.aboutus_image1} className="img-2" />
                        </div>
                      </div>
                      <div className="column-2">
                        <span className="span">
                          <div className="div-7">
                            {aboutDetail && aboutDetail?.subtitle1}
                          </div>
                          <div className="div-8">
                            {aboutDetail && aboutDetail?.title1}
                          </div>
                          <div className="div-9">
                            {aboutDetail && aboutDetail?.description1}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              {/*Three Grid Boxes*/}
              <div className="div-11 pt-0 pb-0">
                <span className="span-2">
                  <div style={{color: '#717171', margin: '0px auto', fontSize: '18px', fontWeight: '600',}}>
                    {aboutDetail && aboutDetail?.subtitle2}
                  </div>
                  <h3 style={{fontSize: '30px', fontWeight: 'bold', margin: '0 auto'}}>{aboutDetail && aboutDetail?.title2}</h3>
                  <div className="div-14">
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div className="column-3">
                        <span className="span-3">
                        <h3 style={{fontSize: '25px', fontWeight: 'bold', margin: '0 auto', textAlign: 'center', height: '50%', width: '60%'}}>{aboutDetail && aboutDetail?.subpoints1}</h3>
                          <div style={{width: '32px', textAlign: 'center', fontWeight: 'semibold', color: '#717171', margin: '0 auto', width: '70%', marginTop: '10px'}}>
                            {aboutDetail && aboutDetail?.subpoints_description1}
                          </div>
                        </span>
                      </div>
                      <div className="column-4">
                        <span className="span-3">
                          <h3 style={{fontSize: '25px', fontWeight: 'bold', margin: '0 auto', textAlign: 'center', height: '50%'}}>{aboutDetail && aboutDetail?.subpoints2}</h3>
                          <div style={{width: '32px', textAlign: 'center', fontWeight: 'semibold', color: '#717171', margin: '0 auto', width: '90%', marginTop: '10px'}}>
                            {aboutDetail && aboutDetail?.subpoints_description2}
                          </div>
                        </span>
                      </div>
                      <div className="column-5">
                        <span className="span-3">
                          <h3 style={{fontSize: '25px', fontWeight: 'bold', margin: '0 auto', textAlign: 'center', height: '50%', width:'60%'}}>{aboutDetail && aboutDetail?.subpoints3}</h3>
                          <div style={{width: '32px', textAlign: 'center', fontWeight: 'semibold', color: '#717171', margin: '0 auto', width: '90%', marginTop: '10px'}}>
                            {aboutDetail && aboutDetail?.subpoints_description3}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </span>
              </div>

              {/*Introducing GG 1*/}
              <div style={{margin: '50px auto 100px'}}>
                    <div className="div-26 wrap-reverse">
                      <div className="column-6">
                        <span className="span"style={{paddingLeft: '15px'}} >
                          {/*STATIC DATA*/}
                          <h3 style={{fontSize: '30px', fontWeight: 'bold'}}>Welcome to Global Garner Community</h3>
                          <p style={{color: '#252525', fontSize: '18px'}}>Engage in meaningful dialogues at the expo to share insights on business strategies, challenges, and successes. These discussions offer fresh perspectives, innovative ideas, and valuable feedback, helping you refine your approach and stay competitive.</p>

                          {/*DYNAMIC DATA*/}
                          {/* <div className="div-27">{aboutDetail && aboutDetail?.subtitle3}</div>
                          <div className="div-28">
                            {aboutDetail && aboutDetail?.title3}
                          </div>
                          <div className="div-29">
                            {aboutDetail && aboutDetail?.description3}
                          </div> */}
                        </span>
                      </div>
                      <div className="column-7" style={{height: '310px', width: '50%'}}>
                        <img loading="lazy" src={welcome} className="img-4" style={{borderRadius: '12px', objectFit: 'cover'}} />
                      </div>
                    </div>
              </div>

              {/*Introducing GG 2*/}
              <div style={{margin: '80px auto'}}>
                    <div className="div-26 wrap">
                      <div className="column-7" style={{height: '310px', width: '50%'}}>
                        <img loading="lazy" src={intro} className="img-4" style={{borderRadius: '12px', objectFit: 'cover'}} />
                      </div>
                      <div className="column-6">
                      <span className="span"style={{paddingLeft: '15px'}} >
                          {/*STATIC DATA*/}
                          <h3 style={{fontSize: '30px', fontWeight: 'bold'}}>{aboutDetail && aboutDetail?.title4}: {aboutDetail && aboutDetail?.subtitle4}</h3>
                          <p style={{color: '#252525', fontSize: '18px'}}>Leverage the diverse expertise of peers at the expo to address business issues. Interact with industry experts and professionals to gain insights into best practices, trends, and strategies. This knowledge sharing enriches your understanding and enhances your decision-making.</p>
                        </span>
                      </div>
                    </div>
              </div>

              {/*3 & 4 Static*/}
              <div style={{margin: '50px auto 100px'}}>
                    <div className="div-26 wrap-reverse">
                      <div className="column-6">
                        <span className="span">
                          {/*STATIC DATA*/}
                          <h3 style={{fontSize: '30px', fontWeight: 'bold'}}>Our Journey: Unveiling Opportunities, Overcoming Challenges</h3>
                          <p style={{color: '#252525', fontSize: '18px'}}>Identify and address common challenges that many businesses face by coming together with other attendees. Through structured brainstorming sessions and group discussions, you can explore practical solutions and strategies that have worked for others.</p>
                        </span>
                      </div>
                      <div className="column-7" style={{height: '310px', width: '50%'}}>
                        <img loading="lazy" src={journey} className="img-4" style={{borderRadius: '12px', objectFit: 'cover'}} />
                      </div>
                    </div>
              </div>
              <div style={{margin: '80px auto'}}>
                    <div className="div-26 wrap">
                      <div className="column-7" style={{height: '310px', width: '50%'}}>
                        <img style={{borderRadius: '12px', objectFit: 'cover'}} loading="lazy" src={vision} className="img-4" />
                      </div>
                      <div className="column-6">
                        <span className="span">
                          {/*STATIC DATA*/}
                          <h3 style={{fontSize: '30px', fontWeight: 'bold'}}>Our Vision: Bridging Gaps, Unlocking Growth</h3>
                          <p style={{color: '#252525', fontSize: '18px'}}>Use the expo to network with peers, industry leaders, and potential partners. Building relationships can unlock new opportunities, collaborations, and alliances. It’s an ideal platform to expand your circle, find mentors, and explore partnerships. Engage with others to exchange contacts and support mutual business growth.</p>
                        </span>
                      </div>
                    </div>
              </div>

               {/*Whatsapp*/}
               <div style={{width: '100%', backgroundColor: '#f1f5f7', padding: '20px 0', margin: '100px 0', borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div style={{fontSize: '32px', fontWeight: 'bold', textAlign: 'center'}}>Let's impact together business ecosytem</div>
                  <button type="button" style={{backgroundColor: "#45c253",  border: 'none', fontSize: '20px', fontWeight: '600', borderRadius: '7px', margin: '25px 0', padding: '10px 30px'}}>
                    <a style={{display: 'flex', gap:'10px', alignItems: 'center', color: '#fff', textDecoration: 'none'}} target="_blank" href="https://api.whatsapp.com/send?phone=916354917511&text=Hello%20Team%20Global%20Garner%20%2C%20I%20have%20seen%20Partner%20Program%20on%20your%20website.%20I%27m%20interested%20in%20participating%20with%20GG%20Community.%20Please%20guide%20me%20for%20the%20next%20step.%20Thank%20You.">
                      <img src={whiteWA} style={{width: '28px', height: '28px'}} /> Join via Whatsapp
                    </a>
                  </button>
               </div>
            </div>

            {/*Trusted By*/}
            <div style={{backgroundColor: '#f1f5f7', width: '100%', marginTop: '100px'}}>
            <div className="responsive" style={{display: 'flex', justifyContent: 'space-between'}}>
              <span className="span-7 col-md-2">
                <div style={{color: '#ff0312', fontWeight: '600', fontSize: "20px"}}>Trusted By</div>
                <div style={{fontSize: '26px', fontWeight: 'bold'}}>Clients</div>
              </span>
                <Slider {...settings}>
                  {aboutDetail && aboutDetail?.trusted_images.map((data) => (
                    <div>
                      <div className="div-44">
                        <img src={data} className="img-6" />
                      </div>
                    </div>
                  ))}
                </Slider>
            </div>
          </div>

              {/*Contact Us*/}
              <div className="responsive" style={{marginBottom: '100px'}}>
              <div className="div-45">
                <div className="div-46">
                  <span className="span-8">
                    <div className="div-47">
                      Are you a local service professional?
                    </div>
                    <div className="div-48">
                      Learn how GGCOMMUNITY can help you double your business
                    </div>
                  </span>
                  <span onClick={()=>navigate("/contact-us")} className="span-9">Contact Now</span>
                </div>
              </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default About;
