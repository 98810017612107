import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Grid, Typography, Button } from "@mui/material";
import { api } from "../../web/config/api";
import { ApiURL } from "../../redux/common";
import UserInfoListingModal from "./UserInfoListingModal";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  userContainer: {
    textAlign: "center",
    padding: "16px", // Replace with your desired padding value
  },
  avatar: {
    width: "56px", // Replace with your desired avatar size
    height: "56px", // Replace with your desired avatar size
    margin: "auto",
  },
  showAllButton: {
    marginTop: "8px", // Replace with your desired margin value
    borderRadius: "30px !important", // Adjust the border-radius for a rounded button
    display: "block", // Display as block to take the full width
    marginLeft: "auto", // Center the button
    marginRight: "auto", // Center the button
    color: "#F07579 !important",
    borderColor: "#F07579 !important",
    background: "white !important",
  },
});

const UserInfoListing = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const classes = useStyles();
  const userDataString = localStorage.getItem("user_data");
  const userData = JSON.parse(userDataString);

  const userHierarchyChange = async () => {
    try {
      const id = userData?.user?._id;
      const res = await api.get(`${ApiURL}user/get-hierarchy-users/${id}`);
      if (res?.status) {
        const dataFlat = res?.data.map((data) => data?.users || []).flat();
        setData(dataFlat);
      }
    } catch (error) {
      // seterror(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    userHierarchyChange();
  }, []);

  const handleClickShowAll = () => {
    setShowAll(!showAll);
  };

  const openModal = (user) => {
    setModalOpen(user);
  };

  const closeModal = (id) => {
    setModalOpen(false);
  };

  function subStr(txt, len) {
    if(!txt){
      return ''
    }
    let acceptedText =
      txt.length < len ? txt : txt.substring(0, len - 1) + "...";
    return acceptedText;
  }
  return (
    <div>
      {data.length > 0 && (
        <Grid container spacing={2} className={classes.userContainer}>
          {(showAll ? data : data.slice(0, 5)).map((user) => (
            <Grid item key={user._id} xs={4} sm={3} md={2}>
              <Avatar
                alt={user.user_name}
                src={user.profile_picture}
                className={classes.avatar}
                onClick={() => {
                  openModal(user);
                }}
              />
              <Typography variant="body2" sx={{overflowWrap: "break-word", /* Modern equivalent of word-wrap */
                          wordBreak: "break-word", /* Breaks words at arbitrary points if necessary */
                          whiteSpace: "normal"}}>{subStr(user.user_name, 15)}</Typography>
            </Grid>
          ))}
        </Grid>
      )}

      {data.length > 5 && (
        <Grid container spacing={2} className={classes.userContainer}>
          <div className="show_all_before_after">
            <Button
              variant="outlined"
              className={classes.showAllButton}
              onClick={handleClickShowAll}
            >
              {!showAll ? "Show All" : "Hide All"}
            </Button>
          </div>
        </Grid>
      )}
      {modalOpen?._id && (
        <UserInfoListingModal
          open={modalOpen}
          userid={modalOpen?._id}
          userdata={modalOpen}
          handleClose={closeModal}
        />
      )}
    </div>
  );
};

export default UserInfoListing;
