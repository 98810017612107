import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getServiceById } from "../../../redux/action/ServiceAction";
import { DisplayHTMLString } from "../../components/htmlToString/HtmlToString";
import PaymentGateway from "../../components/PaymentGateway";
import { api } from "../../config/api";
import { base_Url } from "../../utils/common";
import { snackBar } from "../../../redux/common";
import Login from "../auth/Login";
import CheckIcon from "@mui/icons-material/Check";
import ComCard from "../../../common/ComCard";
import Loader from "../../components/loader/Loader";
import RegistrationLoader from "../../components/loader/RegisterLoader";

const ServiceView = () => {
  const [open, setOpen] = React.useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [planSelected, setPlanSelected] = useState("yearly");
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [startPaymentProcess, setStartPaymentProcess] = useState(false);

  function subStr(txt, len) {
    let acceptedText =
      txt.length < len ? txt : txt.substring(0, len - 1) + "...";
    return acceptedText;
  }

  const [successModal, setSuccessModal] = useState({
    data: {},
    success: false,
    open: false,
  });
  const userData =
    localStorage.getItem("user_data") &&
    JSON.parse(localStorage.getItem("user_data"));
  const token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const { id } = useParams();
  const { getServiceByIdLoading, service } = useSelector(
    (state) => state.ServiceReducer
  );

  useEffect(() => {
    if (id) {
      dispatch(getServiceById(id));
    }
  }, [id]);

  const [activated, setActivated] = useState(false);

  const handleAfterPayment = async (res, service) => {
    setStartPaymentProcess(true);
    try {
      setActivated(true);
      setPaymentLoading(true);
      let body = {
        service_id: service._id,
        razorpay_payment_id: res.razorpay_payment_id,
      };
      await api
        .post(`${base_Url}service/purchase-service`, body)
        .then((res) => {
          if (res.status) {
            snackBar(res?.message, res?.status);
            setSuccessModal({
              success: true,
              open: true,
              data: {
                service_id: service._id,
                razorpay_payment_id: body.razorpay_payment_id,
              },
            });
            setStartPaymentProcess(false);
            setActivated(false);
            setLoading(true);
          } else {
            snackBar(res?.message, res?.status);
            setSuccessModal({
              success: false,
              open: true,
              data: {
                service_id: service._id,
                razorpay_payment_id: body.razorpay_payment_id,
              },
            });
            setActivated(false);
          }
        });
    } catch (error) {
      snackBar("Something went wrong!", false);
      setSuccessModal({
        success: false,
        open: true,
        data: {
          service_id: service._id,
          razorpay_payment_id: res.razorpay_payment_id,
        },
      });
    } finally {
      setPaymentLoading(false);
      setLoading(true);
    }
  };

  const handleStartPaymentProcess = () => {
    setStartPaymentProcess(true);
  };

  const handleClosePaymentProcess = () => {
    setStartPaymentProcess(false);
  };
  useEffect(() => {
    if (id && activated) {
      dispatch(getServiceById(id));
    }
  }, [id, activated]);

  const handleModalOpen = () => {
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    setLoginModalOpen(false);
  };
  const handlePaymentModalClose = () => {
    setSuccessModal({ success: false, open: false, data: {} });
  };
  return (
    <>
      {paymentLoading && <RegistrationLoader />}
      {!loading ? (
        <Loader />
      ) : (
        <div className="responsive">
          <div id="wrapper-content" className="wrapper-content pb-0 pt-0">
            {/*HEAD*/}
            <ul
              className="breadcrumb breadcrumb-style-03 my-2"
              style={{
                overflowWrap: "break-word",
                wordBreak: "break-word",
                whiteSpace: "normal",
              }}
            >
              <li className="breadcrumb-item">
                <Link
                  to="/"
                  style={{
                    color: "#000",
                    fontSize: "18px",
                    letterSpacing: "0.6px",
                    cursor: "pointer",
                  }}
                >
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to="/service"
                  style={{
                    color: "#000",
                    fontSize: "18px",
                    letterSpacing: "0.6px",
                    cursor: "pointer",
                  }}
                >
                  Services{" "}
                </Link>
              </li>
              <li
                className="breadcrumb-item"
                style={{
                  color: "#000",
                  fontSize: "18px",
                  letterSpacing: "0.6px",
                  cursor: "pointer",
                }}
              >
                {service[0]?.service_name}
              </li>
            </ul>

            {/*IMAGE*/}
            <div className="detailImgContainer">
              <img
                style={{ height: "100%", width: "100%"}} // 
                src={service[0]?.service_images[0]}
                alt={`Image`}
              />
            </div>

            {/*DESCRIPTION*/}
            <div className="page-title pt-5 pb-9">
              <div >
                {/*Title / Price / Button*/}
                <div className="activateBtn" style={{marginBottom: '7px'}}>
                  {/* <div className="store">
                  <div className="d-flex flex-wrap mb-3">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <h4
                            className="text-dark mr-3 mb-0"
                            style={{
                              width: "100%",
                              overflowWrap: "break-word",
                              wordBreak: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {service[0]?.service_name}
                          </h4>
                          <span
                            className="check font-weight-semibold text-green my-auto"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <svg
                              className="icon icon-check-circle"
                              style={{ marginRight: "5px" }}
                            >
                              <use xlinkHref="#icon-check-circle" />
                            </svg>
                            Verified
                          </span>
                        </div>
                        <div>
                          <h5 className="ml-1 mt-3">
                            ₹ {service[0]?.service_price}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <ul className="list-inline store-meta d-flex flex-wrap align-items-center">
                      <li className="list-inline-item">
                        <span className="text-link">{chapter_name}</span>
                      </li>
                      <li className="list-inline-item separate" />
                      <li className="list-inline-item">
                        <span>{moment(start_time).format("MMMM Do YYYY")}</span>
                      </li>
                    <li className="list-inline-item">
                      <span className="mr-1">
                  <LocationOnIcon />
                </span>
                <span>{venue}</span>
                    </li>
                    </ul>
                  </div> */}

                  {/*VERIFIED*/}
                  <div style={{width: '100%'}}>
                    <div style={{ display: "flex", gap: "15px" }}>
                      <h3>{service[0]?.service_name}</h3>
                      <span
                        className="font-weight-semibold text-green "
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <svg
                          className="icon icon-check-circle"
                          style={{ marginRight: "5px" }}
                        >
                          <use xlinkHref="#icon-check-circle" />
                        </svg>
                        Verified
                      </span>
                    </div>
                    <h4 style={{ margin: "7px 0" }}>
                      ₹ {service[0]?.service_price}
                    </h4>
                  </div>

                  {/*BUTTON*/}
                  <div>
                    <ul className="list-inline mb-0">
                      {service &&
                      Array.isArray(service[0]?.purchased_by) &&
                      service[0]?.purchased_by.includes(userData?.user?._id) ? (
                        <li
                          className="list-inline-item"
                        >
                          <a
                            className="btnAll" style={{color: '#fff'}}
                            // onClick={handleClickOpen}
                          >
                            {activated ? "Loading..." : "Activated"}
                          </a>
                        </li>
                      ) : (
                        <li className="list-inline-item">
                          {/*ACTIVATE NOW*/}
                          {token ? (
                            <PaymentGateway
                              amount={service[0]?.service_price}
                              afterPayment={(res) =>
                                handleAfterPayment(res, service[0])
                              }
                              enablePayementProcess={handleClosePaymentProcess}
                            >
                              <button
                                className="btnAll"
                                onClick={handleStartPaymentProcess}
                                disabled={startPaymentProcess}
                                type="button"
                              >
                                Activate Now
                              </button>
                            </PaymentGateway>
                          ) : (
                            <div className="card-footer border-0 mt-auto p-0">
                              <button
                                onClick={handleModalOpen}
                                className="btnAll"
                                type="button"
                              >
                                Activate Now
                              </button>
                            </div>
                          )}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                <div
                  style={{
                    height: "1px",
                    width: "100%",
                    backgroundColor: "lightgray",
                    margin: "10px auto",
                  }}
                ></div>

                <h5>Description</h5>
                <div
                  style={{
                    height: "1px",
                    width: "100%",
                    backgroundColor: "lightgray",
                    margin: "10px auto 10px",
                  }}
                ></div>

                <div
                  style={{
                    overflowWrap: "break-word",
                    wordBreak: "break-word",
                    whiteSpace: "normal",
                    width: "100%",
                    paddingLeft: "10px",
                  }}
                >
                  <ol start={1} style={{ paddingLeft: "5px" }}>
                    {service[0]?.description.length > 0
                      ? service[0]?.description.map((faq, j) => (
                          <li key={j} style={{ marginTop: "0.5em" }}>
                            <b>{faq.title}</b>
                            {faq?.points?.map((item, i) => (
                              <ul>
                                <li style={{ marginLeft: "-27px" }}>{item}</li>
                              </ul>
                            ))}
                          </li>
                        ))
                      : "-"}
                  </ol>
                </div>
              </div>
            </div>

            {/* <div className="container">
              <div className="page-container">
                <div className="row">
                  <div className={`page-content col-xl-8 mb-8 mb-xl-0`}>
                    <div className="explore-details-container">
                      <div className="mb-9">
                        
                        <div
                          className="mb-7"
                          style={{
                            overflowWrap: "break-word",
                            wordBreak: "break-word",
                            whiteSpace: "normal",
                          }}
                        >
                          <ol start={1}>
                            {service[0]?.description.length > 0
                              ? service[0]?.description.map((faq, j) => (
                                  <li key={j} style={{ marginTop: "0.5em" }}>
                                    <b>{faq.title}</b>
                                    {faq?.points?.map((item, i) => (
                                      <ul>
                                        <li style={{ marginLeft: "-27px" }}>
                                          {item}
                                        </li>
                                      </ul>
                                    ))}
                                  </li>
                                ))
                              : "-"}
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <Login open={loginModalOpen} handleClose={handleModalClose} />
          <ComCard
            success={successModal?.success}
            open={successModal?.open}
            // success={true}
            // open={true}
            handleClose={handlePaymentModalClose}
          >
            <p>
              <b>Service Id: </b>
              {successModal?.data?.service_id}
            </p>
            <p>
              <b>Razorpay Payment Id: </b>
              {successModal?.data?.razorpay_payment_id}
            </p>
          </ComCard>
        </div>
      )}
    </>
  );
};

export default ServiceView;
