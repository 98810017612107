
const initialState = {
    loading: false,
    createLoading: false,
    deleteLoading: false,
    updateLoading: false,
    citiesData: {},
    allCitiesData: [],
}

function CityReducer(state = initialState, action) {
    switch (action.type) {
        case "CITY_LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "CREATE_CITY_LOADING":
            return {
                ...state,
                createLoading: action.payload
            }
        case "DELETE_CITY_LOADING":
            return {
                ...state,
                deleteLoading: action.payload
            }
        case "UPDATE_CITY_LOADING":
            return {
                ...state,
                updateLoading: action.payload
            }
        case "DELETE_CITY_SUCCESS":
            return {
                ...state,
            }
        case "CITY_SUCCESS":
            return {
                ...state,
                citiesData: action.payload,
            }
        case "ALL_CITY_SUCCESS":
            return {
                ...state,
                allCitiesData: action.payload,
            }
        case "UPDATE_CITY_SUCCESS":
            return {
                ...state,
            }
        case "CREATE_CITY_SUCCESS":
            return {
                ...state,
            }

        default:
            return state;
    }
}

export default CityReducer;