import React from "react";
import { Link } from "react-router-dom";

const NotDataFound = () => {
  return (
    <div id="site-wrapper" class="site-wrapper page-404">
      <div id="wrapper-content" class="wrapper-content pt-0 pb-0">
        <div class="container">
          <div class="page-container text-center">
            <div class="mb-7">
              <svg class="icon icon-map-marker-crossed">
                <use href="#icon-map-marker-crossed"></use>
              </svg>
            </div>
            <div class="mb-7">
              <h3 class="mb-7">No Data Found</h3>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotDataFound;
