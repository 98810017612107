import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

export const GetAllCities = (id, is_active) => async (dispatch) => {
  try {
    dispatch({
      type: "CITY_LOADING",
      payload: true,
    });

    let url = `${ApiURL}city/city-by-stateid/${id}`;
    if (is_active) {
      url = `${ApiURL}city/city-by-stateid/${id}?is_active=${is_active}`;
    }

    const res = await axios.get(url);

    if (res.data.status === true) {
      dispatch({
        type: "CITY_LOADING",
        payload: false,
      });
      dispatch({
        type: "ALL_CITY_SUCCESS",
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: "CITY_LOADING",
      payload: false,
    });
  }
};

export const cleareAllCities = () => async (dispatch) => {
  dispatch({
    type: "ALL_CITY_SUCCESS",
    payload: [],
  });
};

export const GetCities =
  (page, city_name = "", rowsPerPage) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "CITY_LOADING",
        payload: true,
      });

      const res = await axios.get(
        `${ApiURL}city/all-city?limit=${rowsPerPage}&page=${page}&city_name=${city_name}`
      );

      if (res.data.status === true) {
        dispatch({
          type: "CITY_LOADING",
          payload: false,
        });

        const responseData =
          res.data.data || res.data.data.length > 0 ? res.data.data : [];
        dispatch({
          type: "CITY_SUCCESS",
          payload: responseData,
        });
      } else {
        dispatch({
          type: "CITY_LOADING",
          payload: false,
        });
        dispatch({
          type: "CITY_SUCCESS",
          payload: [],
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "CITY_LOADING",
        payload: false,
      });
    }
  };

export const CreateCity = (data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_CITY_LOADING",
      payload: true,
    });
    const res = await axios.post(`${ApiURL}city/create-city`, data);

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_CITY_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "CREATE_CITY_LOADING",
      payload: false,
    });
  }
};

export const UpdateCity = (id, data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_CITY_LOADING",
      payload: true,
    });
    const res = await axios.put(`${ApiURL}city/update-city/${id}`, data);

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_CITY_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      // handleClose();
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "UPDATE_CITY_LOADING",
      payload: false,
    });
  }
};

export const DeleteCity = (id, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_CITY_LOADING",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}city/delete-city/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_CITY_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "DELETE_CITY_LOADING",
      payload: false,
    });
  }
};
