import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  // Input,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
  styled,
  InputLabel,
  FormControl,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import {
  GetUserProfile,
  UpdateUserBusinessManage,
  UpdateUserManage,
  UpdateUserProfile,
} from "../redux/action/UserManageAction";
import Input from "../common/Input";
import * as Yup from "yup";
import { CloseIcon, EditIcon, Visibility } from "../assets/icons/Icons";
import FormDialog from "../components/dialog/dialog";
import { useForm } from "../common/useForms";
import DefaultProfile from "../assets/images/Profile.png";
import { Select } from "@material-ui/core";
import { api } from "../web/config/api";
import { ApiURL, snackBar } from "../redux/common";
import { GetAllCountry } from "../redux/action/CountryAction";
import { GetBusinessCategoryAll } from "../web/redux/findserachAction";
import axios from "../redux/ApiConfig";
import BusinessDocCard from "../components/businessDocCard/BusinessDocCard";
import { Link, useNavigate } from "react-router-dom";
import MuiPhoneNumber from "material-ui-phone-number";
import CopyToClipboardButton from "./CopiedLink";
import { PlayArrow } from "@mui/icons-material";
import ImageIcon from "@mui/icons-material/Image";
import SelectBox from "../common/SelectBox";
import Image1 from "../assets/images/Profile.png";
import { getPermissions } from "../common/permissions";
import ClassNameGenerator from "@mui/utils/ClassNameGenerator";
import parsePhoneNumberFromString from "libphonenumber-js";
import { base_Url } from "../web/utils/common";
import { useFormik } from "formik";

function extractPhoneCodeAndNumber(phoneNumber) {
  const numberArray = phoneNumber.split(" ");
}

const useStyles = makeStyles((theme) => ({
  image: {
    marginTop: "37px",
    marginLeft: "6px",
  },

  mainCompDetails: {
    display: "contents",
    alignItems: "center",
    justifyContent: "center",
    overflowWrap: "break-word" /* Modern equivalent of word-wrap */,
    wordBreak: "break-word" /* Breaks words at arbitrary points if necessary */,
    whiteSpace: "normal",
    "& .MuiTypography-root MuiTypography-body1 css-1ag581o-MuiTypography-root":
      {},
  },

  [theme.breakpoints.down("xs")]: {
    mainCompDetails: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

const rangeOptions = [
  { label: "Thousand", value: "thousand" },
  { label: "Lakhs", value: "lakhs" },
  { label: "Crore", value: "cr" },
];

const Profile = () => {
  const nav = useNavigate();
  const profileData = useSelector(
    (state) => state.UserManageReducer.userProfileData
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [reviewErrors, setReviewErrors] = useState(false);
  const [bankErrors, setBankErrors] = useState({});
  const [popupName, setPopupName] = useState("");
  const [editImageValue, setEditImageValue] = useState();
  const [role, setRole] = useState("");
  const [EditModal, setEditModal] = useState(false);
  const [reviewModel, setReviewModel] = useState(false);
  const [EditBusinessModal, setEditBusinessModal] = useState(false);
  const [EditBusinessDocModal, setEditBusinessDocModal] = useState(false);

  const [allBusinessCategory, setAllBusinessCategory] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectedImage1, setSelectedImage1] = useState();
  const [selectedImage2, setSelectedImage2] = useState();
  const [selectedImage3, setSelectedImage3] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedImageTouched, setSelectedImageTouched] = useState(false);
  const [imageAllowMsg, setImageAllowMsg] = useState();
  const [userReviews, setUserReviews] = useState(null);

  const token = localStorage.getItem("access_token");

  const [changePassword, setChangePassword] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  useEffect(() => {
    if (EditBusinessModal) {
      // Disable scroll
      document.body.style.overflow = "hidden";
    } else {
      // Enable scroll
      document.body.style.overflow = "unset";
    }

    // Clean up on component unmount
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [EditBusinessModal]);

  const initialValueBusiness = {
    business_name: "",
    owner_firstname: "",
    owner_lastname: "",
    business_logo: "",
    business_email: "",
    business_contact: "",
    establish_year: "",
    business_category_id: "",
    business_category_name: "",
    annual_turnover: "",
    business_website: "",
    address: "",
    business_card: "",
    is_active: "",
    business_gst: "",
    business_pan: "",
    business_type: "",
    gst_business_document: "",
    pan_business_document: "",
    business_logo: "",
    max_range_amount: "",
    min_range_amount: "",
    range_type: "",
  };

  const [valuesBusiness, setValuesBusiness] = useState(initialValueBusiness);
  const [errorValueBusiness, setErrorValueBusiness] = useState({});

  const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);
  const [defaultRoleUpdateLoading, setDefaultRoleUpdateLoading] =
    useState(false);
  const [representatives, setRepresentatives] = useState([
    {
      mobile_number: "",
      email: "",
      first_name: "",
    },
  ]);

  const [aboutUs, setAboutUs] = useState([
    {
      link: "",
      title: "",
    },
  ]);

  const [cardinfoLinks, setCardInfoLinks] = useState([
    {
      link: "",
      title: "",
    },
  ]);

  const [youtubeVideo, setYoutubeVideo] = useState([
    {
      link: "",
      title: "",
    },
  ]);

  const [expiryDate, setExpiryDate] = useState("");
  const [expiryNotification, setExpiryNotification] = useState(false);
  const [expiredFinalNotification, setExpiredFinalNotification] =
    useState(false);
  const [expiryNotificationMsg, setExpiryNotificationMsg] = useState("");
  const [loadingExpiryStatus, setLoadingExpiryStatus] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [productBy, setProductBy] = useState([
    {
      link: "",
      image: "",
    },
  ]);
  const [selectedImageError, setSelectedImageError] = useState(false);
  const [reviewValue, setReviewValue] = useState("");

  const [valuess, setValuess] = useState({
    bank_name: "",
    acc_number: "",
    ifsc_code: "",
    acc_holder_name: "",
    business_logo: "",
    youtube_link: "",
  });

  const [addedDiscritption, setAddedDiscritption] = useState([
    { title: "", "sub-points": [""] },
  ]);

  useEffect(() => {
    dispatch(GetUserProfile());
  }, []);

  useEffect(() => {
    checkMemberShipExpireStatus();
  }, [
    profileData,
    expiryNotification,
    expiredFinalNotification,
    loadingExpiryStatus,
  ]);

  const urlRegex =
    /^(https?|ftp):\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+(?:[a-zA-Z]|[0-9]|[-._~:/?#[\]@!$&'()*+,;=])*(?:[a-zA-Z]|[0-9]|[-._~:/?#[\]@!$&'()*+,;=])$/;

  const checkMemberShipExpireStatus = () => {
    if (profileData && profileData?.data?.membership_validity?.expire_at) {
      setLoadingExpiryStatus(true);
      const membershipValidity =
        profileData?.data?.membership_validity?.expire_at;
      // const expiryDateOfMembership = new Date("2024-03-04");
      const expiryDateOfMembership = new Date(membershipValidity);
      // const formattedDate = "2024-03-31"
      const formattedDate = expiryDateOfMembership.toLocaleDateString("en-IN", {
        year: "numeric",
        day: "2-digit",
        month: "2-digit",
      });
      setExpiryDate(formattedDate);

      // Set the number of days before expiry to trigger the notification
      const daysBeforeExpiry = 30;

      if (expiryDateOfMembership) {
        const currentDate = new Date();
        const daysRemaining = Math.floor(
          (expiryDateOfMembership - currentDate) / (1000 * 60 * 60 * 24)
        );
        if (daysRemaining <= daysBeforeExpiry && daysRemaining > 0) {
          // Trigger notification or take some action
          setLoadingExpiryStatus(false);
          setExpiryNotification(true);
          setExpiryNotificationMsg(
            `Your membership will expire in ${daysRemaining} days.`
          );
        } else if (daysRemaining < 0) {
          // Membership has expired
          setLoadingExpiryStatus(false);
          setExpiryNotification(false); // Reset regular notification
          setExpiredFinalNotification(true);
          setExpiryNotificationMsg(`Your membership has expired`);
        } else {
          // Membership expiration is beyond the specified daysBeforeExpiry
          setLoadingExpiryStatus(false);
          setExpiryNotification(false); // Reset regular notification
          setExpiredFinalNotification(false); // Reset expired notification
        }
      }
    }
  };

  const handleImageUploadProduct = (e, index) => {
    const file = e.target.files[0];
    setSelectedImageTouched(true);
    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "image/avif",
      "image/avchd",
      "image/flv",
      "image/f4v",
      "image/swf",
      "mp4",
      "webm",
      "avi",
      "mov",
      "mkv",
      "wmv",
      "video/mp4", // MIME type for MP4 videos
      "video/webm", // MIME type for WebM videos
      "video/avi", // MIME type for AVI videos
      "video/quicktime", // MIME type for MOV videos
      "video/x-matroska", // MIME type for MKV videos
      // Add more video types as needed
    ];

    const files = Array.from(e.target.files);
    const invalidFiles = files.filter((file) => {
      const fileType = file.type.split("/")[1];
      return !allowedTypes.includes(fileType);
    });

    if (invalidFiles.length > 0) {
      setImageAllowMsg("Please selete valid image file");
      return;
    } else {
      setImageAllowMsg("");
    }

    // Create a FileReader instance
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64Image = reader.result;

      const updatedProductBy = [...productBy];

      updatedProductBy[index].image = base64Image;

      setProductBy(updatedProductBy);
      setSelectedImageTouched(false);
    };
  };

  const addRepresentative = () => {
    setRepresentatives([
      ...representatives,
      { mobile_number: "", email: "", first_name: "" },
    ]);
  };

  const deleteRepresentative = (index) => {
    const updatedRepresentatives = [...representatives];
    updatedRepresentatives.splice(index, 1);
    setRepresentatives(updatedRepresentatives);
  };

  const addYoutubeVideo = () => {
    setYoutubeVideo([...youtubeVideo, { link: "", title: "" }]);
  };

  const deleteYoutubeVideo = (index) => {
    const updatedYoutube = [...youtubeVideo];
    updatedYoutube.splice(index, 1);
    setYoutubeVideo(updatedYoutube);
  };

  const handleInputChangeYoutubeVideo = (index, field, value) => {
    const updatedYoutube = [...youtubeVideo];
    updatedYoutube[index][field] = value;
    setYoutubeVideo(updatedYoutube);
  };

  const addProductBy = () => {
    setProductBy([...productBy, { link: "", image: "" }]);
  };

  const deleteProductBy = (index) => {
    const updatedProductBy = [...productBy];
    updatedProductBy.splice(index, 1);
    setProductBy(updatedProductBy);
  };

  const handleInputChangeProductBy = (index, field, value) => {
    const updatedProductBy = [...productBy];
    updatedProductBy[index][field] = value;
    setProductBy(updatedProductBy);
  };

  const addCardInfo = () => {
    setCardInfoLinks([...cardinfoLinks, { link: "", title: "" }]);
  };

  const deleteCardInfo = (index) => {
    const updatedCardInfo = [...cardinfoLinks];
    updatedCardInfo.splice(index, 1);
    setCardInfoLinks(updatedCardInfo);
  };

  const handleInputChangeCardInfo = (index, field, value) => {
    const updatedCardInfo = [...cardinfoLinks];
    updatedCardInfo[index][field] = value;
    setCardInfoLinks(updatedCardInfo);
  };

  const addAbouUs = () => {
    setAboutUs([...aboutUs, { link: "", title: "" }]);
  };

  const deleteAbouUs = (index) => {
    const updatedAboutus = [...aboutUs];
    updatedAboutus.splice(index, 1);
    setAboutUs(updatedAboutus);
  };

  const handleInputChangeAboutUs = (index, field, value) => {
    const updatedAboutus = [...aboutUs];
    updatedAboutus[index][field] = value;
    setAboutUs(updatedAboutus);
  };

  const handleInputChangeRep = (index, field, value) => {
    const updatedRepresentatives = [...representatives];
    updatedRepresentatives[index][field] = value;
    setRepresentatives(updatedRepresentatives);
  };

  const removeSelectedImage = (indexToRemove) => {
    let imagesArray = [];
    if (selectedImage instanceof FileList) {
      imagesArray = Array.from(selectedImage);
    } else {
      imagesArray = Array.from(selectedImage);
    }

    const remainingImg = imagesArray.filter((item, i) => {
      return i !== indexToRemove;
    });
    setSelectedImage(remainingImg);
  };

  const changeSelectedImage = (e, changeIndex) => {
    const files = e.target.files;
    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "avif",
      "avchd",
      "flv",
      "f4v",
      "swf",
      "mp4",
      "webm",
      "avi",
      "mov",
      "mkv",
      "wmv",
      "video/mp4", // MIME type for MP4 videos
      "video/webm", // MIME type for WebM videos
      "video/avi", // MIME type for AVI videos
      "video/quicktime", // MIME type for MOV videos
      "video/x-matroska", // MIME type for MKV videos
      // Add more video types as needed
    ];

    const file = files[0];
    const fileType = file.type.split("/").pop().toLowerCase();

    if (allowedTypes.includes(fileType)) {
      const updatedImages = [...selectedImage];
      updatedImages[changeIndex] = file;
      setSelectedImage(updatedImages);
    } else {
      snackBar(
        "File type not allowed. Please select a valid image file.",
        false
      );
    }
  };

  const viewImages = (selectedImage) => {
    let imagesArray = [];
    if (selectedImage instanceof FileList) {
      imagesArray = Array.from(selectedImage);
    } else {
      imagesArray = Array.from(selectedImage);
    }

    return (
      <>
        {imagesArray.map((item, i) => {
          let isImage = true;
          let fileType = "";

          const item_url = item instanceof File ? item?.name : item;
          if (typeof item_url === "string") {
            fileType = item_url.substring(item_url.lastIndexOf(".") + 1);
            isImage = fileType.match(
              /(JPEG|JPG|PNG|jpeg|jpg|png|svg|webp|avi|avif|avchd|flv|f4v|swf|mpeg-2)/i
            );
          }
          return (
            <React.Fragment key={i}>
              {" "}
              {/* Added flex: 1 to the first div to fill remaining space */}
              {isImage ? (
                <>
                  <a
                    target="_blank"
                    href={
                      typeof item === "string"
                        ? item
                        : URL.createObjectURL(item)
                    }
                  >
                    <img
                      key={i}
                      style={{ width: 80, margin: 5 }}
                      src={
                        typeof item === "string"
                          ? item
                          : URL.createObjectURL(item)
                      }
                      alt={`Selected Image ${i + 1}`}
                      loading="lazy"
                      onError={(e) => {
                        console.error(`Error loading image ${i + 1}:`, e);
                      }}
                    />
                  </a>
                </>
              ) : (
                <a
                  target="_blank"
                  href={
                    typeof item === "string" ? item : URL.createObjectURL(item)
                  }
                >
                  <PlayArrow sx={{ marginRight: "0.5rem" }} />
                  <video style={{ width: 80 }} loop muted playsInline>
                    <source
                      src={
                        typeof item === "string"
                          ? item
                          : URL.createObjectURL(item)
                      }
                      type={`video/${fileType}`}
                    />
                    Your browser does not support the video tag.
                  </video>
                </a>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const showImages = (selectedImage) => {
    let imagesArray = [];
    if (selectedImage instanceof FileList) {
      imagesArray = Array.from(selectedImage);
    } else {
      imagesArray = Array.from(selectedImage);
    }

    return (
      <>
        {imagesArray.map((item, i) => {
          let isImage = true;
          let fileType = "";

          const item_url = item instanceof File ? item?.name : item;
          if (typeof item_url === "string") {
            fileType = item_url.substring(item_url.lastIndexOf(".") + 1);
            isImage = fileType.match(
              /(JPEG|JPG|PNG|jpeg|jpg|png|svg|webp|avi|avif|avchd|flv|f4v|swf|mpeg-2)/i
            );
          }
          return (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid lightgray",
                  padding: "5px",
                }}
              >
                <div
                  style={{ display: "flex", flex: "1", alignItems: "center" }}
                >
                  {" "}
                  {/* Added flex: 1 to the first div to fill remaining space */}
                  {isImage ? (
                    <>
                      <a
                        target="_blank"
                        href={
                          typeof item === "string"
                            ? item
                            : URL.createObjectURL(item)
                        }
                      >
                        <ImageIcon sx={{ marginRight: "0.5rem" }} />
                        <img
                          key={i}
                          style={{ width: 80 }}
                          src={
                            typeof item === "string"
                              ? item
                              : URL.createObjectURL(item)
                          }
                          alt={`Selected Image ${i + 1}`}
                          loading="lazy"
                          onError={(e) => {
                            console.error(`Error loading image ${i + 1}:`, e);
                          }}
                        />
                      </a>
                    </>
                  ) : (
                    <a
                      target="_blank"
                      href={
                        typeof item === "string"
                          ? item
                          : URL.createObjectURL(item)
                      }
                    >
                      <PlayArrow sx={{ marginRight: "0.5rem" }} />
                      <video style={{ width: 80 }} loop muted playsInline>
                        <source
                          src={
                            typeof item === "string"
                              ? item
                              : URL.createObjectURL(item)
                          }
                          type={`video/${fileType}`}
                        />
                        Your browser does not support the video tag.
                      </video>
                    </a>
                  )}
                </div>
                <div style={{ marginLeft: "auto", marginTop: "-0.5em" }}>
                  {" "}
                  {/* Using marginLeft: "auto" to push the button to the end */}
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => removeSelectedImage(i)}
                  >
                    X
                  </Button>
                  <br />
                </div>
                <div style={{ marginLeft: "auto", paddingLeft: "5px" }}>
                  {" "}
                  {/* Using marginLeft: "auto" to push the button to the end */}
                  <input
                    type="file"
                    accept="image/*, video/*"
                    id={`change_images_${i}`}
                    name={`change_images_${i}`}
                    style={{ display: "none" }}
                    onChange={(e) => changeSelectedImage(e, i)}
                  />
                  <label htmlFor={`change_images_${i}`}>
                    <Button component="span" color="success" variant="outlined">
                      Change
                    </Button>
                  </label>
                </div>
              </div>
            </>
          );
        })}
      </>
    );
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImageError(false);

    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "image/avif",
      "image/avchd",
      "image/flv",
      "image/f4v",
      "image/swf",
      "mp4",
      "webm",
      "avi",
      "mov",
      "mkv",
      "wmv",
      "video/mp4", // MIME type for MP4 videos
      "video/webm", // MIME type for WebM videos
      "video/avi", // MIME type for AVI videos
      "video/quicktime", // MIME type for MOV videos
      "video/x-matroska", // MIME type for MKV videos
      // Add more video types as needed
    ];

    const invalidFiles = files.filter((file) => {
      const fileType = file.type.split("/")[1];
      return !allowedTypes.includes(fileType);
    });

    if (invalidFiles.length > 0) {
      setImageAllowMsg(
        `Selected ${
          invalidFiles.length === 1
            ? `${invalidFiles.length} file is`
            : `${invalidFiles.length} files are`
        } not image type, Please selete only image type file`
      );
      return;
    }

    if (selectedImage && selectedImage.length) {
      const totalImages = selectedImage.length + files.length;
      if (totalImages > 10) {
        const remainingSlots = 10 - selectedImage.length;
        const filesToAdd = files.slice(0, remainingSlots);
        setSelectedImage([...selectedImage, ...filesToAdd]);
        setImageAllowMsg(
          `You can only upload a maximum of 10 images. ${
            files.length - remainingSlots
          } image(s) were not added.`
        );
      } else {
        setSelectedImage([...selectedImage, ...files]);
        setImageAllowMsg("");
      }
    } else {
      if (files.length > 10) {
        setSelectedImage(files.slice(0, 10));
        setImageAllowMsg(
          `You can only upload a maximum of 10 images. ${
            files.length - 10
          } image(s) were not added.`
        );
      } else {
        setSelectedImage(files);
        setImageAllowMsg("");
      }
    }
    e.target.value = "";
  };
  const [IsValidGSTDocument, setisValidGSTDocument] = useState({
    isExist: false,
    msg: "",
  });
  const handleImageUpload1 = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "pdf",
      "docx",
      "docm",
      "doc",
      "image/avif",
      "image/avchd",
      "image/flv",
      "image/f4v",
      "image/swf",
    ];
    const files = Array.from(e.target.files);
    const invalidFiles = files.filter((file) => {
      const fileType = file.type.split("/")[1];
      return !allowedTypes.includes(fileType);
    });
    if (invalidFiles.length > 0) {
      setisValidGSTDocument({
        ...IsValidGSTDocument,
        msg: "Please selete valid image/document file",
        isExist: false,
      });
      return;
    } else {
      setisValidGSTDocument({ ...IsValidGSTDocument, msg: "", isExist: true });
    }
    setSelectedImage1(file);
  };
  const [selectedImageError1, setSelectedImageError1] = useState("");

  const handleImageUpload3 = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    const allowedTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/svg",
      "image/webp",
      "image/avif",
      "image/avchd",
      "image/f4v",
      "image/swf",
    ];
    if (file && allowedTypes.includes(file.type)) {
      setSelectedImage3(file);
      setValuesBusiness({ ...valuesBusiness, [name]: file });
      setSelectedImageError1("");
    } else {
      setSelectedImageError1("Please select valid image file");
    }
    e.target.value = "";
  };

  const [IsValidPANDocument, setisValidPANDocument] = useState({
    isExist: false,
    msg: "",
  });
  const handleImageUpload2 = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "pdf",
      "docx",
      "docm",
      "doc",
      "image/avif",
      "image/avchd",
      "image/flv",
      "image/f4v",
      "image/swf",
    ];
    const files = Array.from(e.target.files);
    const invalidFiles = files.filter((file) => {
      const fileType = file.type.split("/")[1];
      return !allowedTypes.includes(fileType);
    });
    if (invalidFiles.length > 0) {
      setisValidPANDocument({
        ...IsValidPANDocument,
        msg: "Please selete valid image/document file",
        isExist: false,
      });
      return;
    } else {
      setisValidPANDocument({ ...IsValidPANDocument, msg: "", isExist: true });
    }
    setSelectedImage2(file);
  };

  const handleBankModal = () => {
    setEditModal(true);
    setBankErrors({});
  };

  const handleBankCloseModal = () => {
    setEditModal(false);
  };
  const handleReviewModal = () => {
    setReviewModel(true);
    setReviewErrors(false);
  };

  const handleReviewCloseModal = () => {
    setReviewModel(false);
  };

  const handleBusinessModal = () => {
    setEditBusinessModal(true);
  };

  const handleChangePasswordModal = () => {
    setChangePassword(true);
  };

  const handleChangePasswordModalClose = () => {
    setChangePassword(false);
    formik.resetForm();
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = Yup.object({
    old_password: Yup.string()
      .required("Please enter a old password")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=[\]{}|\\:;"'<>,.?/~`])(?!.*\s)/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and no spaces allowed."
      ),
    password: Yup.string()
      .required("Please enter a password")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=[\]{}|\\:;"'<>,.?/~`])(?!.*\s)/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and no spaces allowed."
      ),
    confirm_password: Yup.string()
      .required("Please enter confirm password")
      .min(8, "Password must be at least 8 characters")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setChangePasswordLoading(true);
      const body = {
        old_password: values.old_password,
        password: values.password,
        confirm_password: values.confirm_password,
      };

      try {
        const response = await axios.put(
          `${base_Url}user/reset-password`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.data?.message === "Please provide valid old password!") {
          setChangePasswordLoading(false);
          snackBar(response?.data?.message, false);
        } else {
          setChangePasswordLoading(false);
          snackBar(response?.data?.message, response?.data?.status);
          setChangePassword(false);
          resetForm();
          const keysToRemove = ["access_token", "user_data", "uuid"];
          keysToRemove.forEach((key) => {
            localStorage.removeItem(key);
          });
          window.location.href = "/";
        }
      } catch (error) {
        setChangePasswordLoading(false);
        snackBar("Please provide valid password!");
      } finally {
        setChangePasswordLoading(false);
      }
    },
  });

  const handleBusinessCloseModal = () => {
    setEditBusinessModal(false);
  };

  const handleBusinessDocModal = () => {
    setEditBusinessDocModal(true);
    if (profileData?.data?.business_documents) {
      if (profileData?.data?.business_documents[0]) {
        setSelectedImage1(
          profileData?.data?.business_documents[0]?.gst_business_document
        );
        setSelectedImage2(
          profileData?.data?.business_documents[0]?.pan_business_document
        );
      }
    } else {
      setSelectedImage1();
      setSelectedImage2();
    }
  };

  const handleBusinessDocCloseModal = () => {
    setEditBusinessDocModal(false);
    setisValidPANDocument({ ...IsValidPANDocument, msg: "", isExist: false });
    setisValidGSTDocument({ ...IsValidGSTDocument, msg: "", isExist: false });
  };

  useEffect(() => {
    if (profileData?.data?.bank_details) {
      setValuess({
        ...valuess,
        bank_name: profileData?.data?.bank_details?.bank_name,
        acc_number: profileData?.data?.bank_details?.acc_number,
        ifsc_code: profileData?.data?.bank_details?.ifsc_code,
        acc_holder_name: profileData?.data?.bank_details?.acc_holder_name,
        membership_validity: profileData?.data?.membership_validity,
        business_logo: profileData?.data?.business_logo,
      });
    }
  }, [profileData?.data?.bank_details]);

  useEffect(() => {
    if (profileData?.data?.business) {
      setValuesBusiness({
        ...valuesBusiness,

        business_name: profileData?.data?.business?.business_name || "",
        owner_firstname: profileData?.data?.business?.owner_firstname || "",
        owner_lastname: profileData?.data?.business?.owner_lastname || "",
        business_logo: profileData?.data?.business?.business_logo || "",
        business_email: profileData?.data?.business?.business_email || "",
        business_contact: profileData?.data?.business?.business_contact || "",
        establish_year: profileData?.data?.business?.establish_year || "",
        business_category_name: profileData?.data?.business_category || "",
        business_category_id:
          profileData?.data?.business?.business_category_id || "",
        annual_turnover: profileData?.data?.business?.annual_turnover || "",
        business_website: profileData?.data?.business?.business_website || "",
        address: profileData?.data?.business?.address || "",
        phone_code: profileData?.data?.business?.phone_code || "91",
        // city_id: profileData?.data?.business?.city_id,
        // state_id: profileData?.data?.business?.state_id,
        // postalcode_id: profileData?.data?.business?.postalcode_id,
        // country_id: profileData?.data?.business?.country_id,
        // representatives: profileData?.data?.business?.representatives,
        business_card: profileData?.data?.business?.business_card || "",
        is_active: profileData?.data?.business?.is_active,
        business_gst: profileData?.data?.business?.business_gst || "",
        business_pan: profileData?.data?.business?.business_pan || "",
        business_type: profileData?.data?.business?.business_type || "",

        max_range_amount: profileData?.data?.business?.max_range_amount || "",
        min_range_amount: profileData?.data?.business?.min_range_amount || "",
        range_type: profileData?.data?.business?.range_type || "",
        gst_business_document:
          profileData?.data?.business?.gst_business_document,
        pan_business_document:
          profileData?.data?.business?.pan_business_document,
      });

      setRepresentatives(profileData?.data?.business?.representatives || []);
      setAboutUs(profileData?.data?.business?.about_us || []);
      setCardInfoLinks(profileData?.data?.business?.card_info_links || []);
      setYoutubeVideo(profileData?.data?.business?.youtube_videos || []);
      setProductBy(profileData?.data?.business?.product_services || []);

      setSelectedImage(profileData?.data?.business?.image_gallery || "");
    }
  }, [profileData?.data?.business]);

  const userDataString = localStorage.getItem("user_data");
  const userData = JSON.parse(userDataString);

  const userRole =
    userData?.admin?.user_role?.role || userData?.user?.user_role?.role;

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    profile_picture: "",
    address: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let isImage = true;
    let fileTypeArray =
      fieldValues.profile_picture && fieldValues.profile_picture.split(".");
    let fileType = fileTypeArray && fileTypeArray[fileTypeArray.length - 1];
    if (fileTypeArray && fileTypeArray.length >= 1) {
      fileType = fileTypeArray && fileTypeArray[fileTypeArray.length - 1];
    }

    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "image/avif",
      "image/avchd",
      "image/f4v",
      "image/swf",
    ];
    const nameRegex = /^[a-zA-Z]+([a-zA-Z\s]*[a-zA-Z]+)?$/;

    if ("first_name" in fieldValues) {
      if (
        fieldValues.first_name === "" ||
        fieldValues.first_name === undefined ||
        fieldValues.first_name === null ||
        fieldValues.first_name?.trim() === ""
      ) {
        temp.first_name = "Please enter first name";
      } else if (fieldValues.first_name?.length < 3) {
        temp.first_name = "Please enter first name at least 3 character";
      } else if (!nameRegex.test(fieldValues.first_name)) {
        temp.first_name = "First name should contain only alphabets and spaces";
      } else {
        temp.first_name = "";
      }
    } else if ("last_name" in fieldValues) {
      if (
        fieldValues.last_name === "" ||
        fieldValues.last_name === undefined ||
        fieldValues.last_name === null ||
        fieldValues.last_name?.trim() === ""
      ) {
        temp.last_name = "Please enter last name";
      } else if (fieldValues.last_name?.length < 3) {
        temp.last_name = "Please enter last name at least 3 character";
      } else if (!nameRegex.test(fieldValues.last_name)) {
        temp.last_name = "Last name should contain only alphabets and spaces";
      } else {
        temp.last_name = "";
      }
    } else if ("address" in fieldValues) {
      if (
        fieldValues.address === "" ||
        fieldValues.address === undefined ||
        fieldValues.address === null ||
        fieldValues.address?.trim() === ""
      ) {
        temp.address = "Please enter address";
      } else if (fieldValues.address?.length < 3) {
        temp.address = "Please enter address at least 3 character";
      } else {
        temp.address = "";
      }
    } else if ("email" in fieldValues) {
      if (
        fieldValues.email === "" ||
        fieldValues.email === undefined ||
        fieldValues.email === null ||
        fieldValues.email?.trim() === ""
      ) {
        temp.email = "Please enter email";
      } else if (fieldValues.email?.length < 3) {
        temp.email = "Please enter email at least 3 character";
      } else if (
        !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(fieldValues.email)
      ) {
        temp.email = "Please enter valid email";
      } else {
        temp.email = "";
      }
    } else if ("mobile_number" in fieldValues) {
      if (
        fieldValues.mobile_number === "" ||
        fieldValues.mobile_number === undefined ||
        fieldValues.mobile_number === null ||
        fieldValues.mobile_number?.trim() === ""
      ) {
        temp.mobile_number = "Please enter mobile number";
      } else if (!/^\d{10}$/i.test(fieldValues.mobile_number)) {
        temp.mobile_number = "Please enter valid mobile number";
      } else {
        temp.mobile_number = "";
      }
    } else if ("profile_picture" in fieldValues) {
      if (
        fieldValues.profile_picture === "" ||
        fieldValues.profile_picture === undefined ||
        fieldValues.profile_picture === null ||
        fieldValues.profile_picture?.trim() === ""
      ) {
        temp.profile_picture = "Please enter profile picture";
      } else if (fileType && !allowedTypes.includes(fileType)) {
        temp.profile_picture = "Please select valid image file";
      } else {
        temp.profile_picture = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setFieldValues,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  const fields = [
    {
      id: "first_name",
      label: "First Name",
      type: "text",
      value: values.first_name,
      name: "first_name",
      errors: errors.first_name,
      maxLength: 20,
    },
    {
      id: "last_name",
      label: "Last Name",
      type: "text",
      value: values.last_name,
      name: "last_name",
      errors: errors.last_name,
      maxLength: 20,
    },

    {
      id: "address",
      label: "Address",
      type: "text",
      value: values.address,
      name: "address",
      errors: errors.address,
      maxLength: 200,
      rows: 6,
    },

    {
      id: "email",
      label: "Email",
      type: "text",
      value: values.email,
      name: "email",
      errors: errors.email,
      maxLength: 50,
      disable: values.email ? true : false,
    },
    {
      id: "mobile_number",
      label: "Mobile number",
      type: "number",
      value: values.mobile_number,
      name: "mobile_number",
      errors: errors.mobile_number,
      maxLength: 10,
      disable: values.mobile_number ? true : false,
    },

    {
      id: "profile_picture",
      type: "file",
      labelAccess: "profile_picture",
      name: "profile_picture",
      errors: errors.profile_picture,
      src: values.file ? values.file : editImageValue,
    },
  ];

  function subStr(txt, len) {
    let acceptedText =
      txt.length < len ? txt : txt.substring(0, len - 1) + "...";
    return acceptedText;
  }

  const handleClose = () => {
    setOpen(false);
    // resetForm();
  };

  const handleEditClick = async (data) => {
    setErrors({});
    setPopupName("Edit Profile");
    if (data?.profile_picture) {
      setEditImageValue(data?.profile_picture);
    }
    setValues({
      ...values,
      first_name: data?.first_name || "",
      last_name: data?.last_name || "",
      address: data?.address || "",
      email: data?.email || "",
      mobile_number: data?.mobile_number || "",
    });
    setErrors({});
    setOpen(true);
  };

  //profile update
  const handleSubmit = async (e) => {
    // e.preventDefault();
    if (validate()) {
      setLoading(true);
      const formData = new FormData();

      formData.append(
        "profile_picture",
        values.file ? values.file : profileData?.data?.profile_picture
      );

      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("address", values.address);

      formData.append("email", values.email);
      const res = await dispatch(UpdateUserProfile(formData, handleClose));

      if (res?.data?.status) {
        setLoading(false);
      } else {
        setLoading(false);
      }
      dispatch(GetUserProfile());
    }
  };

  const user_roles_options = profileData?.data?.user_roles.map((data) => ({
    value: data?._id,
    label: data?.role,
  }));

  const { allCountryData } = useSelector((state) => state.CountryReducer);
  const { allStateData } = useSelector((state) => state.StateReducer);
  const { allCitiesData } = useSelector((state) => state.CityReducer);
  const { allPostalData } = useSelector((state) => state.PostalCodeReducer);

  useEffect(() => {
    dispatch(GetAllCountry(true));
  }, []);

  const defaultProps = {
    options: allBusinessCategory.map((item) => ({
      value: item._id,
      label: item?.category_name,
    })),

    getOptionLabel: (option) => option.label,
  };

  const getAllBusinessCategory = async () => {
    const res = await GetBusinessCategoryAll();
    if (res) {
      setAllBusinessCategory(res?.data?.categoryData);
    }
  };

  useEffect(() => {
    getAllBusinessCategory();
  }, []);

  const handleUpdateDefault = async () => {
    try {
      setDefaultRoleUpdateLoading(true);
      const res = await api.put(`${ApiURL}user/set-default-role`, {
        role_id: role,
      });
      if (res?.status) {
        snackBar(res?.message, res?.status);
        if (userData && userData.user) {
          userData.user.role_id = role;
          if (res && res.data && res.data[0]) {
            userData.user.role = res.data[0].role;
          }
          if (profileData && profileData.data && profileData.data.user_roles) {
            userData.user.user_role = profileData.data.user_roles.find(
              (data) => data?._id === role
            );
          }
        }
        localStorage.setItem("user_data", JSON.stringify(userData));
        localStorage.setItem("userData", JSON.stringify(userData));
        window.location.href = "/admin/dashboard";
      } else {
        snackBar(res?.message, res?.status);
      }
    } catch {
      snackBar("Something went wrong!", false);
    } finally {
      setDefaultRoleUpdateLoading(false);
    }
  };

  //bankupdate
  const handleBankUpdate = async (e) => {
    e.preventDefault();
    if (!validateBankDetails()) {
      return;
    } else {
      setStatusUpdateLoading(true);

      const payload = {
        bank_name: valuess.bank_name,
        acc_number: valuess.acc_number,
        ifsc_code: valuess.ifsc_code,
        acc_holder_name: valuess.acc_holder_name,
      };

      const formData = new FormData();
      formData.append("bank_details", JSON.stringify(payload));
      try {
        await dispatch(UpdateUserManage(profileData?.data?._id, formData));
        handleBankCloseModal();
        dispatch(GetUserProfile());
        handleClose();
      } catch (error) {
        snackBar("Error updating bank account holder:", error);
      } finally {
        setStatusUpdateLoading(false);
      }
    }
  };

  const handleReviewUpdate = async (e) => {
    e.preventDefault();

    setStatusUpdateLoading(true);
    if (!reviewValue || !reviewValue?.trim()) {
      setReviewErrors(true);
      setStatusUpdateLoading(false);
      return;
    }
    try {
      if (userReviews && userReviews._id) {
        await axios
          .put(`${ApiURL}review/update/${userReviews._id}`, {
            review: reviewValue.trim(),
          })
          .then((res) => {
            if (res && res.data && res.data.status) {
              snackBar("Review updated successfully", true);
            } else {
              snackBar("Error updating user review", false);
              getUserReview();
            }
          })
          .catch((error) => {
            snackBar("Error updating user review:", error);
            getUserReview();
          });
      } else {
        await api.post(`${ApiURL}review/post-review`, {
          review: reviewValue.trim(),
        });
        snackBar("Review added successfully", true);
      }

      handleReviewCloseModal();
      getUserReview();
      handleClose();
    } catch (error) {
      snackBar("Error updating user review:", error);
    } finally {
      setStatusUpdateLoading(false);
    }
  };

  const [errorMsgYoutubeVideo, setErrorMsgYoutubeVideo] = useState();
  const [errorMsgCardInfo, setErrorMsgCardInfo] = useState();
  const [errorProductBy, setErrorProductBy] = useState();
  const [errorMsgCardTitle, setErrorMsgCardTitle] = useState();
  const [errorMsgYoutubeTitle, setErrorMsgYoutubeTitle] = useState();
  const [errorMsgRepMobile, setErrorMsgRepMobile] = useState();
  const [errorMsgRepEmail, setErrorMsgRepEmail] = useState();
  const [errorMsgRepFirstName, setErrorMsgRepFirstName] = useState();

  //businessupdate
  const handlBusinessUpdate = async (e) => {
    e.preventDefault();
    if (!validateBusinessDetails() || imageAllowMsg) {
      return;
    }
    let errorCardInfoMsg = [];
    cardinfoLinks?.map((rep, index) => {
      if (!urlRegex.test(rep.link)) {
        setErrorMsgCardInfo(`Card Info URL is invalid`);
        errorCardInfoMsg.push(rep.link);
      }
      if (rep.title === "") {
        setErrorMsgCardTitle("Card Info title is required");
        errorCardInfoMsg.push(rep.title);
      }
    });
    let errorYoutubeMsg = [];
    youtubeVideo?.map((item, i) => {
      if (!urlRegex.test(item.link)) {
        setErrorMsgYoutubeVideo(`Youtube video URL is invalid`);
        errorYoutubeMsg.push(item.link);
      } else {
        errorYoutubeMsg.push();
      }
      if (item.title === "") {
        setErrorMsgYoutubeTitle("Youtube video title is required");
        errorMsgYoutubeTitle.push(item.title);
      }
    });

    let errorProductBy = [];
    productBy?.map((product, index) => {
      if (!urlRegex.test(product.link)) {
        setErrorProductBy(`Product URL is invalid`);
        errorProductBy.push(product.link);
      }
    });

    let errorRepresentative = [];
    representatives?.map((rep) => {
      const isEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(
        rep.email
      );
      if (rep.mobile_number === "") {
        setErrorMsgRepMobile("Mobile number is required");
        errorRepresentative.push(rep.mobile_number);
      } else {
        setErrorMsgRepMobile("");
      }
      if (!isEmail) {
        setErrorMsgRepEmail("Please enter valid email");
        errorRepresentative.push(rep.email);
      } else {
        setErrorMsgRepEmail("");
      }
      if (rep.first_name === "") {
        setErrorMsgRepFirstName("First name is required");
        errorRepresentative.push(rep.first_name);
      } else {
        setErrorMsgRepFirstName("");
      }
    });

    if (errorYoutubeMsg.length > 0) {
      return;
    }
    if (errorCardInfoMsg.length > 0) {
      return;
    }
    if (errorProductBy.length > 0) {
      return;
    }
    if (errorRepresentative.length > 0) {
      return;
    }

    setErrorMsgCardInfo();
    setErrorMsgCardTitle();
    setErrorMsgYoutubeTitle();
    setErrorMsgYoutubeVideo();
    setErrorProductBy();
    setStatusUpdateLoading(true);

    const formData = new FormData();
    formData.append("business_name", valuesBusiness?.business_name);
    formData.append("owner_firstname", valuesBusiness?.owner_firstname);
    formData.append("owner_lastname", valuesBusiness?.owner_lastname);
    formData.append("business_email", valuesBusiness?.business_email);
    formData.append("business_contact", valuesBusiness?.business_contact);
    formData.append("phone_code", valuesBusiness?.phone_code);
    formData.append("establish_year", valuesBusiness?.establish_year);
    formData.append(
      "business_category_id",
      valuesBusiness?.business_category_id
    );
    formData.append("annual_turnover", valuesBusiness?.annual_turnover);
    formData.append("business_website", valuesBusiness?.business_website);
    formData.append("address", valuesBusiness?.address);
    // formData.append("city_id", valuesBusiness?.city_id?._id);
    // formData.append("state_id", valuesBusiness?.state_id?._id);
    // formData.append("postalcode_id", valuesBusiness?.postalcode_id?._id);
    formData.append("representatives", JSON.stringify(representatives));
    formData.append(
      "card_info_links",
      JSON.stringify(cardinfoLinks.filter((item) => item.link || item.title))
    );
    formData.append(
      "about_us",
      JSON.stringify(aboutUs.filter((item) => item.link || item.title))
    );
    formData.append(
      "youtube_videos",
      JSON.stringify(youtubeVideo.filter((item) => item.link || item.title))
    );
    formData.append(
      "product_services",
      JSON.stringify(productBy.filter((item) => item.link || item.image))
    );

    formData.append("business_card", valuesBusiness?.business_card);
    formData.append("is_active", valuesBusiness?.is_active);
    formData.append("business_gst", valuesBusiness?.business_gst);
    formData.append("business_pan", valuesBusiness?.business_pan);
    formData.append("business_type", valuesBusiness?.business_type);
    formData.append("max_range_amount", valuesBusiness?.max_range_amount);
    formData.append("min_range_amount", valuesBusiness?.min_range_amount);
    formData.append("range_type", valuesBusiness?.range_type);

    if (
      !selectedImage3 &&
      valuesBusiness.business_logo !== "undefined" &&
      typeof valuesBusiness.business_logo == "string"
    ) {
      formData.append("business_logo", valuesBusiness.business_logo);
    } else {
      formData.append("business_logo", selectedImage3);
    }

    // else {
    //   businessLogorray.forEach((image, index) => {
    //     formData.append("business_logo", image);
    //   });
    // }

    const imagesArray = Array.from(selectedImage);
    const existingImages = [];
    imagesArray.forEach((image, index) => {
      if (typeof image === "string") {
        existingImages.push(image);
      } else {
        formData.append(`image_gallery`, image || "");
      }
    });
    formData.append(`image_gallery`, JSON.stringify(existingImages) || "");

    try {
      const res = await dispatch(
        UpdateUserBusinessManage(profileData?.data?.business?._id, formData)
      );
      if (res?.data?.status) {
        handleBusinessCloseModal();
        dispatch(GetUserProfile());
        handleClose();
      } else {
        snackBar(res?.data?.message, res?.data?.status);
      }
    } catch (error) {
      snackBar("Error updating bussines details:", error);
    } finally {
      setStatusUpdateLoading(false);
    }
  };

  console.log("IsValidGSTDocument", IsValidGSTDocument);

  //businessDocumentUpload
  const handlBusinessDocUpdate = async (e) => {
    e.preventDefault();
    if (!selectedImage1 && !selectedImage2) {
      handleBusinessDocCloseModal();
      handleClose();
      return;
    }

    if (IsValidGSTDocument.msg || IsValidPANDocument.msg) {
      if (!(IsValidGSTDocument.isExist || IsValidPANDocument.isExist)) {
        return;
      }
    }
    setStatusUpdateLoading(true);

    const formData = new FormData();
    formData.append("gst_business_document", selectedImage1);
    formData.append("pan_business_document", selectedImage2);

    try {
      const res = await dispatch(
        UpdateUserBusinessManage(profileData?.data?.business?._id, formData)
      );
      handleBusinessDocCloseModal();
      setSelectedImage1(
        res?.data?.data?.business_documents[0]?.gst_business_document
      );
      setSelectedImage2(
        res?.data?.data?.business_documents[0]?.pan_business_document
      );
      dispatch(GetUserProfile());
      getUserDetails(profileData?.data?._id);
      setisValidPANDocument({ ...IsValidPANDocument, msg: "", isExist: false });
      setisValidGSTDocument({ ...IsValidGSTDocument, msg: "", isExist: false });
      handleClose();
    } catch (error) {
      snackBar("Error updating bank account holder:", error);
    } finally {
      setStatusUpdateLoading(false);
    }
  };

  const validateBankDetails = () => {
    let tempErrors = { ...bankErrors };

    // Validate bank_name
    if (
      valuess.bank_name === "" ||
      valuess.bank_name === undefined ||
      valuess.bank_name === null ||
      valuess?.bank_name?.trim() === ""
    ) {
      tempErrors = {
        ...tempErrors,
        bank_name: "Please enter bank name",
      };
    } else if (!/^[A-Za-z ]+$/.test(valuess.bank_name)) {
      tempErrors = {
        ...tempErrors,
        bank_name: "Bank name should only contain alphabets and spaces",
      };
    } else {
      tempErrors = {
        ...tempErrors,
        bank_name: "",
      };
    }

    // Validate acc_number
    if (
      valuess.acc_number === "" ||
      valuess.acc_number === undefined ||
      valuess.acc_number === null ||
      valuess?.acc_number?.trim() === ""
    ) {
      tempErrors = {
        ...tempErrors,
        acc_number: "Please enter account number",
      };
    } else if (isNaN(valuess.acc_number)) {
      tempErrors = {
        ...tempErrors,
        acc_number: "Please enter valid account number",
      };
    } else {
      tempErrors = {
        ...tempErrors,
        acc_number: "",
      };
    }

    // Validate ifsc_code
    if (
      valuess.ifsc_code === "" ||
      valuess.ifsc_code === undefined ||
      valuess.ifsc_code === null ||
      valuess?.ifsc_code?.trim() === ""
    ) {
      tempErrors = {
        ...tempErrors,
        ifsc_code: "Please enter IFSC code",
      };
    } else if (valuess.ifsc_code && valuess.ifsc_code.length !== 11) {
      if (!/^[A-Z0-9]+$/.test(valuess.ifsc_code)) {
        tempErrors = {
          ...tempErrors,
          ifsc_code: "Please enter valid IFSC code",
        };
      } else {
        tempErrors = {
          ...tempErrors,
          ifsc_code: "Please enter a valid 11-character IFSC code",
        };
      }
    } else {
      tempErrors = {
        ...tempErrors,
        ifsc_code: "",
      };
    }

    // Validate acc_holder_name
    if (
      valuess.acc_holder_name === "" ||
      valuess.acc_holder_name === undefined ||
      valuess.acc_holder_name === null ||
      valuess?.acc_holder_name?.trim() === ""
    ) {
      tempErrors = {
        ...tempErrors,
        acc_holder_name: "Please enter account holder name",
      };
    } else if (!/^[A-Za-z ]+$/.test(valuess.acc_holder_name)) {
      tempErrors = {
        ...tempErrors,
        acc_holder_name:
          "Account holder name should only contain alphabets and spaces",
      };
    } else {
      tempErrors = {
        ...tempErrors,
        acc_holder_name: "",
      };
    }

    setBankErrors(tempErrors);
    // Check if has any errors
    const hasErrors = Object.values(tempErrors).some((value) => value !== "");

    if (hasErrors) {
      return false;
    } else {
      return true;
    }
  };

  const validateBusinessDetails = () => {
    let tempErrors = { ...errorValueBusiness };

    const nameRegex = /^[a-zA-Z]+([a-zA-Z\s]*[a-zA-Z]+)?$/;

    const fields = [
      { name: "business_name", message: "Please enter business Name" },
      { name: "owner_firstname", message: "Please enter first name" },
      { name: "owner_lastname", message: "Please enter last name" },
      { name: "business_email", message: "Please enter business email" },
      { name: "business_contact", message: "Please enter business contact" },
      { name: "business_gst", message: "Please enter business GST number" },
      { name: "business_pan", message: "Please enter business PAN number" },
      { name: "business_logo", message: "Please select bussiness logo" },
      { name: "establish_year", message: "Please enter valid year" },
      { name: "min_range_amount", message: "Please enter Minimum range" },
      { name: "max_range_amount", message: "Please enter Maximum range" },
      { name: "business_website", message: "" },
      { name: "business_card", message: "" },
    ];

    // const youtubeField = youtubeVideo?.map((item => {
    //   if (urlRegex.text(item.link)) {

    //   }
    // }))

    let isMinRangeAmount = "";
    let minimumAmountRange = "";

    fields.forEach((field) => {
      const value = valuesBusiness[field.name];

      const youtubeValue = youtubeVideo[field.link];
      if (
        value === "" ||
        value === undefined ||
        value === null ||
        (typeof value === "string" && value.trim() === "")
      ) {
        tempErrors[field.name] = field.message;
      } else if (field.name === "business_pan") {
        const isValidPAN = /^[A-Z0-9]{10}$/.test(value);
        if (!isValidPAN) {
          tempErrors[field.name] =
            "PAN number should be exactly 10 alphanumeric characters";
        } else {
          tempErrors[field.name] = "";
        }
      } else if (field.name === "business_name") {
        if (!nameRegex.test(value)) {
          tempErrors[field.name] =
            "Business name should contain only alphabets and spaces";
        } else {
          tempErrors[field.name] = "";
        }
      } else if (field.name === "owner_firstname") {
        if (!nameRegex.test(value)) {
          tempErrors[field.name] =
            "Owner first name should contain only alphabets and spaces";
        } else {
          tempErrors[field.name] = "";
        }
      } else if (field.name === "owner_lastname") {
        if (!nameRegex.test(value)) {
          tempErrors[field.name] =
            "Owner last name should contain only alphabets and spaces";
        } else {
          tempErrors[field.name] = "";
        }
      } else if (field.name === "business_email") {
        const isEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(value);
        // const isEmail = /^[a-z0-9]+@[a-z0-9]+\.[a-z]{2,}$/.test(value);
        if (!isEmail) {
          tempErrors[field.name] = "Please enter valid email";
        } else {
          tempErrors[field.name] = "";
        }
      } else if (field.name === "establish_year") {
        const isYear = /^[0-9]{4}$/.test(value);
        if (!isYear) {
          tempErrors[field.name] = "Please enter a valid establish year";
        } else {
          const currentYear = new Date().getFullYear();
          const enteredYear = parseInt(value, 10);
          if (enteredYear > currentYear) {
            tempErrors[field.name] = "Establish year cannot be in the future";
          } else {
            tempErrors[field.name] = "";
          }
        }
      } else if (field.name === "business_gst") {
        const isGST = /^[A-Z0-9]{15}$/.test(value);
        if (!isGST) {
          tempErrors[field.name] =
            "GST number should be exactly 15 alphanumeric characters";
        } else {
          tempErrors[field.name] = "";
        }
      } else if (field.name === "min_range_amount") {
        minimumAmountRange = value;
        isMinRangeAmount = /^-?\d+$/.test(value);
        if (!isMinRangeAmount) {
          tempErrors[field.name] = "Please enter number";
        } else {
          tempErrors[field.name] = "";
        }
      } else if (field.name === "max_range_amount") {
        const isMaxRangeAmount = /^-?\d+$/.test(value);
        if (!isMaxRangeAmount) {
          tempErrors[field.name] = "Please enter maximum amount  number";
        } else if (parseFloat(value) < parseFloat(minimumAmountRange)) {
          tempErrors[field.name] =
            "Maximum price range is lower than minimum price";
        } else {
          tempErrors[field.name] = "";
        }
      } else if (field.name === "business_website") {
        const isValidURL =
          /^(https?|ftp|www):\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+(?:[a-zA-Z]|[0-9]|[-._~:/?#[\]@!$&'()*+,;=])*(?:[a-zA-Z]|[0-9]|[-._~:/?#[\]@!$&'()*+,;=])$/.test(
            value
          );
        if (!isValidURL) {
          tempErrors[field.name] = "Please enter valid URL";
        } else {
          tempErrors[field.name] = "";
        }
      } else if (field.name === "business_card") {
        const isValidURL =
          /^(https?|ftp|www):\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+(?:[a-zA-Z]|[0-9]|[-._~:/?#[\]@!$&'()*+,;=])*(?:[a-zA-Z]|[0-9]|[-._~:/?#[\]@!$&'()*+,;=])$/.test(
            value
          );
        if (!isValidURL) {
          tempErrors[field.name] = "Please enter valid URL";
        } else {
          tempErrors[field.name] = "";
        }
      } else {
        tempErrors[field.name] = "";
      }
    });
    setErrorValueBusiness({ ...tempErrors });

    const hasErrors = fields.some((field) => tempErrors[field.name] !== "");

    return !hasErrors;
  };

  const handleChangess = (e) => {
    const { name, value } = e.target;
    setValuess({ ...valuess, [name]: value });
  };

  const [business_website, setBusiness_website] = useState();

  const handleBusinessChanges = (e) => {
    const { name, value } = e.target;
    const numericRegex = /^\d+$/;
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    if (name == "annual_turnover" && value && !numericRegex.test(value)) {
      return;
    }
    setValuesBusiness({ ...valuesBusiness, [name]: value });
  };

  const getUserDetails = async (id) => {
    try {
      const res = await axios.get(`${ApiURL}user/get-user-by-id/${id}`);
      if (res?.data?.status) {
        setDocuments(res?.data?.data[0]?.business_documents);
      }
    } catch (err) {}
  };

  const getUserReview = async () => {
    try {
      const res = await axios.get(`${ApiURL}review/get-my-review`);
      if (res?.data?.status) {
        setUserReviews(res?.data?.data[0]);
        setReviewValue(res?.data?.data[0]?.review);
      } else {
        setUserReviews(null);
        setReviewValue(null);
      }
    } catch (err) {
      setUserReviews(null);
      setReviewValue(null);
    }
  };

  useEffect(() => {
    if (profileData?.data?.business?.document_uploaded === true) {
      getUserDetails(profileData?.data?._id);
    }
    getUserReview();
  }, [profileData?.data?._id]);

  const linkToCopy = `${window.location.origin}/business-card/${userData?.user?._id}`;

  return (
    <>
      <Box
        sx={{
          marginTop: "30px",
          marginLeft: { xs: "20px", md: "25px", lg: "25px" },
          marginRight: { xs: "15px", md: "25px", lg: "25px" },
        }}
      >
        {profileData?.data?.role !== "Community Super Admin" && (
          <div>
            <CopyToClipboardButton textToCopy={linkToCopy} />
          </div>
        )}

        {profileData?.data?.role !== "Community Super Admin" ? (
          <Box
            sx={{
              maxWidth: {
                md: "100%",
                lg: "100%",
              },
            }}
          >
            {/* {profileData?.data?.membership_id ? ( */}
            <>
              <Box
                sx={{
                  flexGrow: 1,
                  marginBottom: 2,
                  bgcolor: "#your-background-color",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {profileData?.data?.role === "Community Member" ? (
                      <Card
                        sx={{
                          height: "100%",
                          paddingTop: "24px",
                          textAlign: "center",
                          color: "black",
                          paddingBottom: "13px",
                          boxShadow: "none",
                          borderRadius: "10px",
                          background: expiredFinalNotification
                            ? "#f9b0b0"
                            : expiryNotification ||
                              !profileData?.data?.membership_id
                            ? "#f1cc81"
                            : "#defce6",
                        }}
                      >
                        {loadingExpiryStatus ? (
                          <p>Loading...</p>
                        ) : (
                          <Grid
                            container
                            spacing={2}
                            sx={{ marginTop: "-29px" }}
                          >
                            <Grid
                              item
                              xs={6}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography
                                variant="h5"
                                fontWeight="600"
                                color="black"
                                sx={{
                                  paddingLeft: "44px",
                                  marginTop: "0px",
                                }}
                              >
                                {expiredFinalNotification
                                  ? expiryNotificationMsg
                                  : expiryNotification ||
                                    !profileData?.data?.membership_id
                                  ? "Purchase Now"
                                  : `Membership Plan Expire at : ${
                                      profileData?.data?.membership_id
                                        ? expiryDate
                                        : ""
                                    }`}
                              </Typography>
                            </Grid>
                            {expiredFinalNotification ||
                            expiryNotification ||
                            !profileData?.data?.membership_id ? (
                              <Grid
                                item
                                xs={6}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  paddingRight: "39px",
                                }}
                              >
                                <Link
                                  to="/pricing-plan"
                                  variant="contained"
                                  style={{
                                    backgroundColor: "#2e7d32",
                                    paddingLeft: "10px",
                                    borderRadius: "24px",
                                    color: "white",
                                    paddingTop: "5px",
                                    paddingRight: "10px",
                                    paddingBottom: "5px",
                                    border: "1px solid black",
                                    boxShadow: "0px 2px 1px 0px black",
                                    textDecoration: "none",
                                    textDecoration: "none",
                                  }}
                                >
                                  {(expiryNotification ||
                                    expiredFinalNotification) &&
                                  profileData?.data?.membership_id
                                    ? "Upgrade Plan"
                                    : "Purchase Plan"}
                                </Link>
                              </Grid>
                            ) : null}
                            {expiryNotification ? (
                              <Grid
                                item
                                xs={6}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  variant="h6"
                                  fontWeight="600"
                                  color="black"
                                  sx={{
                                    paddingLeft: "44px",
                                    marginTop: "0px",
                                  }}
                                >
                                  {expiryNotificationMsg}
                                </Typography>
                              </Grid>
                            ) : null}
                          </Grid>
                        )}
                      </Card>
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
              <Divider />
            </>
            {/* ) : null
          } */}
          </Box>
        ) : (
          <></>
        )}
        <Box
          sx={{
            maxWidth: {
              md: "100%",
              lg: "100%",
            },
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card
                  sx={{
                    height: "100%",
                    paddingTop: "24px",
                    textAlign: "center",
                    color: "black",
                    paddingBottom: "13px",
                    boxShadow: "none",

                    background:
                      "linear-gradient(317deg, rgba(254,118,120,1) 9%, rgba(21,62,128,1) 100%)",
                  }}
                >
                  <Grid container spacing={2} sx={{ marginTop: "-29px" }}>
                    <Grid item xs={6} sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "20px",
                          color: "snow",
                          marginTop: "-11px",
                          padding: " 15px",
                        }}
                      >
                        Personal Details
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        padding: "12px",
                        paddingRight: "39px",
                      }}
                    >
                      <Button
                        onClick={() => handleEditClick(profileData.data)}
                        sx={{
                          ":hover": { bgcolor: "#153E80", color: "#FFFFFF" },
                          textTransform: "none",
                          fontSize: "16px",
                          color: "#FFFFFF",

                          backgroundColor: "#153E80",
                          border: "2px solid #153E80",
                          borderRadius: "15px",
                          height: "37px",

                          "&.Mui-disabled": {
                            backgroundColor: "#153E80",
                            color: "#fff",
                          },
                        }}
                        type="submit"
                        startIcon={<EditIcon sx={{ marginLeft: "10px" }} />}
                      ></Button>
                    </Grid>
                  </Grid>

                  <Divider />

                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                      <img
                        className={classes.image}
                        style={{
                          // maxHeight: "120px",
                          // minWidth: "120px",
                          height: "120px",
                          width: "120px",
                          borderRadius: "50%",
                        }}
                        src={
                          profileData &&
                          profileData?.data &&
                          profileData?.data?.profile_picture &&
                          profileData?.data?.profile_picture !== "null" &&
                          profileData?.data?.profile_picture !== null
                            ? profileData?.data?.profile_picture
                            : DefaultProfile
                        }
                        alt="profilepic"
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <div className={classes.mainCompDetails}>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            sx={{ marginTop: "50px" }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#d1d1d1",
                              }}
                            >
                              Owner Name
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                color: "snow",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                textAlign: "left",
                              }}
                            >
                              {profileData?.data?.name
                                ? profileData?.data?.name
                                : profileData?.data?.first_name +
                                  " " +
                                  profileData?.data?.last_name}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            sx={{ marginTop: "50px" }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#d1d1d1",
                              }}
                            >
                              Email
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                color: "snow",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                              }}
                            >
                              {profileData?.data?.email || "-"}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            sx={{ marginTop: "50px" }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#d1d1d1",
                              }}
                            >
                              Mobile number
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                color: "snow",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                              }}
                            >
                              {profileData?.data?.mobile_number}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            sx={{ marginTop: "50px" }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#d1d1d1",
                              }}
                            >
                              Address Proof
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                color: "snow",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                overflowWrap:
                                  "break-word" /* Modern equivalent of word-wrap */,
                                wordBreak:
                                  "break-word" /* Breaks words at arbitrary points if necessary */,
                                whiteSpace: "normal",
                                textAlign: "left",
                              }}
                            >
                              {profileData?.data?.address || "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  {profileData?.data?.referral_code ? (
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12} sm={12} md={12} lg={2}></Grid>
                      <Grid item xs={9}>
                        <div className={classes.mainCompDetails}>
                          <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                          >
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              sx={{ marginTop: "50px" }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "100",
                                  fontSize: "17px",
                                  marginTop: "-11px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "#d1d1d1",
                                }}
                              >
                                Your referral code
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  color: "snow",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                }}
                              >
                                {profileData?.data?.referral_code}
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "30px",
          marginLeft: { xs: "20px", md: "25px", lg: "25px" },
          marginRight: { xs: "15px", md: "25px", lg: "25px" },
        }}
      >
        <div className="company-details">
          <Box
            sx={{
              maxWidth: {
                md: "100%",
                lg: "100%",
              },
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ paddingBottom: "25px" }}
                >
                  <Card
                    sx={{
                      marginTop: "0px",
                      height: "100%",
                      backgroundColor: "#d1d1d17a",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "20px",
                        color: "black",
                        marginTop: "-11px",
                        padding: "22px",
                      }}
                    >
                      Current Role
                    </Typography>
                    <Box
                      sx={{
                        flexGrow: 1,
                        marginTop: "-40px",
                      }}
                    >
                      <Grid container spacing={2}></Grid>
                      <Grid container spacing={2} sx={{ marginTop: "12px" }}>
                        <Grid item xs={12} md={6}>
                          {user_roles_options?.length > 1 ? (
                            <>
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                }}
                              >
                                <>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Default Role
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={role || profileData?.data?.role_id}
                                      label="Default Role"
                                      onChange={(e, value) => {
                                        setRole(e.target.value);
                                      }}
                                    >
                                      {user_roles_options?.map((item) => (
                                        <MenuItem value={item?.value}>
                                          {item?.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </>
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography
                                sx={{
                                  fontWeight: "100",
                                  fontSize: "17px",
                                  marginTop: "-11px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "#605a5a",
                                }}
                              >
                                Default Role
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                }}
                              >
                                {profileData?.data?.role}
                              </Typography>
                            </>
                          )}
                        </Grid>
                        {user_roles_options?.length > 1 && (
                          <Grid item xs={12} md={6}>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={handleUpdateDefault}
                                disabled={
                                  defaultRoleUpdateLoading ||
                                  !role ||
                                  role === profileData?.data?.role_id
                                }
                              >
                                Update
                              </Button>
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      </Box>

      <Box
        sx={{
          marginTop: "30px",
          marginLeft: { xs: "20px", md: "25px", lg: "25px" },
          marginRight: { xs: "15px", md: "25px", lg: "25px" },
        }}
      >
        <div className="company-details">
          <Box
            sx={{
              maxWidth: {
                md: "100%",
                lg: "100%",
              },
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ paddingBottom: "25px" }}
                >
                  <Card
                    sx={{
                      marginTop: "0px",
                      height: "100%",
                      backgroundColor: "#d1d1d17a",
                    }}
                  >
                    <Grid
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingRight: 5,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "20px",
                          color: "black",
                          marginTop: "-11px",
                          padding: "22px",
                        }}
                      >
                        Change Password
                      </Typography>
                      <Button
                        onClick={() => handleChangePasswordModal()}
                        sx={{
                          ":hover": {
                            bgcolor: "#153E80",
                            color: "#FFFFFF",
                          },
                          textTransform: "none",
                          fontSize: "16px",
                          color: "#FFFFFF",
                          backgroundColor: "#153E80",
                          border: "2px solid #153E80",
                          borderRadius: "15px",
                          height: "37px",
                          marginTop: "10px",
                          "&.Mui-disabled": {
                            backgroundColor: "#153E80",
                            color: "#fff",
                          },
                        }}
                        type="submit"
                        startIcon={<EditIcon sx={{ marginLeft: "10px" }} />}
                      ></Button>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      </Box>

      {userRole !== "Community Super Admin" && (
        <Box
          sx={{
            marginTop: "10px",
            marginLeft: { xs: "20px", md: "25px", lg: "25px" },
            marginRight: { xs: "15px", md: "25px", lg: "25px" },
          }}
        >
          <div className="company-details">
            <Box
              sx={{
                maxWidth: {
                  md: "100%",
                  lg: "100%",
                },
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      paddingBottom: "25px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Card
                      sx={{
                        width: "100%",
                        marginTop: "0px",
                        height: "100%",
                        backgroundColor: "#d1d1d17a",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "20px",
                              color: "black",
                              marginTop: "-11px",
                              padding: "22px",
                            }}
                          >
                            Business Details
                          </Typography>
                        </Grid>
                        {getPermissions("Business", "is_edit") ? (
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              padding: "12px",
                              paddingRight: "39px",
                            }}
                            xs={6}
                            md={6}
                          >
                            <Button
                              onClick={() =>
                                handleBusinessModal(profileData.data)
                              }
                              sx={{
                                ":hover": {
                                  bgcolor: "#153E80",
                                  color: "#FFFFFF",
                                },
                                textTransform: "none",
                                fontSize: "16px",
                                color: "#FFFFFF",
                                backgroundColor: "#153E80",
                                border: "2px solid #153E80",
                                borderRadius: "15px",
                                height: "37px",
                                marginTop: "10px",
                                "&.Mui-disabled": {
                                  backgroundColor: "#153E80",
                                  color: "#fff",
                                },
                              }}
                              type="submit"
                              startIcon={
                                <EditIcon sx={{ marginLeft: "10px" }} />
                              }
                            ></Button>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Box
                        sx={{
                          flexGrow: 1,
                          marginTop: "10px",
                        }}
                      >
                        <Grid item md={6} xs={12}>
                          <Typography
                            sx={{
                              fontWeight: "100",
                              fontSize: "17px",
                              marginTop: "-11px",
                              padding: " 15px",
                              display: "flex",
                              color: "#605a5a",
                            }}
                          >
                            Business Logo
                          </Typography>
                          {profileData?.data?.business?.business_logo &&
                          profileData?.data?.business?.business_logo !== null &&
                          profileData?.data?.business?.business_logo !==
                            "null" ? (
                            <img
                              src={profileData?.data?.business?.business_logo}
                              alt="business logo"
                              style={{ maxWidth: "15%", marginLeft: "20px" }}
                            />
                          ) : (
                            <img
                              style={{ maxWidth: "15%", marginLeft: "20px" }}
                              src={Image1}
                              alt="business logo"
                            />
                          )}
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Name
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {subStr(
                                profileData?.data?.business?.business_name ||
                                  "-",
                                50
                              )}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Owner
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                                overflowWrap:
                                  "break-word" /* Modern equivalent of word-wrap */,
                                wordBreak:
                                  "break-word" /* Breaks words at arbitrary points if necessary */,
                                whiteSpace: "normal",
                                textAlign: "left",
                              }}
                            >
                              {subStr(
                                profileData?.data?.business?.owner_firstname ||
                                  "-",
                                50
                              )}{" "}
                              {subStr(
                                profileData?.data?.business?.owner_lastname ||
                                  "-",
                                50
                              )}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Mobile Number
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.business_contact ||
                                "-"}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Email
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.business_email ||
                                "-"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Website
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData &&
                              profileData?.data?.business?.business_website ? (
                                <a
                                  // href={profileData && profileData?.data?.business?.business_website}
                                  href={
                                    !profileData?.data?.business?.business_website?.startsWith(
                                      "https://"
                                    ) &&
                                    !profileData?.data?.business?.business_website?.startsWith(
                                      "http://"
                                    )
                                      ? "https://" +
                                        profileData?.data?.business
                                          ?.business_website
                                      : profileData?.data?.business
                                          ?.business_website
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: "#0099cc !important",
                                    textDecoration: "none",
                                    cursor: "pointer",
                                  }}
                                >
                                  View
                                  {/* {profileData?.data?.business?.business_website ||
                                  "-"} */}
                                </a>
                              ) : (
                                <span>-</span>
                              )}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Category
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business_category || "-"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Type
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                                overflowWrap:
                                  "break-word" /* Modern equivalent of word-wrap */,
                                wordBreak:
                                  "break-word" /* Breaks words at arbitrary points if necessary */,
                                whiteSpace: "normal",
                              }}
                            >
                              {subStr(
                                profileData?.data?.business?.business_type ||
                                  "Private Ltd",
                                50
                              )}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Annual Turnover
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.annual_turnover ||
                                "-"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Established Year
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.establish_year ||
                                "-"}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Document Uploaded
                            </Typography>

                            {profileData?.data?.business?.document_uploaded ===
                            false ? (
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                }}
                              >
                                No
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                }}
                              >
                                Yes
                              </Typography>
                            )}
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Address
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                                overflowWrap:
                                  "break-word" /* Modern equivalent of word-wrap */,
                                wordBreak:
                                  "break-word" /* Breaks words at arbitrary points if necessary */,
                                whiteSpace: "normal",
                                textAlign: "left",
                              }}
                            >
                              {profileData?.data?.business?.address || "-"}
                            </Typography>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Pan Number
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.business_pan || "-"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business GST Number
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.business_gst || "-"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Typography
                          sx={{
                            fontWeight: "100",
                            fontSize: "17px",
                            marginTop: "-11px",
                            padding: " 15px",
                            display: "flex",
                            color: "#605a5a",
                          }}
                        >
                          Card Info
                        </Typography>
                        {profileData?.data?.business?.card_info_links?.map(
                          (rows) => {
                            return (
                              <Grid
                                container
                                spacing={2}
                                sx={{ marginTop: "-38px" }}
                              >
                                <Grid item md={6} xs={6}>
                                  <Typography
                                    sx={{
                                      fontWeight: "100",
                                      fontSize: "17px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "#605a5a",
                                    }}
                                  >
                                    Title
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "18px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "black",
                                      marginTop: "-32px",
                                      overflowWrap: "break-word",
                                      wordBreak: "break-word",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {subStr(rows?.title || "-", 50)}
                                  </Typography>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: "100",
                                      fontSize: "17px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "#605a5a",
                                    }}
                                  >
                                    Link
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "18px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "black",
                                      marginTop: "-32px",
                                    }}
                                  >
                                    {rows.link ? (
                                      <a
                                        href={
                                          !rows?.link?.startsWith("https://") &&
                                          !rows?.link?.startsWith("http://")
                                            ? "https://" + rows?.link
                                            : rows?.link
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          color: "#0099cc !important",
                                          textDecoration: "none",
                                          cursor: "pointer",
                                        }}
                                      >
                                        View
                                        {/* {subStr(rows.link, 50)} */}
                                      </a>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          }
                        )}

                        <Typography
                          sx={{
                            fontWeight: "100",
                            fontSize: "17px",
                            marginTop: "-11px",
                            padding: " 15px",
                            display: "flex",
                            color: "#605a5a",
                          }}
                        >
                          About Us
                        </Typography>
                        {profileData?.data?.business?.about_us?.map((rows) => {
                          return (
                            <Grid
                              container
                              spacing={2}
                              sx={{ marginTop: "-38px" }}
                            >
                              <Grid item md={6} xs={12}>
                                <Typography
                                  sx={{
                                    fontWeight: "100",
                                    fontSize: "17px",
                                    padding: " 15px",
                                    display: "flex",
                                    color: "#605a5a",
                                  }}
                                >
                                  Title
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: "600",
                                    fontSize: "18px",
                                    padding: " 15px",
                                    display: "flex",
                                    color: "black",
                                    marginTop: "-32px",
                                    overflowWrap:
                                      "break-word" /* Modern equivalent of word-wrap */,
                                    wordBreak:
                                      "break-word" /* Breaks words at arbitrary points if necessary */,
                                    whiteSpace: "normal",
                                  }}
                                >
                                  {rows?.title || "-"}
                                </Typography>
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <Typography
                                  sx={{
                                    fontWeight: "100",
                                    fontSize: "17px",
                                    padding: " 15px",
                                    display: "flex",
                                    color: "#605a5a",
                                  }}
                                >
                                  Description
                                </Typography>

                                <Typography
                                  sx={{
                                    fontWeight: "600",
                                    fontSize: "18px",
                                    padding: " 15px",
                                    display: "flex",
                                    color: "black",
                                    marginTop: "-32px",
                                    overflowWrap:
                                      "break-word" /* Modern equivalent of word-wrap */,
                                    wordBreak:
                                      "break-word" /* Breaks words at arbitrary points if necessary */,
                                    whiteSpace: "normal",
                                  }}
                                >
                                  {subStr(rows?.link || "-", 500)}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })}

                        <Typography
                          sx={{
                            fontWeight: "100",
                            fontSize: "17px",
                            marginTop: "-11px",
                            padding: " 15px",
                            display: "flex",
                            color: "#605a5a",
                          }}
                        >
                          Youtube Video
                        </Typography>
                        {profileData?.data?.business?.youtube_videos?.map(
                          (rows) => {
                            return (
                              <Grid
                                container
                                spacing={2}
                                sx={{ marginTop: "-38px" }}
                              >
                                <Grid item md={6} xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: "100",
                                      fontSize: "17px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "#605a5a",
                                    }}
                                  >
                                    Title
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "18px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "black",
                                      marginTop: "-32px",
                                    }}
                                  >
                                    {rows?.title || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: "100",
                                      fontSize: "17px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "#605a5a",
                                    }}
                                  >
                                    Link
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "18px",
                                      padding: "15px",
                                      display: "flex",
                                      color: "black",
                                      marginTop: "-32px",
                                    }}
                                  >
                                    {rows.link ? (
                                      <a
                                        // href={rows.link}
                                        href={
                                          !rows?.link?.startsWith("https://") &&
                                          !rows?.link?.startsWith("http://")
                                            ? "https://" + rows?.link
                                            : rows?.link
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          color: "#0099cc !important",
                                          textDecoration: "none",
                                          cursor: "pointer",
                                        }}
                                      >
                                        View
                                        {/* {subStr(rows.link, 100)} */}
                                      </a>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          }
                        )}

                        <Typography
                          sx={{
                            fontWeight: "100",
                            fontSize: "17px",
                            marginTop: "-11px",
                            padding: " 15px",
                            display: "flex",
                            color: "#605a5a",
                          }}
                        >
                          Image Gallery
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            marginTop: 1,
                            marginBottom: 2,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Grid item md={6} xs={12}>
                            {profileData?.data?.business?.image_gallery &&
                              viewImages(
                                profileData?.data?.business?.image_gallery
                              )}
                          </Grid>
                        </Grid>

                        <Typography
                          sx={{
                            fontWeight: "100",
                            fontSize: "17px",
                            marginTop: "-11px",
                            padding: " 15px",
                            display: "flex",
                            color: "#605a5a",
                          }}
                        >
                          Product services
                        </Typography>
                        {profileData?.data?.business?.product_services?.map(
                          (rows) => {
                            return (
                              <Grid
                                container
                                spacing={2}
                                sx={{ marginTop: "-38px" }}
                              >
                                <Grid item md={6} xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: "100",
                                      fontSize: "17px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "#605a5a",
                                    }}
                                  >
                                    Link
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "18px",
                                      padding: " 15px",
                                      display: "flex",
                                      marginTop: "-32px",
                                      overflowWrap: "break-word",
                                      wordBreak: "break-word",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {rows?.link ? (
                                      <a
                                        href={
                                          !rows?.link?.startsWith("https://") &&
                                          !rows?.link?.startsWith("http://")
                                            ? "https://" + rows?.link
                                            : rows?.link
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          color: "#0099cc !important",
                                          textDecoration: "none",
                                          cursor: "pointer",
                                        }}
                                      >
                                        View
                                      </a>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: "100",
                                      fontSize: "17px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "#605a5a",
                                    }}
                                  >
                                    Image
                                  </Typography>
                                  {rows?.image && rows?.image !== null ? (
                                    rows?.image && (
                                      <a
                                        target="_blank"
                                        href={
                                          typeof rows?.image === "string"
                                            ? rows?.image
                                            : URL.createObjectURL(rows?.image)
                                        }
                                      >
                                        <img
                                          src={rows?.image}
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                          }}
                                        />
                                      </a>
                                    )
                                  ) : (
                                    <img
                                      style={{
                                        maxWidth: "15%",
                                        marginLeft: "20px",
                                      }}
                                      src={Image1}
                                      alt="business logo"
                                    />
                                  )}
                                </Grid>
                              </Grid>
                            );
                          }
                        )}

                        <Typography
                          sx={{
                            fontWeight: "100",
                            fontSize: "17px",
                            marginTop: "-11px",
                            padding: " 15px",
                            display: "flex",
                            color: "#605a5a",
                          }}
                        >
                          Representative
                        </Typography>
                        {profileData?.data?.business?.representatives?.map(
                          (rows) => {
                            return (
                              <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: "100",
                                      fontSize: "17px",
                                      marginTop: "-11px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "#605a5a",
                                    }}
                                  >
                                    Name
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "18px",
                                      marginTop: "-31px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "black",
                                    }}
                                  >
                                    {rows?.first_name || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: "100",
                                      fontSize: "17px",
                                      marginTop: "-11px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "#605a5a",
                                    }}
                                  >
                                    Email
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "18px",
                                      marginTop: "-31px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "black",
                                    }}
                                  >
                                    {rows?.email || "-"}
                                  </Typography>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: "100",
                                      fontSize: "17px",
                                      marginTop: "-11px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "#605a5a",
                                    }}
                                  >
                                    Mobile Number
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "18px",
                                      marginTop: "-31px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "black",
                                    }}
                                  >
                                    {rows?.mobile_number || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          }
                        )}
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </div>
        </Box>
      )}

      {userRole !== "Community Super Admin" && (
        <Box
          sx={{
            marginTop: "10px",
            marginLeft: { xs: "20px", md: "25px", lg: "25px" },
            marginRight: { xs: "15px", md: "25px", lg: "25px" },
          }}
        >
          <div className="company-details">
            <Box
              sx={{
                maxWidth: {
                  md: "100%",
                  lg: "100%",
                },
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      paddingBottom: "25px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Card
                      sx={{
                        width: "100%",
                        marginTop: "0px",
                        height: "100%",
                        backgroundColor: "#d1d1d17a",
                      }}
                    >
                      <Box
                        sx={{
                          flexGrow: 1,
                          marginTop: "10px",
                          marginBottom: "20px",
                          marginLeft: "10px",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={6}>
                            <Typography
                              sx={{
                                fontWeight: "700",
                                fontSize: "20px",
                                color: "black",
                                marginTop: "-11px",
                                padding: "22px",
                              }}
                            >
                              Business Document
                            </Typography>
                          </Grid>
                          {documents &&
                            Array.isArray(documents) &&
                            (documents[0]?.pan_status !== "approved" ||
                              documents[0]?.gst_status !== "approved") && (
                              <Grid
                                item
                                sx={{
                                  display: "flex",
                                  justifyContent: "end",
                                  padding: "12px",
                                  paddingRight: "39px",
                                }}
                                xs={6}
                                md={6}
                              >
                                <Button
                                  onClick={() =>
                                    handleBusinessDocModal(profileData.data)
                                  }
                                  sx={{
                                    ":hover": {
                                      bgcolor: "#153E80",
                                      color: "#FFFFFF",
                                    },
                                    textTransform: "none",
                                    fontSize: "16px",
                                    color: "#FFFFFF",

                                    backgroundColor: "#153E80",
                                    border: "2px solid #153E80",
                                    borderRadius: "15px",
                                    height: "37px",
                                    marginLeft: "420px",
                                    marginTop: "10px",

                                    "&.Mui-disabled": {
                                      backgroundColor: "#153E80",
                                      color: "#fff",
                                    },
                                  }}
                                  type="submit"
                                  startIcon={
                                    <EditIcon sx={{ marginLeft: "10px" }} />
                                  }
                                ></Button>
                              </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={1}>
                          {documents &&
                            Array.isArray(documents) &&
                            documents.length > 0 &&
                            documents[0]?.gst_business_document && (
                              <>
                                <BusinessDocCard
                                  data={{
                                    business_document:
                                      documents[0]?.gst_business_document,
                                    document_type: "GST",
                                    status: documents[0]?.gst_status,
                                    statusKey: "",
                                    _id: documents[0]?._id,
                                    disable_update: true,
                                    link: documents[0]?.gst_business_document,
                                  }}
                                />
                                {/* <Box>
                                  <a
                                    href={documents[0]?.gst_business_document}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <IconButton
                                      aria-label="visible"
                                      sx={{
                                        marginTop: "-75px",
                                        marginLeft: "25px",
                                      }}
                                    >
                                      <Visibility />
                                    </IconButton>
                                  </a>
                                </Box> */}
                              </>
                            )}

                          {documents &&
                            Array.isArray(documents) &&
                            documents.length > 0 &&
                            documents[0]?.pan_business_document && (
                              <BusinessDocCard
                                data={{
                                  business_document:
                                    documents[0]?.pan_business_document,
                                  document_type: "PAN",
                                  status: documents[0]?.pan_status,
                                  statusKey: "",
                                  _id: documents[0]?._id,
                                  disable_update: true,
                                  link: documents[0]?.pan_business_document,
                                }}
                              />
                            )}
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </div>
        </Box>
      )}

      {userRole !== "Community Super Admin" && (
        <Box
          sx={{
            marginTop: "10px",
            marginLeft: { xs: "20px", md: "25px", lg: "25px" },
            marginRight: { xs: "15px", md: "25px", lg: "25px" },
          }}
        >
          <div className="company-details">
            <Box
              sx={{
                maxWidth: {
                  md: "100%",
                  lg: "100%",
                },
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ paddingBottom: "25px" }}
                  >
                    <Card
                      sx={{
                        marginTop: "0px",
                        height: "100%",
                        backgroundColor: "#d1d1d17a",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "20px",
                              color: "black",
                              marginTop: "-11px",
                              padding: "22px",
                            }}
                          >
                            Bank Details
                          </Typography>
                        </Grid>
                        {getPermissions("Business", "is_edit") ? (
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              padding: "12px",
                              paddingRight: "39px",
                            }}
                            xs={6}
                            md={6}
                          >
                            <Button
                              onClick={() =>
                                handleBankModal(profileData && profileData.data)
                              }
                              sx={{
                                ":hover": {
                                  bgcolor: "#153E80",
                                  color: "#FFFFFF",
                                },
                                textTransform: "none",
                                fontSize: "16px",
                                color: "#FFFFFF",

                                backgroundColor: "#153E80",
                                border: "2px solid #153E80",
                                borderRadius: "15px",
                                height: "37px",
                                marginLeft: "420px",
                                marginTop: "10px",

                                "&.Mui-disabled": {
                                  backgroundColor: "#153E80",
                                  color: "#fff",
                                },
                              }}
                              type="submit"
                              startIcon={
                                <EditIcon sx={{ marginLeft: "10px" }} />
                              }
                            ></Button>
                          </Grid>
                        ) : null}
                      </Grid>

                      <Box
                        sx={{
                          flexGrow: 1,
                          marginTop: "10px",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Account Holder Name
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {subStr(
                                profileData?.data?.bank_details
                                  ?.acc_holder_name || "-",
                                50
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Bank Name
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                                overflowWrap:
                                  "break-word" /* Modern equivalent of word-wrap */,
                                wordBreak:
                                  "break-word" /* Breaks words at arbitrary points if necessary */,
                                whiteSpace: "normal",
                              }}
                            >
                              {subStr(
                                profileData?.data?.bank_details?.bank_name ||
                                  "-",
                                50
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: "12px" }}>
                          <Grid item xs={6}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Account Number
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                                overflowWrap:
                                  "break-word" /* Modern equivalent of word-wrap */,
                                wordBreak:
                                  "break-word" /* Breaks words at arbitrary points if necessary */,
                                whiteSpace: "normal",
                              }}
                            >
                              {subStr(
                                profileData?.data?.bank_details?.acc_number ||
                                  "-",
                                50
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              IFSC Code
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.bank_details?.ifsc_code ||
                                "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </div>
          <div className="company-details">
            <Box
              sx={{
                maxWidth: {
                  md: "100%",
                  lg: "100%",
                },
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ paddingBottom: "25px" }}
                  >
                    <Card
                      sx={{
                        marginTop: "0px",
                        height: "100%",
                        backgroundColor: "#d1d1d17a",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "20px",
                              color: "black",
                              marginTop: "-11px",
                              padding: "22px",
                            }}
                          >
                            Your Review
                          </Typography>
                        </Grid>
                        {getPermissions("Business", "is_edit") ? (
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              padding: "12px",
                              paddingRight: "39px",
                            }}
                            xs={6}
                            md={6}
                          >
                            <Button
                              onClick={() => handleReviewModal()}
                              sx={{
                                ":hover": {
                                  bgcolor: "#153E80",
                                  color: "#FFFFFF",
                                },
                                textTransform: "none",
                                fontSize: "16px",
                                color: "#FFFFFF",

                                backgroundColor: "#153E80",
                                border: "2px solid #153E80",
                                borderRadius: "15px",
                                height: "37px",
                                marginLeft: "420px",
                                marginTop: "10px",

                                "&.Mui-disabled": {
                                  backgroundColor: "#153E80",
                                  color: "#fff",
                                },
                              }}
                              type="submit"
                              startIcon={
                                <EditIcon sx={{ marginLeft: "10px" }} />
                              }
                            ></Button>
                          </Grid>
                        ) : null}
                      </Grid>

                      <Box
                        sx={{
                          marginTop: "10px",
                          overflowWrap: "anywhere",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Box display="flex" maxWidth="100%" flexWrap="wrap">
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                  flexWrap: "wrap",
                                  maxWidth: "100%",
                                }}
                              >
                                {userReviews?.review}
                                {console.log(userReviews)}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </div>
          <Dialog
            open={reviewModel}
            fullWidth={false}
            onClose={false}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            disableScrollLock={true}
            sx={{
              "&.Mui-disabled-scroll": {
                overflow: "hidden !important",
              },
            }}
          >
            <div
              className="mui-dialog-box-popup"
              style={{ backgroundColor: "#fff", color: "#000" }}
            >
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" sx={{ color: "#000 !important" }}>
                  {userReviews && userReviews._id ? `Update` : `Add`} Review
                </Typography>

                <IconButton
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <CloseIcon onClick={handleReviewCloseModal} />
                </IconButton>
              </DialogTitle>

              <DialogContent sx={{ minWidth: "400px" }}>
                <form onSubmit={handleReviewUpdate}>
                  <div style={{ marginTop: "5px" }}>
                    <Input
                      sx={{ input: { color: "#000" } }}
                      maxRows={11}
                      id="update_review"
                      label={
                        userReviews && userReviews._id
                          ? `Update Review`
                          : `Add Review`
                      }
                      type="textarea"
                      fullWidth
                      variant="outlined"
                      value={reviewValue}
                      name="update_review"
                      onChange={(e) => {
                        setReviewValue(e.target.value);
                        if (e.target.value || reviewValue) {
                          setReviewErrors(false);
                        }
                      }}
                      maxLength={500}
                      error={reviewErrors}
                      helperText={reviewErrors ? "Please enter review" : ""}
                    />
                  </div>

                  <DialogActions
                    sx={{ justifyContent: "center", display: "flex" }}
                  >
                    <Button
                      type="submit"
                      disabled={statusUpdateLoading}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        border: "0",
                        ":hover": { bgcolor: "#1976d2", border: "0" },
                        backgroundColor: "#153e80",
                        marginLeft: {
                          xs: "0px",
                          sm: "15px",
                          md: "15px",
                          lg: "15px",
                        },
                        color: "#fff",
                        textTransform: "none",
                        minWidth: "100px",
                      }}
                    >
                      {userReviews && userReviews._id ? `Update` : `Submit`}
                    </Button>
                  </DialogActions>
                </form>
              </DialogContent>
            </div>
          </Dialog>

          <Dialog
            open={EditModal}
            fullWidth={false}
            onClose={false}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            disableScrollLock={true}
            sx={{
              "&.Mui-disabled-scroll": {
                overflow: "hidden !important",
              },
            }}
          >
            <div
              className="mui-dialog-box-popup"
              style={{ backgroundColor: "#fff", color: "#000" }}
            >
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" sx={{ color: "#000 !important" }}>
                  Bank Details
                </Typography>

                <IconButton
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <CloseIcon onClick={handleBankCloseModal} />
                </IconButton>
              </DialogTitle>

              <DialogContent sx={{ minWidth: "400px" }}>
                <form onSubmit={handleBankUpdate}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="bank_name"
                      label="Bank Name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuess.bank_name}
                      name="bank_name"
                      onChange={handleChangess}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                      error={Boolean(bankErrors?.bank_name)}
                      helperText={bankErrors?.bank_name}
                    />
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      margin="dense"
                      id="acc_number"
                      label="Bank Account Number"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuess.acc_number}
                      name="acc_number"
                      onChange={handleChangess}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                      error={Boolean(bankErrors?.acc_number)}
                      helperText={bankErrors?.acc_number}
                    />
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      margin="dense"
                      id="ifsc_code"
                      label="Bank IFSC Code"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuess.ifsc_code}
                      name="ifsc_code"
                      onChange={handleChangess}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                      error={Boolean(bankErrors?.ifsc_code)}
                      helperText={bankErrors?.ifsc_code}
                    />
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      margin="dense"
                      id="acc_holder_name"
                      label="Bank Account Holder"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuess.acc_holder_name}
                      name="acc_holder_name"
                      onChange={handleChangess}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                      error={Boolean(bankErrors?.acc_holder_name)}
                      helperText={bankErrors?.acc_holder_name}
                    />
                  </div>
                  <DialogActions
                    sx={{ justifyContent: "center", display: "flex" }}
                  >
                    <Button
                      type="submit"
                      // disabled={!isAnyFieldNotEmpty}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        border: "0",
                        ":hover": { bgcolor: "#1976d2", border: "0" },
                        backgroundColor: "#153e80",
                        marginLeft: {
                          xs: "0px",
                          sm: "15px",
                          md: "15px",
                          lg: "15px",
                        },
                        color: "#fff",
                        textTransform: "none",
                        minWidth: "100px",
                      }}
                    >
                      Update
                    </Button>
                  </DialogActions>
                </form>
              </DialogContent>
            </div>
          </Dialog>
        </Box>
      )}

      <FormDialog
        // page="profile"
        // selectedImg={editImageValue}
        disableButton={loading}
        open={open}
        dialogTitle={popupName}
        fields={
          popupName === "Edit Profile"
            ? fields.filter((el, index) =>
                [
                  "first_name",
                  "last_name",
                  "email",
                  "mobile_number",
                  "profile_picture",
                  "address",
                ].includes(el.name)
              )
            : fields.filter((el) => !["status"].includes(el.name))
        }
        // loading={popupName === "Edit Profile"}
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
      />

      <Dialog
        open={EditBusinessModal}
        fullWidth={false}
        onClose={false}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        disableScrollLock={true}
        maxWidth="md"
      >
        <div
          className="mui-dialog-box-popup"
          style={{ backgroundColor: "#fff", color: "#000" }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ color: "#000 !important" }}>
              Business Details
            </Typography>

            <IconButton
              sx={{
                color: "black",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#153e80",
                },
                "&:focus": {
                  backgroundColor: "white",
                  color: "#153e80",
                  border: "none",
                },
              }}
            >
              <CloseIcon onClick={handleBusinessCloseModal} />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <form onSubmit={handlBusinessUpdate}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "10px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="business_name"
                      label="Business Name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.business_name || ""}
                      name="business_name"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 50 }}
                      error={Boolean(errorValueBusiness?.business_name)}
                      helperText={errorValueBusiness?.business_name}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "10px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="owner_firstname"
                      label="Owner First Name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.owner_firstname || ""}
                      name="owner_firstname"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 20 }} // Adjust the max length as needed
                      error={Boolean(errorValueBusiness?.owner_firstname)}
                      helperText={errorValueBusiness?.owner_firstname}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="owner_lastname"
                      label="Owner Last Name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.owner_lastname || ""}
                      name="owner_lastname"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 20 }} // Adjust the max length as needed
                      error={Boolean(errorValueBusiness?.owner_lastname)}
                      helperText={errorValueBusiness?.owner_lastname}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="business_email"
                      label="Business Email"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.business_email || ""}
                      name="business_email"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                      error={Boolean(errorValueBusiness?.business_email)}
                      helperText={errorValueBusiness?.business_email}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <MuiPhoneNumber
                      fullWidth
                      defaultCountry={"in"}
                      value={
                        `+${valuesBusiness?.phone_code} ${valuesBusiness?.business_contact}` ||
                        ""
                      }
                      size="small"
                      variant="outlined"
                      id="business_contact"
                      name="business_contact"
                      label="Business Contact"
                      type="text"
                      onChange={(value, e) => {
                        const newContactValue = value
                          .replace("+", "")
                          .slice(e?.dialCode?.length)
                          .replace(/\s+/g, "")
                          .replace(/-/g, "")
                          .replace(/\(/g, "")
                          .replace(/\)/g, "");

                        setValuesBusiness({
                          ...valuesBusiness,
                          business_contact: newContactValue,
                          phone_code: e?.dialCode,
                        });
                      }}
                      sx={{
                        ".css-uxxc54-MuiInputBase-root-MuiOutlinedInput-root": {
                          height: "54px",
                          marginBottom: "10px",
                        },
                      }}
                      InputLabelProps={{ sx: { color: "black" } }}
                      error={Boolean(errorValueBusiness?.business_contact)}
                      helperText={errorValueBusiness?.business_contact}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px", marginBottom: "-5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="establish_year"
                      label="Establish Year"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.establish_year || ""}
                      name="establish_year"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 4 }} // Adjust the max length as needed
                      error={Boolean(errorValueBusiness?.establish_year)}
                      helperText={errorValueBusiness?.establish_year}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6} sx={{ paddingTop: "0px !important" }}>
                  <div style={{ marginTop: "10px" }}>
                    <Autocomplete
                      {...defaultProps}
                      id="business_category_id"
                      name="business_category_id"
                      value={{
                        value: valuesBusiness?.business_category_id || "",
                        label: valuesBusiness?.business_category_name || "",
                      }}
                      autoComplete
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          sx={{ color: "#000" }}
                          {...params}
                          label="Business Category"
                          inputProps={{
                            ...params.inputProps,
                            style: { color: "#000" },
                          }}
                        />
                      )}
                      onChange={(event, newValue) => {
                        setValuesBusiness({
                          ...valuesBusiness,
                          business_category_id: newValue?.value || "",
                          business_category_name: newValue?.label || "",
                        });
                      }}
                      sx={{
                        "& .css-1dylv9m-MuiFormControl-root-MuiTextField-root":
                          {
                            marginTop: "9px",
                          },
                      }}
                      className="form-control form-control-mini border-0 px-0 bg-transparent"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <TextField
                    sx={{
                      marginTop: "10px",
                      marginBottom: "6px",
                      input: { color: "#000" },
                    }}
                    fullWidth
                    variant="outlined"
                    id="min_range_amount"
                    name="min_range_amount"
                    type="text"
                    label="Minimum Range"
                    onChange={handleBusinessChanges}
                    value={valuesBusiness.min_range_amount}
                    error={Boolean(errorValueBusiness?.min_range_amount)}
                    helperText={errorValueBusiness?.min_range_amount}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>

                <Grid item xs={6} md={6}>
                  <TextField
                    sx={{ input: { color: "#000" } }}
                    fullWidth
                    variant="outlined"
                    id="max_range_amount"
                    name="max_range_amount"
                    type="text"
                    label="Maximum Range"
                    onChange={handleBusinessChanges}
                    value={valuesBusiness.max_range_amount || ""}
                    error={Boolean(errorValueBusiness?.max_range_amount)}
                    helperText={errorValueBusiness?.max_range_amount}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <div>
                    <SelectBox
                      type="text"
                      id="range_type"
                      name="range_type"
                      label="Range Type"
                      ClassName="range-select-filter"
                      value={valuesBusiness.range_type}
                      onChange={handleBusinessChanges}
                      options={rangeOptions}
                      mt={"0"}
                      temp="profile_range_type"
                    />
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="business_website"
                      label="Business WebSite"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.business_website || ""}
                      name="business_website"
                      onChange={handleBusinessChanges}
                      error={Boolean(errorValueBusiness?.business_website)}
                      helperText={errorValueBusiness?.business_website}
                      inputProps={{ maxLength: 100 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="annual_turnover"
                      label="Annual Turnover"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.annual_turnover || ""}
                      name="annual_turnover"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 10 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="business_card"
                      label="Business Card"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.business_card || ""}
                      name="business_card"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                      error={Boolean(errorValueBusiness?.business_card)}
                      helperText={errorValueBusiness?.business_card}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="business_gst"
                      label="Business GST"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.business_gst || ""}
                      name="business_gst"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 15 }} // Adjust the max length as needed
                      error={Boolean(errorValueBusiness?.business_gst)}
                      helperText={errorValueBusiness?.business_gst}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="business_pan"
                      label="Business Pan"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.business_pan || ""}
                      name="business_pan"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 10 }} // Adjust the max length as needed
                      error={Boolean(errorValueBusiness?.business_pan)}
                      helperText={errorValueBusiness?.business_pan}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="business_type"
                      label="Business Type"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.business_type || ""}
                      name="business_type"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 30 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div style={{ marginTop: "-2px" }}>
                    <Input
                      id="address"
                      label="Address"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.address || ""}
                      name="address"
                      onChange={handleBusinessChanges}
                      maxLength={200}
                      inputProps={{ maxLength: 200 }} // Adjust the max length as needed
                      maxRows={2}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid item md={12} xs={12} sm={12}>
                    <label>Business Logo</label>
                    <input
                      type="file"
                      accept="image/*"
                      id="image-upload3"
                      name="business_logo"
                      style={{ display: "none" }}
                      onChange={handleImageUpload3}
                    />
                    <label htmlFor="image-upload3">
                      <Button
                        component="span"
                        variant="contained"
                        color="inherit"
                        sx={{ marginLeft: "20px" }}
                      >
                        Upload Logo
                      </Button>
                    </label>
                    <p style={{ color: "#d32f2f", fontSize: "12px" }}>
                      {Boolean(errorValueBusiness?.business_logo) &&
                        errorValueBusiness?.business_logo}
                    </p>
                    {/* {selectedImage1 ? null : (
                <div className="err-msg-font">
                  Please select an image to upload.
                </div>
              )} */}
                  </Grid>
                  <Grid item md={12} xs={12} sm={12}>
                    {selectedImageError1 ? (
                      <div className="err-msg-font">{selectedImageError1}</div>
                    ) : (
                      selectedImage3 && (
                        <Grid item md={12} xs={12} sm={12}>
                          <Typography
                            variant="subtitle1"
                            sx={{ marginTop: "1rem" }}
                          >
                            Selected Image:
                            <br />
                            <a
                              target="_blank"
                              href={
                                typeof selectedImage3 !== "object" &&
                                typeof valuesBusiness.business_logo === "string"
                                  ? valuesBusiness.business_logo
                                  : URL.createObjectURL(selectedImage3)
                              }
                            >
                              <img
                                style={{ width: 80 }}
                                src={
                                  typeof selectedImage3 !== "object" &&
                                  typeof valuesBusiness.business_logo ===
                                    "string"
                                    ? valuesBusiness.business_logo
                                    : URL.createObjectURL(selectedImage3)
                                }
                                loading="lazy"
                              />
                            </a>
                          </Typography>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
                <Divider sx={{ width: "100%", border: "1px solid #D3D3D3" }} />
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12} sm={12}>
                    <input
                      type="file"
                      multiple
                      accept="image/*, video/*"
                      id="image_gallery"
                      name="image_gallery"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                    />
                    <label htmlFor="image_gallery">
                      <Button
                        component="span"
                        variant="contained"
                        color="inherit"
                        sx={{ marginTop: "1rem" }}
                      >
                        <ImageIcon sx={{ marginRight: "0.5rem" }} />
                        Upload Image Gallery
                      </Button>
                    </label>

                    {selectedImageError ? (
                      <div className="err-msg-font">
                        Please upload media files.
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      width: "100%",
                      maxHeight: "200px",
                      overflowX: "scroll",
                      scrollBehavior: "smooth",
                      marginTop: "25px",
                      marginLeft: "25px",
                    }}
                  >
                    {selectedImage && showImages(selectedImage)}
                    <p style={{ color: "#d32f2f", fontSize: "12px" }}>
                      {Boolean(errorValueBusiness?.image_gallery)}
                      {errorValueBusiness?.image_gallery}
                    </p>
                  </Grid>
                </Grid>

                <Grid item md={12} xs={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "17px", fontWeight: "700" }}>
                      Add AboutUs
                    </Typography>
                    <Button
                      type="button"
                      onClick={addAbouUs}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        border: "0",
                        ":hover": { bgcolor: "#1976d2", border: "0" },
                        backgroundColor: "#153e80",
                        marginLeft: {
                          xs: "0px",
                          sm: "15px",
                          md: "15px",
                          lg: "15px",
                        },
                        color: "#fff",
                        textTransform: "none",
                        minWidth: "100px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      Add AboutUs
                    </Button>
                  </div>
                  <div>
                    {aboutUs?.map((about, index) => {
                      return (
                        <div key={index}>
                          <Grid container spacing={2}>
                            <Grid item xs={5} md={5}>
                              <TextField
                                sx={{ input: { color: "#000" } }}
                                id="title "
                                label="Title"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={about.title}
                                name="title "
                                onChange={(e) =>
                                  handleInputChangeAboutUs(
                                    index,
                                    "title",
                                    e.target.value
                                  )
                                }
                                inputProps={{ maxLength: 50 }}
                              />
                            </Grid>
                            <Grid item xs={5} md={5}>
                              <TextField
                                sx={{ input: { color: "#000" } }}
                                id="link "
                                label="Description"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={about.link}
                                name="link "
                                onChange={(e) =>
                                  handleInputChangeAboutUs(
                                    index,
                                    "link",
                                    e.target.value
                                  )
                                }
                                inputProps={{ maxLength: 250 }}
                              />
                            </Grid>

                            <Grid item xs={2} md={2}>
                              <Button
                                type="button"
                                onClick={() => deleteAbouUs(index)}
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  border: "0",
                                  ":hover": { bgcolor: "#eb6767", border: "0" },
                                  backgroundColor: "#153e80",
                                  marginLeft: {
                                    xs: "0px",
                                    sm: "15px",
                                    md: "15px",
                                    lg: "15px",
                                  },
                                  color: "#fff",
                                  textTransform: "none",
                                  minWidth: "81px",
                                  height: "35px",
                                  marginTop: "8px",
                                }}
                              >
                                Delete
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </div>
                </Grid>

                <Grid item md={12} xs={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "17px", fontWeight: "700" }}>
                      Add Card Links
                    </Typography>
                    <Button
                      type="button"
                      onClick={addCardInfo}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        border: "0",
                        ":hover": { bgcolor: "#1976d2", border: "0" },
                        backgroundColor: "#153e80",
                        marginLeft: {
                          xs: "0px",
                          sm: "15px",
                          md: "15px",
                          lg: "15px",
                        },
                        color: "#fff",
                        textTransform: "none",
                        minWidth: "100px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      Add CardLinks
                    </Button>
                  </div>
                  <div>
                    {cardinfoLinks?.map((rep, index) => {
                      const isLinkValid = urlRegex.test(rep.link);

                      return (
                        <div key={index}>
                          <Grid container spacing={2}>
                            <Grid item xs={5} md={5}>
                              <TextField
                                sx={{ input: { color: "#000" } }}
                                id="title "
                                label="Title"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={rep.title}
                                name="title "
                                onChange={(e) =>
                                  handleInputChangeCardInfo(
                                    index,
                                    "title",
                                    e.target.value
                                  )
                                }
                                inputProps={{ maxLength: 100 }}
                              />
                              <p style={{ color: "red" }}>
                                {errorMsgCardTitle}
                              </p>
                            </Grid>

                            <Grid item xs={5} md={5}>
                              <TextField
                                sx={{ input: { color: "#000" } }}
                                id="link "
                                label="Link"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={rep.link}
                                name="link "
                                onChange={(e) =>
                                  handleInputChangeCardInfo(
                                    index,
                                    "link",
                                    e.target.value
                                  )
                                }
                                // error={!isLinkValid}
                                // helperText={
                                //   !isLinkValid ? "Invalid link format" : ""
                                // }
                              />
                              <p style={{ color: "red" }}>{errorMsgCardInfo}</p>
                            </Grid>

                            <Grid item xs={2} md={2}>
                              <Button
                                type="button"
                                onClick={() => deleteCardInfo(index)}
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  border: "0",
                                  ":hover": { bgcolor: "#eb6767", border: "0" },
                                  backgroundColor: "#153e80",
                                  marginLeft: {
                                    xs: "0px",
                                    sm: "15px",
                                    md: "15px",
                                    lg: "15px",
                                  },
                                  color: "#fff",
                                  textTransform: "none",
                                  minWidth: "81px",
                                  height: "35px",
                                  marginTop: "8px",
                                }}
                              >
                                Delete
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </div>
                </Grid>

                <Grid item md={12} xs={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "17px", fontWeight: "700" }}>
                      Add Youtube Video
                    </Typography>
                    <Button
                      type="button"
                      onClick={addYoutubeVideo}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        border: "0",
                        ":hover": { bgcolor: "#1976d2", border: "0" },
                        backgroundColor: "#153e80",
                        marginLeft: {
                          xs: "0px",
                          sm: "15px",
                          md: "15px",
                          lg: "15px",
                        },
                        color: "#fff",
                        textTransform: "none",
                        minWidth: "100px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      Add YoutubeVideo
                    </Button>
                  </div>
                  <div>
                    {youtubeVideo?.map((youtube, index) => {
                      const isLinkValid =
                        youtube.link !== "" ? urlRegex.test(youtube.link) : "";
                      return (
                        <div key={index}>
                          <Grid container spacing={2}>
                            <Grid item xs={5} md={5}>
                              <TextField
                                sx={{ input: { color: "#000" } }}
                                id="title "
                                label="Title"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={youtube.title}
                                name="title "
                                onChange={(e) =>
                                  handleInputChangeYoutubeVideo(
                                    index,
                                    "title",
                                    e.target.value
                                  )
                                }
                                inputProps={{ maxLength: 50 }}
                              />
                              <p style={{ color: "red" }}>
                                {errorMsgYoutubeTitle}
                              </p>
                            </Grid>

                            <Grid item xs={5} md={5}>
                              <TextField
                                sx={{ input: { color: "#000" } }}
                                id="link "
                                label="Link"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={youtube.link}
                                name="youtube_link "
                                onChange={(e) =>
                                  handleInputChangeYoutubeVideo(
                                    index,
                                    "link",
                                    e.target.value
                                  )
                                }
                                // error={isLinkValid}
                                // helperText={
                                //   isLinkValid ? "Invalid link format" : ""
                                // }
                                // error={Boolean(errorValueBusiness?.youtube_link)}
                                // helperText={errorValueBusiness?.youtube_link}
                              />
                              <p style={{ color: "red" }}>
                                {errorMsgYoutubeVideo}
                              </p>
                            </Grid>

                            <Grid item xs={2} md={2}>
                              <Button
                                type="button"
                                onClick={() => deleteYoutubeVideo(index)}
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  border: "0",
                                  ":hover": { bgcolor: "#eb6767", border: "0" },
                                  backgroundColor: "#153e80",
                                  marginLeft: {
                                    xs: "0px",
                                    sm: "15px",
                                    md: "15px",
                                    lg: "15px",
                                  },
                                  color: "#fff",
                                  textTransform: "none",
                                  minWidth: "81px",
                                  height: "35px",
                                  marginTop: "8px",
                                }}
                              >
                                Delete
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </div>
                </Grid>

                <Grid item md={12} xs={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "17px", fontWeight: "700" }}>
                      Add Product By
                    </Typography>
                    <Button
                      type="button"
                      onClick={addProductBy}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        border: "0",
                        ":hover": { bgcolor: "#1976d2", border: "0" },
                        backgroundColor: "#153e80",
                        marginLeft: {
                          xs: "0px",
                          sm: "15px",
                          md: "15px",
                          lg: "15px",
                        },
                        color: "#fff",
                        textTransform: "none",
                        minWidth: "100px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      Add Product By
                    </Button>
                  </div>
                  <div>
                    {productBy?.map((product, index) => {
                      const isLinkValid = urlRegex.test(product.link);
                      return (
                        <div key={index}>
                          <Grid container spacing={2}>
                            <Grid item xs={5} md={5}>
                              <TextField
                                sx={{ input: { color: "#000" } }}
                                id="link "
                                label="Link"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={product.link}
                                name="link "
                                onChange={(e) =>
                                  handleInputChangeProductBy(
                                    index,
                                    "link",
                                    e.target.value
                                  )
                                }
                                // error={!isLinkValid}
                                // helperText={
                                //   !isLinkValid ? "Invalid link format" : ""
                                // }
                              />
                              <p style={{ color: "red" }}>{errorProductBy}</p>
                            </Grid>

                            <Grid item xs={5} md={5}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    id={"image-" + index}
                                    style={{ display: "none" }}
                                    onChange={(e) =>
                                      handleImageUploadProduct(e, index)
                                    }
                                  />
                                  <label htmlFor={"image-" + index}>
                                    <Button
                                      component="span"
                                      variant="contained"
                                      color="inherit"
                                      sx={{
                                        marginTop: "1rem",
                                        width: "170px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Upload Image
                                    </Button>
                                  </label>
                                </Grid>
                                <Grid item md={9} xs={12} sm={12}>
                                  {selectedImageTouched && imageAllowMsg ? (
                                    <div
                                      className="err-msg-font"
                                      style={{
                                        width: "150px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {imageAllowMsg
                                        ? imageAllowMsg
                                        : "Please select an image to upload."}
                                    </div>
                                  ) : (
                                    product?.image && (
                                      <Grid item md={12} xs={12} sm={12}>
                                        <Typography
                                          variant="subtitle1"
                                          sx={{ marginTop: "0.5rem" }}
                                        >
                                          Selected Image:
                                          <br />
                                          <img
                                            src={product?.image}
                                            alt="Selected Image"
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                              marginTop: "10px",
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          />
                                        </Typography>
                                      </Grid>
                                    )
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={2} md={2}>
                              <Button
                                type="button"
                                onClick={() => deleteProductBy(index)}
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  border: "0",
                                  ":hover": { bgcolor: "#eb6767", border: "0" },
                                  backgroundColor: "#153e80",
                                  marginLeft: {
                                    xs: "0px",
                                    sm: "15px",
                                    md: "15px",
                                    lg: "15px",
                                  },
                                  color: "#fff",
                                  textTransform: "none",
                                  minWidth: "81px",
                                  height: "35px",
                                  marginTop: "8px",
                                }}
                              >
                                Delete
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </div>
                </Grid>

                <Grid item md={12} xs={12}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "17px", fontWeight: "700" }}>
                        Add Representative
                      </Typography>
                      <Button
                        type="button"
                        onClick={addRepresentative}
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          border: "0",
                          ":hover": { bgcolor: "#1976d2", border: "0" },
                          backgroundColor: "#153e80",
                          marginLeft: {
                            xs: "0px",
                            sm: "15px",
                            md: "15px",
                            lg: "15px",
                          },
                          color: "#fff",
                          textTransform: "none",
                          minWidth: "100px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Add Representative
                      </Button>
                    </div>
                    {representatives?.map((rep, index) => (
                      <div key={index}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={6}>
                            {/* <MuiPhoneNumber
                              fullWidth
                              defaultCountry={"in"}
                              size="small"
                              id="mobile_number "
                              label="Mobile number"
                              type="text"
                              variant="outlined"
                              value={rep.mobile_number || ""}
                              name="mobile_number "
                              onChange={(e) =>
                                handleInputChangeRep(index, "mobile_number", e)
                              }
                              InputLabelProps={{ sx: { color: "black" } }}
                            /> */}

                            <TextField
                              sx={{ input: { color: "#000" } }}
                              id="mobile_number"
                              label="Mobile Number"
                              type="text"
                              fullWidth
                              variant="outlined"
                              value={rep.mobile_number}
                              name="mobile_number"
                              onChange={(e) =>
                                handleInputChangeRep(
                                  index,
                                  "mobile_number",
                                  e.target.value
                                )
                              }
                              inputProps={{ maxLength: 10 }}
                            />
                            <p style={{ color: "red" }}>{errorMsgRepMobile}</p>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <TextField
                              sx={{ input: { color: "#000" } }}
                              id="email "
                              label="Email"
                              type="text"
                              fullWidth
                              variant="outlined"
                              value={rep.email}
                              name="email "
                              onChange={(e) =>
                                handleInputChangeRep(
                                  index,
                                  "email",
                                  e.target.value
                                )
                              }
                              inputProps={{ maxLength: 50 }}
                            />
                            <p style={{ color: "red" }}>{errorMsgRepEmail}</p>
                          </Grid>

                          <Grid item xs={6} md={6}>
                            <TextField
                              sx={{ input: { color: "#000" } }}
                              id="first_name "
                              label="Name"
                              type="text"
                              fullWidth
                              variant="outlined"
                              value={rep.first_name}
                              name="first_name "
                              onChange={(e) =>
                                handleInputChangeRep(
                                  index,
                                  "first_name",
                                  e.target.value
                                )
                              }
                              inputProps={{ maxLength: 20 }}
                            />
                            <p style={{ color: "red" }}>
                              {errorMsgRepFirstName}
                            </p>
                          </Grid>

                          <Button
                            type="button"
                            onClick={() => deleteRepresentative(index)}
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              border: "0",
                              ":hover": { bgcolor: "#eb6767", border: "0" },
                              backgroundColor: "#153e80",
                              marginLeft: {
                                xs: "0px",
                                sm: "15px",
                                md: "15px",
                                lg: "15px",
                              },
                              color: "#fff",
                              textTransform: "none",
                              minWidth: "81px",
                              height: "35px",
                              marginTop: "21px",
                            }}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>

              <DialogActions sx={{ justifyContent: "center", display: "flex" }}>
                <Button
                  type="submit"
                  // disabled={!isAnyFieldNotEmpty}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    border: "0",
                    ":hover": { bgcolor: "#1976d2", border: "0" },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    color: "#fff",
                    textTransform: "none",
                    minWidth: "100px",
                  }}
                >
                  Update
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </div>
      </Dialog>

      <Dialog
        open={EditBusinessDocModal}
        fullWidth={false}
        onClose={false}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <div
          className="mui-dialog-box-popup"
          style={{ backgroundColor: "#fff", color: "#000" }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ color: "#000 !important" }}>
              Business Document
            </Typography>

            <IconButton
              sx={{
                color: "black",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#153e80",
                },
                "&:focus": {
                  backgroundColor: "white",
                  color: "#153e80",
                  border: "none",
                },
              }}
            >
              <CloseIcon onClick={handleBusinessDocCloseModal} />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ minWidth: "400px" }}>
            <form onSubmit={handlBusinessDocUpdate}>
              <Grid>
                {documents && Array.isArray(documents) && (
                  <Grid item xs={6} md={6}>
                    <Grid
                      item
                      md={3}
                      xs={12}
                      sm={12}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <label>GST Document</label>
                      <input
                        type="file"
                        accept="image/*,application/pdf"
                        id="image-upload1"
                        style={{ display: "none" }}
                        onChange={handleImageUpload1}
                      />
                      <label htmlFor="image-upload1">
                        <Button
                          component="span"
                          variant="contained"
                          color="inherit"
                        >
                          Upload GST
                        </Button>
                      </label>
                    </Grid>
                    <Grid item md={9} xs={12} sm={12}>
                      {IsValidGSTDocument.msg ? (
                        <div className="err-msg-font mt-2 mb-2">
                          {IsValidGSTDocument.msg}
                        </div>
                      ) : IsValidGSTDocument.isExist ? (
                        <div
                          className="mt-2 mb-2"
                          style={{
                            overflowWrap:
                              "break-word" /* Modern equivalent of word-wrap */,
                            wordBreak:
                              "break-word" /* Breaks words at arbitrary points if necessary */,
                            whiteSpace: "normal",
                          }}
                        >
                          <span>
                            {selectedImage1
                              ? selectedImage1.name
                              : selectedImage1}
                          </span>
                        </div>
                      ) : (
                        selectedImage1 &&
                        profileData?.data && (
                          <Grid item md={12} xs={12} sm={12}>
                            <Typography
                              variant="subtitle1"
                              sx={{ marginTop: "1rem" }}
                            >
                              <a
                                target="_blank"
                                href={selectedImage1.name || selectedImage1}
                              >
                                {/* {selectedImage1.name || selectedImage1} */}
                                View GST
                              </a>
                            </Typography>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Grid>
                )}
                <div className="mt-2 mb-2">
                  <Divider />
                </div>
                {documents && Array.isArray(documents) && (
                  <Grid item xs={6} md={6}>
                    <Grid
                      item
                      md={3}
                      xs={12}
                      sm={12}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <label>PAN Document</label>
                      <input
                        type="file"
                        accept="image/*,application/pdf"
                        id="image-upload2"
                        style={{ display: "none" }}
                        onChange={handleImageUpload2}
                      />
                      <label htmlFor="image-upload2">
                        <Button
                          component="span"
                          variant="contained"
                          color="inherit"
                          style={{ marginLeft: "16px" }}
                        >
                          Upload Pan
                        </Button>
                      </label>
                      {/* {selectedImage1 ? null : (
                <div className="err-msg-font">
                  Please select an image to upload.
                </div>
              )} */}
                    </Grid>
                    <Grid item md={9} xs={12} sm={12}>
                      {IsValidPANDocument.msg ? (
                        <div className="err-msg-font mt-2 mb-2">
                          {IsValidPANDocument.msg}
                        </div>
                      ) : IsValidPANDocument.isExist ? (
                        <div
                          className="mt-2 mb-2"
                          style={{
                            overflowWrap:
                              "break-word" /* Modern equivalent of word-wrap */,
                            wordBreak:
                              "break-word" /* Breaks words at arbitrary points if necessary */,
                            whiteSpace: "normal",
                          }}
                        >
                          <span>
                            {selectedImage2
                              ? selectedImage2.name
                              : selectedImage2}
                          </span>
                        </div>
                      ) : (
                        selectedImage2 &&
                        profileData?.data && (
                          <Grid item md={12} xs={12} sm={12}>
                            <Typography
                              variant="subtitle1"
                              sx={{ marginTop: "1rem" }}
                            >
                              <a
                                target="_blank"
                                href={selectedImage2.name || selectedImage2}
                              >
                                {/* {selectedImage2.name || selectedImage2} */}
                                View PAN
                              </a>
                            </Typography>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <DialogActions sx={{ justifyContent: "center", display: "flex" }}>
                <Button
                  type="submit"
                  // disabled={!isAnyFieldNotEmpty}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    border: "0",
                    ":hover": { bgcolor: "#1976d2", border: "0" },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    color: "#fff",
                    textTransform: "none",
                    minWidth: "100px",
                  }}
                >
                  Update
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </div>
      </Dialog>

      <Dialog
        open={changePassword}
        fullWidth={false}
        onClose={false}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        disableScrollLock={true}
        sx={{
          "&.Mui-disabled-scroll": {
            overflow: "hidden !important",
          },
        }}
      >
        <div
          className="mui-dialog-box-popup"
          style={{ backgroundColor: "#fff", color: "#000" }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ color: "#000 !important" }}>
              Change Password
            </Typography>

            <IconButton
              sx={{
                color: "black",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#153e80",
                },
                "&:focus": {
                  backgroundColor: "white",
                  color: "#153e80",
                  border: "none",
                },
              }}
            >
              <CloseIcon onClick={handleChangePasswordModalClose} />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ minWidth: "400px" }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12} sm={12}>
                  <div>
                    <FormControl
                      sx={{ marginTop: "10px", width: "100%" }}
                      variant="outlined"
                    >
                      <InputLabel
                        htmlFor="outlined-adornment-password"
                        sx={{
                          color: "#000",
                          bgcolor: "#fff",
                        }}
                      >
                        Old Password
                      </InputLabel>
                      <OutlinedInput
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            paddingTop: "10px!important",
                            borderColor: "rgba(107, 114, 128, .5)",

                            borderRadius: "7px!important",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "blue",
                          },
                          paddingTop: "0px",
                          color: "#000",
                        }}
                        id="old_password"
                        name="old_password"
                        type={showOldPassword ? "text" : "password"}
                        label="Old Password"
                        onChange={formik.handleChange}
                        value={formik.values.old_password}
                        onBlur={formik.handleBlur}
                        inputProps={{ maxLength: 25 }}
                        error={
                          formik.touched.old_password &&
                          Boolean(formik.errors.old_password)
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              sx={{
                                color: "#000",
                                "&:hover": {
                                  backgroundColor: "white !important",
                                },
                              }}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowOldPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showOldPassword ? (
                                <VisibilityOff
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "white !important",
                                      color: "black !important",
                                    },
                                  }}
                                />
                              ) : (
                                <Visibility
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "white !important",
                                      color: "black !important",
                                    },
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        // helperText={
                        //   formik.touched.confirm_password &&
                        //   formik.errors.confirm_password ? (
                        //     <div className="err-msg err-msg-font">
                        //       {formik.errors.confirm_password}
                        //     </div>
                        //   ) : null
                        // }
                      />
                      {formik.touched.old_password &&
                      Boolean(formik.errors.old_password) ? (
                        <p
                          style={{
                            color: "rgb(255 0 0)",
                            fontSize: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {formik.errors.old_password}
                        </p>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </div>
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  <div>
                    <FormControl sx={{ width: "100%" }} variant="outlined">
                      <InputLabel
                        htmlFor="outlined-adornment-password"
                        sx={{
                          color: "#000",
                          bgcolor: "#fff",
                        }}
                      >
                        Password
                      </InputLabel>
                      <OutlinedInput
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            paddingTop: "10px!important",
                            borderColor: "rgba(107, 114, 128, .5)",

                            borderRadius: "7px!important",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "blue",
                          },
                          paddingTop: "0px",
                          color: "#000",
                        }}
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        onBlur={formik.handleBlur}
                        inputProps={{ maxLength: 25 }}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              sx={{
                                color: "#000",
                                "&:hover": {
                                  backgroundColor: "white !important",
                                },
                              }}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "white !important",
                                      color: "black !important",
                                    },
                                  }}
                                />
                              ) : (
                                <Visibility
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "white !important",
                                      color: "black !important",
                                    },
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        // helperText={
                        //   formik.touched.confirm_password &&
                        //   formik.errors.confirm_password ? (
                        //     <div className="err-msg err-msg-font">
                        //       {formik.errors.confirm_password}
                        //     </div>
                        //   ) : null
                        // }
                      />
                      {formik.touched.password &&
                      Boolean(formik.errors.password) ? (
                        <p
                          style={{
                            color: "rgb(255 0 0)",
                            fontSize: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {formik.errors.password}
                        </p>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </div>
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  <div>
                    <FormControl sx={{ width: "100%" }} variant="outlined">
                      <InputLabel
                        htmlFor="outlined-adornment-password"
                        sx={{
                          color: "#000",
                          bgcolor: "#fff",
                        }}
                      >
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            paddingTop: "10px!important",
                            borderColor: "rgba(107, 114, 128, .5)",

                            borderRadius: "7px!important",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "blue",
                          },
                          paddingTop: "0px",
                          color: "#000",
                        }}
                        id="confirm_password"
                        name="confirm_password"
                        type={showConfirmPassword ? "text" : "password"}
                        label="Confirm Password"
                        onChange={formik.handleChange}
                        value={formik.values.confirm_password}
                        onBlur={formik.handleBlur}
                        inputProps={{ maxLength: 25 }}
                        error={
                          formik.touched.confirm_password &&
                          Boolean(formik.errors.confirm_password)
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              sx={{
                                color: "#000",
                                "&:hover": {
                                  backgroundColor: "white !important",
                                },
                              }}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "white !important",
                                      color: "black !important",
                                    },
                                  }}
                                />
                              ) : (
                                <Visibility
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "white !important",
                                      color: "black !important",
                                    },
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        // helperText={
                        //   formik.touched.confirm_password &&
                        //   formik.errors.confirm_password ? (
                        //     <div className="err-msg err-msg-font">
                        //       {formik.errors.confirm_password}
                        //     </div>
                        //   ) : null
                        // }
                      />
                      {formik.touched.confirm_password &&
                      Boolean(formik.errors.confirm_password) ? (
                        <p
                          style={{
                            color: "rgb(255 0 0)",
                            fontSize: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {formik.errors.confirm_password}
                        </p>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
              <DialogActions sx={{ justifyContent: "center", display: "flex" }}>
                <Button
                  type="submit"
                  // disabled={!isAnyFieldNotEmpty}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    border: "0",
                    ":hover": { bgcolor: "#1976d2", border: "0" },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    color: "#fff",
                    textTransform: "none",
                    minWidth: "100px",
                  }}
                  disabled={changePasswordLoading ? true : false}
                >
                  Change
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default Profile;
