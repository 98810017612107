import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

export const GetAllStates = (id, is_active) => async (dispatch) => {
  try {
    dispatch({
      type: "LOADING",
      payload: true,
    });

    let url = `${ApiURL}state/state-by-countryid/${id}`;
    if (is_active) {
      url = `${ApiURL}state/state-by-countryid/${id}?is_active=${is_active}`;
    }

    const res = await axios.get(url);

    if (res.data.status === true) {
      dispatch({
        type: "LOADING",
        payload: false,
      });
      dispatch({
        type: "ALL_STATES_SUCCESS",
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: "LOADING",
      payload: false,
    });
  }
};

export const cleareAllStates = () => async (dispatch) => {
  dispatch({
    type: "ALL_STATES_SUCCESS",
    payload: [],
  });
};

export const GetStates =
  (page, search = "", rowsPerPage) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "LOADING",
        payload: true,
      });

      const res = await axios.get(
        `${ApiURL}state/all-state?limit=${rowsPerPage}&page=${page}&search=${search}`
      );

      if (res.data.status === true) {
        dispatch({
          type: "LOADING",
          payload: false,
        });
        dispatch({
          type: "STATES_SUCCESS",
          payload: res.data.data,
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "LOADING",
        payload: false,
      });
    }
  };

export const CreateRole = (data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_ROLE_LOADING",
      payload: true,
    });
    const res = await axios.post(`${ApiURL}role/create-roles`, data);

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_ROLE_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      // handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "CREATE_ROLE_LOADING",
      payload: false,
    });
  }
};

export const DeleteState = (id, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_STATE_LOADING",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}state/delete-state/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_STATE_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "DELETE_STATE_LOADING",
      payload: false,
    });
  }
};

export const UpdateState = (id, data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_STATE_LOADING",
      payload: true,
    });
    const res = await axios.put(`${ApiURL}state/update-state/${id}`, data);

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_STATE_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "UPDATE_STATE_LOADING",
      payload: false,
    });
  }
};

export const GetUserRoleList =
  (search, limit = 10, page = 1) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "LOADING_USER_ROLE_LIST",
        payload: true,
      });

      const res = await axios.get(
        `${ApiURL}role/all-roles?limit=${limit}&page=${page}&role=${
          search || ""
        }`
      );

      if (res.data.status === true) {
        dispatch({
          type: "LOADING_USER_ROLE_LIST",
          payload: false,
        });
        dispatch({
          type: "GET_USER_ROLE_LIST_SUCCESS",
          payload: res.data.data,
        });

        localStorage.setItem("role_data", JSON.stringify(res.data.data));
      } else {
        dispatch({
          type: "LOADING_USER_ROLE_LIST",
          payload: false,
        });
        dispatch({
          type: "GET_USER_ROLE_LIST_SUCCESS",
          payload: [],
        });
        localStorage.setItem("role_data", JSON.stringify([]));
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "LOADING_USER_ROLE_LIST",
        payload: false,
      });
    }
  };
