import { api } from "../config/api";

export const LoginApi = async (body) => {
  try {
    const response = await api.post("user/login", body);
    const responseData = response;
    if (responseData?.status === true) {
      localStorage.setItem("userData", JSON.stringify(responseData?.data));
      localStorage.setItem("user_data", JSON.stringify(responseData?.data));
      localStorage.setItem("access_token", responseData?.data?.token);
      localStorage.setItem("uuid", JSON.stringify(body?.device_id));
    }
    return responseData;
  } catch (error) {
    return error;
  }
};
