import React, { Component, createRef } from "react";
import { Map as LeafletMap, TileLayer, Marker, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import { Context } from "./Context";
import icon from "./MapConstant";
import "./map.css";

class MapLeaflet extends Component {
  constructor(props) {
    super(props);
    this.mapRef = createRef();
    this.state = {
      showCtrlScrollMessage: false,
    };
  }

  componentDidMount() {
    const waitForMap = setInterval(() => {
      if (
        this.mapRef &&
        this.mapRef.current &&
        this.mapRef.current.leafletElement
      ) {
        clearInterval(waitForMap);

        const map = this.mapRef.current.leafletElement;
        this.props.setMap(map);

        map.whenReady(() => {
          map.scrollWheelZoom.disable();

          const mapContainer = map.getContainer();
          if (mapContainer) {
            const wheelHandler = (e) => {
              if (e.ctrlKey && e.isTrusted) {
                e.preventDefault();
                map.scrollWheelZoom.enable();
                this.setState({ showCtrlScrollMessage: false });
              } else {
                map.scrollWheelZoom.disable();
                if (!e.ctrlKey && !this.state.showCtrlScrollMessage) {
                  this.setState({ showCtrlScrollMessage: true });
                }
              }
            };

            mapContainer.addEventListener("wheel", wheelHandler);

            map.on("blur", () => {
              map.scrollWheelZoom.disable();
              mapContainer.removeEventListener("wheel", wheelHandler);
            });
          }
        });
      }
    }, 100);
  }

  render() {
    const markerIcon = (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="100pt"
        height="40pt"
        viewBox="0 0 50.000000 50.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
          fill="#e50000"
          stroke="none"
        >
          <path
            d="M191 483 c-107 -56 -101 -183 17 -365 20 -32 39 -58 42 -58 9 0 79
    115 104 172 35 79 42 144 19 187 -37 70 -117 98 -182 64z m101 -90 c22 -20 23
    -61 1 -85 -34 -38 -103 -11 -103 40 0 54 62 81 102 45z"
          />
          <path
            d="M97 146 c-59 -16 -92 -45 -83 -74 16 -50 183 -84 316 -64 195 28 216
    107 40 146 -22 4 -30 -2 -64 -54 -23 -36 -45 -60 -56 -60 -11 0 -33 24 -56 60
    -22 33 -41 60 -44 59 -3 0 -27 -6 -53 -13z"
          />
        </g>
      </svg>
    );
    const iconMarkup = renderToStaticMarkup(markerIcon);
    const customMarkerIcon = divIcon({
      html: iconMarkup,
    });

    return (
      <>
        {this.state.showCtrlScrollMessage && (
          <div
            style={{
              position: "absolute",
              marginTop: "70px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              padding: "10px",
              borderRadius: "5px",
              zIndex: 1000,
            }}
          >
            Please press Ctrl + scroll to zoom
          </div>
        )}

        <LeafletMap
          style={{ width: "100%", height: "100%" }}
          ref={this.mapRef}
          center={[22.9839956, 72.4905409]}
          zoom={7}
          zoomControl={false}
          minZoom={3}
          maxZoom={20}
          scrollWheelZoom={false}
          touchZoom={true}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?"
          />
          <Marker
            position={[23.110423589782933, 72.60526179463511]}
            icon={icon}
          >
            <Tooltip>
              <span>
                <b>Global Garner Sales Services Limited</b>
              </span>
              <br />
              <span>
                5th floor,Grand Emporio, Motera Stadium Road,
                <br />
                Ahmedabad, Gujarat 380005
              </span>
            </Tooltip>
          </Marker>
        </LeafletMap>
      </>
    );
  }
}

export default (props) => (
  <Context.Consumer>
    {({ setMap }) => <MapLeaflet {...props} setMap={setMap} />}
  </Context.Consumer>
);
