import React from "react";
import { Banner } from "../Banners/Banner";
import { Link } from "react-router-dom";

import nextArrow from "../../assets/GG_C_Events/whtRightArw.svg";

export const Head = ({ title }) => {
  return (
    <>
      <div style={{ width: "100%", position: "relative", height: "auto" }}>
        <div style={{ width: "100%", opacity: "70%" }}>
          <Banner bannerTitle={title} />
        </div>
        
        <div
          style={{
            height: "100%",
            backgroundColor: "#000",
            opacity: "50%",
            width: "100%",
            position: "absolute",
            top: "0%",
          }}
        ></div>

        {title === "Some Tips & Articles" ? (
          <div
            className="mb-2"
            style={{
              position: "absolute",
              top: "33%",
              left: "37%",
              textAlign: "center",
            }}
          >
            <h1
              style={{
                width: "100%",
                fontSize: "36px",
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              {title}
            </h1>

            <Link
              to="/"
              style={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: "17px",
                textDecoration: "none",
              }}
            >
              Home
            </Link>
            <span
              style={{
                color: "#fff",
                marginLeft: "10px",
                fontWeight: "bold",
                fontSize: "17px",
              }}
            >
              {" "}
              <img src={nextArrow} />
            </span>
            <span
              style={{
                color: "#fff",
                marginLeft: "10px",
                fontWeight: "bold",
                fontSize: "17px",
              }}
            >
              {title}
            </span>
          </div>
        ) : (
          <div
            className="mb-2"
            style={{
              position: "absolute",
              top: "33%",
              left: "45%",
              textAlign: "center",
            }}
          >
            <h1
              style={{
                width: "100%",
                fontSize: "36px",
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              {title}
            </h1>
            <Link
              to="/"
              style={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: "17px",
                textDecoration: "none",
              }}
            >
              Home
            </Link>
            <span
              style={{
                color: "#fff",
                marginLeft: "10px",
                fontWeight: "bold",
                fontSize: "17px",
              }}
            >
              {" "}
              <img src={nextArrow} />
            </span>
            <span
              style={{
                color: "#fff",
                marginLeft: "10px",
                fontWeight: "bold",
                fontSize: "17px",
              }}
            >
              {title}
            </span>
          </div>
        )}
      </div>
    </>
  );
};
