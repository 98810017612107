import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

const axiosApiCall = () =>
  axios.create({
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });

export const GetAllNotifications =
  (page, rowsPerPage, title = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "NOTIFICATION_GET_LOADING",
        payload: true,
      });

      const res = await axios.get(
        `${ApiURL}notification/get-all?limit=${rowsPerPage}&page=${page}&title=${title}`
      );

      if (res.data.status === true) {
        const responseData =
          res.data.data || res.data.data.length > 0 ? res.data.data : [];
        dispatch({
          type: "NOTIFICATION_GET_SUCCESS",
          payload: responseData,
        });
      } else {
        dispatch({
          type: "NOTIFICATION_GET_SUCCESS",
          payload: [],
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "NOTIFICATION_GET_LOADING",
        payload: false,
      });
    }
  };

export const CreateNotifications = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_LOADING_NOTIFICATION",
      payload: true,
    });

    const res = await axiosApiCall().post(`${ApiURL}notification/create`, data);

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_NOTIFICATION_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    } else {
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.message, err?.response?.status);
  } finally {
    dispatch({
      type: "CREATE_LOADING_NOTIFICATION",
      payload: false,
    });
  }
};

export const UpdateNotification = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_LOADING_NOTIFICATION",
      payload: true,
    });

    const res = await axiosApiCall().put(
      `${ApiURL}notification/update/${id}`,
      data
    );

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_NOTIFICATION_SUCCESS",
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "UPDATE_LOADING_NOTIFICATION",
      payload: false,
    });
  }
};

export const DeleteNotification = (id, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_NOTIFICATION_LOADING",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}notification/delete/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_NOTIFICATION_LOADING",
        payload: false,
      });
      dispatch({
        type: "DELETE_NOTIFICATION_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
    dispatch({
      type: "DELETE_NOTIFICATION_LOADING",
      payload: false,
    });
  }
};

export const SendNotifications = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "SEND_LOADING_NOTIFICATION",
      payload: true,
    });

    const res = await axios.post(`${ApiURL}notification/send`, data);

    if (res.data.status === true) {
      dispatch({
        type: "SEND_NOTIFICATION_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.message, err?.response?.status);
  } finally {
    dispatch({
      type: "SEND_LOADING_NOTIFICATION",
      payload: false,
    });
  }
};
