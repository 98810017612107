import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Select,
  Typography,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useFormik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as Yup from "yup";
import axios from "axios";
import Input from "../../components/Input";
import SelectBox from "../../components/SelectBox";
import { base_Url, snackBar } from "../../utils/common";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../web/config/api";
import { ApiURL } from "../../../redux/common";
import { GetUserRole } from "../../../redux/action/UserManageAction";
import moment from "moment";
import { addYears, isAfter, subYears } from "date-fns";
import dayjs from "dayjs";

const timer = 60;

var emailValidation =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

var mobileValidation = /^\+\d{2}\s\d{5}-\d{5}$/;
// /^\+\d{1,3} \d{10}$/

const checkEmailExistOrNot = async (value) => {
  let body = {
    existence_check: value,
  };
  try {
    await axios
      .post(`${base_Url}/user/existence-check`, body)
      .then((res) => { });
  } catch (error) { }
};
const dateRegExp = /^\d{2}\/\d{2}\/\d{4}$/;

const validationSchemaForm = Yup.object().shape({
  first_name: Yup.string()
    .required("Please enter first name")
    .matches(/^[A-Za-z][A-Za-z\s]*$/, "Please enter a valid first name without numbers and without leading spaces"),
  last_name: Yup.string()
    .required("Please enter last name")
    .matches(/^[A-Za-z][A-Za-z\s]*$/, "Please enter a valid last name without numbers and without leading spaces"),
  refferel_type: Yup.string().required("Please select refferel type"),
  referred_by: Yup.string().required("Please enter referral code"),

  email: Yup.string()
    .required("Please enter email")
    .matches(emailValidation, "Please enter valid email")
    // .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(com|org|gov|edu|net|mil|in|co)$/, "Please enter valid email")
    .test("Unique email", "Email already in use", async function (value) {
      if (!this.parent.email) {
        // No need to make API call if email is empty
        return true;
      }
      try {
        // Only make API call if email validation is successful
        await Yup.string().matches(emailValidation).validate(value);
        const response = await axios.post(`${base_Url}user/existence-check`, {
          existence_check: value,
        });
        // Check if email already exists in the database
        return response.data.status;
      } catch (error) {
        console.error("Error checking email existence:", error);
        // If an error occurs, consider it as a failure of uniqueness check
        return false;
      }
    }),
  mobile_number: Yup.string()
    .required("Please enter mobile number")
    .matches(mobileValidation, "Please enter a valid mobile number")
    .test(
      "Unique mobile number",
      async function (value) {
        if (!value) {
          // No need to make API call if value is empty
          return true;
        }
        try {
          // Validate the mobile number format before making the API call
          await Yup.string().matches(mobileValidation).validate(value);
  
          // Make the API call to check if the mobile number already exists
          const response = await axios.post(`${base_Url}user/existence-check`, {
            existence_check: value.replace(/^\+\d+\s?|\-/g, ""),
          });
  
          // Check if the mobile number already exists in the database
          if (response.data.status) {
            // If mobile number exists, return false and provide a custom message
            return true;
          } else {

            return this.createError({
              message: response.data.message || "Mobile number already in use",
            });
          }
  
          // If mobile number does not exist, return true
        } catch (error) {
          console.error("Error checking mobile number existence:", error);
  
          // If an error occurs, return false with a generic error message
          return this.createError({
            message: "Error checking mobile number existence. Please try again.",
          });
        }
      }
    ),
  // description: Yup.string().required("Please enter Description"),
  gender: Yup.string().required("Please select gender"),
  birth_date: Yup.string()
    .required("Please select birth date")
    .test(
      "date-format",
      "Date format must be DD/MM/YYYY ex. 01/01/2001",
      function (value) {
        if (!value) return false;
        const bDate = moment(value).format("DD/MM/YYYY");
        return dateRegExp.test(bDate);
      }
    )
    .test("age-check", "You must be at least 18 years old", function (value) {
      const bDate = moment(value).format("DD/MM/YYYY");
      if (!bDate || !dateRegExp.test(bDate)) return false;

      const [day, month, year] = bDate.split("/");
      const birthDate = new Date(year, month - 1, day);
      const today = new Date();
      const minAgeDate = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate()
      );

      return birthDate <= minAgeDate;
    }),
  password: Yup.string()
    .required("Please enter a password")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  confirm_password: Yup.string()
    .required("Please enter confirm password")
    .min(8, "Password must be at least 8 characters")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  otp: Yup.string()
    .required("Please verify otp")
    .max(6, "Otp must be exactly 6 numbers")
    .min(6, "Otp must be exactly 6 numbers"),
});

const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Transgender", value: "transgender" },
  { label: "Other", value: "other" },
];

const useStyles = makeStyles((theme) => ({
  customComponent: {
    marginBottom: `${theme.spacing(3)} !important`,
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100% !important",
    marginTop: "0px !important",
  },
  formControl: {
    // border: '1px solid #ced4da !important',
    // padding: '0.375rem 0.75rem !important',
    marginLeft: "0 !important",
    marginTop: "0 !important",
    marginRight: "0 !important",
    width: "100% !important",
    cursor: "not-allowed !important",
  },
  formControl_Input: {
    width: "100%", // Adjust width as needed
    marginBottom: `${theme.spacing(1)} !important`, // Adjust spacing between input and label
    cursor: "not-allowed !important",
  },
  copyButton: {
    cursor: "pointer !important",
    // padding: '0.375rem 0.75rem !important',
    backgroundColor: "#007bff !important",
    border: "1px solid #007bff !important",
    color: "#fff !important",
    borderRadius: "0 0.25rem 0.25rem 0 !important",
    transition:
      "background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !important",
    "&:hover": {
      backgroundColor: "#0056b3 !important",
      borderColor: "#0056b3 !important",
    },
    marginTop: "0px !important",
  },
}));

const Step1 = ({
  handleBack,
  handleNext,
  activeStep,
  value,
  isRefferalVerified,
  setisRefferalVerified,
  isOtpVerified,
  setIsOtpVerified,
}) => {
  const initialDate = new Date();
  const [chapterList, setChapterList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [roleId, setRoleId] = React.useState(false);

  const [referredData, setReferredData] = useState();

  const simplifiedReferredData = referredData?.map(
    ({ first_name, last_name, _id }) => ({ first_name, last_name, _id })
  );

  const simpleData = simplifiedReferredData?.map((user) => ({
    value: `${user._id}`,
    label: `${user.first_name} ${user.last_name}`,
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const removeRoles = ["Community Super Admin", "Community Admin"];

  useEffect(() => {
    dispatch(GetUserRole());
  }, []);

  const { roleData } = useSelector((state) => state.UserManageReducer);
  const location = useLocation();

  const allRolesData =
    roleData &&
    roleData?.roleData
      ?.map((el) => {
        return { value: el._id, label: el.role };
      })
      .filter((data) => !removeRoles.includes(data.label));

  // const dispatch = useDispatch();
  // const { getAllChapter } = useSelector((state) => state.ChapterReducer);

  const getAllChapter = async (rowsPerPage, page, search) => {
    try {
      const res = await axios.get(`${base_Url}chapter/all-chapter?status=approved`);
      if (res.data.status === true) {
        setChapterList(
          res?.data?.data?.chapterData.map((el) => {
            return { value: el?._id, label: el.chapter_name };
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllChapter();
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: value.first_name || "",
      last_name: value.last_name || "",
      email: value.email || "",
      mobile_number: value.mobile_number || "",
      refferel_type: value.refferel_type || "",
      referred_by: value.referred_by || "",
      gender: value.gender || "",
      birth_date: value.birth_date || null,
      otp: value.otp || "",
      password: value?.password || "",
      confirm_password: value?.confirm_password || "",
      // role: value?.role || "",
    },
    validationSchema: validationSchemaForm,
    onSubmit: (values) => {
      handleNext(values);
    },
  });

  const [otpGetButton, setOtpGetButton] = useState(false);
  const [otpGetButtonSeconds, setOtpGetButtonSeconds] = useState();
  const [otpApiCalledOneTime, setotpApiCalledOneTime] = useState(false);
  const [isRefferedBy, setIsRefferedBy] = useState();

  const handleOtpClick = async () => {
    setotpApiCalledOneTime(true);
    setOtpGetButtonSeconds(timer);
    setOtpGetButton(true);

    const payload = {};
    payload.mobile_number = formik.values.mobile_number
      .split(" ")[1]
      .replace("-", "");
    payload.phone_code = Number(
      formik.values.mobile_number.split(" ")[0].replace("+", "")
    );
    try {
      const res = await axios.post(`${base_Url}user/send-otp`, payload);
      if (res.data.status === true) {
        snackBar(res?.data?.message, res?.data?.status);
      } else {
        snackBar(res?.data?.message, res?.data?.status);
      }
    } catch (err) {
      snackBar("Please enter valid mobile number", false);
    }

    const interval = setInterval(() => {
      setOtpGetButtonSeconds((prev) =>
        prev == 1 ? clearInterval(interval) : prev - 1
      );
    }, 1000);
    setTimeout(() => {
      setOtpGetButton(false);
    }, timer * 1000);
  };

  const verifyRefferalApi = async (payload) => {
    try {
      const res = await axios.post(
        `${base_Url}chapter/verify-reffreal-code`,
        payload
      );
      if (res.data.status === true) {
        snackBar(res?.data?.message, res?.data?.status);
        setisRefferalVerified(res?.data?.data);
        return res;
      } else {
        snackBar(res?.data?.message, res?.data?.status);
      }
    } catch (err) {
      snackBar(err?.response?.data?.message, err?.response?.data?.status);
      console.error(err);
    } finally {
      return;
    }
  };

  const verifyRefferalCode = async () => {
    const payload = {
      chapterId_refferalType: formik.values.refferel_type,
      referral_code: formik.values.referred_by,
    };
    verifyRefferalApi(payload);
  };

  const isOver18YearsAgo = (date) => {
    const maximumDate = subYears(new Date(), 18);
    return date > maximumDate;
  };

  useEffect(() => {

  }, [otpGetButtonSeconds])


  const handleOtpVerifyClick = async () => {
    const deviceId = localStorage?.getItem("uuid")?.replace(/"/gi, "");
    const payload = {};
    payload.mobile_number = formik.values.mobile_number
      .split(" ")[1]
      .replace("-", "");
    payload.otp = formik.values.otp;
    payload.device_id = deviceId;

    try {
      const res = await axios.post(
        `${base_Url}user/verify-member-otp`,
        payload
      );
      if (res.data.status === true) {
        setIsOtpVerified(true);
        setOtpGetButton(false);
        snackBar(res?.data?.message, res?.data?.status);
      } else {
        snackBar(res?.data?.message, res?.data?.status);
      }
    } catch {
      snackBar("Something went wrong", false);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getRefferals = async (refferelType) => {
    try {
      const res = await api.get(`${ApiURL}user/all-users`, {
        params: {
          chapterId_refferalType: refferelType,
          role_id: "6538a1576ad5de8a89f5ee6f",
        },
      });

      setReferredData(res.data.userData);
      if (res && res.data.userData == undefined) {
        setIsRefferedBy(
          "Selected refferal type have not any chapter president"
        );
      } else {
        setIsRefferedBy();
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (formik.values.refferel_type) {
      getRefferals(formik.values.refferel_type);
    }
  }, [formik.values.refferel_type]);

  return (
    <div>
      <Box
        component={Paper}
        sx={{
          padding: {
            xs: "30px",
            sm: "30px",
            md: "40px",
            lg: "50px",
            xl: "60px",
          },
        }}

      // sx={{ backgroundColor: "green" }}
      >
        <Typography
          textAlign="center"
          fontWeight={500}
          fontSize={28}
          marginBottom={2}
          color="black"
          font-family="Nunito,Inter var,Roboto,Helvetica Neue,Arial,sans-serif"
        >
          User Details
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="first_name"
                name="first_name"
                type="text"
                maxLength={20}
                label="First Name"
                onChange={formik.handleChange}
                value={formik.values.first_name}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.first_name}
                    </div>
                  ) : null
                }
              />
            </Grid>

            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="last_name"
                name="last_name"
                type="text"
                label="Last Name"
                maxLength={20}
                onChange={formik.handleChange}
                value={formik.values.last_name}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={
                  formik.touched.last_name && formik.errors.last_name ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.last_name}
                    </div>
                  ) : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12} sm={12}>
              <Input
                id="email"
                name="email"
                type="email"
                label="Email"
                maxLength={50}
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={
                  formik.touched.email && formik.errors.email ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.email}
                    </div>
                  ) : null
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <SelectBox
                mt={1}
                id="refferel_type"
                name="refferel_type"
                label="Refferal Type"
                ClassName="range-select-filter"
                value={formik.values.refferel_type}
                onChange={(e) => {
                  getRefferals(e.target.value);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                options={chapterList || []}
                error={
                  formik.touched.refferel_type &&
                  Boolean(formik.errors.refferel_type)
                }
                touched={formik.touched.refferel_type}
              />
              {formik.touched.refferel_type && formik.errors.refferel_type && (
                <div className="err-msg-font">
                  {formik.errors.refferel_type}
                </div>
              )}
              <p
                style={{
                  fontSize: "12px",
                  color: "#d32f2f",
                  marginLeft: "5px",
                  marginBottom: "0px",
                }}
              >
                {isRefferedBy}
              </p>
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <Box>
                <SelectBox
                  mt={1}
                  id="referred_by"
                  name="referred_by"
                  label="Referred by"
                  className="range-select-filter"
                  value={formik.values.referred_by}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  options={simpleData || []}
                  error={
                    formik.touched.referred_by &&
                    Boolean(formik.errors.referred_by)
                  }
                  touched={formik.touched.referred_by}

                // touched={formik.touched.is_active}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <SelectBox
                type="text"
                id="gender"
                name="gender"
                label="Gender"
                ClassName="range-select-filter"
                value={formik.values.gender}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={genderOptions}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                touched={formik.touched.gender}
              />
              {formik.touched.gender && formik.errors.gender && (
                <div className="err-msg-font">{formik.errors.gender}</div>
              )}
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <div style={{ marginTop: "7px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      id="birth_date"
                      sx={{ width: "100%", padding: "0px 0px !important" }}
                      name="birth_date"
                      fullWidth
                      label="Birth Date"
                      value={
                        formik.values.birth_date
                      }
                      format="DD/MM/YYYY"
                      onChange={(newValue) =>
                        formik.setFieldValue("birth_date", newValue)
                      }
                      onBlur={() => formik.setFieldTouched("birth_date", true)}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          error: Boolean(
                            formik.touched.birth_date &&
                            formik.errors.birth_date
                          ), //error handling
                          helperText: formik.touched.birth_date &&
                            formik.errors.birth_date && (
                              <div
                                style={{ marginLeft: "-12px" }}
                                className="err-msg-font"
                              >
                                {formik.errors.birth_date}
                              </div>
                            ),
                        },
                      }}
                      shouldDisableDate={(date) => isOver18YearsAgo(date)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <div style={{ marginTop: "5px" }}>
                <FormControl
                  sx={{ marginTop: "10px", width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{
                      color: "#000",
                      bgcolor: "#fff",
                    }}
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        paddingTop: "10px!important",
                        borderColor: "rgba(107, 114, 128, .5)",

                        borderRadius: "7px!important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "blue",
                      },
                      paddingTop: "0px",
                      color: "#000",
                      width: "100%",
                    }}
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    inputProps={{ maxLength: 25 }}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            color: "#000",
                            "&:hover": { backgroundColor: "white !important" },
                          }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff
                              sx={{
                                "&:hover": {
                                  backgroundColor: "white !important",
                                  color: "black !important",
                                },
                              }}
                            />
                          ) : (
                            <Visibility
                              sx={{
                                "&:hover": {
                                  backgroundColor: "white !important",
                                  color: "black !important",
                                },
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    helperText={
                      formik.touched.password && formik.errors.password ? (
                        <div className="err-msg err-msg-font">
                          {formik.errors.password}
                        </div>
                      ) : null
                    }
                  />
                  {formik.touched.password && formik.errors.password && (
                    <FormHelperText>
                      <div style={{ color: "red" }}>
                        {formik.errors.password}
                      </div>
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </Grid>

            <Grid item md={6} xs={12} sm={12}>
              <div>
                <FormControl
                  sx={{ marginTop: "14px", width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{
                      color: "#000",
                      bgcolor: "#fff",
                    }}
                  >
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        paddingTop: "10px!important",
                        borderColor: "rgba(107, 114, 128, .5)",

                        borderRadius: "7px!important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "blue",
                      },
                      paddingTop: "0px",
                      color: "#000",
                    }}
                    id="confirm_password"
                    name="confirm_password"
                    type={showConfirmPassword ? "text" : "password"}
                    label="Confirm Password"
                    onChange={formik.handleChange}
                    value={formik.values.confirm_password}
                    onBlur={formik.handleBlur}
                    inputProps={{ maxLength: 25 }}
                    error={
                      formik.touched.confirm_password &&
                      Boolean(formik.errors.confirm_password)
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            color: "#000",
                            "&:hover": { backgroundColor: "white !important" },
                          }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff
                              sx={{
                                "&:hover": {
                                  backgroundColor: "white !important",
                                  color: "black !important",
                                },
                              }}
                            />
                          ) : (
                            <Visibility
                              sx={{
                                "&:hover": {
                                  backgroundColor: "white !important",
                                  color: "black !important",
                                },
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  // helperText={
                  //   formik.touched.confirm_password &&
                  //   formik.errors.confirm_password ? (
                  //     <div className="err-msg err-msg-font">
                  //       {formik.errors.confirm_password}
                  //     </div>
                  //   ) : null
                  // }
                  />
                  {formik.touched.confirm_password &&
                    Boolean(formik.errors.confirm_password) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {formik.errors.confirm_password}
                    </p>
                  ) : (
                    ""
                  )}
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item md={6} xs={12} sm={12}>
              <div className={classes.customComponent}>
                <div
                  className={classes.inputGroup}
                  style={{ alignItems: "start" }}
                >
                  <MuiPhoneNumber
                    className={classes.formControl}
                    sx={{
                      "& .css-5sfu98-MuiGrid-root .MuiGrid-item": {
                        paddingTop: "0px",
                      },
                      "& .MuiInputBase-root": {
                        borderTopRightRadius: "0px !important",
                        borderBottomRightRadius: "0px !important",
                      },
                      // width:"100% !important"
                    }}
                    fullWidth
                    defaultCountry={"in"}
                    size="small"
                    variant="outlined"
                    id="mobile_number"
                    name="mobile_number"
                    label="Mobile Number"
                    type="text"
                    onBlur={formik.handleBlur}
                    onChange={(value) => {
                      formik.setFieldValue("mobile_number", value);
                    }}
                    value={formik.values.mobile_number}
                    error={
                      formik.touched.mobile_number &&
                      formik.errors.mobile_number
                    }
                    helperText={
                      formik.touched.mobile_number &&
                        formik.errors.mobile_number ? (
                        <div className="err-msg-mobile err-msg-font">
                          {formik.errors.mobile_number}
                        </div>
                      ) : null
                    }
                    InputLabelProps={{ sx: { color: "black" } }}
                    disabled={isOtpVerified}
                  />
                  <Button
                    className={classes.copyButton}
                    sx={{
                      height: "fit-content !important",
                      padding: "7px 0px !important",
                    }}
                    type="button"
                    onClick={handleOtpClick}
                    variant="contained"
                    disabled={
                      otpGetButton ||
                      !formik.values.mobile_number ||
                      formik.errors.mobile_number ||
                      isOtpVerified
                    }
                  >
                    {otpGetButtonSeconds > 1 ? otpGetButtonSeconds : "Get OTP"}
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              sx={{
                ".css-1qbzj0w-MuiFormControl-root-MuiTextField-root .MuiInputBase-root":
                {
                  // marginTop:"-20px !important",
                  padding: "8.5px 14px !important",
                  borderRadius: "4px !important",
                },
              }}
            >
              <div className={classes.customComponent}>
                <div
                  className={classes.inputGroup}
                  style={{ alignItems: "start" }}
                >
                  <Input
                    className={classes.formControl}
                    name={`otp`}
                    sx={{
                      "& .MuiInputBase-root": {
                        borderTopRightRadius: "0px !important",
                        borderBottomRightRadius: "0px !important",
                      },
                    }}
                    maxLength={6}
                    type="number"
                    value={formik.values.otp}
                    label="OTP"
                    step1={true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.otp && Boolean(formik.errors.otp)}
                    helperText={
                      formik.touched.otp && (
                        <div className="err-msg err-msg-font">
                          {formik.errors.otp}
                        </div>
                      )
                    }
                    disable={!otpApiCalledOneTime || isOtpVerified}
                  />
                  <Button
                    className={classes.copyButton}
                    sx={{
                      height: "100% !important",
                      padding: "9px 0px !important",
                    }}
                    type="button"
                    onClick={handleOtpVerifyClick}
                    variant="contained"
                    disabled={
                      otpGetButtonSeconds == undefined ||
                      !otpApiCalledOneTime ||
                      !formik.values.otp ||
                      isOtpVerified ||
                      formik.errors.otp
                    }
                  >
                    Verify
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "center", mt: 1 }}
          >
            <Grid item>
              <Button
                sx={{ margin: 1 }}
                type="submit"
                // onClick={handleSubmit}
                variant="contained"
                disabled={!isOtpVerified}
              >
                Next
              </Button>
            </Grid>
          </Grid>
          {/* </Grid> */}
        </form>
      </Box>
    </div>
  );
};

export default Step1;
