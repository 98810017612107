import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import ReviewCard from "../reviewCard/ReviewCard";
import { GetClientReview } from "../../redux/clientReviewAction";
import arrow from "../../GGC_LendingPage/svg/arrowLeft.svg";

const ClientReview = ({ title, cardsPerScreen }) => {
  const [allGetClient, setAllGetClient] = useState([]);
  const sliderRef = useRef();

  const getClient = async () => {
    const res = await GetClientReview();
    setAllGetClient(res?.data);
  };

  useEffect(() => {
    getClient();
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: cardsPerScreen || 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <>
      {allGetClient?.reviewData?.length > 0 ? (
        <div style={{ position: "relative" }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f1f5f7",
              padding: "50px 0",
            }}
          >
            <h3 className="mb-0 text-center text-capitalize">
              {title || "clients say about GGC?"}
            </h3>
            <div className="container">
              <div className="row">
                <div className="col col-md-12" style={{ position: "relative" }}>
                  <Slider ref={sliderRef} {...settings}>
                    {allGetClient &&
                      allGetClient?.reviewData?.map((item) => (
                        <ReviewCard item={item} />
                      ))}
                  </Slider>

                  {/*Arrows*/}
                  <div
                    style={{
                      backgroundColor: "transparent",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      cursor: "pointer",
                      top: "53%",
                      right: "-2.5%",
                      padding: "10px",
                    }}
                  >
                    <img
                      src={arrow}
                      style={{ width: "100%", height: "100%" }}
                      onClick={goToNextSlide}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "transparent",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      cursor: "pointer",
                      top: "53%",
                      left: "-2.5%",
                      padding: "10px",
                    }}
                  >
                    <img
                      src={arrow}
                      style={{
                        width: "100%",
                        height: "100%",
                        rotate: "180deg",
                      }}
                      onClick={goToPrevSlide}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ClientReview;
