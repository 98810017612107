import React from "react";
import ManageUserList from "../components/manageUser/ManageUserList";

const ManageUser = () => {
  return (
    <div>
      <ManageUserList />
    </div>
  );
};

export default ManageUser;
