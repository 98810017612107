import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

const axiosApiCall = () =>
  axios.create({
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });

export const CreateEvent = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_EVENT_LOADING",
      payload: true,
    });

    const res = await axiosApiCall().post(`${ApiURL}event/create-event`, data);

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_EVENT_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "CREATE_EVENT_LOADING",
      payload: false,
    });
  }
};

export const UpdateEvent = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_EVENT_LOADING",
      payload: true,
    });
    const res = await axiosApiCall().put(`${ApiURL}event/update/${id}`, data);

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_EVENT_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "UPDATE_EVENT_LOADING",
      payload: false,
    });
  }
};

export const getAllEvents = (event_name, rowsPerPage) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_EVENT_LOADING",
      payload: true,
    });

    let allEvents = [];
    let page = 1;
    let totalPages;

    do {
      const response = await axios.get(
        `${ApiURL}event/all-event?limit=${rowsPerPage}&page=${page}&event_name=${event_name}`
      );

      if (response.data.status && response.data.data) {
        const filteredEvents = response.data.data.eventData.filter(
          (events) => events.approval_status === "approved"
        );

        allEvents = [...allEvents, ...filteredEvents]; // Combine results

        totalPages = response.data.data.totalPages; // Assuming your API provides totalPages
        page++; // Move to the next page
      } else {
        break; // Exit loop if there's an issue with the response
      }
    } while (page <= totalPages);

    dispatch({
      type: 'GET_EVENT_SUCCESS',
      payload: {
        events: allEvents,
        count: allEvents.length,
      },
    });
    
    dispatch({
      type: "GET_EVENT_LOADING",
      payload: false,
    });
    
  } catch (error) {
    console.error("Error fetching events:", error);
    dispatch({
      type: "GET_EVENT_LOADING",
      payload: false,
    });
  }
};

export const GetEvent =
  (page, event_name = "", rowsPerPage, events = "all") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_EVENT_LOADING",
        payload: true,
      });

      const payload = {
        scheduled_events: false,
        attendeed_events: false,
        created_events: false,
        approval_event: false
      };

      switch (events) {
        case "scheduled":
          payload.scheduled_events = true;
          break;

        case "attendeed":
          payload.attendeed_events = true;
          break;

        case "created":
          payload.created_events = true;
          break;

        default:
          break;
      }

      const res = await axios.get(
        `${ApiURL}event/all-event?limit=${rowsPerPage}&page=${page}&event_name=${event_name}&scheduled_events=${payload.scheduled_events}&attendeed_events=${payload.attendeed_events}&created_events=${payload.created_events}`
      );

      if (res.data.status === true) {
        const responseData =
          res.data.data || res.data.data.length > 0 ? res.data.data : [];
        dispatch({
          type: "GET_EVENT_SUCCESS",
          payload: responseData,
        });
      } else {
        dispatch({
          type: "GET_EVENT_SUCCESS",
          payload: [],
        });
      }

      return res;
    } catch (err) {
      console.error(err);
    } finally {
      dispatch({
        type: "GET_EVENT_LOADING",
        payload: false,
      });
    }
  };

export const DeleteEvent = (id, handleCloseDelete) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_EVENT_LOADING",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}event/delete/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_EVENT_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleCloseDelete();
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "DELETE_EVENT_LOADING",
      payload: false,
    });
  }
};
