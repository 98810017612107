import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteScheduleServiceById,
  getAllScheduleServiceRequests,
} from "../../redux/action/ServiceAction";
import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import PageHeader from "../pageHeader/PageHeader";
import Loader from "../../web/components/loader/Loader";
import EnhancedTable from "../table/EnhancedTable.js";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import { useNavigate } from "react-router-dom";
import { CloseIcon, DeleteIcon, EditIcon } from "../../assets/icons/Icons";
import ScheduleServiceDialog from "../../web/pages/service/ScheduleServiceDialog";
import DeleteDialog from "../dialog/deletedialog";
import Input from "../../common/Input";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const columns = [
  {
    id: "service_name",
    numeric: false,
    disablePadding: true,
    label: "Service Name",
  },
  {
    id: "schdedule_time",
    numeric: true,
    disablePadding: false,
    label: "Schdedule Time",
  },
  {
    id: "service_contact_number",
    numeric: false,
    disablePadding: true,
    label: "Service Contact Number",
  },
  {
    id: "user_name",
    numeric: false,
    disablePadding: true,
    label: "User Name",
  },
  {
    id: "user_email",
    numeric: true,
    disablePadding: false,
    label: "User Email",
  },
  {
    id: "user_mobile_number",
    numeric: false,
    disablePadding: true,
    label: "User Mobile Number",
  },
  {
    id: "note",
    numeric: false,
    disablePadding: true,
    label: "Note",
  },
  {
    id: "is_active",
    numeric: true,
    disablePadding: false,
    label: "Is Active",
  },
  // (isEditAllowed || isDeleteAllowed) ?
  !!getPermissions(permissions.Service, "is_delete") ||
  !!getPermissions(permissions.Service, "is_edit")
    ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
    : null,
].filter((el) => el !== null);

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
  // { id: 30, title: "30" },
];

const initialFilterValues = {
  service_name: "",
};

// -----------------> Component Start Here <-------------------- //

const ScheduleServiceList = () => {
  const { getAllScheduleServiceRequestsLoading, service } = useSelector(
    (state) => state?.ServiceReducer
  );
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [open, setOpen] = useState(false);
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();
  const classes = useStyles();
  const [errors, setErrors] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [editValue, setEditValue] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [DeleteId, SetDeleteId] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [scheduleTime, setScheduleTime] = useState();
  const [endScheduleTime, setEndScheduleTime] = useState();

  function subStr(txt, len) {
    let acceptedText =
      txt.length < len ? txt : txt.substring(0, len - 1) + "...";
    return acceptedText;
  }

  useEffect(()=>{
    if(scheduleTime){
      setFilterDatas({
        ...filterDatas,
        'start_date': scheduleTime,
      });
    }
    if(endScheduleTime){
      setFilterDatas({
        ...filterDatas,
        'end_date': endScheduleTime,
      });
    }
  },[scheduleTime, endScheduleTime])


  useEffect(() => {
    if (!open) {
      let searchParams = {};
      searchParams.user_email = filterDatas.user_email;
      searchParams.user_mobile_number = filterDatas.user_mobile_number;

      searchParams.service_name = filterDatas.service_name;
      searchParams.service_number = filterDatas.service_number;

      if (scheduleTime) {
        const getScheduleTime = scheduleTime && new Date(scheduleTime);
        const adjustedTime =
          getScheduleTime && moment(getScheduleTime).utcOffset(0, true);

        // Convert to ISO string with the specified time zone (UTC)
        searchParams.start_date = adjustedTime && adjustedTime.toISOString();
        // moment(getScheduleTime)
      }
      if (endScheduleTime) {
        const getEndScheduleTime = endScheduleTime && new Date(endScheduleTime);
        const adjustedTime =
          getEndScheduleTime && moment(getEndScheduleTime).utcOffset(0, true);
        searchParams.end_date = adjustedTime && adjustedTime.toISOString();
        // moment(getEndScheduleTime)
      }
      dispatch(getAllScheduleServiceRequests(rowsPerPage, searchParams, page));
    } else {
      dispatch(getAllScheduleServiceRequests(rowsPerPage, "", page));
    }
  }, [page, open]);

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    setPage(1);
    let searchParams = {};
    searchParams.user_email = filterDatas.user_email;
    searchParams.user_mobile_number = filterDatas.user_mobile_number;

    searchParams.service_name = filterDatas.service_name;
    searchParams.service_number = filterDatas.service_number;

    if (scheduleTime) {
      const getScheduleTime = scheduleTime && new Date(scheduleTime);
      const adjustedTime =
        getScheduleTime && moment(getScheduleTime).utcOffset(0, true);

      // Convert to ISO string with the specified time zone (UTC)
      searchParams.start_date = adjustedTime && adjustedTime.toISOString();
      // moment(getScheduleTime)
    }
    if (endScheduleTime) {
      const getEndScheduleTime = endScheduleTime && new Date(endScheduleTime);
      const adjustedTime =
        getEndScheduleTime && moment(getEndScheduleTime).utcOffset(0, true);
      searchParams.end_date = adjustedTime && adjustedTime.toISOString();
      // moment(getEndScheduleTime)
    }
    dispatch(getAllScheduleServiceRequests(rowPerPage, searchParams, page));
  };

  const handleEditClick = (schedule) => {
    setOpenEdit(true);
    setEditValue(schedule);
    setErrors({});
  };

  const handleCloseScheduleServiceModule = async () => {
    setOpenEdit(false);
    setEditValue();
    await dispatch(getAllScheduleServiceRequests(rowsPerPage, "", page));
  };

  const handleClickOpenDelete = (schedule) => {
    setOpenDelete(true);
    SetDeleteId(schedule._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    handleCloseDelete();
    await dispatch(deleteScheduleServiceById(DeleteId));
    dispatch(getAllScheduleServiceRequests("", ""));
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const rows =
    service?.requestData && Array.isArray(service?.requestData)
      ? service?.requestData.map((request) => {
          // const selected = service === selectPostal;
          // const formattedDate = originalDate.format("DD-MM-YYYY");

          return {
            _id: request._id,
            service_name: subStr(request.service_name, 20),
            schdedule_time: dayjs(request.schedule_time).format("DD/MM/YYYY"),
            service_contact_number: request.service_contact_number,
            user_name: request.user_name,
            user_email: request.user_email,
            user_mobile_number: request.user_mobile_number,
            note: subStr(request.note, 20),
            is_active: request.is_active ? "Active" : "inActive",
            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {!!getPermissions(permissions.Service, "is_edit") && (
                  <IconButton
                    onClick={() => handleEditClick(request)}
                    sx={{
                      color: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#153e80",
                      },
                      "&:focus": {
                        backgroundColor: "white",
                        color: "#153e80",
                        border: "none",
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {!!getPermissions(permissions.Service, "is_delete") && (
                  <IconButton
                    onClick={() => handleClickOpenDelete(request)}
                    sx={{
                      color: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#153e80",
                      },
                      "&:focus": {
                        backgroundColor: "white",
                        color: "#153e80",
                        border: "none",
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ),
            selected: selected,
          };
        })
      : [];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelected = rows.map((n) => n.id);
      // setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericRegex = /^\d+$/;

    if (name === "service_number" && value && !numericRegex.test(value)) {
      return;
    } else if (name === "user_mobile_number" && value && !numericRegex.test(value)) {
      return;
    }

    setFilterDatas({
      ...filterDatas,
      [name]: value,
    });
  };

  const [isFieldFilled, setIsFieldFilled] = useState()

  useEffect(() => {
    if (filter) {
      const anyFieldHasValue = Object.values(filterDatas).some(val => val !== "");
      setIsFieldFilled(anyFieldHasValue);
    }
  }, [filter, filterDatas])



  const handleSubmitFilter = (e) => {
    e.preventDefault();
    let searchParams = {};
    searchParams.user_email = filterDatas.user_email;
    searchParams.user_mobile_number = filterDatas.user_mobile_number;

    searchParams.service_name = filterDatas.service_name;
    searchParams.service_number = filterDatas.service_number;

    if (scheduleTime) {
      const getScheduleTime = scheduleTime && new Date(scheduleTime);
      const adjustedTime =
        getScheduleTime && moment(getScheduleTime).utcOffset(0, true);

      // Convert to ISO string with the specified time zone (UTC)
      searchParams.start_date = adjustedTime && adjustedTime.toISOString();
      // moment(getScheduleTime)
    }
    if (endScheduleTime) {
      const getEndScheduleTime = endScheduleTime && new Date(endScheduleTime);
      const adjustedTime =
        getEndScheduleTime && moment(getEndScheduleTime).utcOffset(0, true);
      searchParams.end_date = adjustedTime && adjustedTime.toISOString();
      // moment(getEndScheduleTime)
    }

    setPage(1);
    dispatch(getAllScheduleServiceRequests(rowsPerPage, searchParams, page));
    closeFilterDrawer1();
  };

  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setScheduleTime()
    setEndScheduleTime()
    setPage(1);
    dispatch(getAllScheduleServiceRequests(rowsPerPage, "", page));
    closeFilterDrawer();
  };

  return (
    <>
      <div>
        <Box sx={{ backgroundColor: "#f1f5f9" }}>
          <div style={{ paddingTop: "7px", paddingLeft: "18px" }}>
            <ArrowCircleLeftOutlinedIcon
              fontSize="large"
              sx={{ cursor: "pointer", backgroundColor: "#f1f5f9" }}
              onClick={() => navigate("/admin-services")}
            />
          </div>
          <PageHeader
            title="Schedule Services List"
            onFilterClick={openFilterDrawer}
          />
          {getAllScheduleServiceRequestsLoading ? (
            <Loader />
          ) : (
           
            <EnhancedTable
              key={5}
              columns={columns}
              rowsPerPage={rowsPerPage}
              rows={rows}
              order={order}
              orderBy={orderBy}
              selected={selected}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              // title={tableTitle}
              tableList={service && service?.requestData}
            />
          )}

          <DeleteDialog
            open={openDelete}
            // loading={deleteLoading}
            dialogTitle="Are you sure to want to delete this record?"
            handleClose={handleCloseDelete}
            handleDelete={() => handleSingleDelete(DeleteId)}
          />

          <Box
            sx={{
              display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
              marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
              mt: 2,
              mb: 2,
            }}
          >
            <Box
              sx={{
                fontSize: "14px",
                marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                gap: 2,
                width: { sm: "100%", md: "50%" },
              }}
              display="flex"
              alignItems="center"
            >
              <Box>
                {" "}
                Showing {service?.currentPage} of {service?.totalPages} Results
              </Box>
              <Box display="flex" sx={{ gap: 1 }} alignItems="center">
                <Typography>Records per page</Typography>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    id="limites"
                    name="limites"
                    value={rowsPerPage}
                    onChange={handleChangeRow}
                    displayEmpty
                    sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {RecordPerPage?.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name || item.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Pagination
              count={service && service?.totalPages}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              sx={{
                "& .MuiButtonBase-root ": {
                  color: "#000 !important",
                },
                "& .Mui-selected ": {
                  background: "#4b4b4e70 !important",
                },
                "& .Mui-disabled ": {
                  background: "#6c696966 !important",
                  color: "#000000 !important",
                  opacity: 1,
                },
              }}
            />
          </Box>
          <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
            <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
              <Box className={classes.mainFilterBox}>
                <Stack className={classes.stackFilter}>
                  <Typography className={classes.typographyFilter}>
                    Filter
                  </Typography>

                  <CloseIcon
                    className="close-button"
                    onClick={closeFilterDrawer1}
                  />
                </Stack>
                <Box
                  sx={{
                    marginTop: "5px",
                  }}
                >
                  <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
                </Box>

                <div>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          sx={{
                            "button:focus": {
                              backgroundColor: "none !important",
                              color: "black !important",
                            },
                          }}
                          id="schedule_time"
                          name="schedule_time"
                          label="Start Date"
                          // defaultValue={dayjs(new Date())}
                          value={scheduleTime}
                          // value={filterDatas.schedule_time}
                          onChange={(e) => setScheduleTime(e)}
                          slotProps={{
                            textField: {
                              variant: "outlined",
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          id="schedule_time"
                          name="schedule_time"
                          label="End Date"
                          value={endScheduleTime}
                          // value={filterDatas.schedule_time}
                          onChange={(e) => setEndScheduleTime(e)}
                          slotProps={{
                            textField: {
                              variant: "outlined",
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <Input
                      variant="outlined"
                      color="primary"
                      label="Service Name"
                      name="service_name"
                      value={filterDatas.service_name}
                      onChange={handleChange}
                      maxLength={50}
                    />
                    <Input
                      variant="outlined"
                      color="primary"
                      label="Service Number"
                      name="service_number"
                      value={filterDatas.service_number}
                      onChange={handleChange}
                      maxLength={20}
                    />
                    <Input
                      variant="outlined"
                      color="primary"
                      label="Email"
                      name="user_email"
                      value={filterDatas.user_email}
                      onChange={handleChange}
                      maxLength={200}
                    />
                    <Input
                      variant="outlined"
                      color="primary"
                      label="User Mobile Number"
                      name="user_mobile_number"
                      value={filterDatas.user_mobile_number}
                      onChange={handleChange}
                      maxLength={20}
                    />
                  </Box>
                  <Stack className={classes.buttonFilter}>
                    <Button
                      disabled={!isFieldFilled}
                      color="secondary"
                      size="large"
                      variant="contained"
                      className="cancel-filter"
                      onClick={resetFormFilter}
                    >
                      Reset
                    </Button>
                    <Button
                      disabled={!isFieldFilled}
                      color="primary"
                      size="large"
                      variant="contained"
                      className="order-filter-button"
                      type="submit"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Filter
                    </Button>
                  </Stack>
                </div>
              </Box>
            </form>
          </Drawer>
        </Box>
      </div>
      <ScheduleServiceDialog
        isOpen={openEdit}
        closeModel={handleCloseScheduleServiceModule}
        editScheduleService={editValue}
      />
    </>
  );
};

export default ScheduleServiceList;
