import React, { useEffect, useState } from "react";
import logo from "../../assets/image/logo-home.svg";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box } from "@mui/material";
import instaIcon from "../../GGC_LendingPage/svg/colorINSTA.svg";
import waIcon from "../../GGC_LendingPage/svg/whatsapp.svg";
import uTIcon from "../../GGC_LendingPage/svg/uTube.svg";

//Icons
const icons = [
  {
    title: "Whatsapp",
    img: waIcon,
    link: "https://api.whatsapp.com/send?phone=916354917511&text=Hello%20Team%20Global%20Garner%20%2C%20I%20have%20seen%20Partner%20Program%20on%20your%20website.%20I%27m%20interested%20in%20participating%20with%20GG%20Community.%20Please%20guide%20me%20for%20the%20next%20step.%20Thank%20You.",
  },
  {
    title: "Instagram",
    img: instaIcon,
    link: "https://www.instagram.com/myggcommunity/?igsh=cWR4Znc3NjBmcmht",
  },
  {
    title: "Youtube",
    img: uTIcon,
    link: "https://www.youtube.com/@GlobalGarnerOfficial?si=pLbPk5lraRX0Zog6",
  },
];

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  const goTopStyle = {
    zIndex: 1600,
    opacity: 0,
    visibility: "hidden",
    transition: "all 0.8s ease-in-out",
    transform: "translate3d(0, 200%, 0)",
    position: "fixed",
    bottom: "114px",
    right: { xs: "10px", sm: "40px", md: "40px", lg: "40px" },
    ...(isVisible && {
      opacity: 1,
      visibility: "visible",
      transform: "translate3d(0, 0, 0)",
    }),
    display: { xs:'none', sm: 'none', md: 'block'}
  };

  const linkStyle = {
    textDecoration: "none",
    border: "0 none",
    display: "block",
    height: "3.6rem",
    width: "3.6rem",
    lineHeight: "3.6rem",
    textAlign: "center",
    backgroundPosition: "center center",
    backgroundSize: "10px 15px",
    backgroundColor: "rgb(8,8,8)",
    textTransform: "uppercase",
    borderRadius: "50%",
    transition: "all 0.3s ease-in-out",
    color: "white",
  };

  const linkHoverStyle = {
    backgroundColor: "#153E80",
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const currentYear = new Date().getFullYear();

  return (
    <footer
      className="main-footer main-footer-style-01 bg-pattern-01 "
      style={{ paddingBottom: "20px", paddingTop: "20px" }}
    >
      <div className="footer-second responsive">
        <div className="">
          <div
            className="mainFooter"
          >
            {/*Side 1*/}
            <div className="leftFooter">
              {/*LOGO*/}
              <Link to={'/'} className="mb-3" style={{marginLeft: '-8px'}}>
                <img style={{ width: "300px", cursor: 'pointer' }} src={logo} alt="Global Garner Community" />
              </Link>

              {/*Company*/}
              <div className="">
                <div className="font-size-md font-weight-semibold text-dark mb-4">
                  Global Garner Community Pvt Ltd
                  <br />
                  CIN : U70200GJ2024PTC149720
                </div>
              </div>
            </div>

            <div className="rightFooter">
              {/*Side 2*/}
              <div >
              <div className="font-size-lg font-weight-bold text-dark mb-2">
                Company
              </div>
              <ul
                className="list-group list-group-flush list-group-borderless"
                style={{ marginTop: "-10px" }}
              >
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link to="/" className="footerLink">
                    Home
                  </Link>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link to="/blog" className="footerLink">
                    Blog
                  </Link>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link to="/about-us" className="footerLink">
                    About Us
                  </Link>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link to="/contact-us" className="footerLink">
                    Contact Us
                  </Link>
                </li>
              </ul>
              </div>

            {/*Side 3*/}
            <div>
              <div className="font-size-lg font-weight-bold text-dark mb-2">
                Quick Links
              </div>
              <ul
                className="list-group list-group-flush list-group-borderless"
                style={{ marginTop: "-10px" }}
              >
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link to="/faq" className="footerLink">
                    FAQS
                  </Link>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link to="/privacy-policy" className="footerLink">
                    Privacy
                  </Link>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link to="/terms-and-condition" className="footerLink">
                    Terms & Conditions
                  </Link>
                </li>
              </ul>
            </div>

            {/*Side 4*/}
            <div>
              <div className="font-size-lg font-weight-bold text-dark mb-2">
                Address
              </div>
              <ul
                className="list-group list-group-flush list-group-borderless"
                style={{ color: "#707070", marginTop: "-10px" }}
              >
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <p className="mb-0">
                    5th floor, Grand Emporio, Motera Stadium Road, Ahmedabad,
                    Gujarat
                    <br />
                    380005
                  </p>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-0">
                  {/*Social Media*/}
                  <div
                    className="topbar responsive d-flex"
                    style={{
                      width: "100%",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginTop: '7px',
                    }}
                  >
                    {/*ICONS*/}
                    {icons?.map((icon, key) => (
                      <a
                        target="_blank"
                        href={icon.link}
                        key={key}
                        style={{ margin: "auto 0" }}
                      >
                        <img
                          src={icon.img}
                          alt={icon.title}
                          width="30px"
                          height="30px"
                        />
                      </a>
                    ))}
                  </div>
                </li>
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div className="responsive">
        <div
          className="row align-items-center"
          style={{ width: "100%", margin: "25px auto 10px", position: 'relative' }}
        >
          {/*Reserved Rights*/}
          <div className="col-lg-5 mb-lg-0" style={{ margin: "0 auto" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "18px",
              }}
            >
              © {currentYear}&#x2002;
              <Link
                to="/"
                className="link-hover-dark-primary font-weight-semibold"
              >
                GGC&#x2002;
              </Link>
              All Rights Resevered.
            </div>
          </div>

          {/*Smoothly move Up Arrow*/}
          <Box className="go-top" sx={goTopStyle}>
            <a
              className="smoothscroll"
              title="Back to Top"
              href="#top"
              onClick={handleScrollToTop}
              style={{ ...linkStyle, ...(isVisible && linkHoverStyle) }}
            >
              <ArrowUpwardIcon style={{ fontSize: "2rem" }} />
            </a>
          </Box>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
