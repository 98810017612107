import React from 'react'
import DefaultProfile from "../../../assets/images/Profile.png";
const SearchList = ({ show, upadtedList }) => {
    function subStr(txt, len) {
        let acceptedText =
          txt.length < len ? txt : txt.substring(0, len - 1) + "...";
        return acceptedText;
      }

    if (!show) {
        return null;
    }

    return (
        <div>
            <section id="section-01" className="pb-13">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-5 mb-lg-0">
                            <ul className="nav nav-pills tab-style-03 mb-6" role="tablist" aria-orientation="vertical">

                                <li className="nav-item">
                                    <a className="nav-link active" id="recommend-provider-tab" data-toggle="pill" role="tab" aria-controls="recommend-provider" aria-selected="true">Recommended
                                        Providers</a>
                                </li>
                            </ul>
                            <div className="tab-content">

                                <div className="tab-pane fade active show" id="recommend-provider" role="tabpanel" aria-labelledby="recommend-provider-tab">
                                    <div className="table-responsive">
                                        <table className="table table-hover listing-table">
                                            <thead>
                                                <tr>
                                                    <th>Profile</th>
                                                    <th>Address</th>
                                                    <th>Role</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {upadtedList?.map((item) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <div className="media align-items-center">
                                                                    <a className="image mr-3">
                                                                        <img src={
                                                                            item &&
                                                                                item?.profile_picture &&
                                                                                item?.profile_picture &&
                                                                                item?.profile_picture !== "null" &&
                                                                                item?.profile_picture !== null
                                                                                ? item?.profile_picture
                                                                                : DefaultProfile
                                                                        } alt="Provider 1" />
                                                                    </a>
                                                                    <div className="media-body">
                                                                        <div className="text-dark font-size-sm">{item?.first_name || "-"} {" "} {item?.last_name || "-"}
                                                                        </div>
                                                                        <a className="font-weight-semibold text-link  d-block font-size-md name">{item?.email || "-"}</a>
                                                                        <a className="font-weight-semibold text-link  d-block font-size-md name">+{item?.phone_code || "-"} {item?.mobile_number || "-"}</a>

                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style={{
                                                                overflowWrap: "break-word", /* Modern equivalent of word-wrap */
                                                                wordBreak: "break-word", /* Breaks words at arbitrary points if necessary */
                                                                whiteSpace: "normal"
                                                            }}><span className="text-gray">{subStr(item?.address,100)}, {item?.postal_code}</span></td>
                                                            {/* <td><span className="text-gray">{item?.city_name} {item?.state_name} {item?.country_name}, {item?.postal_code}</span></td> */}
                                                            <td><span className="text-gray">{item?.role}</span></td>

                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default SearchList