import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

export const CreateMembership = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_MEMBERSHIP_LOADING",
      payload: true,
    });
    const res = await axios.post(`${ApiURL}membership/create-membership`, data);

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_MEMBERSHIP_SUCCESS",
        payload: res.data.data,
      });
      // snackBar(res?.data?.message, res?.data?.status)
      return res;
    }
    // snackBar(res?.data?.message, res?.data?.status)
  } catch (err) {
    // snackBar(err?.response?.data?.message, err?.response?.data?.status)
    console.error(err);
  } finally {
    dispatch({
      type: "CREATE_MEMBERSHIP_LOADING",
      payload: false,
    });
  }
};

export const UpdateMembership = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_MEMBERSHIP_LOADING",
      payload: true,
    });
    const res = await axios.put(`${ApiURL}membership/update/${id}`, data)

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_MEMBERSHIP_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status)
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status)
    console.error(err);
  } finally {
    dispatch({
      type: "UPDATE_MEMBERSHIP_LOADING",
      payload: false,
    });
  }
};

export const GetMembership = (page, membership_name = '', rowsPerPage) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_MEMBERSHIP_LOADING",
      payload: true,
    });
    const res = await axios.get(`${ApiURL}membership/get-all-memberships?limit=${rowsPerPage}&page=${page}&membership_name=${membership_name}`);

    if (res.data.status === true) {


      const responseData = res.data.data || res.data.data.length > 0 ? res.data.data : [];
      dispatch({
        type: "GET_MEMBERSHIP_SUCCESS",
        payload: responseData,
      });
    } else {
      dispatch({
        type: "GET_MEMBERSHIP_SUCCESS",
        payload: [],
      });
      return res;
    }
  } catch (err) {
    console.error(err);
  } finally {
    dispatch({
      type: "GET_MEMBERSHIP_LOADING",
      payload: false,
    });
  }
};

export const DeleteMembership = (id, handleCloseDelete) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_MEMBERSHIP_LOADING",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}membership/delete/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_MEMBERSHIP_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status)
      handleCloseDelete()
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status)
    console.error(err);
  } finally {
    dispatch({
      type: "DELETE_MEMBERSHIP_LOADING",
      payload: false,
    });
  }
};

