import React from "react";
import Auth from "../components/auth/Auth";

const SigninPage = () => {
  return (
    <>
      <Auth />
    </>
  );
};

export default SigninPage;
