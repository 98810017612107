import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../pageHeader/PageHeader";
import EnhancedTable from "../table/EnhancedTable.js";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { Form, useForm } from "../../common/useForms";
import FormDialog from "../dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import DeleteDialog from "../dialog/deletedialog";
import SelectBox from "../../common/SelectBox";
import { GetAllCountry } from "../../redux/action/CountryAction";
import { GetAllStates } from "../../redux/action/StateAction";
import { GetAllCities } from "../../redux/action/CityAction";
import {
  CreatePostal,
  UpdatePostal,
} from "../../redux/action/PostalCodeAction";
import { useNavigate } from "react-router-dom";
import {
  DeleteMembership,
  GetMembership,
} from "../../redux/action/MembershipAction";
import useUserRolePermissions from "../../common/UserPermissions";
import axios from "axios";
import { ApiURL } from "../../redux/common";
import { saveAs } from "file-saver";
import Loader from "../loader/Loader";
import { permissions } from "../../common/constants";
import { getPermissions } from "../../common/permissions";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const initialValues = {
  country: "",
  state: "",
  city: "",
  postalcode: "",
  is_active: "",
};
const initialFilterValues = {
  membership_name: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
  // { id: 30, title: "30" },
];

const MembershipList = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectPostal, setselectPostal] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [downLoadData, setDownLoadData] = useState("");
  const [isFilterClicked, setIsFilterClicked] = useState(false);

  const userRoleData = useUserRolePermissions("Membership");

  const isEditAllowed = getPermissions(permissions.membership, "is_edit");
  const isDeleteAllowed = getPermissions(permissions.membership, "is_delete");
  const isAddAllowed = getPermissions(permissions.membership, "is_add");

  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const columns = [
    {
      id: "membership_name",
      numeric: false,
      disablePadding: true,
      label: "Membership Name",
    },
    {
      id: "range",
      numeric: false,
      disablePadding: true,
      label: "Amount Range",
    },
    {
      id: "yearly_price",
      numeric: false,
      disablePadding: true,
      label: "Yearly Price",
    },
    {
      id: "is_active",
      numeric: true,
      disablePadding: false,
      label: "Is Active",
    },
    isEditAllowed || isDeleteAllowed
      ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
      : null,
  ].filter((el) => el !== null);

  const { allStateData } = useSelector((state) => state.StateReducer);
  const { allCountryData } = useSelector((state) => state.CountryReducer);
  const { allCitiesData } = useSelector((state) => state.CityReducer);
  const {
    loading,
    updateLoading,
    createLoading,
    deleteLoading,
    membershipData,
  } = useSelector((state) => state.MembershipReducer);

  const tableTitle = "MembershipList";

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    setPage(1);
    const membership_name = filterDatas.membership_name;
    dispatch(GetMembership(1, membership_name, rowPerPage));
  };

  // useEffect(() => {
  //   const allValuesSet = Object.values(filterDatas).every(value => value !== undefined || value !== '');
  //   if (allValuesSet) {
  //     const membership_name = filterDatas.membership_name;
  //     setPage(1);
  //     dispatch(GetMembership(page, membership_name, rowsPerPage));
  //   } else if (!open) {
  //     const membership_name = filterDatas.membership_name;
  //     dispatch(GetMembership(page, membership_name, rowsPerPage));
  //   }
  // }, [page, open]);

  useEffect(() => {
    if (!open) {
      const membership_name = filterDatas.membership_name;
      dispatch(GetMembership(page, membership_name, rowsPerPage));
    }
  }, [page, open]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setDialogTitle("Add PostalCode");
    setValues(initialValues);
  };

  const handleBtnClick = () => {
    navigate("/admin-memberships/create-membership", {
      state: { formName: "Create Membership", membershipData: {} },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if (dialogTitle === "Update PostalCode") {
      if ("postalcode" in fieldValues) {
        if (
          fieldValues.postalcode === "" ||
          fieldValues.postalcode === undefined ||
          fieldValues.postalcode === null
        ) {
          temp.postalcode = "This field is required.";
        } else {
          temp.postalcode = "";
        }
      }
      if ("is_active" in fieldValues) {
        if (
          fieldValues.is_active === "" ||
          fieldValues.is_active === undefined ||
          fieldValues.is_active === null
        ) {
          temp.is_active = "This field is required.";
        } else {
          temp.is_active = "";
        }
      }
    } else {
      if ("country" in fieldValues) {
        if (
          fieldValues.country === "" ||
          fieldValues.country === undefined ||
          fieldValues.country === null
        ) {
          temp.country = "This field is required.";
        } else {
          temp.country = "";
        }
      }

      if ("state" in fieldValues) {
        if (
          fieldValues.state === "" ||
          fieldValues.state === undefined ||
          fieldValues.state === null
        ) {
          temp.state = "This field is required.";
        } else {
          temp.state = "";
        }
      }

      if ("city" in fieldValues) {
        if (
          fieldValues.city === "" ||
          fieldValues.city === undefined ||
          fieldValues.city === null
        ) {
          temp.city = "This field is required.";
        } else {
          temp.city = "";
        }
      }

      if ("postalcode" in fieldValues) {
        if (
          fieldValues.postalcode === "" ||
          fieldValues.postalcode === undefined ||
          fieldValues.postalcode === null
        ) {
          temp.postalcode = "This field is required.";
        } else {
          temp.postalcode = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleEditClick = (membership) => {
    setselectPostal(membership);
    // setDialogTitle("Update PostalCode");
    navigate("/admin-memberships/update-membership", {
      state: { formName: "Update Membership", membershipData: membership },
    });
    // setValues({
    //     ...values,
    //     postalcode: postal.postal_code || "",
    //     is_active: postal.is_active ? 'true' : 'false' || "",
    // });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (postal) => {
    setOpenDelete(true);
    SetDeleteId(postal._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    try {
      handleCloseDelete();
      await dispatch(DeleteMembership(DeleteId, handleCloseDelete));
      dispatch(GetMembership(page, "", rowsPerPage));
    } catch (error) {
      console.error("Error deleting membership or fetching memberships", error);
    }
  };


  const fields = [
    {
      id: "country",
      label: "Country",
      type: "autocomplete",
      autoCompleteData: allCountryData?.countryData,
      value: values.country,
      name: "country",
      errors: errors.country,
    },
    {
      id: "state",
      label: "State",
      type: "autocomplete",
      autoCompleteData: allStateData?.stateData,
      value: values.state,
      labelAccess: "state_name",
      name: "state",
      errors: errors.state,
    },
    {
      id: "city",
      label: "City",
      type: "autocomplete",
      autoCompleteData: allCitiesData?.cityData,
      labelAccess: "city_name",
      value: values.city,
      name: "city",
      errors: errors.city,
    },
    {
      id: "postalcode",
      label: "Postal Code",
      type: "number",
      value: values.postalcode,
      name: "postalcode",
      errors: errors.postalcode,
    },
    {
      id: "is_active",
      label: "Is Active",
      type: "select",
      options: [
        { value: "true", label: "ACTIVE" },
        { value: "false", label: "INACTIVE" },
      ],
      value: values.is_active,
      name: "is_active",
      errors: errors.is_active,
    },
  ];


  //filter
  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(GetMembership(1, "", rowsPerPage));
    setIsFilterClicked(false)
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericRegex = /^\d+$/;

    if (name === "postalcode" && !numericRegex.test(value)) {
      return;
    }

    setFilterDatas({
      ...filterDatas,
      [name]: value.trim().length ? value : value.trim(),
    });
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setIsFilterClicked(true)
    const membership_name = filterDatas.membership_name;
    setPage(1);
    dispatch(GetMembership(page, membership_name, rowsPerPage));
    closeFilterDrawer1();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      let body = {};
      if (dialogTitle === "Update PostalCode") {
        body = {
          is_active: values.is_active,
          postal_code: +values.postalcode,
        };
      } else {
        body = {
          country_id: values.country._id,
          state_id: values.state._id,
          city_id: values.city._id,
          postal_code: +values.postalcode,
        };
      }

      if (dialogTitle === "Update PostalCode") {
        dispatch(UpdatePostal(selectPostal._id, body, handleClose));
      } else {
        dispatch(CreatePostal(body, handleClose));
      }
    }
  };

  const rows =
    membershipData && Array.isArray(membershipData?.membershipData)
      ? membershipData?.membershipData.map((membership) => {
        const selected = membership === selectPostal;
        return {
          _id: membership._id,
          membership_name: membership.membership_name || "-",
          range:
            membership.min_range_amount +
            " - " +
            membership.max_range_amount +
            " " +
            membership.range_type || "-",
          yearly_price: membership.yearly_price || 0,
          is_active: membership.is_active ? "Active" : "inActive",
          action: (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {isEditAllowed ? (
                <IconButton
                  onClick={() => handleEditClick(membership)}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <EditIcon />
                </IconButton>
              ) : null}
              {/* {isDeleteAllowed ? (
                <IconButton
                  onClick={() => handleClickOpenDelete(membership)}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              ) : null} */}
            </div>
          ),
          selected: selected,
        };
      })
      : [];

  const isAnyFieldFilled = Object.values(filterDatas).some((value) =>
    Boolean(value)
  );
  
  const DownLoadPdf = async () => {
    const membership_name = filterDatas.membership_name;
    try {
      // dispatch(GetMembership(page, "", rowsPerPage));
      const res = await axios.get(`${ApiURL}membership/download-data?search=${membership_name}`);
      setDownLoadData(res?.data?.data);

    } catch (error) {
      console.error("Error fetching role data:", error);
      return {};
    }
  };

  useEffect(() => {
    DownLoadPdf();
  }, [isFilterClicked]);


  return (
    <Box>
      <PageHeader
        title="Memberships"
        isShowAdd={false}
        onBtnClick={handleBtnClick}
        btnText={isAddAllowed ? "Create Membership" : ""}
        // onAddClick={handleClickOpen}
        onFilterClick={openFilterDrawer}
        loading={loading}
        href={downLoadData}
      />
      {loading ? (
        <Loader />
      ) : (
        <EnhancedTable
          key={rows._id}
          columns={columns}
          rows={rows}
          rowsPerPage={rowsPerPage}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={membershipData?.membershipData}
        />
      )}

      <FormDialog
        open={open}
        dialogTitle={dialogTitle}
        fields={
          dialogTitle === "Add PostalCode"
            ? fields.filter((el) =>
              ["country", "state", "city", "postalcode"].includes(el.name)
            )
            : fields.filter((el) =>
              ["postalcode", "is_active"].includes(el.name)
            )
        }
        loading={
          dialogTitle === "Update PostalCode" ? updateLoading : createLoading
        }
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
      />
      <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {membershipData?.currentPage} of{" "}
            {membershipData?.totalPages} Pages
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={membershipData?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>

      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div>
              <Box>
                <Input
                  variant="outlined"
                  color="primary"
                  label="Membership Name"
                  name="membership_name"
                  value={filterDatas.membership_name}
                  onChange={handleChange}
                  maxLength={50}
                />
              </Box>
              <Stack className={classes.buttonFilter}>
                <Button
                  disabled={filterDatas.membership_name ? false : true}
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                >
                  Reset
                </Button>
                <Button
                  disabled={filterDatas.membership_name ? false : true}
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  style={{
                    cursor: "pointer",

                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
    </Box>
  );
};

export default MembershipList;
