import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  Input,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeader from "../pageHeader/PageHeader";
import EnhancedTable from "../table/EnhancedTable.js";
import axios from "axios";
import { ApiURL, snackBar } from "../../redux/common";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseIcon, EditIcon } from "../../assets/icons/Icons";
import { makeStyles } from "@material-ui/core/styles";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import Loader from "../loader/Loader";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import SelectBox from "../../common/SelectBox";
import { api } from "../../web/config/api";


const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
  // { id: 30, title: "30" },
];

const statusOptions = [
  { value: "pending", label: "Pending" },
  { value: "ongoing", label: "Ongoing" },
  { value: "delivered", label: "Delivered" },
];

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const initialFilterValues = {
  purchased_at: "",
};

const ServiceDetails = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [servicedetails, setServicedetails] = useState([]);
  const [statusModal, setStatusModal] = useState(false);
  const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);
  const [fetchServiceDetailLoading, setFetchServiceDetailLoading] = useState(false);
  const navigate = useNavigate();
  const useClasses = useStyles();

  const isEditAllowed = getPermissions(permissions.user, "is_edit");

  const state = useLocation();
  const classes = useStyles();

  const ServiceDetails = state?.state?.serviceData;
  const ServiceDetailsLoading = state?.state?.loading;


  const columns = [
    {
      id: "user_name",
      numeric: false,
      disablePadding: true,
      label: "User Name",
    },
    {
      id: "mobile_number",
      numeric: true,
      disablePadding: false,
      label: "Mobile Number",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: "Email",
    },
    { id: "status", numeric: true, disablePadding: false, label: "Status" }
  ].filter((el) => el !== null);

  const tableTitle = ServiceDetails?.service_name;

  const handleChangeRow = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };



  const getServicedetails = async (serviceId, purchasedAt) => {
    setFetchServiceDetailLoading(true)
    try {
      const res = await axios.get(
        `${ApiURL}service/service-by-id/${serviceId}`,
        {
          params: { purchased_at: purchasedAt },
        }
      );
      setFetchServiceDetailLoading(false)
      setServicedetails(res?.data?.data);
    } catch (error) {
      setFetchServiceDetailLoading(false)
      console.error("Error fetching service details data:", error);
      return {};
    }
  };

  useEffect(() => {
    getServicedetails(ServiceDetails?._id);
  }, [page]);

  const rows =
    servicedetails && Array.isArray(servicedetails)
      ? servicedetails.flatMap((service) =>
        service?.purchased_by_users?.map((item) => ({
          _id: item?._id,
          user_name: item?.user_name || "-",
          mobile_number: item?.mobile_number || "-",
          email: item?.email || "-",
          status: item?.service_status,
        })
        )
      )
      : [];


  const handleChipClick = (data) => {
    setStatusModal(data);
  };

  const handleModalClose = () => {
    setStatusModal(false);
    setStatusUpdateLoading(false);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelected = rows.map((n) => n.id);
      // setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  //filter
  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    getServicedetails(ServiceDetails?._id);

    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilterDatas((prevFilterDatas) => ({
      ...prevFilterDatas,
      [name]: value,
    }));
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const purchased_at = filterDatas?.purchased_at;
    setPage(1);
    getServicedetails(ServiceDetails?._id, purchased_at);
    closeFilterDrawer1();
  };


  const handleStatusUpdate = async (e) => {
    e.preventDefault();
    setStatusUpdateLoading(true);
    try {
      const serviceStatus = e.target[0].value;
      const payload = {
        service_id: ServiceDetails._id,
        service_status: serviceStatus,
      };

      const res = await api.put(
        `${ApiURL}user/update/${statusModal._id}`,
        payload
      );
      if (res?.status) {
        snackBar(res?.message, res?.status);
        await getServicedetails(ServiceDetails._id)
        handleModalClose();
      }
    } catch (err) {
      await getServicedetails(ServiceDetails._id)
      handleModalClose();
    } finally {
      handleModalClose();
    }
  };

  return (
    <div>
      <Box sx={{ backgroundColor: "#f1f5f9" }}>
        <div style={{ paddingTop: "7px", paddingLeft: "18px" }}>
          <ArrowCircleLeftOutlinedIcon
            fontSize="large"
            sx={{ cursor: "pointer", backgroundColor: "#f1f5f9" }}
            onClick={() => navigate("/admin-services")}
          />
        </div>
        <PageHeader
          title={ServiceDetails?.service_name}
          isShowAdd={false}
          onFilterClick={openFilterDrawer}
        />
        {fetchServiceDetailLoading ? (
          <Loader />
        ) : (
          <EnhancedTable
            key={5}
            columns={columns}
            rows={rows}
            order={order}
            orderBy={orderBy}
            selected={selected}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            title={tableTitle}
            tableList={rows}
            handleChipClick={isEditAllowed ? handleChipClick : () => { }}
          />
        )}
        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
              gap: 2,
              width: { sm: "100%", md: "50%" },
            }}
            display="flex"
            alignItems="center"
          >
            <Box>
              {/* {" "}
                Showing {membershipData?.currentPage} of{" "}
                {membershipData?.totalPages} Pages */}
            </Box>
            <Box display="flex" sx={{ gap: 1 }} alignItems="center">
              <Typography>Records per page</Typography>

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  id="limites"
                  name="limites"
                  value={rowsPerPage}
                  onChange={handleChangeRow}
                  displayEmpty
                  sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {RecordPerPage?.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name || item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Pagination
            count={servicedetails && servicedetails?.totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
            <Box className={classes.mainFilterBox}>
              <Stack className={classes.stackFilter}>
                <Typography className={classes.typographyFilter}>
                  Filter
                </Typography>

                <CloseIcon
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>
              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
              </Box>

              <div>
                <Box>
                  <div style={{ marginTop: "7px" }}>
                    <Box
                      sx={{
                        marginTop: "7px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        type="date"
                        id="outlined-required"
                        name="purchased_at"
                        label="Purchased Date"
                        value={filterDatas?.purchased_at}
                        onChange={handleChange}
                        sx={{ marginBottom: "10px" }}
                      />
                    </Box>
                  </div>
                </Box>
                <Stack className={classes.buttonFilter}>
                  <Button
                    color="secondary"
                    size="large"
                    variant="contained"
                    className="cancel-filter"
                    onClick={resetFormFilter}
                  >
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    className="order-filter-button"
                    type="submit"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Filter
                  </Button>
                </Stack>
              </div>
            </Box>
          </form>
        </Drawer>
      </Box>
      <Dialog
        open={statusModal}
        fullWidth={false}
        onClose={false}
        disableBackdropClick={true} // Prevent closing when clicking outside
        disableEscapeKeyDown={true}
      >
        <div
          className="mui-dialog-box-popup"
          style={{ backgroundColor: "#fff", color: "#000" }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ color: "#000 !important" }}>
              Update Status
            </Typography>

            <IconButton
              sx={{
                color: "black",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#153e80",
                },
                "&:focus": {
                  backgroundColor: "white",
                  color: "#153e80",
                  border: "none",
                },
              }}
            >
              <CloseIcon onClick={handleModalClose} />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ minWidth: "400px" }}>
            <form onSubmit={handleStatusUpdate}>
              <div style={{ marginTop: "5px" }}>
                <SelectBox
                  type="text"
                  id="approval_status"
                  name="approval_status"
                  label="Status"
                  ClassName="range-select-filter"
                  defaultValue={statusModal?.status || "pending"}
                  options={statusOptions}
                />
              </div>
              <DialogActions sx={{ justifyContent: "center", display: "flex" }}>
                <Button
                  type="submit"
                  disabled={statusUpdateLoading}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    border: "0",
                    ":hover": { bgcolor: "#1976d2", border: "0" },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    color: "#fff",
                    textTransform: "none",
                    minWidth: "100px",
                  }}
                >
                  Update
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default ServiceDetails;
