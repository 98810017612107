import { api } from "../config/api";


export const ContactUsApi = async (body) => {
  try {
    const response = await api.post("comment/post-comment", body);
    const responseData = response;
    return responseData;
  } catch (error) {
    throw error;
  }
};