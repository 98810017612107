import React, { useEffect, useState } from "react";
import { Menu, Sidebar, MenuItem, SubMenu } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { useSidebarContext } from "./sidebarContext";
import { tokens } from "../../theme";
import { useTheme, Box, Typography, IconButton, Tooltip } from "@mui/material";
import { MenuOutlinedIcon } from "../../assets/icons/Icons";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo-home.svg";
import { setSelectedSidebar } from "../../common-control";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import RoofingIcon from "@mui/icons-material/Roofing";
import RateReviewIcon from "@mui/icons-material/RateReview";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import EventIcon from "@mui/icons-material/Event";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PeopleIcon from "@mui/icons-material/People";
import PaymentsIcon from "@mui/icons-material/Payments";
import PlaceIcon from "@mui/icons-material/Place";
import ChatIcon from "@mui/icons-material/Chat";
import BusinessIcon from "@mui/icons-material/Business";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SendIcon from "@mui/icons-material/Send";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import ContactsIcon from '@mui/icons-material/Contacts';

const Item = ({ id, title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Navigate = useNavigate();

  const userDataString = localStorage.getItem("user_data");
  const userData = JSON.parse(userDataString);
  const approval_status =
    userData?.user?.approval_status ||
    userData?.admin?.approval_status ||
    "approved";

  const handleItemClick = () => {
    setSelected(id);
    Navigate(to);
  };

  const isDisabled = approval_status !== "approved";

  return (
    <Tooltip title={isDisabled ? "Not allow without approval" : ""}>
      <MenuItem
        active={selected === id}
        onClick={isDisabled ? undefined : handleItemClick}
        icon={icon}
        routerLink={<Link to={to} />}
      >
        <Typography>{title}</Typography>
      </MenuItem>
    </Tooltip>
  );
};

const MyProSidebar = () => {
  // const userType = useSelector((state) => state?.auth);
  const userType = "admin";

  const userDataStrings = localStorage.getItem("user_data");
  const userDatas = JSON.parse(userDataStrings);

  const sidebar = {
    dashboard: {
      tital: "Dashboard",
      path: "/admin/dashboard",
      icon: <GridViewIcon />,
    },
    packages: {
      tital: "Packages",
      path: "/admin-packages",
      icon: <CardMembershipOutlinedIcon />,
    },

    manage_subscription: {
      tital: "Manage Subscription",
      path: "/admin-subscription",
      icon: <SubscriptionsOutlinedIcon />,
    },
    manage_user: {
      tital: "Manage User",
      path: "/admin-user",
      icon: <ManageAccountsOutlinedIcon />,
    },
    lead: {
      tital: "Lead",
      path: "/admin-lead",
      icon: <LeaderboardIcon />,
    },
    user_role: {
      tital: "User Role & Permission",
      path: "/admin-role-permission",
      icon: <BadgeOutlinedIcon />,
    },
    chapter: {
      tital: "Chapter",
      path: "/admin-chapter",
      icon: <GroupsOutlinedIcon />,
    },
    blog_post: {
      tital: "Blog Post",
      path: "/admin-blog",
      icon: <ChatIcon />,
    },
    banner: {
      tital: "Banner Mobile",
      path: "/admin-banner",
      icon: <ViewCarouselIcon />,
    },
    partner_request: {
      tital: "Partner Requests",
      path: "/admin-partner-request",
      icon: <GroupAddOutlinedIcon />,
    },
    memberships: {
      tital: "Memberships",
      path: "/admin-memberships",
      icon: <SubscriptionsOutlinedIcon />,
    },
    events: {
      tital: "Events",
      path: "/admin-events",
      icon: <EventIcon />,
    },

    Service: {
      tital: "Services",
      path: "/admin-services",
      icon: <RoofingIcon />,
    },

    Business_Category: {
      tital: "Business Category",
      path: "/admin/business-category",
      icon: <BusinessIcon />,
    },
    ClientReview: {
      tital: "Client Review",
      path: "/admin-clientreview",
      icon: <RateReviewIcon />,
    },
    commentlist: {
      tital: "Comment List",
      path: "/commentlist",
      icon: <ContactsIcon />,
    },
    wallet: {
      tital: "Wallet Transactions",
      path: "/admin-wallet",
      icon: <AccountBalanceWalletIcon />,
    },
    transactions: {
      tital: "Transactions",
      path: "/admin-transactions",
      icon: <SwapHorizIcon />,
    },
    Notification: {
      tital: "Send Notifications",
      path: "/admin-notification",
      icon: <SendIcon />,
    },
    Withdraw: {
      tital: "Withdraw Request",
      path: "/admin-withdraw ",
      icon: <PaymentsIcon />,
    },
    referral: {
      tital: "Referral",
      path: "/admin-referral ",
      icon: <PeopleIcon />,
    },
    cms_content: {
      tital: "CMS content",
      path: "/admin-cms-content",
      hasSubmenu: true,
      submenuItems: [
        !!getPermissions(permissions.privacy_policy, "is_read")
          ? { tital: "Privacy Policy", path: "/admin-privacy-policy" }
          : null,
        !!getPermissions(permissions.terms_codition, "is_read")
          ? { tital: "Terms & Conditions", path: "/admin-terms-and-conditions" }
          : null,
        !!getPermissions(permissions.faqs, "is_read")
          ? { tital: "FAQs", path: "/admin-faqs" }
          : null,
        !!getPermissions(permissions.faqs, "is_read")
          ? { tital: "FAQs Category", path: "/admin-faqs-category" }
          : null,
        !!getPermissions(permissions.trusted_by_About_us, "is_read")
          ? { tital: "About us", path: "/admin-about-us" }
          : null,
        // !!getPermissions(permissions.page_banners, "is_read")
        //   ? { tital: "Pages", path: "/admin-pages" }
        //   : null,
        !!getPermissions(permissions.page_banners, "is_read")
          ? { tital: "Page Banners", path: "/admin-page-banners" }
          : null,
      ].filter((el) => el !== null),
      icon: <ViewQuiltOutlinedIcon />,
    },
    setPermit_countries: {
      tital: "Permitted Locations",
      path: "/admin-permitted-location",
      hasSubmenu: true,
      submenuItems: [
        !!getPermissions(permissions.country, "is_read")
          ? { tital: "Country", path: "/admin-countries" }
          : null,
        !!getPermissions(permissions.state, "is_read")
          ? { tital: "State", path: "/admin-states" }
          : null,
        !!getPermissions(permissions.city, "is_read")
          ? { tital: "City", path: "/admin-cities" }
          : null,
        !!getPermissions(permissions.postalcode, "is_read")
          ? { tital: "Postal Code", path: "/admin-postal-codes" }
          : null,
      ].filter((el) => el !== null),
      icon: <PlaceIcon />,
    },
  };

  const DataForSidebar = {
    user: [sidebar.dashboard, sidebar.packages],
    admin: [
      sidebar.dashboard,
      !!getPermissions(permissions.membership, "is_read") && userDatas?.user?.user_role?.role !== "Community Member"
        ? sidebar.memberships
        : null,
      !!getPermissions(permissions.postalcode, "is_read") ||
        !!getPermissions(permissions.city, "is_read") ||
        !!getPermissions(permissions.state, "is_read") ||
        !!getPermissions(permissions.country, "is_read")
        ? sidebar.setPermit_countries
        : null,
      !!getPermissions(permissions.user, "is_read")
        ? sidebar.manage_user
        : null,
      userDatas?.user?.user_role?.role === "Community Super Admin"
        ? sidebar.lead
        : null,
      !!getPermissions(permissions.role, "is_read") ? sidebar.user_role : null,
      !!getPermissions(permissions.event, "is_read") ? sidebar.events : null,
      !!getPermissions(permissions.blog, "is_read") ? sidebar.blog_post : null,
      !!getPermissions(permissions.banner, "is_read") ? sidebar.banner : null,
      !!getPermissions(permissions.Service, "is_read") ? sidebar.Service : null,
      !!getPermissions(permissions.ClientReview, "is_read")
        ? sidebar.ClientReview
        : null,
      !!getPermissions(permissions.chapter, "is_read") ? sidebar.chapter : null,
      !!getPermissions(permissions.partnerRequest, "is_read")
        ? sidebar.partner_request
        : null,
      !!getPermissions(permissions.business_category, "is_read")
        ? sidebar.Business_Category
        : null,
      !!getPermissions(permissions.wallet, "is_read") ? sidebar.wallet : null,
      !!getPermissions(permissions.transaction, "is_read")
        ? sidebar.transactions
        : null,
      !!getPermissions(permissions.withdraw, "is_read")
        ? sidebar.Withdraw
        : null,
      !!getPermissions(permissions.referral, "is_read")
        ? sidebar.referral
        : null,
      !!getPermissions(permissions.privacy_policy, "is_read") ||
        !!getPermissions(permissions.terms_codition, "is_read") ||
        !!getPermissions(permissions.faqs, "is_read") ||
        !!getPermissions(permissions.trusted_by_About_us, "is_read") ||
        !!getPermissions(permissions.page_banners, "is_read")
        ? // !!getPermissions(permissions.country, "is_read")
        sidebar.cms_content
        : null,
        userDatas.user.user_role.role === "Community Super Admin" ? sidebar.commentlist : null,
      !!getPermissions(permissions.notification, "is_read")
        ? sidebar.Notification
        : null,
      // !!getPermissions(permissions.postalcode, "is_read") ||
      // !!getPermissions(permissions.city, "is_read") ||
      // !!getPermissions(permissions.state, "is_read") ||
      // !!getPermissions(permissions.country, "is_read")
      // : null,
      // !!getPermissions(permissions.transaction, "is_read")
      // ? sidebar.Withdraw
      // : null,
      // Withdraw
    ].filter((el) => el !== null),
    // admin: [sidebar.dashboard, sidebar.packages, sidebar.memberships, sidebar.setPermit_countries, sidebar.manage_user, sidebar.user_role, sidebar.blog_post, sidebar.chapter, sidebar.partner_request]
  };

  const accessSidebar = DataForSidebar[userType];

  const theme = useTheme();
  const location = useLocation();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState();

  const [activeSubmenu, setActiveSubmenu] = useState({});
  const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();

  const userDataString = localStorage.getItem("user_data");
  const userData = JSON.parse(userDataString);
  const approval_status =
    userData?.user?.approval_status ||
    userData?.admin?.approval_status ||
    "approved";

  useEffect(() => {
    const pathname = window.location.pathname;
    setSelected(setSelectedSidebar(pathname));
  }, [selected, location]);

  const handleSubmenuClick = (data) => {
    if (approval_status === "pending") {
      setActiveSubmenu({
        ...activeSubmenu,
        [data?.path]: !activeSubmenu[data?.path],
      });
    }
  };

  const isSubmenuOpen = (submenu, currentLocation) => {
    setActiveSubmenu(
      submenu.submenuItems.some(
        (item) => currentLocation.pathname === item.path
      )
    );
  };

  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        flexDirection: "column",
        height: !broken && "100vh",
        backgroundColor: "#EDEDED",
        top: 0,
        bottom: 0,
        zIndex: 100,
        "& .sidebar": {
          border: "none",
        },
        "& .ps-sidebar-container": {
          backgroundColor: "#EDEDED !important",
        },
        "& .menu-item": {
          padding: "5px 10px 5px 10px !important",
          // backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
      }}
    >
      <div
        style={{
          position: broken && "fixed",
          display: !broken ? "none" : "flex",
          minHeight: "60px",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={broken ? () => toggleSidebar() : () => collapseSidebar()}
        >
          <MenuOutlinedIcon />
        </IconButton>
        <Link to="/">
          <img src={Logo} alt="" style={{ width: "170px" }} />
        </Link>
      </div>
      <Sidebar
        id="sidebar"
        breakPoint="md"
        rtl={sidebarRTL}
        image={sidebarImage}
        style={{
          scrollBehavior: "auto",
          overflow: "scroll",
        }}
      >
        <Menu closeOnClick={true} iconshape="square">
          <MenuItem
            className="main-icon-menuitem"
            style={{
              margin: "10px 0px 20px 6px",
            }}
          >
            {!collapsed ? (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Link to="/">
                  <img src={Logo} alt="" style={{ width: "170px" }} />
                </Link>

                <IconButton
                  onClick={
                    broken ? () => toggleSidebar() : () => collapseSidebar()
                  }
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Box>
            ) : (
              <IconButton
                onClick={
                  broken ? () => toggleSidebar() : () => collapseSidebar()
                }
              >
                <MenuOutlinedIcon />
              </IconButton>
            )}
          </MenuItem>

          <Box
            display={collapsed ? "none" : "block"}
            padding={collapsed ? undefined : "0% 3%"}
          >
            <div
              className="sidebar-items"
              style={{ display: "flex", flexDirection: "column", gap: "2px" }}
            >
              {accessSidebar &&
                accessSidebar.map((el, i) => {
                  return el.hasSubmenu ? (
                    <SubMenu
                      key={i}
                      id={el.tital}
                      label={el.tital}
                      open={activeSubmenu[el?.path]}
                      onClick={() => handleSubmenuClick(el)}
                      icon={el.icon}
                    >
                      {el.submenuItems.map((data, i) => {
                        return (
                          <MenuItem
                            key={i}
                          >
                            <Item
                              key={data.tital}
                              id={data.tital}
                              title={data.tital}
                              to={data.path}
                              icon={data.icon}
                              selected={selected}
                              setSelected={setSelected}
                            />
                          </MenuItem>
                        );
                      })}
                    </SubMenu>
                  ) : (
                    <Item
                      key={el.tital}
                      id={el.tital}
                      title={el.tital}
                      to={el.path}
                      icon={el.icon}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  );
                })}
            </div>
          </Box>

          <Box
            display={collapsed ? "bolck" : "none"}
            padding={collapsed ? "0% 9%" : "0% 3%"}
            style={{
              listStyleType: collapsed ? "none" : "",
            }}
          >
            {accessSidebar &&
              accessSidebar.map((el, i) => {
                return el.hasSubmenu ? (
                  <SubMenu
                    key={i}
                    id={el.tital}
                    label={el.tital}
                    open={activeSubmenu[el?.path]}
                    onClick={() => handleSubmenuClick(el)}
                    closeOnClick={true}
                    icon={el.icon}
                  >
                    {el.submenuItems.map((data, index) => {
                      return (
                        <MenuItem
                          key={index}
                        >
                          <Item
                            key={data.tital}
                            id={data.tital}
                            title={data.tital}
                            to={data.path}
                            icon={data.icon}
                            selected={selected}
                            setSelected={setSelected}
                          />
                        </MenuItem>
                      );
                    })}
                  </SubMenu>
                ) : (
                  <Item
                    key={el.tital}
                    id={el.tital}
                    title={el.tital}
                    to={el.path}
                    icon={el.icon}
                    selected={selected}
                    setSelected={setSelected}
                  />
                );
              })}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
