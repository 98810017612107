import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Container, Grid, Link, Paper } from "@mui/material";
import Image1 from "../../web/assets/image/Be_Member/Image1.png";
import Image2 from "../../web/assets/image/Be_Member/Image6.png";
import axios from "axios";
import { base_Url } from "../../web/utils/common";
import { snackBar } from "../../redux/common";
import { useParams } from "react-router-dom";
import NotFound from "../../web/pages/notFound/NotFound";
import { makeStyles } from "@material-ui/core/styles";
import Image4 from "../../web/assets/image/about/about1.png";
import { BUSSINES_CARD_LIVE_URL, LIVE_URL, BUSSINES_CARD_LIVE_URL_FULL } from "../../ApiUrlConstant";

const useStyles = makeStyles((theme) => ({
  othManDiv: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "10px",
  },
  image: {
    objectFit: "cover",
    width: "12%",
    paddingRight: 15,
  },

  imgGall: {
    textAlign: "center",
    margin: "10px auto",
    maxHeight: 400,
    maxWidth: "90%",
    verticalAlign: "text-top",
  },
}));
const BusinessUser = () => {
  const [allGetBusiness, setAllGetBusiness] = useState([]);
  const [error, setError] = useState(false);

  const params = useParams();
  const classes = useStyles();

  const getBusinessDetials = async () => {
    try {
      const res = await axios.get(
        `${base_Url}business/get-business-details/${params?.id}`
      );
      if (res?.data?.status === true) {
        setAllGetBusiness(res?.data?.data);
      } else {
        setError(true);
      }
    } catch (err) {
      snackBar(err?.message || "API call failed");
    }
  };

  const getYouTubeVideoId = (url) => {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    let match;
    while ((match = regex.exec(url)) !== null) {
      if (match.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      if (match[1] === "v") {
        return match[2];
      }
    }
    return null;
  };

  useEffect(() => {
    getBusinessDetials();
  }, []);

  if (error) {
    return <NotFound />;
  }

  const handleButtonClick = () => {
    const linkUrl = "https://mui.com/material-ui/react-card/";
    window.open(linkUrl, "_blank");
  };

  return (
    <>
      <div
        style={{
          background:
            "linear-gradient(317deg, rgba(254,118,120,1) 9%, rgba(21,62,128,1) 100%)",
          height: "100%",
          paddingTop: "25px",
          marginBottom: "-20px",
        }}
      >
        <Box>
          <Card
            sx={{
              width: "400px",
              marginTop: "20px",
              overflow: "hidden",
              margin: "20px auto",
              zIndex: 0,
              background: "linear-gradient(45deg, #ffffff, #FFFFFF)",
              backgroundSize: "100% 100%",
              color: "black",
              padding: " 0px 0px 27px",
              position: "relative",
              borderRadius: "20px",
            }}
          >
            <CardMedia
              sx={{
                height: 140,
                position: "relative",
                backgroundImage: `url(${Image1})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Avatar
                  alt="Avatar"
                  src={allGetBusiness?.profile_picture}
                  sx={{ width: 96, height: 96 }}
                />
              </Box>
            </CardMedia>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                marginTop: "55px",
                wordWrap: "break-word",
              }}
            >
              <Typography
                variant="h6"
                fontSize="23px"
                fontWeight="bold"
                marginBottom="10px"
              >
                {allGetBusiness?.first_name} {allGetBusiness?.last_name}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWight: "600",
                  fontSize: "14px",
                }}
              >
                {allGetBusiness?.business?.business_name}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWight: "600",
                  fontSize: "14px",
                }}
              >
                {allGetBusiness?.email}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWight: "600",
                  fontSize: "14px",
                }}
              >
                {allGetBusiness?.mobile_number}
              </Typography>
            </CardContent>

            <div
              style={{
                padding: "45px 0 28px",
                display: "block",
                textAlign: "center",
              }}
            >
              <a
                href={`tel:${allGetBusiness?.mobile_number}`}
                target="_blank"
                style={{ padding: "0 8px" }}
              >
                <img
                  src="https://digicarda.com/images/call.png"
                  alt="Call"
                  title="Call"
                  style={{ width: "45px", maxWidth: "100%" }}
                />
              </a>
              <a
                href={`https://api.whatsapp.com/send?phone=${allGetBusiness?.mobile_number}&text=Hi,%20${allGetBusiness?.first_name} ${allGetBusiness?.last_name},%20Got%20your%20information%20from%20your%20${BUSSINES_CARD_LIVE_URL_FULL}%20...`}
                target="_blank"
                style={{ padding: "0 8px" }}
              >
                <img
                  src="https://digicarda.com/images/whatsapp.png"
                  alt="Whats App"
                  title="Connect with WhatsApp"
                  style={{ width: "45px", maxWidth: "100%" }}
                />
              </a>
            </div>
          </Card>
        </Box>

        <Box>
          <Card
            sx={{
              width: "400px",
              marginTop: "20px",
              overflow: "hidden",
              margin: "20px auto",
              zIndex: 0,
              background: "linear-gradient(45deg, #ffffff, #FFFFFF)",
              backgroundSize: "100% 100%",
              color: "black",
              padding: " 0px 0px 27px",
              position: "relative",
              borderRadius: "20px",
            }}
          >
            <Box sx={{ backgroundColor: "#ff5252" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  justifyContent: "center",
                  display: "flex",
                  fontWeight: "700",
                }}
              >
                Business Details
              </Typography>
            </Box>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                marginTop: "40px",
              }}
            >
              <Typography variant="h6" fontSize="18px" fontWeight="500">
                <strong>Business Name: </strong>{" "}
                {allGetBusiness?.business?.business_name}
              </Typography>
              <Typography variant="h6" fontSize="18px" fontWeight="500">
                <strong>Email Address: </strong>{" "}
                {allGetBusiness?.business?.business_email}
              </Typography>
              <Typography variant="h6" fontSize="18px" fontWeight="500">
                <strong>Category: </strong>{" "}
                {allGetBusiness?.business?.business_category}
              </Typography>
              <Typography variant="h6" fontSize="18px" fontWeight="500" sx={{
                overflowWrap: "break-word",
                wordBreak: "break-word",
                whiteSpace: "normal",
              }}>
                <strong>Type: </strong> {allGetBusiness?.business?.business_type}
              </Typography>
              <Typography variant="h6" fontSize="18px" fontWeight="500" sx={{
                overflowWrap: "break-word", /* Modern equivalent of word-wrap */
                wordBreak: "break-word", /* Breaks words at arbitrary points if necessary */
                whiteSpace: "normal"
              }}>
                <strong>Address: </strong>
                {allGetBusiness?.address}
              </Typography>
            </CardContent>
          </Card>
        </Box>

        <Box>
          {allGetBusiness?.business?.card_info_links?.length > 0 && (
            <Card
              sx={{
                width: "400px",
                marginTop: "20px",
                overflow: "hidden",
                margin: "20px auto",
                zIndex: 0,
                background: "linear-gradient(45deg, #ffffff, #FFFFFF)",
                backgroundSize: "100% 100%",
                color: "black",
                padding: " 0px 0px 27px",
                position: "relative",
                borderRadius: "20px",
              }}
            >
              <Box sx={{ backgroundColor: "#ff5252" }}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "700",
                  }}
                >
                  Other Links
                </Typography>
              </Box>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10px",
                  gap: "8px",
                }}
              >
                {allGetBusiness.business.card_info_links.map((item) => (
                  <Paper
                    elevation={3}
                    className={classes.othManDiv}
                    key={item.title}
                  >
                    <img
                      src="https://digicarda.com/images/link-icon.png"
                      alt="Link Icon"
                      className={classes.image}
                    />
                    <Box>
                      <a
                        href={item.link}
                        target="_blank"
                        style={{ color: "#141414", textDecoration: "none" }}
                      >
                        {item.title}
                      </a>
                    </Box>
                  </Paper>
                ))}
              </CardContent>
            </Card>
          )}
        </Box>

        <Box>
          {allGetBusiness?.business?.about_us?.length > 0 && (
            <Card
              sx={{
                width: "400px",
                marginTop: "20px",
                overflow: "hidden",
                margin: "20px auto",
                zIndex: 0,
                background: "linear-gradient(45deg, #ffffff, #FFFFFF)",
                backgroundSize: "100% 100%",
                color: "black",
                padding: " 0px 0px 27px",
                position: "relative",
                borderRadius: "20px",
              }}
            >
              <Box sx={{ backgroundColor: "#ff5252" }}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "700",
                  }}
                >
                  About Us
                </Typography>
              </Box>
              {allGetBusiness?.business?.about_us?.map((item) => {
                return (
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      wordWrap: "break-word",
                      // marginTop: "40px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "20px", fontWeight: "700" }}
                    >
                      {item.title}:
                    </Typography>
                    <Typography variant="body1">{item?.link}</Typography>
                  </CardContent>
                );
              })}
            </Card>
          )}
        </Box>

        <Box>
          {allGetBusiness?.business?.youtube_videos?.length > 0 && (
            <Card
              sx={{
                width: "400px",
                marginTop: "20px",
                overflow: "hidden",
                margin: "20px auto",
                zIndex: 0,
                background: "linear-gradient(45deg, #ffffff, #FFFFFF)",
                backgroundSize: "100% 100%",
                color: "black",
                padding: " 0px 0px 27px",
                position: "relative",
                borderRadius: "20px",
              }}
            >
              <Box sx={{ backgroundColor: "#ff5252" }}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "700",
                  }}
                >
                  Youtube Videos
                </Typography>
              </Box>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "40px",
                }}
              >
                <Container maxWidth="xl">
                  {allGetBusiness?.business?.youtube_videos?.map((item) => {
                    const videoId = getYouTubeVideoId(item.link);

                    if (videoId) {
                      return (
                        <div
                          key={item.title}
                          style={{ width: "100%", height: "100%" }}
                        >
                          <p style={{ fontSize: "medium", fontWeight: "700" }}>
                            {item.title}
                          </p>

                          <iframe
                            width="100%"
                            height="100%"
                            src={`https://www.youtube.com/embed/${videoId}`}
                            title={item.title}
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                        </div>
                      );
                    }
                    return null;
                  })}
                </Container>
              </CardContent>
            </Card>
          )}
        </Box>

        <Box>
          {allGetBusiness?.business?.product_services?.length > 0 && (
            <Card
              sx={{
                width: "400px",
                marginTop: "20px",
                overflow: "hidden",
                margin: "20px auto",
                zIndex: 0,
                background: "linear-gradient(45deg, #ffffff, #FFFFFF)",
                backgroundSize: "100% 100%",
                color: "black",
                padding: " 0px 0px 27px",
                position: "relative",
                borderRadius: "20px",
              }}
            >
              <Box sx={{ backgroundColor: "#ff5252" }}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "700",
                  }}
                >
                  Products & Services
                </Typography>
              </Box>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "40px",
                }}
              >
                {allGetBusiness?.business?.product_services?.map((item) => {
                  return (
                    <Container maxWidth="xl">
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          {/* CardMedia */}
                          <Box sx={{ marginTop: "10px" }}>
                            <CardMedia
                              sx={{ height: 140 }}
                              image={item?.image}
                              title="green iguana"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "-30px",
                            }}
                          >
                            <Button
                              variant="contained"
                              // color="primary"
                              sx={{
                                marginTop: "20px",
                                backgroundColor: "#ff5252",
                              }}
                              onClick={() => {
                                const linkUrl = item?.link;
                                window.open(linkUrl, "_blank");
                              }}
                            >
                              Enquire Now
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Container>
                  );
                })}
              </CardContent>
            </Card>
          )}
        </Box>

        <Box>
          {allGetBusiness?.business?.image_gallery?.length > 0 && (
            <Card
              sx={{
                width: "400px",
                marginTop: "20px",
                overflow: "hidden",
                margin: "20px auto",
                zIndex: 0,
                background: "linear-gradient(45deg, #ffffff, #FFFFFF)",
                backgroundSize: "100% 100%",
                color: "black",
                padding: " 0px 0px 27px",
                position: "relative",
                borderRadius: "20px",
                maxHeight: "400px",
                overflowY: "auto",
              }}
            >
              <Box sx={{ backgroundColor: "#ff5252" }}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "700",
                  }}
                >
                  Image Gallery
                </Typography>
              </Box>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "40px",
                }}
              >
                {allGetBusiness?.business?.image_gallery?.map((item) => {
                  return (
                    <Box className={classes.imgGall} sx={{ marginTop: "10px" }}>
                      <img src={item} alt="Gallery Image" />
                    </Box>
                  );
                })}
              </CardContent>
            </Card>
          )}
        </Box>
      </div>
    </>
  );
};

export default BusinessUser;
