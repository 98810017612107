import React, { useEffect, useState } from "react";
import { CloseIcon } from "../../assets/icons/Icons";
import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Loader from "../../web/components/loader/Loader";
import { ApiURL, snackBar } from "../../redux/common";
import { api } from "../../web/config/api";
import { makeStyles } from "@material-ui/core/styles";
import NotDataFound from "../../web/pages/notFound/NotDataFound";
import NoUserFound from "../../web/pages/notFound/NoUserFound";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { Link, useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  userContainer: {
    textAlign: "center",
    padding: "16px", // Replace with your desired padding value
  },
  myOwnAvatar: {
    width: "66px !important",
    height: "66px !important",
    margin: "auto",
  },
  avatar: {
    width: "56px", // Replace with your desired avatar size
    height: "56px", // Replace with your desired avatar size
    margin: "auto",
  },
  showAllButton: {
    marginTop: "8px", // Replace with your desired margin value
    borderRadius: "30px !important", // Adjust the border-radius for a rounded button
    display: "block", // Display as block to take the full width
    marginLeft: "auto", // Center the button
    marginRight: "auto", // Center the button
    color: "#F07579 !important",
    borderColor: "#F07579 !important",
    background: "white !important",
  },
  tooltip: {
    fontSize: "12px",
    padding: "8px",
  },
});

function showFirstLetter(data) {
  return data
    ? data
        ?.split(" ")
        ?.map((data) => data[0])
        ?.join("")
        ?.toUpperCase()
    : "";
}

const UserInfoListingModal = ({ userid, handleClose, open, userdata }) => {
  const [userId, setUserId] = useState("");
  const [myUser, setMyUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [userHistory, setUserHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const navigate = useNavigate();

  const classes = useStyles();

  const userHierarchyChange = async () => {
    try {
      setLoading(true);
      const res = await api.get(
        `${ApiURL}user/get-hierarchy-users/${userId || userid}`
      );
      if (res?.status) {
        const dataFlat = res?.data.map((data) => data?.users || []).flat();
        setData(dataFlat);
      } else {
        setData([]);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigatePrevious = () => {
    if (historyIndex > 0) {
      const newIndex = historyIndex - 1;
      setMyUser(userHistory[newIndex]);
      setUserId(userHistory[newIndex]?._id);
      setHistoryIndex(newIndex);
    }
  };

  useEffect(() => {
    if (userHistory.length === 0) {
      setUserHistory([myUser]);
      setHistoryIndex(0);
    } else {
      setUserHistory([...userHistory.slice(0, historyIndex + 1), myUser]);
      setHistoryIndex(historyIndex + 1);
    }
  }, [myUser]);

  useEffect(() => {
    if (userid) {
      userHierarchyChange();
    }
  }, [userid, userId]);

  useEffect(() => {
    setMyUser({});
    setUserId("");
    setUserHistory([]);
    setHistoryIndex(-1);
  }, [open]);

  useEffect(() => {
    setMyUser(userdata);
  }, [userdata]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setUserId("");
        setMyUser({});
        handleClose();
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: historyIndex > 0 ? "space-between" : "end",
          alignItems: "center",
        }}
      >
        {historyIndex > 0 ? (
          <IconButton onClick={handleNavigatePrevious}>
            <ArrowCircleLeftOutlinedIcon />
          </IconButton>
        ) : null}
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Grid container spacing={2} className={classes.userContainer}>
        <Grid
          xs={12}
          sm={12}
          md={12}
          paddingBottom={"10px"}
          sx={{ cursor: "pointer" }}
        >
          <Tooltip
            title={
              <span className={classes.tooltip}>
                {showFirstLetter(myUser.role || userdata?.role)}
              </span>
            }
            arrow
            placement="top"
            open // Default open
            componentsProps={{
              arrow: {
                sx: {
                  color: "#1C3E80",
                },
              },
              tooltip: {
                sx: {
                  bgcolor: "#1C3E80",
                  color: "white",
                },
              },
            }}
            onClick={() => {
              navigate("/user-details", {
                state: { id: myUser?._id || userdata?._id },
              });
            }}
          >
            <Avatar
              alt={myUser?.user_name}
              src={myUser.profile_picture}
              className={classes.myOwnAvatar}
            />

            <Typography variant="h5">
              {myUser.user_name || userdata?.user_name}
            </Typography>
          </Tooltip>
        </Grid>

        <Grid
          xs={12}
          sm={12}
          md={12}
          paddingBottom={"10px"}
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: "3em",
          }}
        >
          {loading ? (
            <Loader />
          ) : data.length > 0 ? (
            data.map((user) => {
              return (
                <>
                  <Grid item key={user._id} xs={4} sm={3} md={2}>
                    <Avatar
                      alt={user.user_name}
                      src={user.profile_picture}
                      className={classes.avatar}
                      onClick={() => {
                        setMyUser(user);
                        setUserId(user?._id);
                      }}
                    />
                    <Typography variant="body2">{user.user_name}</Typography>
                  </Grid>
                </>
              );
            })
          ) : (
            <NoUserFound />
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default UserInfoListingModal;
