import axios from "axios";
import { ApiURL, snackBar } from "../common";

const axiosApiCall = () =>
  axios.create({
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });

  export const allServices = async(dispatch) => {
    try{
      dispatch({
        type: 'GET_SERVICES',
        payload: true,
      });
      await axios.get(`${ApiURL}service/all-service`)
      .then((res)=> {
        if (res.data.status && res.data.data) {
          dispatch({
            type: "SERVICE_SUCCESS",
            payload: res.data.data,
          });
          dispatch({
            type: "GET_SERVICE_LOADING",
            payload: false,
          });
        } else {
          dispatch({
            type: "SERVICE_FAIL",
            payload: [],
          });
        }
      })
    }catch(err) {};
    return;
  };

export const getAllService =
  (page, service_name, rowsPerPage) => async (dispatch) => {
    try {
      dispatch({
        type: "GET_SERVICE_LOADING",
        payload: true,
      });

      await axios
        .get(
          `${ApiURL}service/all-service?limit=${rowsPerPage}&page=${page}&service_name=${service_name}`
        )
        .then((res) => {
          if (res.data.status && res.data.data) {
            dispatch({
              type: "SERVICE_SUCCESS",
              payload: res.data.data,
            });
            dispatch({
              type: "GET_SERVICE_LOADING",
              payload: false,
            });
          } else {
            dispatch({
              type: "SERVICE_FAIL",
              payload: [],
            });
          }
        });
    } catch (error) {}
    return;
  };

export const createService = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_SERVICE_LOADING",
      payload: true,
    });
    await axiosApiCall()
      .post(`${ApiURL}service/create-service`, data)
      .then((res) => {
        if (res?.data?.status) {
          dispatch({
            type: "CREATE_SERVICE_SUCCESS",
            payload: res.data.data,
          });
          dispatch({
            type: "CREATE_SERVICE_LOADING",
            payload: false,
          });
          snackBar(res?.data?.message, res.data.status);
          return res;
        } else {
          dispatch({
            type: "CREATE_SERVICE_LOADING",
            payload: false,
          });
          dispatch({
            type: "CREATE_SERVICE_FAIL",
            payload: [],
          });
        }
      });
  } catch (error) {
    dispatch({
      type: "CREATE_SERVICE_LOADING",
      payload: false,
    });
    // snackBar(error?.response?.data?.message, error?.response?.data?.status);
    console.error(error);
  } finally {
    dispatch({
      type: "CREATE_SERVICE_LOADING",
      payload: false,
    });
    return true;
  }
};

export const updateService = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_SERVICE_LOADING",
      payload: true,
    });
    const res = await axiosApiCall()
      .put(`${ApiURL}service/update/${id}`, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: "UPDATE_SERVICE_SUCCESS",
            payload: res.data.data,
          });
          dispatch({
            type: "UPDATE_SERVICE_LOADING",
            payload: false,
          });
          snackBar(res?.data?.message, res.data.status);
          return res;
        } else {
          dispatch({
            type: "UPDATE_SERVICE_LOADING",
            payload: false,
          });
          dispatch({
            type: "UPDATE_SERVICE_FAIL",
            payload: [],
          });
        }
      });
    return res;
  } catch (error) {
    dispatch({
      type: "UPDATE_SERVICE_LOADING",
      payload: false,
    });
    // snackBar(error?.response?.data?.message, error?.response?.data?.status);
    console.error(error);
  }
};

export const deleteService = (id, handleCloseDelete) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_SERVICE_LOADING",
      payload: true,
    });
    await axios
      .delete(`${ApiURL}service/delete/${id}`, {
        header: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: "DELETE_SERVICE_SUCCESS",
            payload: res.data.data,
          });
          dispatch({
            type: "DELETE_SERVICE_LOADING",
            payload: false,
          });
          snackBar(res?.data?.message, res.data.status);
          return res;
        } else {
          dispatch({
            type: "DELETE_SERVICE_LOADING",
            payload: false,
          });
          snackBar(res?.data?.message, res.data.status);
        }
      });
  } catch (error) {
    snackBar(error?.response?.data?.message, error?.response?.data?.status);
    console.error(error);
  }
};

export const getServiceById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SERVICE_BY_ID_LOADING",
      payload: true,
    });

    await axios.get(`${ApiURL}service/service-by-id/${id}`).then((res) => {
      if (res.data.status && res.data.data) {
        dispatch({
          type: "GET_SERVICE_BY_ID_SUCCESS",
          payload: res.data.data,
        });
        dispatch({
          type: "GET_SERVICE_BY_ID_LOADING",
          payload: false,
        });
      } else {
        dispatch({
          type: "GET_SERVICE_BY_ID_FAIL",
          payload: [],
        });
      }
    });
  } catch (error) {
    dispatch({
      type: "GET_SERVICE_BY_ID_LOADING",
      payload: false,
    });
    // snackBar(error?.response?.data?.message, error?.response?.data?.status);
    console.error(error);
  } finally {
    dispatch({
      type: "GET_SERVICE_BY_ID_LOADING",
      payload: false,
    });
    return true;
  }
};

/////////////////////    Schedule Service Requests   /////////////////////
/////////////////////////////////////////////////////////////////////////

export const getAllScheduleServiceRequests =
  (rowsPerPage, searchParams, page) => async (dispatch) => {
    const {
      user_email,
      user_mobile_number,
      service_name,
      service_number,
      start_date,
      end_date,
    } = searchParams;

    const startDate = start_date ? start_date : "";
    const endDate = end_date ? end_date : "";
 
    try {
      dispatch({
        type: "SCHEDULE_SERVICE_LOADING",
        payload: true,
      });
      await axios
        .get(
          `${ApiURL}service/all-schedule-services?start_date=${startDate}&end_date=${endDate}&limit=${rowsPerPage}&page=${page}&service_name=${
            service_name || ""
          }&service_number=${service_number || ""}&user_email=${
            user_email || ""
          }&user_mobile_number=${user_mobile_number || ""}`
        )
        .then((res) => {
          if (res.data.data.status) {
            dispatch({
              type: "SCHEDULE_SERVICE_SUCCESS",
              payload: res.data.data,
            });
          } else {
            dispatch({
              type: "SCHEDULE_SERVICE_FAIL",
              payload: res.data.data,
            });
          }
        });
    } catch (error) {
      dispatch({
        type: "SCHEDULE_SERVICE_FAIL",
        payload: error,
      });
    }
  };

export const addScheduleService = (data, closeModel) => async (dispatch) => {
  try {
    dispatch({
      type: "ADD_SCHEDULE_SERVICE_LOADING",
      payload: true,
    });

    await axios.post(`${ApiURL}service/schedule-service`, data).then((res) => {
      if (res.data.status) {
        dispatch({
          type: "ADD_SCHEDULE_SERVICE_LOADING",
          payload: false,
        });
        snackBar(res?.data?.message, res.data.status);
        closeModel();
      } else {
        dispatch({
          type: "ADD_SCHEDULE_SERVICE_FAIL",
          payload: res.data.data,
        });
        snackBar(res?.data?.message, res.data.status);
      }
    });
  } catch (error) {
    dispatch({
      type: "ADD_SCHEDULE_SERVICE_FAIL",
      payload: error,
    });
    snackBar(error, false);
  }
};

export const editScheduleServiceById =
  (id, data, closeModel) => async (dispatch) => {
    try {
      dispatch({
        type: "EDIT_SCHEDULE_SERVICE_LOADING",
        payload: true,
      });

      await axios
        .put(`${ApiURL}service/update-schedule-service/${id}`, data)
        .then((res) => {
          if (res.data.status) {
            dispatch({
              type: "EDIT_SCHEDULE_SERVICE_LOADING",
              payload: false,
            });
            snackBar(res?.data?.message, res.data.status);
            closeModel();
          } else {
            dispatch({
              type: "EDIT_SCHEDULE_SERVICE_FAIL",
              payload: res.data.data,
            });
            snackBar(res?.data?.message, res.data.status);
          }
        });
    } catch (error) {
      dispatch({
        type: "EDIT_SCHEDULE_SERVICE_FAIL",
        payload: error,
      });
      snackBar(error, false);
    }
  };

export const deleteScheduleServiceById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_SCHEDULE_SERVICE_LOADING",
      payload: true,
    });

    await axios
      .delete(`${ApiURL}service/delete-schedule-service/${id}`)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: "DELETE_SCHEDULE_SERVICE_LOADING",
            payload: false,
          });
          snackBar(res?.data?.message, res.data.status);
        } else {
          dispatch({
            type: "DELETE_SCHEDULE_SERVICE_FAIL",
            payload: res.data.data,
          });
          snackBar(res?.data?.message, res.data.status);
        }
      });
  } catch (error) {
    dispatch({
      type: "DELETE_SCHEDULE_SERVICE_FAIL",
      payload: error,
    });
    snackBar(error, false);
  }
};
