import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { GetAllEvent } from "../../redux/eventAction";
import Loader from "../../components/loader/Loader";
import NotDataFound from "../notFound/NotDataFound";
import EventCard from "../../components/eventCard/EventCard";
import { Head } from "../commonDesign/Head";
import CommonSlide from "../../components/slides/HomeOurDirectory/CommonSlide";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getAllEvents } from "../../../redux/action/EventAction";

const useStyles = makeStyles((theme) => ({
  pagination: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
      padding: 0,
      margin: 0,
    },
    "& .MuiPaginationItem-root": {
      margin: "0 2px",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#153e80",
      border: "none",
      "&:hover": {
        color: "#153e80",
      },
    },
    "& .MuiPaginationItem-page:hover": {
      color: "#153e80",
    },
  },
}));

const cardPerPage = 9;

const Events = () => {
  const classes = useStyles();
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [allGetEvent, setAllGetEvent] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const dispatch = useDispatch();
  const { eventData, loading, ...rest } = useSelector(
    (state) => state?.EventReducer
  );

  // const getEvent = async () => {
  //   try {
  //     const res = await GetAllEvent(cardPerPage, currentPage, "");
  //     if (res.status) {
  //       const data = res?.data;
  //       if (data && Array.isArray(data.eventData)) {
  //         setAllGetEvent(data);
  //       console.log(data);
  //       } else {
  //         setError(true);
  //       }
  //     } else {
  //       setError(true);
  //     }
  //   } catch (err) {
  //     setError(true);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    // getEvent();
    dispatch(getAllEvents( "", cardPerPage))
  }, [dispatch]);

  // useEffect(() => {
  //   const filtered = allGetEvent?.eventData?.filter((itemEvent) => {
  //       return (
  //         itemEvent.is_active &&
  //         itemEvent?.approval_status !== "rejected" &&
  //         itemEvent?.approval_status !== "pending" &&
  //         itemEvent?.schedule_status !== "cancelled"
  //       );
  //     });
  //   setFilteredEvents(filtered); // Store filtered data in state
  // }, [allGetEvent]); 

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <NotDataFound />;
  }

  const handleCurrentPageChange = (_, val) => {
    setCurrentPage(val);
  };

  // Ensure we are using the correct totalPages
  const tottalEvents = eventData?.events || [];
  const totalPages = Math.ceil(eventData?.count / cardPerPage) || 1;

  const lastEventIndex = currentPage * cardPerPage;
  const firstEventIndex = lastEventIndex - cardPerPage;
  const currentEvent = tottalEvents.slice(firstEventIndex, lastEventIndex );

  return (
    <>
      <Head title="Events" />

      <div id="wrapper-content" className="wrapper-content">
        <div className="responsive">
<div
              style={{
                marginTop: "-60px",
                fontSize: "19px",
                marginBottom: "10px",
              }}
            >
               Showing <b>{currentEvent?.length}</b> of <b>{eventData?.count}</b> Events
               </div>
          
          <div className="services" style={{ marginBottom: "30px" }}>
            {/* {allGetEvent?.eventData && allGetEvent?.eventData?.length > 0 ? (
              allGetEvent?.eventData
                // .filter((itemEvent) => {
                //   return (
                //     itemEvent.is_active &&
                //     itemEvent?.approval_status !== "rejected" &&
                //     itemEvent?.approval_status !== "pending" &&
                //     itemEvent?.schedule_status !== "cancelled"
                //   );
                // })
                .map((item) => (
                  <EventCard key={item._id} item={item} />
                 ))
            ) : (
              <h3 style={{ fontSize: "40px", color: "black" }}>
                No event available
              </h3>
            )}  */}

            {currentEvent?.length && currentEvent?.map((item) => {
              return (
                <EventCard key={item._id} item={item} />
              )
            })}
          </div>

          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              className={classes.pagination}
              shape="rounded"
              page={currentPage}
              onChange={handleCurrentPageChange}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Events;
